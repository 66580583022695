/**
 * SclImage
 *
 * showInfo prop
 * @param {boolean} showInfo - toggles rendering of container for overlay text
 */

// template
const template = `
	<div>
		<figure>
			<v-card>
				<v-img src="https://cdn.vuetifyjs.com/images/parallax/material.jpg" class="scl-image__content">
					<v-row v-if="propApi.showInfo" >
						<v-spacer></v-spacer>
						<v-col class="scl-image__overlay" cols="12">
							<div>{{ $root.dummyWords }}</div>
							<a :href="$root.dummyUrl" target="_top">{{ $root.dummyWords }}</a>
						</v-col>
					</v-row>
				</v-img>
			</v-card>
			<figcaption class="scl-image__caption">{{ $root.dummyWords }}
			<a :href="$root.dummyUrl">{{ $root.dummyWords }}</a>
			</figcaption>
		</figure>
	</div>
`;

// export template
export default template;
