// @vue/component
const component = {
	name: 'SclImageList',
	props: {
		api: {
			type: Object,
			// props type validation
			validator: (obj) => {
				const set = new Set();
				set.add(!!(obj.divider ? typeof obj.divider === 'boolean' : true));
				set.add(!!(obj.rounded ? typeof obj.rounded === 'boolean' : true));
				set.add(!!(obj.verticleAlign ? typeof obj.verticleAlign === 'boolean' : true));
				set.add(!!(obj.maxWidth ? typeof obj.maxWidth === 'number' : true));
				return !set.has(false);
			}
		}
	},
	computed: {
		propApi() {
			// props defaults
			return {
				divider: true,
				rounded: false,
				verticleAlign: false,
				maxWidth: 200,
				...this.api
			};
		}
	},
	template: null
};

// set template and dummy data if development environment variable string
if (process.env.VUE_APP_DEVELOPMENT === 'true') {
	component.template = require(`./${component.name}-template`).default;
}

// css import
require(`./_${component.name}.scss`);

// export component
export default component;
