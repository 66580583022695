/**
 * SclRegionSelector
 */

// template
const template = `
	<div>
		
			<ul class="scl-region-selector__list">
			<li v-for="item in propApi.regions" class="scl-region-selector__list-item">
				<a @click="handleClick(item)">
					<v-img :src="item.img"  :aspect-ratio="4/3" />
					<div class="scl-region-selector__list-item__content">
					{{item.name}}
					<v-icon size="18">$vuetify.icons.faArrowRightReg</v-icon>
					</div>
					
				</a>			
			</li>
			</ul>

	</div>
`;

// export template
export default template;
