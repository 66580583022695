/**
 * SclEventsCalendar
 */

// template
const template = `
	<div class="scl-component scl-events-calendar">
		<v-btn class="scl-events-calendar__link" :class="{'mb-5':$vuetify.breakpoint.xsOnly}" color="primaryDark" href="#" text @click="addToCalendar">Add to calendar</v-btn>
	</div>
`;

// export template
export default template;
