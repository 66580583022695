import blogApi from '@/_scl-fed/services/api/blog';
import mxText from '@/_scl-fed/mixins/global/mxText';
import { mapGetters } from 'vuex';
// @vue/component
const component = {
	name: 'SclArticleListing',
	mixins: [mxText],
	props: {
		categoryRoot: null,
		typeFilter: null,
		isActive: {
			type: Boolean
		},
		api: {
			type: Object,
			// props type validation
			validator: (obj) => {
				const set = new Set();
				set.add(!!(obj.resultsMessage ? typeof obj.resultsMessage === 'string' : true));
				set.add(!!(obj.showMemberDetail ? typeof obj.showMemberDetail === 'boolean' : true));
				set.add(!!(obj.showArticleType ? typeof obj.showArticleType === 'boolean' : true));
				set.add(!!(obj.showCategoryFacets ? typeof obj.showCategoryFacets === 'boolean' : true));
				set.add(!!(obj.showTypeFacets ? typeof obj.showTypeFacets === 'boolean' : true));
				set.add(!!(obj.showRegionFacets ? typeof obj.showRegionFacets === 'boolean' : true));
				return !set.has(false);
			}
		},
		selectedLocation: {
			type: Object,
			default() {
				return {};
			}
		}
	},
	data() {
		return {
			error: {
				message: '',
				messageError: 'Something has gone wrong please try again.',
				messageNoResults: 'Use the tabs above to see more results or try another search term.',
				type: ''
			},
			hasErrored: false,
			isLoading: false,
			pagination: {
				currentPage: 1,
				pageSize: 9
			},
			sorting: {
				sort: 'date-newest'
			},
			facets: [],
			facetFilter: [],
			response: null,
			search: null,
			itemCount: 0,
			items: [],
			selected: [],
			articleTags: {
				tags: [],
				backgroundColor: 'secondary',
				isLabel: false
			},
			facetsSelected: []
		};
	},
	computed: {
		// props defaults
		propApi() {
			return {
				resultsMessage: 'articles and resources',
				showMemberDetail: false,
				showArticleType: false,
				showCategoryFacets: true,
				showTypeFacets: true,
				showRegionFacets: true,
				...this.api
			};
		},
		searchParams() {
			let params = `?pg=${this.pagination.currentPage}&ps=${this.pagination.pageSize}&s=${this.sorting.sort}&cr=${this.categoryRoot}&tf=${this.typeFilter}`;
			if (this.search) {
				params += `&q=${this.search}`;
			}
			if (this.facetFilter && this.facetFilter.length > 0) {
				params += `&f=${encodeURIComponent(this.facetFilter.join('&'))}`;
			}
			return params;
		},
		pageCount() {
			return Math.ceil(this.itemCount / this.pagination.pageSize);
		},
		getResultsMessage() {
			let message = '';
			const pagetotal = Math.ceil(this.pagination.currentPage * this.pagination.pageSize);
			const total = this.itemCount;
			if (this.search !== null) {
				message = `${total} search results for '${this.search}' `;
			} else {
				message = `${Math.ceil(pagetotal - this.pagination.pageSize + 1)} to ${pagetotal > total ? total : pagetotal} of ${total} ${
					this.propApi.resultsMessage
				}`;
			}
			return message;
		},
		showCta() {
			// returns true if the user role is "Member"
			return this.getMemberStatus() !== 'Member' && this.propApi.showMemberDetail;
		}
	},
	created() {
		this.initialFetch();
	},
	methods: {
		...mapGetters(['getMemberStatus']),
		initialFetch() {
			// items have already been loaded
			if (this.items.length > 0) {
				return;
			}

			this.parseUrlParameters();
			this.articleSearch();
		},
		parseUrlParameters() {
			const params = new URLSearchParams(window.location.search.substring(1));
			const pg = Number(params.get('pg'));
			const ps = Number(params.get('ps'));
			this.pagination.currentPage = Number.isNaN(pg) || pg < 1 ? this.pagination.currentPage : pg;
			this.pagination.pageSize = Number.isNaN(ps) || ps < 1 ? this.pagination.pageSize : ps;
			this.sorting.sort = params.get('s') ?? this.sorting.sort;
			this.sorting.by = params.get('sd') ?? this.sorting.by;
			this.search = params.get('q') ?? this.search;
			if (params.has('f')) {
				this.facetFilter = params.get('f').split(/[&|]/);
				const isRegion = this.facetFilter.filter((el) => el.includes('region='));
				if (isRegion.length > 0) {
					const tag = {
						label: isRegion[0].split('=')[1],
						value: isRegion
					};
					this.handleFacetFilter(this.facetFilter, [tag]);
				}
			}
		},
		processError(type, message, error) {
			this.error.type = type;
			this.error.message = message;
			this.hasErrored = true;
			console.error(error);
		},
		articleSearch() {
			this.hasErrored = false;
			// articles have already been requested
			if (this.isLoading) {
				return;
			}
			this.isLoading = true;
			// get articles
			blogApi
				.articleSearch(this.searchParams)
				.then((response) => {
					this.response = response.Result;
					this.items = response.Result.Data;
					this.itemCount = response.Result.TotalResults;
					this.facets = response.Result.Facets;
					this.formatFacets();
					if (response.Result.Facets === 0) {
						this.processError('info', this.error.messageNoResults, 'no facets found');
					}
					if (!this.items) {
						this.processError('info', this.error.messageNoResults, 'no articles found');
					}
				})
				.catch((err) => {
					this.processError('error', this.error.messageError, err);
				})
				.finally(() => {
					window.history.pushState({}, '', this.searchParams);
					this.scrollTop;
					this.isLoading = false;
				});
		},
		highlight(val) {
			const options = {
				targetSpanClass: 'scl-article-search__highlight',
				target: '[class*="scl-article-list-item__title"], [class*="scl-article-list-item__content"]'
			};
			this._mxText_highlight(val, options);
		},
		formatFacets() {
			if (!this.propApi.showCategoryFacets) {
				this.facets = this.facets.filter((categoryFacet) => categoryFacet.Category !== 'categories');
			}
			if (!this.propApi.showTypeFacets) {
				this.facets = this.facets.filter((categoryFacet) => categoryFacet.Category !== 'type');
			}
			if (!this.propApi.showRegionFacets) {
				this.facets = this.facets.filter((categoryFacet) => categoryFacet.Category !== 'region');
			}
			if (this.facets && this.facets.length > 0) {
				this.facets.forEach((facetData) => {
					if (Object.entries(facetData.Values).length > 0) {
						facetData.Values = Object.keys(facetData.Values).map((facet) => {
							const facetValue = {
								label: facet,
								quantity: facetData.Values[facet],
								isSelected: this.facetsSelected.filter((selectedFacet) => selectedFacet.label === facet).length > 0 // set the isSelected based on the collection of facets found
							};
							return facetValue;
						});
					}
				});
			}
		},
		handlePageChange(val) {
			this.pagination.currentPage = val;
			this.articleSearch();
			this.highlight(this.search);
		},
		handlePageSize(val) {
			this.pagination.pageSize = val;
			this.articleSearch();
			this.highlight(this.search);
		},
		handleSearchQuery(val) {
			this.search = val;
			this.pagination.currentPage = 1;
			// Handle search
			if (this.search) {
				this.articleTags.tags.push({ label: this.search, value: this.search, tagType: 'search' });
			} else {
				this.handleTagRemove({ label: '', value: '', tagType: 'search' });
			}
			this.articleSearch();
			this.highlight(val);
		},
		handleSortItems(val) {
			this.sorting.sort = val;
			this.pagination.currentPage = 1;
			this.articleSearch();
			this.highlight(this.search);
		},
		handleFacetFilter(val, tags) {
			this.facetFilter = val;
			this.articleTags.tags = tags;
			this.pagination.currentPage = 1;
			this.handleFacetSelection(tags);
			this.articleSearch();
			this.highlight(this.search);
		},
		handleTagRemove(tag) {
			if (!this.search && tag.tagType === 'search') {
				this.articleTags.tags = this.articleTags.tags.filter((el) => el.tagType !== tag.tagType);
			}
			this.articleTags.tags = this.articleTags.tags.filter((el) => el.label !== tag.label);
			this.facetFilter = this.facetFilter.filter((el) => !el.includes(tag.value));

			// Handle Search query
			if (tag.tagType === 'search') {
				this.search = '';
			}

			this.handleFacetDeselection(tag);
			this.articleSearch();
			this.highlight(this.search);
		},
		handleFacetSelection(tags) {
			this.facetsSelected = [];
			// builds a collection of selected facets stored based on the user selection
			if (tags.length > 0) {
				tags.forEach((tag) => {
					const facetLabel = tag.label;
					this.facetsSelected.push({ label: facetLabel, isSelected: true });
				});
			}
		},
		handleFacetDeselection(tag) {
			if (this.facets && this.facets.length > 0) {
				this.facets.forEach((facet) => {
					if (facet.Values.length > 0) {
						facet.Values.forEach((facetValue) => {
							if (facetValue.label === tag.label) {
								facetValue.isSelected = false;
							}
						});
					}
				});
			}
			// de-select the facet
			this.facetsSelected = this.facetsSelected.filter((facet) => facet.label !== tag.label);
		},
		scrollTop() {
			window.scrollTo({
				top: 0,
				left: 0,
				behavior: 'smooth'
			});
		},
		clearFilters() {
			this.search = '';
			this.articleTags.tags = [];
			this.facetFilter = [];
			this.facetsSelected = [];
			this.articleSearch();
		}
	},
	template: null
};

// set template and dummy data if development environment variable string
// if (process.env.VUE_APP_DEVELOPMENT === 'true') {
component.template = require(`./${component.name}-template`).default;
// }

// css import
require(`./_${component.name}.scss`);

// export component
export default component;
