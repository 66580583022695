import { mapGetters } from 'vuex';
import commerceApi from '@/_scl-fed/services/api/commerce';
// eslint-disable-next-line import/no-useless-path-segments
import { SclCartNotification } from '../commerce/cart/index';

// @vue/component
const component = {
	name: 'SclKeyInfoProduct',
	components: { SclCartNotification },
	props: {
		productId: {
			type: String,
			default: process.env.NODE_ENV === 'development' ? '1234567' : null
		},
		variantId: {
			type: String,
			default: process.env.NODE_ENV === 'development' ? '1234567' : null
		},
		digitalProductUrl: {
			type: String
		}
	},
	data() {
		return {
			hasErrored: false,
			isLoading: false,
			dialog: false,
			product: null,
			discountPercent: 10
		};
	},
	computed: {
		...mapGetters(['getLoading', 'getToast', 'getMemberStatus']),
		image() {
			return process.env.VUE_APP_ROOT_DOMAIN + this.product.imageUrls[0];
		},
		isMember() {
			return this.getMemberStatus === 'Member';
		},
		membersPrice() {
			return this.returnNumber(this.product.adjustedPrice);
		},
		productHasimage() {
			return this.product.imageUrls.length > 0;
		}
	},
	created() {
		this.initialFetch();
	},
	mounted() {
		this.productGet();
	},
	methods: {
		addToCart() {
			const payload = {
				ProductId: this.productId,
				VariantId: this.variantId,
				Quantity: 1
			};
			this.$store.dispatch('cartApiUpdate', payload);
			this.$emit('emit-cart-notification', true);
		},
		returnNumber(val) {
			return Number(val).toFixed(2);
		},
		initialFetch() {
			// product has already been loaded
			if (this.product) {
				return;
			}
			if (!this.productId) {
				this.hasErrored = true;
				console.error('no product id param');
			}
		},
		productGet() {
			// products have already been requested
			if (this.isLoading) {
				return;
			}
			this.isLoading = true;
			// get products
			commerceApi
				.productGet(this.productId)
				.then((response) => {
					this.product = response.data;
				})
				.catch((error) => {
					this.hasErrored = true;
					console.error(error);
				})
				.finally(() => (this.isLoading = false));
		}
	},
	template: null
};

// set template and dummy data if development environment variable string
// if (process.env.VUE_APP_DEVELOPMENT === 'true') {
component.template = require(`./${component.name}-template`).default;
// }

// css import
require(`./_${component.name}.scss`);

// export component
export default component;
