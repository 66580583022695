import axios from 'axios';
import '@/_scl-fed/config/axios';
// temp dummy data import
// import mockCart from '@/_scl-fed/root/assets/data/cart-response.json';
// import mockCartAdd from '@/_scl-fed/root/assets/data/cart-response-quantity.json';
// import mockPromo from '@/_scl-fed/root/assets/data/cart-response-promo.json';
// import mockPromoError from '@/_scl-fed/root/assets/data/cart-response-promo-error.json';

// https://kapeli.com/cheat_sheets/Axios.docset/Contents/Resources/Documents/index
export default {
	// account
	// --------------------------------------------------

	// address add
	async accountAddressAdd() {
		const response = await axios.post();
		return response.data;
	},

	// address list
	async accountAddressList() {
		const response = await axios.get();
		return response.data;
	},

	// address remove
	async accountAddressRemove() {
		const response = await axios.post();
		return response.data;
	},

	// address update
	async accountAddressUpdate() {
		const response = await axios.post();
		return response.data;
	},

	// create
	async accountCreate() {
		const response = await axios.post();
		return response.data;
	},

	// password
	async accountPassword() {
		const response = await axios.post();
		return response.data;
	},

	// update
	async accountUpdate() {
		const response = await axios.post();
		return response.data;
	},

	// cart
	// --------------------------------------------------

	// get
	async cartGet() {
		const response = await axios.get('/commerce/cart/get');
		// const response = mockCart;
		return response.data;
	},

	// promo
	async cartPromo(payload) {
		const response = await axios.post('/commerce/cart/addpromo', payload);
		return response.data;
	},

	// remove
	async cartRemove(payload) {
		const response = await axios.delete('/commerce/cart/remove', { data: payload });
		return response.data;
	},

	// update
	async cartUpdate(payload) {
		const response = await axios.post('/commerce/cart/update', payload);
		// const response = mockCartAdd;
		return response.data;
	},

	// get client token
	async checkoutGetClientToken() {
		const response = await axios.get('/commerce/checkout/getclienttoken');
		return response.data;
	},

	// checkout
	// --------------------------------------------------

	// billing data
	async checkoutBilling() {
		const response = await axios.get();
		return response.data;
	},

	// delivery data
	async checkoutDelivery() {
		const response = await axios.get();
		return response.data;
	},

	// payment methods
	async checkoutSetPayment(payload) {
		const response = await axios.post('/commerce/checkout/SetPaymentMethods', payload);
		return response.data;
	},

	// shipping get
	async checkoutShippingGet() {
		const response = await axios.get();
		return response.data;
	},

	// shipping post
	async checkoutShippingPost(payload) {
		const response = await axios.post('/commerce/checkout/SetShippingMethods', payload);
		return response.data;
	},

	// shipping methods
	async checkoutGetShippingMethods() {
		const response = await axios.get('/commerce/checkout/GetShippingMethods');
		return response;
	},

	// billing and delivery address methods
	async checkoutGetCustomerAddresses() {
		const response = await axios.get('/commerce/account/address-list');
		return response.data;
	},
	async checkoutAddCustomerAddress(payload) {
		const response = await axios.post('/commerce/account/address-add', payload);
		return response.data;
	},

	// submit
	async checkoutSubmit() {
		const response = await axios.post('/commerce/checkout/SubmitOrder');
		return response.data;
	},

	// order
	// --------------------------------------------------

	// get order
	async orderGet() {
		const response = await axios.get();
		return response.data;
	},

	// get all
	async orderGetAll() {
		const response = await axios.get();
		return response.data;
	},

	// product
	// --------------------------------------------------

	// get product
	async productGet(id) {
		const response = await axios.get(`/commerce/product/get/${id}`);
		return response.data;
	},

	// search products
	async productSearch(params) {
		const fixedParams = new URLSearchParams(params);
		if (fixedParams.has('pg')) {
			fixedParams.set('pg', parseInt(fixedParams.get('pg'), 10) - 1);
		}
		const response = await axios.get(`/commerce/product/search?${fixedParams.toString()}`);
		return response.data;
	},

	async categories(params) {
		const fixedParams = new URLSearchParams(params);
		const response = await axios.get(`/commerce/product/categories?${fixedParams.toString()}`);
		return response.data;
	}
};
