// @vue/component
const component = {
	name: 'SclCategoryAccordion',
	props: {
		api: {
			type: Object,
			// props type validation
			validator: (obj) => {
				const set = new Set();
				set.add(!!(obj.multiple ? typeof obj.multiple === 'boolean' : true));
				return !set.has(false);
			}
		},
		selected: {
			type: String
		}
	},
	data() {
		return {
			panel: null
		};
	},
	computed: {
		// props defaults
		propApi() {
			return {
				multiple: false,
				...this.api
			};
		}
	},
	mounted() {
		this.getSelectedItem(this.$refs.panels.items);
	},
	methods: {
		getSelectedItem(items) {
			try {
				for (let i = 0; i <= items.length; i++) {
					if (items[i].$el.children[0].innerText === this.selected) {
						if (this.propApi.multiple) {
							this.panel = [i];
						} else {
							this.panel = i;
						}
					}
				}
			} catch {
				return false;
			}
		}
	},
	template: null
};

// set template and dummy data if development environment variable string
if (process.env.VUE_APP_DEVELOPMENT === 'true') {
	component.template = require(`./${component.name}-template`).default;
}

// css import
require(`./_${component.name}.scss`);

// export component
export default component;
