// @vue/component
const component = {
	name: 'SclTrainingListItem',
	props: {
		description: {
			type: String
		},
		imageSrc: {
			type: String
		},
		location: {
			type: String
		},
		title: {
			type: String
		},
		type: {
			type: String
		},
		href: {
			type: String
		}
	},
	data() {
		return {};
	},
	computed: {
		image() {
			if (this.imageSrc === null) {
				return;
			}
			return `${process.env.VUE_APP_ROOT_DOMAIN}/${this.imageSrc}`;
		},
		isShortCourse() {
			if (this.type === 'Short Course') {
				return true;
			}
			return false;
		},
		isOnline() {
			if (this.location === 'Online') {
				return true;
			}
			return false;
		},
		url() {
			if (this.href === null) {
				return '#';
			}
			return this.href;
		}
	},
	methods: {
		truncate(val, limit) {
			if (val.length > limit) {
				return `${val.substr(0, limit)}...`;
			}
			return val;
		}
	},
	template: null
};

// set template and dummy data if development environment variable string
// if (process.env.VUE_APP_DEVELOPMENT === 'true') {
component.template = require(`./${component.name}-template`).default;
// }

// css import
require(`./_${component.name}.scss`);

// export component
export default component;
