/**
 * SclAuthLoginForm
 */

// template
const template = `
	<div>
		<h5>Login now</h5>
		<p>Enter your email and password to sign in</p>
		<!-- email -->
		<label class="scl-auth-login-form__label">Email</label>
		<v-text-field
			v-model="form.username"
			autocapitalize="off"
			autocomplete="email"
			autocorrect="off"
			autofocus
			:background-color="inputOptions.backgroundColor"
			:class="inputOptions.class"
			:color="inputOptions.color"
			:disabled="loading"
			:error="getAuthError"
			:error-messages="getAuthError ? errorMessage : null"
			:height="inputOptions.height"
			inputmode="email"
			:outlined="inputOptions.outlined"
			placeholder="name@domain.com"
			:rules="[inputRules.required, inputRules.email]"
			type="email"
			@keyup.enter.native="login"
		>
		</v-text-field>
		<!-- password -->
		<label class="scl-auth-login-form__label">Password</label>
		<v-text-field
			v-model="form.password"
			autocomplete="current-password"
			autocapitalize="off"
			autocorrect="off"
			:background-color="inputOptions.backgroundColor"
			:class="inputOptions.class"
			:color="inputOptions.color"
			:disabled="loading"
			:height="inputOptions.height"
			:outlined="inputOptions.outlined"
			placeholder="Password"
			:rules="[inputRules.required]"
			type="password"
			@keyup.enter.native="login"
		>
		</v-text-field>
		<v-btn class="scl-auth-login-form__btn-text" color="primaryDark" text @click="forgotPassword">Forgot password</v-btn>
		<v-checkbox @change="toggleRememberMe" class="scl-checkout__form-selection" label="Remember me"></v-checkbox>
		<v-btn @click="login" block class="scl-checkout__form-btn my-5" color="primary" depressed height="64" :disabled="!isValid || loading" :ripple="false">
			<span v-if="!loading">Login</span>
			<v-icon v-if="!loading" right>$vuetify.icons.faArrowRight</v-icon>
			<v-progress-circular v-if="loading" color="primary" indeterminate :size="40" :width="5"></v-progress-circular>
		</v-btn>
		<v-alert class="scl-alert__alert scl-alert--flat mb-0" icon="$vuetify.icons.faCheckCircle" tile>
			<p class="scl-alert__copy">Did you become a member online? If not, you will need to activate your account to login.</p>
		</v-alert>
		<v-alert class="scl-alert__alert scl-alert--flat" icon="$vuetify.icons.faHeadset" tile>
			<p class="scl-alert__copy">{{$root.dummySentence}}</p>
		</v-alert>
	</div>
`;

// export template
export default template;
