// @vue/component
const component = {
	name: 'SclCardIcon',
	props: {
		api: {
			type: Object,
			required: false,
			// props type validation
			validator: (obj) => {
				const set = new Set();
				set.add(!!(obj.accent ? typeof obj.accent === 'string' : true));
				set.add(!!(obj.backgroundColor ? typeof obj.backgroundColor === 'string' : true));
				set.add(!!(obj.textAlignment ? typeof obj.textAlignment === 'string' : true));
				set.add(!!(obj.iconAlignment ? typeof obj.iconAlignment === 'string' : true));
				return !set.has(false);
			}
		}
	},
	computed: {
		// props defaults
		propApi() {
			return {
				accent: null,
				backgroundColor: null,
				textAlignment: 'left',
				iconAlignment: 'start',
				...this.api
			};
		}
	},
	methods: {
		truncate(val) {
			return val && val.length > 200 ? `${val.substr(0, 200)}...` : val;
		}
	},
	template: null
};

// set template and dummy data if development environment variable string
if (process.env.VUE_APP_DEVELOPMENT === 'true') {
	component.template = require(`./${component.name}-template`).default;
}

// css import
require(`./_${component.name}.scss`);

// export component
export default component;
