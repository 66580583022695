import commerceApi from '@/_scl-fed/services/api/commerce';

// @vue/component
const component = {
	name: 'SclExampleApi',
	data() {
		return {
			hasErrored: false,
			loading: false,
			products: [],
			testParam1: 12,
			testParam2: 5
		};
	},
	created() {
		// this.fetchData();
	},
	methods: {
		fetchData() {
			// products have already been loaded
			if (this.products.length > 0) {
				return;
			}
			// products have already been requested
			if (this.loading) {
				return;
			}
			this.loading = true;
			// get products
			commerceApi
				.productSearch(this.params())
				.then((data) => {
					this.products = data;
				})
				.catch((error) => {
					this.hasErrored = true;
					console.error(error);
				})
				.finally(() => (this.loading = false));
		},
		params() {
			return `?test1=${this.testParam1}&test2=${this.testParam2}`;
		}
	},
	template: null
};

// set template and dummy data if development environment variable string
if (process.env.VUE_APP_DEVELOPMENT === 'true') {
	component.template = require(`./${component.name}-template`).default;
}

// css import
require(`./_${component.name}.scss`);

// export component
export default component;
