/**
 * SclMultiStepFramework
 *
 */

// template
const template = `
<div>
	<v-container>
		<v-row>
			<v-col class="scl-multi-step-framework__col" cols="12" :md="(noOfSteps > 4? 12 : 7)" :xl="(noOfSteps > 4? 12 : 5)">
				<v-stepper v-model="activeStep">
					<v-stepper-header class="scl-multi-step-framework__slider">
						<!-- Loop with Data Source in BE -->
						<div class="scl-multi-step-framework__slide-item">
							<div class="scl-multi-step-framework__progress-circle"  :class="{ 'is-filled': activeStep >= 1 }" ><span v-if="1 < activeStep"><a @click="goToStep(1)">1. Step 1 Name</a></span><span v-else>1. Step 1 Name</span></div>
							<div v-if="!(1 == noOfSteps)" class="scl-multi-step-framework__progress-bar" :class="{ 'is-filled': activeStep > 1 }" ></div>
						</div>

						<div class="scl-multi-step-framework__slide-item">
							<div class="scl-multi-step-framework__progress-circle" :class="{ 'is-filled': activeStep >= 2 }" ><span v-if="2 < activeStep"><a @click="goToStep(1)">2. Step 2 Name</a></span><span v-else>2. Step 2 Name</span></div>
							<div v-if="!(2 == noOfSteps)" class="scl-multi-step-framework__progress-bar" :class="{ 'is-filled': activeStep > 2 }" ></div>
						</div>

						<div class="scl-multi-step-framework__slide-item">
							<div class="scl-multi-step-framework__progress-circle" :class="{ 'is-filled': activeStep >= 3 }" ><span v-if="3 < activeStep"><a @click="goToStep(1)">3. Step 3 Name</a></span><span v-else>3. Step 3 Name</span></div>
							<div v-if="!(3 == noOfSteps)" class="scl-multi-step-framework__progress-bar" :class="{ 'is-filled': activeStep > 3 }" ></div>
						</div>

						<div class="scl-multi-step-framework__slide-item">
							<div class="scl-multi-step-framework__progress-circle" :class="{ 'is-filled': activeStep >= 4 }" ><span v-if="4 < activeStep"><a @click="goToStep(1)">4. Step 4 Name</a></span><span v-else>4. Step 4 Name</span></div>
							<div v-if="!(4 == noOfSteps)" class="scl-multi-step-framework__progress-bar" :class="{ 'is-filled': activeStep > 4 }" ></div>
						</div>
					</v-stepper-header>

					<v-stepper-items class="scl-multi-step-framework__stepper-items">
					<!-- Loop with Data Source in BE -->
						<v-stepper-content :step="1" class="scl-multi-step-framework__stepper-item">
							<!-- This will be replaced with Sitecore placeholder to inject the child multi step rendering in -->
							<scl-multi-child-step-framework :parent-step-no="1" class="scl-component scl-multi-child-step-framework" :no-of-child-steps="1" @emit-progress-parent="handleProgressStep"></scl-multi-child-step-framework>
						</v-stepper-content>
					</v-stepper-items>
				</v-stepper>

				<v-alert class="scl-alert__alert scl-alert--flat mb-0 scl-multi-step-framework__icon" icon="$vuetify.icons.faHeadset" tile>
					<h5 class="mb-2">Need some help?</h5>
					<p class="scl-alert__copy">{{$root.dummySentence}}</p>
				</v-alert>
				<v-alert class="scl-alert__alert scl-alert--flat" tile>
					<p class="scl-alert__copy">
						Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.
						Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.
					</p>
				</v-alert>
			</v-col>
		</v-row>
	</v-container>
</div>
`;
// export template
export default template;
