import { mapGetters } from 'vuex';
import { SclCartOrderSummary } from './cart/index';
import {
	SclBillingDetails,
	SclDeliveryDetails,
	SclGuest,
	SclLogin,
	SclPaymentDetails,
	SclPersonalDetails,
	SclProgress,
	SclShippingDetails,
	SclShippingOptions
} from './checkout/index';

// @vue/component
const component = {
	name: 'SclCheckout',
	components: {
		SclBillingDetails,
		SclDeliveryDetails,
		SclCartOrderSummary,
		SclGuest,
		SclLogin,
		SclPaymentDetails,
		SclPersonalDetails,
		SclProgress,
		SclShippingDetails,
		SclShippingOptions
	},
	props: {
		redirectUrl: {
			type: String,
			default: '/'
		}
	},
	data() {
		return {
			form: {
				inputOptions: {
					backgroundColor: '#f4f4f4',
					class: 'scl-checkout__form-input',
					color: 'accent',
					height: 48,
					outlined: true
				},
				inputRules: {
					email: (value) => {
						return value === null || value === '' || this.form.pattern.email.test(value) || 'Invalid email address';
					},
					number: (value) => {
						return value === null || value === '' || this.form.pattern.number.test(value) || 'Enter a number, no spaces';
					},
					phone: (value) => {
						return value === null || value === '' || this.form.pattern.phone.test(value) || 'Enter 10 digits';
					},
					postcode: (value) => {
						return value === null || value === '' || this.form.pattern.postcode.test(value) || 'Enter at 4 digits';
					},
					required: (value) => !!value || 'This field is required'
				},
				pattern: {
					email: /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
					number: /^\d*$/,
					phone: /^\d{10}$/,
					postcode: /^\d{4}$/,
					expiry: /^(0[1-9]|1[0-2])\/?([0-9]{4}|[0-9]{2})$/
				}
			},
			formData: {
				personal: {
					title: {
						label: 'Title',
						value: ''
					},
					firstName: {
						label: 'First name',
						value: ''
					},
					middleName: {
						label: 'Middle name',
						value: ''
					},
					lastName: {
						label: 'Last name',
						value: ''
					},
					preferredName: {
						label: 'Preferred name',
						value: ''
					},
					dateOfBirth: {
						label: 'Date of Birth',
						value: ''
					}
				},
				contact: {
					phone: {
						label: 'Phone',
						value: ''
					},
					mobile: {
						label: 'Mobile',
						value: ''
					},
					fax: {
						label: 'Fax',
						value: ''
					},
					// Not sure if required
					email: {
						label: 'Email',
						value: ''
					}
				},
				companyInfo: {
					tradingName: {
						label: 'Trading/Business name',
						value: ''
					},
					companyName: {
						label: 'Company name',
						value: ''
					},
					website: {
						label: 'Website',
						value: ''
					},
					role: {
						label: 'Position in Business/Job title',
						value: ''
					}
				},
				billingDetails: {
					street: {
						label: 'Address',
						value: ''
					},
					suburb: {
						label: 'Suburb',
						value: ''
					},
					state: {
						label: 'State',
						value: ''
					},
					postcode: {
						label: 'Postcode',
						value: ''
					},
					saveDetails: {
						label: 'Save billing details',
						value: ''
					}
				},
				deliveryDetails: {
					street: {
						label: 'Address',
						value: ''
					},
					suburb: {
						label: 'Suburb',
						value: ''
					},
					state: {
						label: 'State',
						value: ''
					},
					postcode: {
						label: 'Postcode',
						value: ''
					},
					delivery: {
						label: 'Delivery instructions',
						value: ''
					},
					saveDetails: {
						label: 'Save delivery details',
						value: ''
					}
				}
			},
			step: 2,
			step1: {
				login: {
					isValid: false,
					form: {}
				}
			},
			step2: {
				personal: {
					isValid: false,
					form: {}
				},
				billing: {
					isValid: false,
					form: {}
				},
				delivery: {
					isValid: false,
					form: {}
				}
			},
			step3: {
				payment: {
					isValid: false,
					form: {}
				}
			},
			shippingOptions: null,
			selectedShippingOption: null
		};
	},
	computed: {
		...mapGetters(['getAuth', 'getError', 'getHostedFieldsInstance', 'getLoading']),
		step1Valid() {
			return !!this.step1.login.isValid;
		},
		step2Valid() {
			return !!(this.step2.personal.isValid && this.step2.billing.isValid && this.step2.delivery.isValid);
		},
		step3Valid() {
			return !!this.step3.payment.isValid;
		},
		successRedirect() {
			return this.redirectUrl;
		}
	},
	created() {
		this.$store.dispatch('cartApiGet');
	},
	async mounted() {
		await this.mapFormData();
		await this.getShippingOptions();
	},
	methods: {
		async getShippingOptions() {
			const that = this;
			this.$store.dispatch('checkoutAPIGetShipping').then((response) => {
				if (response.data.status === 'ok') {
					that.shippingOptions = response.data.data.shippingMethods;
				}
			});
		},
		async mapFormData() {
			const that = this;
			this.$store.dispatch('authApiGetPersonalDetails').then((response) => {
				if (response.data.Success) {
					that.formData = { ...that.formData, ...JSON.parse(response.data.Result) };

					// Addding missing property
					that.formData.billingDetails.saveDetails = {
						label: 'Save billing details',
						value: false
					};

					that.formData.deliveryDetails = that.formData.registeredDetails;

					// Addding missing property
					that.formData.deliveryDetails.saveDetails = {
						label: 'Save delivery details',
						value: false
					};
				}
			});
		},
		nextStep() {
			let currStep = this.step;
			currStep++;
			this.step = currStep;
		},
		prevStep() {
			let currStep = this.step;
			currStep--;
			this.step = currStep;
		},
		handleLoginForm(bool, obj) {
			this.step1.login.isValid = bool;
			this.step1.login.form = obj;
		},
		login() {
			console.log('login');
			this.$store.commit('setAuth', true);
			this.nextStep();
		},
		handleGuestCheckout() {
			this.$store.commit('setAuth', false);
			this.nextStep();
		},
		handlePersonalForm(bool, obj) {
			this.step2.personal.isValid = bool;
			this.step2.personal.form = obj;
		},
		handleBillingForm(bool, obj) {
			this.step2.billing.isValid = bool;
			this.step2.billing.form = obj;
		},
		handleDeliveryForm(bool, obj) {
			this.step2.delivery.isValid = bool;
			this.step2.delivery.form = obj;
		},
		handlePaymentForm(bool, obj) {
			this.step3.payment.isValid = bool;
			this.step3.payment.form = obj;
		},
		handleNextStep() {
			this.nextStep();
		},
		handlePreviousStep() {
			this.prevStep();
		},
		handleShippingOptions(shippingOption, selectedIndex) {
			const that = this;
			const shippingMethods = [];
			if (shippingOption) {
				shippingMethods.push(shippingOption);
				this.selectedShippingOption = selectedIndex;
			}
			const payload = {
				OrderShippingPreferenceType: shippingOption.shippingPreferenceType !== null ? shippingOption.shippingPreferenceType : '1',
				ShippingAddresses: [this.getShippingDetailsFormData()],
				ShippingMethods: shippingMethods
			};
			this.$store
				.dispatch('checkoutAPISetShipping', payload)
				.then((response) => {
					if (response.data.success) {
						this.$store.dispatch('cartApiGet');
					} else {
						that.$store.dispatch('toastError');
					}
				})
				.catch((e) => {
					console.log(e);
					that.$store.dispatch('toastError');
				});
		},
		submitShippingDetails() {
			const that = this;
			const payload = {
				OrderShippingPreferenceType: this.shippingOptions !== null ? this.shippingOptions[this.selectedShippingOption].shippingPreferenceType : '1',
				shippingAddresses: [this.getShippingDetailsFormData()],
				ShippingMethods: [this.shippingOptions[this.selectedShippingOption]]
			};
			this.$store
				.dispatch('checkoutAPISetShipping', payload)
				.then((response) => {
					if (response.data.success) {
						that.nextStep();
					} else {
						that.$store.dispatch('toastError');
					}
				})
				.catch((e) => {
					console.log(e);
					that.$store.dispatch('toastError');
				});
		},
		getBillingDetailsFormData() {
			const that = this;

			let billingAddress = {
				name: 'BillingAddress',
				externalId: 'BillingAddress',
				partyId: '',
				country: 'Australia',
				CountryCode: 'AU',
				isPrimary: true,
				address1: this.formData.billingDetails.street.value,
				city: this.formData.billingDetails.suburb.value,
				state: this.formData.billingDetails.state.value,
				zipPostalCode: this.formData.billingDetails.postcode.value,
				email: this.formData.contact.email.value,
				firstName: this.formData.personal.firstName.value,
				lastName: this.formData.personal.lastName.value,
				saveToExternal: this.formData.billingDetails.saveDetails.value
			};
			this.$store
				.dispatch('checkoutAPIAddCustomerAddress', billingAddress)
				.then((response) => {
					if (response.status === 'ok') {
						billingAddress = response.data.filter((address) => address.Name === 'BillingAddress');
					} else {
						that.$store.dispatch('toastError');
					}
				})
				.catch((e) => {
					console.log(e);
					that.$store.dispatch('toastError');
				});
			return billingAddress;
		},
		getShippingDetailsFormData() {
			const that = this;

			let shippingAddress = {
				name: 'ShippingAddress',
				externalId: 'ShippingAddress',
				partyId: '',
				country: 'Australia',
				CountryCode: 'AU',
				isPrimary: false,
				address1: this.formData.deliveryDetails.street.value,
				city: this.formData.deliveryDetails.suburb.value,
				state: this.formData.deliveryDetails.state.value,
				zipPostalCode: this.formData.deliveryDetails.postcode.value,
				email: this.formData.contact.email.value,
				firstName: this.formData.personal.firstName.value,
				lastName: this.formData.personal.lastName.value,
				saveToExternal: this.formData.deliveryDetails.saveDetails.value
			};
			if (this.step2.delivery.isValid) {
				this.$store
					.dispatch('checkoutAPIAddCustomerAddress', shippingAddress)
					.then((response) => {
						if (response.status === 'ok') {
							const name = response.data.filter((address) => address.name === 'ShippingAddress')[0];
							shippingAddress = name;
							return shippingAddress;
						}
						that.$store.dispatch('toastError');
					})
					.catch((e) => {
						console.log(e);
						that.$store.dispatch('toastError');
					});
			}
			return shippingAddress;
		},
		submitPayment() {
			this.getHostedFieldsInstance.tokenize((tokenErr, secureFieldPayload) => {
				if (tokenErr) {
					this.$store.dispatch('toastError');
				}

				const payload = {
					billingAddress: this.getBillingDetailsFormData(),
					federatedPayment: {
						cardToken: secureFieldPayload.nonce,
						paymentMethodId: '4'
					}
				};
				this.$store.dispatch('checkoutAPISetPayment', payload).then((response) => {
					if (response.status === 'ok') {
						this.$store.dispatch('checkoutApiSubmit').then((paymentResponse) => {
							// Successful payment
							if (paymentResponse.status === 'ok' && response.data.orderAmount !== null) {
								this.$store.dispatch('cartApiGet'); // get the new cart to clear the state with an empty cart
								if (this.successRedirect) {
									window.location.href = this.successRedirect;
								}
							} else if (paymentResponse.status !== 'ok' && response.data.orderAmount === null) {
								// create order and payment failed
								// todo: HANDLE CARD FAILURE MESSAGE - THIS WILL BE CARD DETAIL ERRORS
							} else {
								this.$store.dispatch('toastError');
							}
						});
					}
					this.$store.dispatch('toastError');
				});
			});
		}
	},
	template: null
};

// set template and dummy data if development environment variable string
// if (process.env.VUE_APP_DEVELOPMENT === 'true') {
component.template = require(`./${component.name}-template`).default;
// }

// css import
require(`./_${component.name}.scss`);

// export component
export default component;
