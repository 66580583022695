/**
 * SclRelatedProducts
 *
 * cycle prop
 * @param {boolean} true || false
 *
 * delimiterIcon prop
 * @param {string} string "$vuetify.icons.faCircle"
 *
 * hideDelimiters prop
 * @param {boolean} true || false
 *
 * showArrows prop
 * @param {boolean} true || false
 *
 * height prop
 * @param {string} string 'auto'
 */

const template = `
	<div>
		<h3 class="scl-related-products__heading">{{$root.dummyWords}}</h3>
		<v-lazy>
			<v-carousel
				:cycle="propApi.cycle"
				class="scl-related-products__carousel scl-related-products__carousel--center"
				:delimiter-icon="propApi.delimiterIcon"
				:height="propApi.height"
				hide-delimiter-background
				:show-arrows="$vuetify.breakpoint.smAndUp? propApi.showArrows : false"
				:hide-delimiters="propApi.hideDelimiters"
				:touch="{
					left: () => nextItem(),
        			right: () => prevItem()
				}"
				v-model="currentSlide"
			>
				<!-- item -->
				<v-carousel-item class="scl-related-products__item" :key="i"  v-for="(slide, i) in carouselGroups">
					<v-row class="scl-related-products__row">
						<v-col class="py-0" cols="12" sm="6" lg="3" :key="index" v-for="(product, index) in slide">
							<v-card class="scl-related-products__card" :ripple="false" flat :href="product.Url" >
									<v-img
										:alt="product.Name"
										:aspect-ratio="4/3"
										:lazy-src="$root.lazyImg"
										:src="product.ImagePaths[0]"
										contain
									>
									</v-img>
								<v-card-title class="scl-related-products__title">
									<div>{{ product.Name }}</div>
									<v-icon class="ml-4">$vuetify.icons.faArrowRightReg</v-icon>
								</v-card-title>
							</v-card>
						</v-col>
					</v-row>
				</v-carousel-item>
			</v-carousel>
		</v-lazy>
	</div>
`;

export default template;
