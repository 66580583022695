// @vue/component
import { bus } from '../../main';

const component = {
	name: 'SclStoreCategoryList',
	data() {
		return {
			facets: []
		};
	},
	computed: {
		groupedFacets() {
			return this.facets;
		}
	},
	watch: {
		groupedFacets(val) {
			this.facets = val;
		}
	},
	created() {
		bus.$on('emit-store-facets', (val) => {
			this.facets = val;
		});
		bus.$on('emit-facet-remove', (tag, selectedFacetes) => {
			this.handleFacetDeselection(tag, selectedFacetes);
		});
	},
	methods: {
		updateCategories(DisplayName, FacetType, facetValue, e) {
			bus.$emit('emit-facet-change', DisplayName, FacetType, facetValue, e);
		}
	},
	template: null
};

// set template and dummy data if development environment variable string
if (process.env.VUE_APP_DEVELOPMENT === 'true') {
	component.template = require(`./${component.name}-template`).default;
}

// css import
require(`./_${component.name}.scss`);

// export component
export default component;
