/**
 * SclExample api
 *
 * boolean prop
 * @param {boolean} true
 *
 * number prop
 * @param {number} 12
 *
 * string prop
 * @param {string} string API example
 */

// template
const template = `
	<div>
		<v-container>
			<v-row>
				<v-col cols="12" sm="6" offset-sm="3">
					<v-card>
						<v-img :lazy-src="$root.dummyImage43" :src="$root.dummyImage43" aspect-ratio="1.33">
							<template v-slot:placeholder>
								<v-row class="fill-height ma-0" align="center" justify="center">
									<v-progress-circular indeterminate color="black"></v-progress-circular>
								</v-row>
							</template>
						</v-img>
						<v-card-title primary-title>
							<div>
								<h3 class="headline mb-3">Prop API JS Data Type Examples</h3>
								<div>
									<p v-if="propApi.boolean">boolean API example - visible if true</p>
									<p>{{ propApi.number }} - number API example</p>
									<p>{{ propApi.string }}</p>
								</div>
							</div>
						</v-card-title>
						<v-card-actions>
							<v-btn :ripple="false" class="btn btn--large btn-primary">{{ jsMessage }}</v-btn>
						</v-card-actions>
					</v-card>
				</v-col>
			</v-row>
		</v-container>
	</div>
`;

// export template
export default template;
