/**
 * SclDeliveryDetails
 */

// template
const template = `
	<div>
		<h3 class="scl-checkout__form-title"><span>Delivery details</span></h3>
		<v-checkbox @change="toggleBilling" class="scl-checkout__form-selection" label="Same as billing address"></v-checkbox>

		<!-- address line 1 -->
		<label class="scl-checkout__form-label" :class="{'is-disabled' : sameAsBilling}">{{ formData.deliveryDetails.street.label }}</label>
		<v-text-field
			v-model="formData.deliveryDetails.street.value"
			@input="checkValidity"
			autocorrect="off"
			:background-color="inputOptions.backgroundColor"
			:class="inputOptions.class"
			:color="inputOptions.color"
			:disabled="sameAsBilling"
			:height="inputOptions.height"
			:outlined="inputOptions.outlined"
			placeholder="24 Example Street"
			:rules="[inputRules.required]"
		>
		</v-text-field>
		<!-- suburb -->
		<label class="scl-checkout__form-label" :class="{'is-disabled' : sameAsBilling}">{{ formData.deliveryDetails.suburb.label }}</label>
		<v-text-field
			v-model="formData.deliveryDetails.suburb.value"
			@input="checkValidity"
			autocorrect="off"
			:background-color="inputOptions.backgroundColor"
			:class="inputOptions.class"
			:color="inputOptions.color"
			:disabled="sameAsBilling"
			:height="inputOptions.height"
			:outlined="inputOptions.outlined"
			placeholder="Sydney"
			:rules="[inputRules.required]"
		>
		</v-text-field>
		<!-- state -->
		<label class="scl-checkout__form-label" :class="{'is-disabled' : sameAsBilling}">{{ formData.deliveryDetails.state.label }}</label>
		<v-select
			v-model="formData.deliveryDetails.state.value"
			@change="checkValidity"
			append-icon="$vuetify.icon.faChevronDown"
			:background-color="inputOptions.backgroundColor"
			:class="inputOptions.class"
			:color="inputOptions.color"
			:disabled="sameAsBilling"
			:height="inputOptions.height"
			:outlined="inputOptions.outlined"
			:items="stateItems"
			placeholder="State"
			:rules="[inputRules.required]"
		>
		</v-select>
		<!-- postcode -->
		<label class="scl-checkout__form-label" :class="{'is-disabled' : sameAsBilling}">{{ formData.deliveryDetails.postcode.label }}</label>
		<v-text-field
			v-model="formData.deliveryDetails.postcode.value"
			@input="checkValidity"
			autocomplete="postal-code"
			autocorrect="off"
			:background-color="inputOptions.backgroundColor"
			class="is-narrow"
			:class="inputOptions.class"
			:color="inputOptions.color"
			:disabled="sameAsBilling"
			:height="inputOptions.height"
			maxlength="4"
			minlength="3"
			:outlined="inputOptions.outlined"
			pattern="[0-9]*"
			placeholder="2000"
			:rules="[inputRules.required, inputRules.number, inputRules.postcode]"
		>
		</v-text-field>

		<v-checkbox v-model="formData.deliveryDetails.saveDetails.value" class="scl-checkout__form-selection" label="Save delivery details" :disabled="!isValid"></v-checkbox>

	</div>
`;

// export template
export default template;
