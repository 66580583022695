const template = `
	<div>
		<v-pagination
			v-model="page"
			:disabled="isDisabled"
			:length="totalPages"
			:total-visible="visiblePages"
			@input="$emit('page-change', $event)"
			class="scl-pagination__list">
		</v-pagination>
	</div>
`;

export default template;
