import { mapGetters } from 'vuex';

// @vue/component
const component = {
	name: 'SclHeaderProfileMenu',
	props: {
		icon: {
			type: String
		}
	},
	data() {
		return {
			profileIcon: this.icon,
			active: false
		};
	},
	computed: {
		...mapGetters(['getAuth', 'getUserFullName']),
		fullName() {
			return this.getUserFullName || '';
		},
		firstName() {
			return this.fullName.split(' ')[0];
		},
		initials() {
			let initialLetters = '';
			if (!this.getUserFullName || typeof this.getUserFullName === 'undefined') {
				return initialLetters;
			}
			const names = this.getUserFullName.split(' ');
			if (names.length === 0) {
				return initialLetters;
			}
			names.forEach((name) => {
				initialLetters += `${name.charAt(0).toUpperCase()}`;
			});
			return initialLetters;
		}
	},
	methods: {
		gotoUrl(url) {
			if (!url) {
				return;
			}
			window.location.href = url;
		},
		openLoginModal() {
			this.$root.$emit('emitLoginModal');
		},
		handleClose() {
			this.active = !this.active;
		},
		handleLogout() {
			if (this.getAuth) {
				this.$store.dispatch('authApiLogOut').then(() => {
					if (!this.getAuth) {
						window.location.href = '/';
					}
				});
			}
		},
		emitState(val) {
			const isActive = Object.values(val)[2] !== 'false';
			this.active = isActive;
			this.$emit('emit-is-active', this.active);
		}
	},
	template: null
};

// set template and dummy data if development environment variable string
if (process.env.VUE_APP_DEVELOPMENT === 'true') {
	component.template = require(`./${component.name}-template`).default;
}

// css import
require(`./_${component.name}.scss`);

// export component
export default component;
