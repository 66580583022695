/**
 * SclShippingDetails
 */

// template
const template = `
	<div>
		<h3 class="scl-checkout__form-title"><span>Shipping details</span></h3>
		<ul class="scl-shipping-details__list">
			<li><span>{{ shipping.personal.title.value }} {{ shipping.personal.firstName.value }} {{ shipping.personal.lastName.value }}</span><v-btn @click="edit" class="scl-shipping-details__edit" color="primaryDark" text>Edit</v-btn></li>
			<li>{{ shipping.deliveryDetails.street.value }}</li>
			<li>{{ shipping.deliveryDetails.suburb.value }}</li>
			<li>{{ shipping.deliveryDetails.state.value }}</li>
			<li>{{ shipping.deliveryDetails.postcode.value }}</li>
		</ul>
	</div>
`;

// export template
export default template;
