/**
 * SclMultiChildStepFramework
 *
 */

// template
const template = `
	<div>
		<v-stepper v-model="activeChildStep" vertical class="scl-multi-child-step-framework__stepper">

			<!-- Loop in the BE Razor -->
			<v-stepper-step step="1" class="scl-multi-child-step-framework__stepper-header">
				<div class="scl-multi-child-step-framework__stepper-header-link">
					<h4>1. Step Name From Sitecore</h4>
					<a v-if="(activeChildStep > 1)" @click="handleChildStepChange('1')">Change</a>
				</div>
				<p>Summary of child step  1</p>
			</v-stepper-step>
			<scl-step-summary v-if="(activeChildStep > 1)" class="scl-step-summary" :child-step-no="1" :parent-step-no="parentStepNo"></scl-step-summary>

			<v-stepper-content data-step="1" data-cta-text="Next" :step="1" class="scl-multi-child-step-framework__stepper-content" :class="{ 'scroll-reference' : 1 === 1}">
				<!-- Create and insert child step items here -->
			</v-stepper-content>

			<v-spacer class="scl-multi-child-step-framework__spacer"></v-spacer>

		</v-stepper>

		<v-btn block v-if="activeChildStep > totalChildSteps" class="btn btn-primary justify-space-between" :ripple="false" @click="handleProgressParentStep" :disabled="!(activeChildStep > totalChildSteps)">
			Continue
			<v-icon class="btn__icon-link" right>$vuetify.icons.faArrowRight</v-icon>
		</v-btn>
	</div>
`;
// export template
export default template;
