import Vue from 'vue';
import * as VueGoogleMaps from 'gmap-vue';

Vue.use(VueGoogleMaps, {
	load: {
		// should use a different one for different environments via env configs
		key: process.env.VUE_APP_ROOT_GOOGLE_API_KEY,
		language: 'en-AU',
		// https://developers.google.com/maps/documentation/javascript/libraries
		libraries: 'places',
		region: 'AU'
	},
	installComponents: true
});
