/**
 * SclHeader api
 *
 * @param {boolean} logo - whether the header has a logo or not
 * @param {string} name - this text will be displayed in the toolbar
 */

// template
const template = `
	<div>
		<v-navigation-drawer v-model="drawer" fixed temporary v-if="$vuetify.breakpoint.smAndDown">
			<v-container class="scl-header__secondary-mobile">
				<v-row class="align-center">
					<v-col cols="12" class="pt-0 pb-0">
					<div class="scl-header__left-side">
						<v-img
							v-if="propApi.logo"
							:src="$root.dummyImageSquare"
							aspect-ratio="1"
							class="scl-header__logo"
							:alt="propApi.name"
							max-width="70"
						></v-img>
						</div>
					</v-col>
					<v-col cols="12" class="pt-0 pb-0 text-right scl-header__secondary-icons">
						<div :class="propApi.search || propApi.profile ? 'scl-header__border-right' : null">
							<span v-if="propApi.search">
								<v-icon class="scl-header__secondary-icon"> {{ propApi.searchIcon }}</v-icon>
							</span>
							<span v-if="propApi.profile">
								<v-icon class="scl-header__secondary-icon"> {{ propApi.profileIcon }}</v-icon>
							</span>
						</div>
						<div>
							<v-icon class="scl-header__secondary-icon" @click="drawer = false"> {{ propApi.closeIcon }}</v-icon>
						</div>
					</v-col>
				</v-row>
			</v-container>
			<v-expansion-panels accordion class="scl-header__accordions" flat ref="panels" v-model="panel" >
				<!-- panel -->
				<v-expansion-panel class="scl-header__accordion">
					<v-expansion-panel-header expand-icon="$vuetify.icons.faCaretDown" ref="panelHeader"  class="scl-header__accordion-header">
						{{$root.dummyWords}}
					</v-expansion-panel-header>
					<v-expansion-panel-content class="scl-header__accordion-content">
					<!-- Nav List -->
					<v-list class="scl-header__nav-list-items">
					<div>
						<v-btn class="btn btn-link scl-header__btn-link" @click="gotoUrl($root.dummyUrl)">{{$root.dummyWords}} <v-icon class="btn__icon-link"> $vuetify.icons.faArrowRight </v-icon></v-btn>
					</div>
					<h8 class="scl-header__nav-list-title">Nav List title</h8>
						<v-list-item :ripple="false" class="scl-header__nav-list-link" @click="gotoUrl($root.dummyUrl)">
							<v-list-item-title>{{ $root.dummyWords }}</v-list-item-title>
						</v-list-item>
						<v-list-item :ripple="false" class="scl-header__nav-list-link" @click="gotoUrl($root.dummyUrl)">
							<v-list-item-title>{{ $root.dummyWords }}</v-list-item-title>
						</v-list-item>
						<v-list-item :ripple="false" class="scl-header__nav-list-link" @click="gotoUrl($root.dummyUrl)">
							<v-list-item-title>{{ $root.dummyWords }}</v-list-item-title>
						</v-list-item>
						<v-list-item :ripple="false" class="scl-header__nav-list-link" @click="gotoUrl($root.dummyUrl)">
							<v-list-item-title>{{ $root.dummyWords }}</v-list-item-title>
						</v-list-item>
					</v-list>
					<!-- Nav List -->
					<v-list class="scl-header__nav-list-items">
					<div>
						<v-btn class="btn btn-link scl-header__btn-link" @click="gotoUrl($root.dummyUrl)">{{$root.dummyWords}} <v-icon class="btn__icon-link"> $vuetify.icons.faArrowRight </v-icon></v-btn>
					</div>
					<h8 class="scl-header__nav-list-title">Nav List title</h8>
						<v-list-item :ripple="false" class="scl-header__nav-list-link" @click="gotoUrl($root.dummyUrl)">
							<v-list-item-title>{{ $root.dummyWords }}</v-list-item-title>
						</v-list-item>
						<v-list-item :ripple="false" class="scl-header__nav-list-link" @click="gotoUrl($root.dummyUrl)">
							<v-list-item-title>{{ $root.dummyWords }}</v-list-item-title>
						</v-list-item>
						<v-list-item :ripple="false" class="scl-header__nav-list-link" @click="gotoUrl($root.dummyUrl)">
							<v-list-item-title>{{ $root.dummyWords }}</v-list-item-title>
						</v-list-item>
						<v-list-item :ripple="false" class="scl-header__nav-list-link" @click="gotoUrl($root.dummyUrl)">
							<v-list-item-title>{{ $root.dummyWords }}</v-list-item-title>
						</v-list-item>
					</v-list>
					</v-expansion-panel-content>
				</v-expansion-panel>
				<!-- panel -->
				<v-expansion-panel class="scl-header__accordion">
					<v-expansion-panel-header expand-icon="$vuetify.icons.faCaretDown" ref="panelHeader"  class="scl-header__accordion-header">
						{{$root.dummyWords}}
					</v-expansion-panel-header>
					<v-expansion-panel-content class="scl-header__accordion-content">
					<!-- Nav List -->
					<v-list class="scl-header__nav-list-items">
						<h8 class="scl-header__nav-list-title">Nav List title</h8>
						<v-list-item :ripple="false" class="scl-header__nav-list-link" @click="gotoUrl($root.dummyUrl)">
							<v-list-item-title>{{ $root.dummyWords }}</v-list-item-title>
						</v-list-item>
						<v-list-item :ripple="false" class="scl-header__nav-list-link" @click="gotoUrl($root.dummyUrl)">
							<v-list-item-title>{{ $root.dummyWords }}</v-list-item-title>
						</v-list-item>
						<v-list-item :ripple="false" class="scl-header__nav-list-link" @click="gotoUrl($root.dummyUrl)">
							<v-list-item-title>{{ $root.dummyWords }}</v-list-item-title>
						</v-list-item>
						<v-list-item :ripple="false" class="scl-header__nav-list-link" @click="gotoUrl($root.dummyUrl)">
							<v-list-item-title>{{ $root.dummyWords }}</v-list-item-title>
						</v-list-item>
					</v-list>
					</v-expansion-panel-content>
				</v-expansion-panel>
			</v-expansion-panels>
			<v-list dense>
				<v-list-item :ripple="false" class="scl-header__secondary-link" @click="gotoUrl($root.dummyUrl)">
					<v-list-item-content>
						<v-list-item-title>Item 1</v-list-item-title>
					</v-list-item-content>
				</v-list-item>
				<v-list-item :ripple="false" class="scl-header__secondary-link" @click="gotoUrl($root.dummyUrl)">
					<v-list-item-content>
						<v-list-item-title>Item 2</v-list-item-title>
					</v-list-item-content>
				</v-list-item>
				<v-list-item :ripple="false" class="scl-header__secondary-link" @click="gotoUrl($root.dummyUrl)">
					<v-list-item-content>
						<v-list-item-title>Item 3</v-list-item-title>
					</v-list-item-content>
				</v-list-item>
			</v-list>
			<div class="scl-header__secondary-placeholder scl-header__secondary-placeholder--mobile">
			<scl-form-location class="scl-form-location" region="NSW">></scl-form-location>
			<scl-header-contact class="scl-header-contact scl-component"></scl-header-contact>
			</div>
		</v-navigation-drawer>

		<v-menu offset-y :close-on-content-click="false" transition="slide-y-transition" content-class="scl-header__mega-menu scl-header__search-menu">
		<template v-slot:activator="scope">

      

		<v-toolbar role="menu" flat class="scl-header__secondary" v-bind="scope.attrs" :class="(!isActive? '' : 'scl-header__secondary--active')">
		<v-container class="pb-0">
			<v-row class="scl-header__row">
			<v-col cols="12" md="3" class="pt-0 pb-0">
				<div class="scl-header__left-side justify-center justify-md-start">
					<v-img
						v-if="propApi.logo"
						:src="$root.dummyImageSquare"
						aspect-ratio="1"
						class="scl-header__logo"
						:alt="propApi.name"
						max-width="56"
					></v-img>

					<v-toolbar-title v-if="propApi.name" class="hidden-sm-and-down align-center ml-2">
						<span class="title">Youtube</span>
					</v-toolbar-title>
				</div>
			</v-col>
			<v-col cols="6" class="py-0" v-if="$vuetify.breakpoint.mdAndUp">
				<v-text-field v-model="searchQuery" flat solo class="scl-header__search-input" append-icon="$vuetify.icons.faSearch" placeholder="Search" @keyup.enter="handleSearch($root.dummyUrl)" @click:append="handleSearch($root.dummyUrl)"></v-text-field>
			</v-col>
		<v-col cols="12" md="3" class="pt-0 pb-0">
			<div class="scl-header__right-side justify-end">		

			<div v-if="$vuetify.breakpoint.mdAndUp" class="scl-header__secondary-placeholder">				
				<scl-header-contact class="scl-header-contact scl-component"></scl-header-contact>
			</div>

		<div class="scl-header__secondary-icons" v-if="$vuetify.breakpoint.smAndDown">			
			<div v-if="propApi.search">
				<v-btn :ripple="false" class="scl-header__primary-search" v-if="propApi.search" text v-on="scope.on" ><v-icon class="scl-header__secondary-icon"> {{ propApi.searchIcon }}</v-icon></v-btn>
			</div>
			<scl-header-contact class="scl-header-contact scl-component"></scl-header-contact>
			<div>
				<v-app-bar-nav-icon  @click.stop="drawer = !drawer"><v-icon class="scl-header__secondary-icon">{{propApi.icon}}</v-icon></v-app-bar-nav-icon>
			</div>
		</div>
		</div>
		</v-col>
			<v-col cols="11" class="d-flex justify-center" v-if="$vuetify.breakpoint.mdAndUp">
				<v-toolbar-items class="scl-header__primary-nav-items hidden-sm-and-down">
					<v-menu offset-y :close-on-content-click="false" transition="slide-y-transition" content-class="scl-header__mega-menu">
						<template v-slot:activator="scope">
							<v-btn :ripple="false" class="btn btn--small scl-header__primary-nav-item" text v-on="scope.on" >{{ $root.dummyWords }}</v-btn>
						</template>
						<v-container>
							<v-row>
								<v-col cols="4" lg="3">
									<h2>{{ $root.dummyWords }}</h2>
									<p>{{$root.dummySentence}}</p>
									<a class="btn btn-link  btn--x-small" text>{{ $root.dummyWords }} <v-icon class="btn__icon-link"> $vuetify.icons.faArrowRight </v-icon></a>
									<div class="scl-header__accent"></div>
								</v-col>
								<v-col cols="8" offset-lg="1">
									<div class="scl-header__nav-list">
										<!-- Nav List -->
										<v-list class="scl-header__nav-list-items">
											<h5 class="scl-header__nav-list-title">Nav List title</h5>
											<v-list-item :ripple="false" class="scl-header__nav-list-link" @click="gotoUrl($root.dummyUrl)">
												<v-list-item-title>{{ $root.dummyWords }}</v-list-item-title>
											</v-list-item>
											<v-list-item :ripple="false" class="scl-header__nav-list-link" @click="gotoUrl($root.dummyUrl)">
												<v-list-item-title>{{ $root.dummyWords }} {{ $root.dummyWords }} {{ $root.dummyWords }}</v-list-item-title>
											</v-list-item>
											<v-list-item :ripple="false" class="scl-header__nav-list-link" @click="gotoUrl($root.dummyUrl)">
												<v-list-item-title>{{ $root.dummyWords }}</v-list-item-title>
											</v-list-item>
											<v-list-item :ripple="false" class="scl-header__nav-list-link" @click="gotoUrl($root.dummyUrl)">
												<v-list-item-title>{{ $root.dummyWords }}</v-list-item-title>
											</v-list-item>
										</v-list>
										<!-- Nav List -->
										<v-list class="scl-header__nav-list-items">
											<h5 class="scl-header__nav-list-title">Nav List title</h5>
											<v-list-item :ripple="false" class="scl-header__nav-list-link" @click="gotoUrl($root.dummyUrl)">
												<v-list-item-title>{{ $root.dummyWords }}</v-list-item-title>
											</v-list-item>
											<v-list-item :ripple="false" class="scl-header__nav-list-link" @click="gotoUrl($root.dummyUrl)">
												<v-list-item-title>{{ $root.dummyWords }}</v-list-item-title>
											</v-list-item>
											<v-list-item :ripple="false" class="scl-header__nav-list-link" @click="gotoUrl($root.dummyUrl)">
												<v-list-item-title>{{ $root.dummyWords }}</v-list-item-title>
											</v-list-item>
											<v-list-item :ripple="false" class="scl-header__nav-list-link" @click="gotoUrl($root.dummyUrl)">
											<v-list-item-title>{{ $root.dummyWords }} {{ $root.dummyWords }} {{ $root.dummyWords }}</v-list-item-title>
											</v-list-item>
											<v-list-item :ripple="false" class="scl-header__nav-list-link" @click="gotoUrl($root.dummyUrl)">
												<v-list-item-title>{{ $root.dummyWords }}</v-list-item-title>
											</v-list-item>
										</v-list>
										<!-- Nav List -->
										<v-list class="scl-header__nav-list-items">
											<h5 class="scl-header__nav-list-title">Nav List title</h5>
											<v-list-item :ripple="false" class="scl-header__nav-list-link" @click="gotoUrl($root.dummyUrl)">
												<v-list-item-title>{{ $root.dummyWords }}</v-list-item-title>
											</v-list-item>
											<v-list-item :ripple="false" class="scl-header__nav-list-link" @click="gotoUrl($root.dummyUrl)">
												<v-list-item-title>{{ $root.dummyWords }}</v-list-item-title>
											</v-list-item>
										</v-list>
										<!-- Nav List -->
										<v-list class="scl-header__nav-list-items">
											<h5 class="scl-header__nav-list-title">Nav List title</h5>
											<v-list-item :ripple="false" class="scl-header__nav-list-link" @click="gotoUrl($root.dummyUrl)">
												<v-list-item-title>{{ $root.dummyWords }}</v-list-item-title>
											</v-list-item>
											<v-list-item :ripple="false" class="scl-header__nav-list-link" @click="gotoUrl($root.dummyUrl)">
												<v-list-item-title>{{ $root.dummyWords }}</v-list-item-title>
											</v-list-item>
											<v-list-item :ripple="false" class="scl-header__nav-list-link" @click="gotoUrl($root.dummyUrl)">
												<v-list-item-title>{{ $root.dummyWords }}</v-list-item-title>
											</v-list-item>
											<v-list-item :ripple="false" class="scl-header__nav-list-link" @click="gotoUrl($root.dummyUrl)">
												<v-list-item-title>{{ $root.dummyWords }}</v-list-item-title>
											</v-list-item>
										</v-list>

									</div>
								</v-col>
							</v-row>
						</v-container>
					</v-menu>
					<v-menu offset-y :close-on-content-click="false" transition="slide-y-transition" content-class="scl-header__mega-menu">
						<template v-slot:activator="scope">
							<v-btn :ripple="false" class="btn btn--small scl-header__primary-nav-item" text v-on="scope.on" v-bind="scope.attrs">{{ $root.dummyWords }}</v-btn>
						</template>
						<v-container>
							<v-row>
								<v-col cols="4" lg="3">
									<h2>{{ $root.dummyWords }}</h2>
									<p>{{$root.dummySentence}}</p>
									<a class="btn btn-link  btn--x-small" text>{{ $root.dummyWords }} <v-icon class="btn__icon-link"> $vuetify.icons.faArrowRight </v-icon></a>
									<div class="scl-header__accent"></div>
								</v-col>
								<v-col cols="8" offset-lg="1">
									<div class="scl-header__nav-list">
										<!-- Nav List -->
										<v-list class="scl-header__nav-list-items">
											<h5 class="scl-header__nav-list-title">Nav List title</h5>
											<v-list-item :ripple="false" class="scl-header__nav-list-link" @click="gotoUrl($root.dummyUrl)">
												<v-list-item-title>{{ $root.dummyWords }}</v-list-item-title>
											</v-list-item>
											<v-list-item :ripple="false" class="scl-header__nav-list-link" @click="gotoUrl($root.dummyUrl)">
												<v-list-item-title>{{ $root.dummyWords }}</v-list-item-title>
											</v-list-item>
											<v-list-item :ripple="false" class="scl-header__nav-list-link" @click="gotoUrl($root.dummyUrl)">
												<v-list-item-title>{{ $root.dummyWords }}</v-list-item-title>
											</v-list-item>
											<v-list-item :ripple="false" class="scl-header__nav-list-link" @click="gotoUrl($root.dummyUrl)">
												<v-list-item-title>{{ $root.dummyWords }}</v-list-item-title>
											</v-list-item>
										</v-list>
										<!-- Nav List -->
										<v-list class="scl-header__nav-list-items">
											<h5 class="scl-header__nav-list-title">Nav List title</h5>
											<v-list-item :ripple="false" class="scl-header__nav-list-link" @click="gotoUrl($root.dummyUrl)">
												<v-list-item-title>{{ $root.dummyWords }}</v-list-item-title>
											</v-list-item>
											<v-list-item :ripple="false" class="scl-header__nav-list-link" @click="gotoUrl($root.dummyUrl)">
												<v-list-item-title>{{ $root.dummyWords }}</v-list-item-title>
											</v-list-item>
										</v-list>
										<!-- Nav List -->
										<v-list class="scl-header__nav-list-items">
											<h5 class="scl-header__nav-list-title">Nav List title</h5>
											<v-list-item :ripple="false" class="scl-header__nav-list-link" @click="gotoUrl($root.dummyUrl)">
												<v-list-item-title>{{ $root.dummyWords }}</v-list-item-title>
											</v-list-item>
											<v-list-item :ripple="false" class="scl-header__nav-list-link" @click="gotoUrl($root.dummyUrl)">
												<v-list-item-title>{{ $root.dummyWords }}</v-list-item-title>
											</v-list-item>
										</v-list>
									</div>
								</v-col>
							</v-row>
						</v-container>
					</v-menu>
					<v-menu offset-y :close-on-content-click="false" transition="slide-y-transition" content-class="scl-header__mega-menu">
						<template v-slot:activator="scope">
							<v-btn :ripple="false" class="btn btn--small scl-header__primary-nav-item" text v-on="scope.on" v-bind="scope.attrs">{{ $root.dummyWords }}</v-btn>
						</template>
						<v-container>
							<v-row>
								<v-col cols="4" lg="3">
									<h2>{{ $root.dummyWords }}</h2>
									<p>{{$root.dummySentence}}</p>
									<a class="btn btn-link  btn--x-small" text>{{ $root.dummyWords }} <v-icon class="btn__icon-link"> $vuetify.icons.faArrowRight </v-icon></a>
									<div class="scl-header__accent"></div>
								</v-col>
								<v-col cols="8" offset-lg="1">
									<div class="scl-header__nav-list">
										<!-- Nav List -->
										<v-list class="scl-header__nav-list-items">
											<h5 class="scl-header__nav-list-title">Nav List title</h5>
											<v-list-item :ripple="false" class="scl-header__nav-list-link" @click="gotoUrl($root.dummyUrl)">
												<v-list-item-title>{{ $root.dummyWords }}</v-list-item-title>
											</v-list-item>
											<v-list-item :ripple="false" class="scl-header__nav-list-link" @click="gotoUrl($root.dummyUrl)">
												<v-list-item-title>{{ $root.dummyWords }}</v-list-item-title>
											</v-list-item>
											<v-list-item :ripple="false" class="scl-header__nav-list-link" @click="gotoUrl($root.dummyUrl)">
												<v-list-item-title>{{ $root.dummyWords }}</v-list-item-title>
											</v-list-item>
											<v-list-item :ripple="false" class="scl-header__nav-list-link" @click="gotoUrl($root.dummyUrl)">
												<v-list-item-title>{{ $root.dummyWords }}</v-list-item-title>
											</v-list-item>
										</v-list>
										<!-- Nav List -->
										<v-list class="scl-header__nav-list-items">
											<h5 class="scl-header__nav-list-title">Nav List title</h5>
											<v-list-item :ripple="false" class="scl-header__nav-list-link" @click="gotoUrl($root.dummyUrl)">
												<v-list-item-title>{{ $root.dummyWords }}</v-list-item-title>
											</v-list-item>
											<v-list-item :ripple="false" class="scl-header__nav-list-link" @click="gotoUrl($root.dummyUrl)">
												<v-list-item-title>{{ $root.dummyWords }}</v-list-item-title>
											</v-list-item>
										</v-list>
										<!-- Nav List -->
										<v-list class="scl-header__nav-list-items">
											<h5 class="scl-header__nav-list-title">Nav List title</h5>
											<v-list-item :ripple="false" class="scl-header__nav-list-link" @click="gotoUrl($root.dummyUrl)">
												<v-list-item-title>{{ $root.dummyWords }}</v-list-item-title>
											</v-list-item>
											<v-list-item :ripple="false" class="scl-header__nav-list-link" @click="gotoUrl($root.dummyUrl)">
												<v-list-item-title>{{ $root.dummyWords }}</v-list-item-title>
											</v-list-item>
										</v-list>
									</div>
								</v-col>
							</v-row>
						</v-container>
					</v-menu>					
					<v-menu offset-y :close-on-content-click="false" transition="slide-y-transition" content-class="scl-header__mega-menu">
						<template v-slot:activator="scope">
							<v-btn :ripple="false" class="btn btn--small scl-header__primary-nav-item" text v-on="scope.on" v-bind="scope.attrs">{{ $root.dummyWords }}</v-btn>
						</template>
						<v-container>
							<v-row>
								<v-col cols="4" lg="3">
									<h2>{{ $root.dummyWords }}</h2>
									<p>{{$root.dummySentence}}</p>
									<a class="btn btn-link  btn--x-small" text>{{ $root.dummyWords }} <v-icon class="btn__icon-link"> $vuetify.icons.faArrowRight </v-icon></a>
									<div class="scl-header__accent"></div>
								</v-col>
								<v-col cols="8" offset-lg="1">
									<div class="scl-header__nav-list">
										<!-- Nav List -->
										<v-list class="scl-header__nav-list-items">
											<h5 class="scl-header__nav-list-title">Nav List title</h5>
											<v-list-item :ripple="false" class="scl-header__nav-list-link" @click="gotoUrl($root.dummyUrl)">
												<v-list-item-title>{{ $root.dummyWords }}</v-list-item-title>
											</v-list-item>
											<v-list-item :ripple="false" class="scl-header__nav-list-link" @click="gotoUrl($root.dummyUrl)">
												<v-list-item-title>{{ $root.dummyWords }}</v-list-item-title>
											</v-list-item>
											<v-list-item :ripple="false" class="scl-header__nav-list-link" @click="gotoUrl($root.dummyUrl)">
												<v-list-item-title>{{ $root.dummyWords }}</v-list-item-title>
											</v-list-item>
											<v-list-item :ripple="false" class="scl-header__nav-list-link" @click="gotoUrl($root.dummyUrl)">
												<v-list-item-title>{{ $root.dummyWords }}</v-list-item-title>
											</v-list-item>
										</v-list>
										<!-- Nav List -->
										<v-list class="scl-header__nav-list-items">
											<h5 class="scl-header__nav-list-title">Nav List title</h5>
											<v-list-item :ripple="false" class="scl-header__nav-list-link" @click="gotoUrl($root.dummyUrl)">
												<v-list-item-title>{{ $root.dummyWords }}</v-list-item-title>
											</v-list-item>
											<v-list-item :ripple="false" class="scl-header__nav-list-link" @click="gotoUrl($root.dummyUrl)">
												<v-list-item-title>{{ $root.dummyWords }}</v-list-item-title>
											</v-list-item>
										</v-list>
										<!-- Nav List -->
										<v-list class="scl-header__nav-list-items">
											<h5 class="scl-header__nav-list-title">Nav List title</h5>
											<v-list-item :ripple="false" class="scl-header__nav-list-link" @click="gotoUrl($root.dummyUrl)">
												<v-list-item-title>{{ $root.dummyWords }}</v-list-item-title>
											</v-list-item>
											<v-list-item :ripple="false" class="scl-header__nav-list-link" @click="gotoUrl($root.dummyUrl)">
												<v-list-item-title>{{ $root.dummyWords }}</v-list-item-title>
											</v-list-item>
										</v-list>
									</div>
								</v-col>
							</v-row>
						</v-container>
					</v-menu>

				</v-toolbar-items>
				<v-toolbar-items class="scl-header__secondary-nav-items" v-if="$vuetify.breakpoint.mdAndUp">
				<v-menu offset-y color="primary" open-on-hover content-class="scl-header__secondary__no-shadow">
					<template v-slot:activator="scope">
						<v-btn :ripple="false" class="btn btn--small scl-header__secondary__link"  @click="gotoUrl($root.dummyUrl)" text v-on="scope.on">Core Nav 1</v-btn>
					</template>
					<v-list>
						<v-list-item :ripple="false" class="scl-header__secondary-link" @click="gotoUrl($root.dummyUrl)">
							<v-list-item-title>Dropdown Item 1</v-list-item-title>
						</v-list-item>
						<v-list-item :ripple="false" class="scl-header__secondary-link" @click="gotoUrl($root.dummyUrl)">
							<v-list-item-title>Dropdown Item 2</v-list-item-title>
						</v-list-item>
					</v-list>
				</v-menu>

				<v-menu offset-y open-on-hover content-class="scl-header__secondary__no-shadow">
					<template v-slot:activator="scope">
						<v-btn :ripple="false" class="btn btn--small scl-header__secondary__link" @click="gotoUrl($root.dummyUrl)" text v-on="scope.on">Core Nav 2</v-btn>
					</template>
					<v-list>
						<v-list-item :ripple="false" class="scl-header__secondary-link" @click="gotoUrl($root.dummyUrl)">
							<v-list-item-title>Dropdown Item 1</v-list-item-title>
						</v-list-item>
						<v-list-item :ripple="false" class="scl-header__secondary-link" @click="gotoUrl($root.dummyUrl)">
							<v-list-item-title>Dropdown Item 2</v-list-item-title>
						</v-list-item>
						<v-list-item :ripple="false" class="scl-header__secondary-link" @click="gotoUrl($root.dummyUrl)">
							<v-list-item-title>Dropdown Item 3</v-list-item-title>
						</v-list-item>
					</v-list>
				</v-menu>

				<v-menu offset-y>
					<template v-slot:activator="scope">
						<v-btn :ripple="false" class="btn btn--small" text v-on="scope.on">Core Nav 3</v-btn>
					</template>
					<v-list>
						<v-list-item :ripple="false" class="scl-header__secondary-link" @click="gotoUrl($root.dummyUrl)">
							<v-list-item-title>Dropdown Item 1</v-list-item-title>
						</v-list-item>
					</v-list>
				</v-menu>
			</v-toolbar-items>
			</v-col>
			<v-col cols="1" class="pt-0 pb-0" v-if="$vuetify.breakpoint.mdAndUp">
				<v-menu offset-y :close-on-content-click="false" transition="slide-y-transition" content-class="scl-header__mega-menu scl-header__search-menu">
						<template v-slot:activator="scope">
							<v-btn :ripple="false" class="scl-header__primary-search" v-if="propApi.search" text v-on="scope.on" v-bind="scope.attrs"><v-icon class="scl-header__secondary-icon mr-0"> {{ propApi.searchIcon }}</v-icon></v-btn>
						</template>
						<v-container class="pt-0 pb-0">
							<v-row>
								<v-col cols="12" class="pt-0 pb-0">
									<div class="scl-header__search">
										<v-text-field v-model="searchQuery"
											light
											solo
											class="scl-header__search-input"
											prepend-inner-icon="$vuetify.icons.faSearch"
											placeholder="Search"
											@keyup.enter="handleSearch($root.dummyUrl)"
										></v-text-field>
										<v-btn :ripple="false" text class="btn btn-primary scl-header__search-btn" @click="handleSearch($root.dummyUrl)"> {{ $root.dummyWord }}</v-btn>
									</div>
								</v-col>
							</v-row>
						</v-container>
					</v-menu>
			</v-col>
		</v-row>
		</v-container>
		</v-toolbar>
		</template>
						<v-container class="pt-0 pb-0">
							<v-row>
								<v-col cols="12" class="pt-0 pb-0">
									<div class="scl-header__search">
										<v-text-field v-model="searchQuery"
											light
											solo
											class="scl-header__search-input"
											append-icon="$vuetify.icons.faSearch"
											placeholder="Search"
											@keyup.enter="handleSearch($root.dummyUrl)"
											@click:append="handleSearch($root.dummyUrl)"
										></v-text-field>
									</div>
								</v-col>
							</v-row>
						</v-container>
					</v-menu>
					<v-container class="py-0 scl-header__title">
							<v-row class="py-0">
								<v-col cols="12" class="py-0">
										<h1 class="white--text"> {{$root.dummyWords}} </h1>
								</v-col>
							</v-row>
						</v-container>
					
					<div class="scl-header__options d-flex justify-center">
						<div class="scl-header__options-item">
							<v-img :src="$root.dummyImage43" :max-width="24"></v-img>
							<div class="ml-2">{{$root.dummyWords}}</div>
						</div>
						<div class="scl-header__options-item">
							<v-img :src="$root.dummyImage43" :max-width="24"></v-img>
							<div class="ml-2">{{$root.dummyWords}}</div>
						</div>
						<div class="scl-header__options-item">
							<v-img :src="$root.dummyImage43" :max-width="24"></v-img>
							<div class="ml-2">{{$root.dummyWords}}</div>
						</div>
					</div>
	</div>
`;

// export template
export default template;
