/**
 * SclCard api
 *
 * overlayTextColour prop
 * @param {string} 'white--text'
 * @param {string} 'black--text'
 *
 */

// template
const template = `
	<div>
		<v-lazy>
			<v-card flat :ripple="false" href="#">
				<v-img
					:lazy-src="$root.dummyImage43"
					:src="$root.dummyImage43"
					:aspect-ratio="16/9"
					:class="propApi.overlayTextColour"
					class="scl-card-video__image"
				>
					<video class="scl-card-video__video" autoplay muted loop playsinline>
						<source :src="$root.dummyVideo" type="video/mp4">
					</video>
					<v-container class="align-end fill-height scl-card-video__overlay scl-card-video__overlay--half" fluid>
						<v-row>
							<v-col class="scl-card-video__text" cols="12">
								<div class="scl-card-video__title">{{ $root.dummyWords }}</div>
								<div class="scl-card-video__headline">{{ $root.dummyWords }}</div>
								<p>{{ $root.dummyParagraph }}</p>
								<v-btn class="btn btn--large btn-primary scl-card-video__button">{{ $root.dummyWords }}</v-btn>
							</v-col>
						</v-row>
					</v-container>
				</v-img>
			</v-card>
		</v-lazy>
		<div class="scl-card-video__ribbon" :class="'scl-card-video__ribbon--' + propApi.ribbonColour">{{ $root.dummyWords }}</div>
	</div>
`;

// export template
export default template;
