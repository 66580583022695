/**
 * SclProductCard
 */

// template
const template = `
	<div>
		<v-lazy>
			<v-card class="scl-product-card__card" :ripple="false" flat :href="url" v-if="cardType === 'grid'">
			<v-btn v-if="outOfStock" class="scl-product-card__stock"> Out of Stock</v-btn>
					<v-img
						:alt="title"
						:aspect-ratio="4/3"
						:lazy-src="$root.lazyImg"
						:src="image"
						contain
					>
					</v-img>
				<div class="scl-product-card__content">
					<v-card-title><h3 class="scl-product-card__title">{{ title }}</h3></v-card-title>
					<div class="scl-product-card__sku">{{computedSku}}</div>
					<v-card-text class="scl-product-card__text">
					{{  truncate }} 

					</v-card-text>
				</div>
			</v-card>
			<v-card class="scl-product-card__card" :ripple="false" flat :href="url" v-else>
			<v-btn v-if="outOfStock" class="scl-product-card__stock"> Out of Stock</v-btn>
			<v-row class="py-0 fill-height">
				<v-col cols="12" sm="3" class="pa-0 white">
					<v-img
					:alt="title"
					:aspect-ratio="4/3"
					:lazy-src="$root.lazyImg"
					:src="image"
					contain>
					</v-img>
				</v-col>
				<v-col cols="12" sm="9" class="pl-0">
					<div class="scl-product-card__content">
						<v-card-title><h3 class="scl-product-card__title">{{ title }}</h3></v-card-title>
						<div class="scl-product-card__sku">{{computedSku}}</div>
						<v-card-text class="scl-product-card__text">
							{{  truncate }} 
						</v-card-text>
					</div>
				</v-col>
			</v-row>
			</v-card>
		</v-lazy>
	</div>
`;

// export template
export default template;
