/**
 * SclCategoryCarouselList
 *
 * cycle prop
 * @param {boolean} true || false
 *
 * delimiterIcon prop
 * @param {string} string "$vuetify.icons.faCircle"
 *
 * hideDelimiters prop
 * @param {boolean} true || false
 *
 * height prop
 * @param {string} string 'auto'
 */

const template = `
	<div>
		<v-row class="scl-category-carousel-list__row">
			<v-col cols="12" sm="4" lg="3">
				<div class="scl-category-carousel-list__subject-card" >
					<h3 class="scl-category-carousel-list__subject-title">{{$root.dummyWords}}</h3>
					<p class="text--primary scl-category-carousel-list__subject-text">
					{{$root.dummySentence}}
					</p>
					<v-btn v-if="$vuetify.breakpoint.smAndUp" :ripple="false" class="btn btn-outlined scl-category-carousel-list__subject-button">{{$root.dummyWords}}<v-icon class="btn__icon-link"> $vuetify.icons.faArrowRight</v-icon></v-btn>

				</div>
			</v-col>
			<v-col cols="12" sm="8" lg="9">
			<scl-carousel-category class="scl-carousel-category scl-component" :slides="[{
				image: 'https://picsum.photos/800/600',
				title: 'Topic 1',
				introText: 'onsectetur adipiscing elit, sed do eiusmod tempor incididunt ut.t',
				link: ''
			},
			{
				image: 'https://picsum.photos/800/600',
				title: 'Topic 2',
				introText: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed doeiusmod tempor incididunt ut. Lorem ipsum dolor sit amet',
				link: ''
			},
			{
				image: 'https://picsum.photos/800/600',
				title: 'Topic 3',
				introText: 'Lorem ipsum dolor sit amet, consectetur Lorem ipsum dolor sit amet',
				link: ''
			},
			{
				image: 'https://picsum.photos/800/600',
				title: 'Topic 4',
				introText: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit',
				link: ''
			},
			{
				image: 'https://picsum.photos/800/600',
				title: 'Topic 5',
				introText: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit,ncididunt ut. Lorem ipsum dolor sit amet',
				link: ''
			}]"></scl-carousel-category>
			</v-col>
		</v-row>
		<div class="scl-category-carousel-list__divider"></div>

	</div>
`;

export default template;
