/**
 * SclKeyInfo
 */

// template
const template = `
	<div>

		<!-- events example 1 -->
		<div class="scl-key-info__container">
			<div>
				<h5 class="scl-key-info__title"><span>Tickets</span></h5>
				<div class="d-flex">
					<div class="mr-5">
						<h2 class="scl-key-info__price">$<span v-html="trunc(350.50)"></span><sup>*</sup></h2>
						<span class="d-block">for members</span>
					</div>
					<div class="ml-5">
						<h2 class="scl-key-info__price">$<span v-html="trunc(400.4646445665467879980)"></span><sup>*</sup></h2>
						<span class="d-block">for non-members</span>
					</div>
				</div>
			</div>
			<div class="pb-0">
				<div class="scl-key-info__flex-container">
					<ul class="scl-key-info__date-list">
						<li>
							<small class="x-small d-block">Date</small>
							<h5 class="mb-3">Sunday 12th July</h5>
						</li>
						<li>
							<small class="x-small d-block">Time</small>
							<h5 class="mb-0">11:00 AM to 12:00PM</h5>
						</li>
					</ul>
					<v-btn class="scl-key-info__calendar" :class="{'mb-5':$vuetify.breakpoint.xsOnly}" color="primaryDark" href="#" text>Add to calendar</v-btn>
				</div>
				<v-select
					append-icon="$vuetify.icons.faChevronDown"
					background-color="#f4f4f4"
					@change="redirect($event)"
					class="scl-key-info__select"
					full-width
					height="48px"
					hide-details
					:items="[ { text: 'item 1', value: 'http://www.google.com.au/' }, { text: 'item 2', value: 'http://www.switchit.com.au/' } ]"
					outlined
					:value="{ text: 'item 1', value: 'http://www.google.com.au/' }"
				></v-select>
			</div>
			<div>
				<div class="scl-key-info__flex-container">
					<v-btn
						:block="$vuetify.breakpoint.xsOnly"
						:ripple="false"
						class="scl-key-info__btn btn btn-primary"
						:class="{'mb-4':$vuetify.breakpoint.xsOnly}"
						depressed
						height="64px"
						href="#"
					>
						<span class="white--text">Register</span>
						<v-icon color="white" right size="24">$vuetify.icons.faClipboardListCheck</v-icon>
					</v-btn>
					<v-btn
						:block="$vuetify.breakpoint.xsOnly"
						:ripple="false"
						class="scl-key-info__btn btn btn-outlined"
						color="primaryDark"
						depressed
						height="64px"
						href="#"
						outlined
					>
						<span>Enquire</span>
						<v-icon color="primaryDark" right size="24">$vuetify.icons.faComments</v-icon>
					</v-btn>
				</div>
			</div>
		</div>

		<!-- events example 2 -->
		<div class="scl-key-info__container">
			<div>
				<h5 class="scl-key-info__title"><span>Enrol now</span></h5>
				<div class="d-flex">
					<div class="mr-5">
						<h2 class="scl-key-info__price">$<span v-html="trunc(580.50)"></span><sup>*</sup></h2>
						<span class="d-block">for members</span>
					</div>
					<div class="ml-5">
						<h2 class="scl-key-info__price">$<span v-html="trunc(740.4646445665467879980)"></span><sup>*</sup></h2>
						<span class="d-block">for non-members</span>
					</div>
				</div>
			</div>
			<div>
				<p>This event is no longer available. contact us if you have an enquiry or try browsing our other events.</p>
				<div class="scl-key-info__flex-container">
					<v-btn
						block
						:ripple="false"
						class="scl-key-info__btn btn btn-primary"
						depressed
						height="64px"
						href="#"
					>
						<span class="white--text">Enquire now</span>
						<v-icon color="white" right size="24">$vuetify.icons.faBell</v-icon>
					</v-btn>
				</div>
				<v-btn class="btn btn-link mt-3" color="primaryDark" href="#" text>
					Browse events
					<v-icon class="btn__icon-link" color="primaryDark" right size="16">$vuetify.icons.faArrowRight</v-icon>
				</v-btn>
			</div>
		</div>

		<!-- events example 3 -->
		<div class="scl-key-info__container">
			<div>
				<h5 class="scl-key-info__title"><span>Tickets</span></h5>
				<div class="d-flex">
					<div class="mr-5">
						<h2 class="scl-key-info__price">$<span v-html="trunc(350.50)"></span><sup>*</sup></h2>
						<span class="d-block">for members</span>
					</div>
					<div class="ml-5">
						<h2 class="scl-key-info__price">$<span v-html="trunc(400.4646445665467879980)"></span><sup>*</sup></h2>
						<span class="d-block">for non-members</span>
					</div>
				</div>
			</div>
			<div class="pb-0">
				<div class="scl-key-info__flex-container">
					<ul class="scl-key-info__date-list">
						<li>
							<small class="x-small d-block">Date</small>
							<h5 class="mb-3">Sunday 12th July</h5>
						</li>
						<li>
							<small class="x-small d-block">Time</small>
							<h5 class="mb-0">11:00 AM to 12:00PM</h5>
						</li>
					</ul>
					<v-btn class="scl-key-info__calendar" :class="{'mb-5':$vuetify.breakpoint.xsOnly}" color="primaryDark" href="#" text>Add to calendar</v-btn>
				</div>
				<v-select
					append-icon="$vuetify.icons.faChevronDown"
					background-color="#f4f4f4"
					@change="redirect($event)"
					class="scl-key-info__select"
					full-width
					height="48px"
					hide-details
					:items="[ { text: 'item 1', value: 'http://www.google.com.au/' }, { text: 'item 2', value: 'http://www.switchit.com.au/' } ]"
					outlined
					:value="{ text: 'item 1', value: 'http://www.google.com.au/' }"
				></v-select>
			</div>
			<div>
				<div class="scl-key-info__flex-container">
					<v-btn
						block
						:ripple="false"
						class="scl-key-info__btn btn btn-primary"
						depressed
						height="64px"
						href="#"
					>
						<span class="white--text">Notify me when <br :class="{'d-none':$vuetify.breakpoint.smAndUp}" />tickets are available</span>
						<v-icon color="white" right size="24">$vuetify.icons.faBell</v-icon>
					</v-btn>
				</div>
				<v-btn class="btn btn-link mt-3" color="primaryDark" href="#" text>
					Interested in this event? <br v-if="$vuetify.breakpoint.xsOnly" />Enquire now
					<v-icon class="btn__icon-link" color="primaryDark" right size="16">$vuetify.icons.faArrowRight</v-icon>
				</v-btn>
			</div>
		</div>


		<!-- training example 1 -->
		<div class="scl-key-info__container">
			<div>
				<h5 class="scl-key-info__title">
					<span>Enrol now</span>
					<span class="scl-key-info__ribbon">
						<v-icon class="mr-2" color="#fff" size="24">$vuetify.icons.faHandReceiving</v-icon>
						<span>Funding available</span>
					</span>
				</h5>
				<div class="d-flex">
					<div class="mr-5">
						<h2 class="scl-key-info__price">$<span v-html="trunc(350.50)"></span><sup>*</sup></h2>
						<span class="d-block">for members</span>
					</div>
					<div class="ml-5">
						<h2 class="scl-key-info__price">$<span v-html="trunc(400.4646445665467879980)"></span><sup>*</sup></h2>
						<span class="d-block">for non-members</span>
					</div>
				</div>
			</div>
			<div class="pb-0">
				<v-select
					append-icon="$vuetify.icons.faChevronDown"
					background-color="#f4f4f4"
					@change="redirect($event)"
					class="scl-key-info__select"
					full-width
					height="48px"
					hide-details
					:items="[ { text: 'item 1', value: 'http://www.google.com.au/' }, { text: 'item 2', value: 'http://www.switchit.com.au/' } ]"
					outlined
					:value="{ text: 'item 1', value: 'http://www.google.com.au/' }"
				></v-select>
			</div>
			<div>
				<div class="scl-key-info__flex-container">
					<v-btn
						:block="$vuetify.breakpoint.xsOnly"
						:ripple="false"
						class="scl-key-info__btn btn btn-primary"
						:class="{'mb-4':$vuetify.breakpoint.xsOnly}"
						depressed
						height="64px"
						href="#"
					>
						<span class="white--text">Apply</span>
						<v-icon color="white" right size="24">$vuetify.icons.faClipboardListCheck</v-icon>
					</v-btn>
					<v-btn
						:block="$vuetify.breakpoint.xsOnly"
						:ripple="false"
						class="scl-key-info__btn btn btn-outlined"
						color="primaryDark"
						depressed
						height="64px"
						href="#"
						outlined
					>
						<span>Enquire</span>
						<v-icon color="primaryDark" right size="24">$vuetify.icons.faComments</v-icon>
					</v-btn>
				</div>
			</div>
		</div>

		<!-- training example 2 -->
		<div class="scl-key-info__container">
			<div>
				<h5 class="scl-key-info__title">
					<span>Enrol now</span>
					<span class="scl-key-info__ribbon">
						<v-icon class="mr-2" color="#fff" size="24">$vuetify.icons.faHandReceiving</v-icon>
						<span>Funding available</span>
					</span>
				</h5>
				<div class="d-flex">
					<div class="mr-5">
						<h2 class="scl-key-info__price">$<span v-html="trunc(580.50)"></span><sup>*</sup></h2>
						<span class="d-block">for members</span>
					</div>
					<div class="ml-5">
						<h2 class="scl-key-info__price">$<span v-html="trunc(740.4646445665467879980)"></span><sup>*</sup></h2>
						<span class="d-block">for non-members</span>
					</div>
				</div>
			</div>
			<div>
				<p>There are currently no dates available for your region, try changing your region or enquiring now.</p>
				<div class="scl-key-info__flex-container">
					<v-btn
						block
						:ripple="false"
						class="scl-key-info__btn btn btn-primary"
						depressed
						height="64px"
						href="#"
					>
						<span class="white--text">Enquire now</span>
						<v-icon color="white" right size="24">$vuetify.icons.faComments</v-icon>
					</v-btn>
				</div>
			</div>
		</div>

		<!-- training example 3 -->
		<div class="scl-key-info__container">
			<div>
				<h5 class="scl-key-info__title">
					<span>Enrol now</span>
					<span class="scl-key-info__ribbon">
						<v-icon class="mr-2" color="#fff" size="24">$vuetify.icons.faHandReceiving</v-icon>
						<span>Funding available</span>
					</span>
				</h5>
				<div class="d-flex">
					<div class="mr-5">
						<h2 class="scl-key-info__price">Registration starts at $<span v-html="trunc(350.50)"></span><sup>*</sup></h2>
						<span class="d-block"><sup>*</sup>Funding available to eligible members</span>
					</div>
				</div>
			</div>
			<div class="pb-0">
				<v-select
					append-icon="$vuetify.icons.faChevronDown"
					background-color="#f4f4f4"
					@change="redirect($event)"
					class="scl-key-info__select"
					full-width
					height="48px"
					hide-details
					:items="[ { text: 'item 1', value: 'http://www.google.com.au/' }, { text: 'item 2', value: 'http://www.switchit.com.au/' } ]"
					outlined
					:value="{ text: 'item 1', value: 'http://www.google.com.au/' }"
				></v-select>
			</div>
			<div>
				<div class="scl-key-info__flex-container">
					<v-btn
						:block="$vuetify.breakpoint.xsOnly"
						:ripple="false"
						class="scl-key-info__btn btn btn-primary"
						:class="{'mb-4':$vuetify.breakpoint.xsOnly}"
						depressed
						height="64px"
						href="#"
					>
						<span class="white--text">Apply</span>
						<v-icon color="white" right size="24">$vuetify.icons.faClipboardListCheck</v-icon>
					</v-btn>
					<v-btn
						:block="$vuetify.breakpoint.xsOnly"
						:ripple="false"
						class="scl-key-info__btn btn btn-outlined"
						color="primaryDark"
						depressed
						height="64px"
						href="#"
						outlined
					>
						<span>Enquire</span>
						<v-icon color="primaryDark" right size="24">$vuetify.icons.faComments</v-icon>
					</v-btn>
				</div>
			</div>
		</div>

		<!-- Awards -->
		<div class="scl-key-info__container">
			<div>
				<h5 class="scl-key-info__title"><span>Enter now</span></h5>
				<div class="d-flex">
					<div class="mr-5">
						<h2 class="scl-key-info__price">$<span>200.00</span><sup></sup></h2>
						<span class="d-block">Per entry</span>
					</div>
					<div class="ml-5">
						<h2 class="scl-key-info__price">$<span>99.00</span></h2>
						<span class="d-block">Per subsequent entry</span>
					</div>
				</div>
			</div>
			<div class="pb-0">
				<div class="scl-key-info__flex-container">
					<ul class="scl-key-info__date-list">
						<li>
							<small class="x-small d-block">Entry closing date</small>
							<h5 class="mb-3">September 20 2022</h5>
						</li>
					</ul>
					<v-btn class="scl-key-info__calendar" :class="{'mb-5':$vuetify.breakpoint.xsOnly}" color="primaryDark" href="#" text>Add to calendar</v-btn>
				</div>
			</div>
			<div>
				<div class="scl-key-info__flex-container">
					<v-btn :block="$vuetify.breakpoint.xsOnly"
							:ripple="false"
						   class="scl-key-info__btn btn btn-primary"
						   :class="{'mb-4':$vuetify.breakpoint.xsOnly}"
						   depressed
						   height="64px"
						   href="#">
						<span class="white--text">Submit entry</span>
						<v-icon color="white" right size="24">$vuetify.icons.faClipboardListCheck</v-icon>
					</v-btn>
					<v-btn :block="$vuetify.breakpoint.xsOnly"
							:ripple="false"
						   class="scl-key-info__btn btn btn-outlined"
						   color="primaryDark"
						   depressed
						   height="64px"
						   href="#"
						   outlined>
						<span>Enquire</span>
						<v-icon color="primaryDark" right size="24">$vuetify.icons.faComments</v-icon>
					</v-btn>
				</div>
			</div>
			<p>There are currently no dates available for your region, try changing your region or enquiring now.</p>
		</div>
		<div>
			<p class="scl-key-info__footnote"> {{$root.dummyWords }} </p>
		</div>
	</div>
`;

// export template
export default template;
