import trainingApi from '@/_scl-fed/services/api/training';
import SclTrainingFiltering from './SclTrainingFiltering';
import SclTrainingListItem from './SclTrainingListItem';
import SclTrainingSearch from './SclTrainingSearch';

// @vue/component
const component = {
	name: 'SclTrainingListing',
	components: { SclTrainingFiltering, SclTrainingListItem, SclTrainingSearch },
	props: {
		categoryRoot: null
	},
	data() {
		return {
			error: {
				message: '',
				messageError: 'Something has gone wrong please try again.',
				messageNoResults: 'Adjust your filters to see more results or try another search term.',
				type: ''
			},
			hasErrored: false,
			isLoading: false,
			pagination: {
				currentPage: 1,
				pageSize: 12
			},
			resultsMessage: 'training courses',
			facets: [],
			facetFilter: [],
			regions: [],
			response: null,
			search: null,
			types: [],
			categories: [],
			qualifications: [],
			memberIsLoggedIn: false,
			itemCount: 0,
			items: [],
			trainingTags: {
				tags: [],
				backgroundColor: 'secondary',
				isLabel: false
			},
			showFilter: true
		};
	},
	computed: {
		searchParams() {
			let params = `?pg=${this.pagination.currentPage}&ps=${this.pagination.pageSize}`;
			if (this.search) {
				params += `&q=${this.search}`;
			}
			if (this.facetFilter && Array.isArray(this.facetFilter) && this.facetFilter.length > 0) {
				params += `&f=${this.facetFilter.join('%26')}`;
			}
			return params;
		},
		pageCount() {
			return Math.ceil(this.itemCount / this.pagination.pageSize);
		},
		getResultsMessage() {
			let message = this.resultsMessage;
			const pagetotal = Math.ceil(this.pagination.currentPage * this.pagination.pageSize);
			const total = this.itemCount;
			if (this.search !== null) {
				message = `${total} search results for '${this.search}'`;
			} else {
				message = `${Math.ceil(pagetotal - this.pagination.pageSize + 1)} to ${pagetotal > total ? total : pagetotal} of ${total} ${
					this.resultsMessage
				}`;
			}
			return message;
		}
	},
	created() {
		this.initialFetch();
	},
	methods: {
		initialFetch() {
			// items have already been loaded
			if (this.items.length > 0) {
				return;
			}
			// this.parseUrlParameters();
			this.eventsSearch();
		},
		parseUrlParameters() {
			const params = new URLSearchParams(window.location.search.substring(1));
			const pg = Number(params.get('pg'));
			const ps = Number(params.get('ps'));
			this.pagination.currentPage = Number.isNaN(pg) || pg < 1 ? this.pagination.currentPage : pg;
			this.pagination.pageSize = Number.isNaN(ps) || ps < 1 ? this.pagination.pageSize : ps;
			this.search = params.get('q') ?? this.search;
			if (params.has('f')) {
				this.facetFilter = params.get('f').split(/[%3D|]/);
			}
		},
		processError(type, message, error) {
			this.error.type = type;
			this.error.message = message;
			this.hasErrored = true;
			console.error(error);
		},
		eventsSearch() {
			this.hasErrored = false;
			// events have already been requested
			if (this.isLoading) {
				return;
			}
			this.isLoading = true;

			// get events
			trainingApi
				.trainingSearch(this.searchParams)
				.then((response) => {
					this.response = response.Result;
					this.items = response.Result.Data;
					this.itemCount = response.Result.TotalResults;
					this.facets = response.Result.Facets;
					this.setRegions();
					this.setTypes();
					this.setCategories();
					this.setQualifications();
					if (response.Result.Facets === 0) {
						this.processError('info', this.error.messageNoResults, 'no facets found');
					}
					if (this.items.length === 0) {
						this.processError('info', this.error.messageNoResults, 'no articles found');
					}
				})
				.catch((err) => {
					this.processError('error', this.error.messageError, err);
				})
				.finally(() => {
					window.history.pushState({}, '', this.searchParams);
					this.scrollTop;
					this.isLoading = false;
				});
		},
		handlePageChange(val) {
			this.pagination.currentPage = val;
			this.eventsSearch();
		},
		handlePageSize(val) {
			this.pagination.pageSize = val;
			this.eventsSearch();
		},
		handleSearchQuery(val) {
			this.search = val;
			this.pagination.currentPage = 1;
			this.eventsSearch();
		},
		handleFacetFilter(val) {
			this.facetFilter = [];
			this.trainingTags.tags = [];
			this.handleRegions(val.region.model);
			this.handleType(val.type.model);
			this.handleCategories(val.category.model);
			this.handleQualifications(val.qualification.model);
			this.pagination.currentPage = 1;
			this.eventsSearch();
		},
		handleTagRemove(val, tagFilters) {
			this.facetFilter = [];
			this.trainingTags.tags = [];
			this.handleRegions(tagFilters.region.model);
			this.handleType(tagFilters.type.model);
			this.handleCategories(tagFilters.category.model);
			this.handleQualifications(tagFilters.qualification.model);
			this.pagination.currentPage = 1;
			this.eventsSearch();
		},
		handleRegions(arr) {
			const queryParam = 'region%3D';
			arr.forEach((entry) => {
				const val = entry.replace(/ *\([^)]*\) */g, '');
				this.facetFilter.push(queryParam + encodeURIComponent(val));
				this.trainingTags.tags.push({ label: val, value: queryParam + val });
			});
		},
		handleType(arr) {
			const queryParam = 'type%3D';
			arr.forEach((entry) => {
				const val = entry.replace(/ *\([^)]*\) */g, '');
				this.facetFilter.push(queryParam + encodeURIComponent(val));
				this.trainingTags.tags.push({ label: val, value: queryParam + val });
			});
		},
		handleCategories(arr) {
			const queryParam = 'coursecategory%3D';
			arr.forEach((entry) => {
				const val = entry.replace(/ *\([^)]*\) */g, '');
				this.facetFilter.push(queryParam + encodeURIComponent(val));
				this.trainingTags.tags.push({ label: val, value: queryParam + val });
			});
		},
		handleQualifications(arr) {
			const queryParam = 'qualificationtype%3D';
			arr.forEach((entry) => {
				const val = entry.replace(/ *\([^)]*\) */g, '');
				this.facetFilter.push(queryParam + encodeURIComponent(val));
				this.trainingTags.tags.push({ label: val, value: queryParam + val });
			});
		},
		setRegions() {
			// eslint-disable-next-line
			const regions = Object.entries(this.facets[0].Values).filter(([key, value]) => value !== 0).map(([key, val]) => `${key} (${val})`);
			this.regions = regions;
		},
		setTypes() {
			// eslint-disable-next-line
			const types = Object.entries(this.facets[3].Values).filter(([key, value]) => value !== 0).map(([key, val]) => `${key} (${val})`);
			this.types = types;
		},
		setCategories() {
			// eslint-disable-next-line
			const categories = Object.entries(this.facets[2].Values).filter(([key, value]) => value !== 0).map(([key, val]) => `${key} (${val})`);
			this.categories = categories;
		},
		setQualifications() {
			// eslint-disable-next-line
			const qualifications = Object.entries(this.facets[1].Values).filter(([key, value]) => value !== 0).map(([key, val]) => `${key} (${val})`);
			this.qualifications = qualifications;
		},
		scrollTop() {
			window.scrollTo({
				top: 0,
				left: 0,
				behavior: 'smooth'
			});
		}
	},
	template: null
};

// set template and dummy data if development environment variable string
if (process.env.VUE_APP_DEVELOPMENT === 'true') {
	component.template = require(`./${component.name}-template`).default;
}

// css import
require(`./_${component.name}.scss`);

// export component
export default component;
