// @vue/component
import { mapGetters } from 'vuex';

const component = {
	name: 'SclStepSummary',
	props: {
		childStepNo: {
			type: Number
		},
		parentStepNo: {
			type: Number
		}
	},
	computed: {
		...mapGetters(['getMultiStepFrameworkState']),
		stepSummary() {
			const currentState = this.getMultiStepFrameworkState;
			const summary = currentState.steps[this.parentStepNo - 1].childSteps[this.childStepNo - 1].formData;
			return summary;
		}
	},
	methods: {
		isSingle(summary) {
			const isArray = summary.length === 1;
			return isArray;
		},
		isPassword(val) {
			const password = val.includes('Password');
			return password;
		}
	},
	template: null
};

// set template and dummy data if development environment variable string
if (process.env.VUE_APP_DEVELOPMENT === 'true') {
	component.template = require(`./${component.name}-template`).default;
}

// css import
require(`./_${component.name}.scss`);

// export component
export default component;
