/**
 * SclCategorySelector
 */

// template
const template = `
	<div>	
	<v-btn class="scl-category-selector__filter-button btn btn-outlined" @click="isActive = !isActive"><v-icon left>$vuetify.icons.faSlidersH</v-icon>Categories</v-btn>
		<div class="scl-category-selector__filter" :class="isActive? 'scl-category-selector__filter--active': null">
		<h4>{{$root.dummyWords}}</h4>
		<v-expansion-panels accordion class="scl-category-selector__accordions" flat multiple v-model="panel" ref="panel">

			<!-- panel -->
			<v-expansion-panel class="scl-category-selector__accordion" >
				<v-expansion-panel-header hide-actions  class="scl-category-selector__header">
					<div class="scl-category-selector__title">
						{{$root.dummyWords}}
					</div>
					<v-icon class="scl-category-selector__accordion-icon scl-category-selector__accordion-icon--plus">{{ propApi.collapsedIcon }}</v-icon>
				</v-expansion-panel-header>
				<v-expansion-panel-content class="scl-category-selector__content">
					<ul>
						<li >
							<span>	{{$root.dummyWords}}</span>
						</li>
						<li>
							<span>	{{$root.dummyWords}}</span>
						</li>
					</ul>
				</v-expansion-panel-content>
			</v-expansion-panel>
			<!-- panel -->
			<v-expansion-panel class="scl-category-selector__accordion scl-category-selector__accordion--active">
				<v-expansion-panel-header hide-actions  class="scl-category-selector__header">
					<div class="scl-category-selector__title">
						{{$root.dummyWords}}
					</div>
					<v-icon class="scl-category-selector__accordion-icon scl-category-selector__accordion-icon--plus">{{ propApi.collapsedIcon }}</v-icon>
				</v-expansion-panel-header>
				<v-expansion-panel-content class="scl-category-selector__content">
					<ul>
						<li >
							<span>	{{$root.dummyWords}}</span>
							<ul>
						<li >
							<span class="active">	{{$root.dummyWords}}</span>
						</li>
						<li>
							<span>	{{$root.dummyWords}}</span>
						</li>
					</ul>
						</li>
						<li>
							<span>	{{$root.dummyWords}}</span>
						</li>
					</ul>
				</v-expansion-panel-content>
			</v-expansion-panel>

		</v-expansion-panels>
		</div>
	</div>
`;

// export template
export default template;
