// @vue/component
const component = {
	name: 'SclTags',
	props: {
		api: {
			type: Object,
			required: false
		}
	},
	computed: {
		// props defaults
		propApi() {
			return {
				tags: [], // emptied out as default state
				backgroundColor: 'secondary',
				isLabel: true, // always set to category tags
				isLink: false,
				url: '',
				...this.api
			};
		}
	},
	methods: {
		handleRemove(tag) {
			this.$emit('emit-tags-remove', tag);
		},
		buildUrl(tag) {
			const facettedUrl = `${this.api.url}?ps=8&pg=0&f=${encodeURIComponent(`tag=${tag.label}`)}`;
			return facettedUrl;
		}
	},
	template: null
};

// set template and dummy data if development environment variable string
// if (process.env.VUE_APP_DEVELOPMENT === 'true') {
component.template = require(`./${component.name}-template`).default;
// }

// css import
require(`./_${component.name}.scss`);

// export component
export default component;
