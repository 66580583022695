/**
 * SclDateInformationList
 *
 */

const template = `
	<div>
		<div class="scl-date-information-list__control-wrapper">
			<div class="scl-date-information-list__title">
				{{ $root.dummyWords }}
			</div>
			<div class="scl-date-information-list__btn">
				<v-btn :ripple="false" class="pa-0 btn btn-link" text href="#">
					View all
					<v-icon class="btn__icon-link" right>$vuetify.icons.faArrowRight</v-icon>
				</v-btn>
			</div>
		</div>
		<v-list class="scl-date-information-list__list">
			<v-list-item class="scl-date-information-list__item card-accent card-accent--left card-accent__secondary ">
				<v-card :ripple="false"  href="#" class="scl-date-information-list__item-link">
					<div class="scl-date-information-list__item-date">
						<span>26 Jul</span>
					</div>
					<div class="scl-date-information-list__item-content-wrapper">
						<div class="scl-date-information-list__item-content">
							<h6 class="scl-date-information-list__item-title">{{ $root.dummyWords }}</h6>
							<p v-if="$vuetify.breakpoint.smAndUp" class="scl-date-information-list__item-text">{{ $root.dummySentence }}</p>
						</div>
					</div>
				</v-card>
			</v-list-item>
			<v-list-item class="scl-date-information-list__item card-accent card-accent--left card-accent__secondary ">
				<v-card :ripple="false"  href="#" class="scl-date-information-list__item-link">
					<div class="scl-date-information-list__item-date">
						<span>26 Jul</span>
					</div>
					<div class="scl-date-information-list__item-content-wrapper">
						<div class="scl-date-information-list__item-content">
							<h6 class="scl-date-information-list__item-title">{{ $root.dummyWords }}</h6>
							<p v-if="$vuetify.breakpoint.smAndUp" class="scl-date-information-list__item-text">{{ $root.dummySentence }}</p>
						</div>
					</div>
				</v-card>
			</v-list-item>
		</v-list>
	</div>
`;

export default template;
