// @vue/component
import { mapGetters } from 'vuex';
import mxSecuredPaymentForms from '@/_scl-fed/mixins/payment/mxSecuredPaymentForms';

const component = {
	name: 'SclPaymentDetails',
	props: {
		inputOptions: {
			type: Object
		},
		inputRules: {
			type: Object
		},
		pattern: {
			type: Object
		}
	},
	mixins: [mxSecuredPaymentForms],
	created() {
		this.$store.dispatch('checkoutApiGetClientToken').then(() => {
			const paymentClientToken = this.getPaymentClientToken;
			this.setHostedFieldsForm(paymentClientToken);
		});
	},
	data() {
		return {
			paymentMethod: 'creditCard',
			formData: {
				name: {
					label: 'Name on card',
					error: '',
					isValid: false
				},
				cardNumber: {
					label: 'Card Number',
					error: '',
					isValid: false
				},
				expiry: {
					label: 'Expiry',
					error: '',
					isValid: false
				},
				cvv: {
					label: 'CVV',
					error: '',
					isValid: false
				}
			},
			braintreeOptions: {
				auth: {
					authorization: null
				},
				fieldStyles: {
					input: {
						margin: '0',
						font: 'MuseoSans,"system-ui",-apple-system,Segoe UI,Helvetica Neue,Roboto,Arial,Noto Sans,sans-serif',
						flex: '1 1 auto',
						padding: '8px 0 8px',
						width: '100%',
						color: '#2d373e',
						'-webkit-font-smoothing': 'antialiased',
						'-webkit-tap-highlight-color': 'rgba(0,0,0,0)',
						'--animate-duration': '1s',
						'--animate-delay': '1s',
						'--animate-repeat': '1',
						'-webkit-box-direction': 'normal',
						'box-sizing': 'inherit',
						'background-repeat': 'no-repeat',
						'border-radius': '0',
						'background-color': 'transparent',
						'border-style': 'none',
						'-webkit-box-flex': '1',
						'max-width': '100%',
						'min-width': '0',
						'max-height': '32px',
						'font-weight': '600',
						'font-size': '16px',
						'line-height': '1.5'
					}
				},
				fieldDefinitions: {
					cardholderName: {
						container: '#cardholder-name',
						placeholder: 'Mr J Smith'
					},
					number: {
						selector: '#card-number',
						placeholder: '4111 1111 1111 1111'
					},
					cvv: {
						selector: '#cvv',
						placeholder: '123'
					},
					expirationDate: {
						selector: '#expiration-date',
						placeholder: 'MM/YYYY'
					}
				},
				callbackEventName: 'emit-hosted-fields-blur',
				hasLocalHostedInstance: false,
				hostedInstanceEventName: '',
				hasDistpachEvent: true,
				dispatchEventName: 'checkoutSetHostedFieldsInstance'
			},
			paymentError: false
		};
	},
	computed: {
		...mapGetters(['getPaymentClientToken']),
		isValid() {
			return !!(this.formData.name.isValid && this.formData.cardNumber.isValid && this.formData.expiry.isValid && this.formData.cvv.isValid);
		}
	},
	mounted() {
		this.$root.$on(this.braintreeOptions.callbackEventName, (emittedField) => {
			this._mxSecuredPaymentForms_validateHostedFields(emittedField);
			this.checkValidity();
		});
	},
	methods: {
		checkValidity() {
			if (this.isValid) {
				this.$emit('emit-payment-form', true, this.form);
			} else {
				this.$emit('emit-payment-form', false, this.form);
			}
		},
		getDate() {
			return new Date().toLocaleDateString('en-Au');
		},
		setPaymentMethod(val) {
			this.paymentMethod = val;
		},
		setHostedFieldsForm(token) {
			if (!token) {
				return;
			}
			this.braintreeOptions.auth.authorization = token;
			this._mxSecuredPaymentForms_createInstance(this.braintreeOptions);
		}
	},
	template: null
};

// set template and dummy data if development environment variable string
// if (process.env.VUE_APP_DEVELOPMENT === 'true') {
component.template = require(`./${component.name}-template`).default;
// }

// css import
require(`./_${component.name}.scss`);

// export component
export default component;
