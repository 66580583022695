/**
 * SclTrainingFiltering
 */

// template
const template = `
	<div>
		<v-btn v-if="$vuetify.breakpoint.xsOnly" class="scl-training-filtering__filter-button btn btn-outlined" @click="filtersActive = !filtersActive">
			<v-icon left>$vuetify.icons.faSlidersH</v-icon>
			Filters
		</v-btn>
		<div class="scl-training-filtering__container" :class="{ 'scl-training-filtering__container--active' : filtersActive }">
			<v-row>
				<v-col cols="12" sm="3">
					<v-select
						ref="region-filter"
						:append-icon="region.active ? icons.filterOpen : icons.filterClosed"
						attach=".scl-training-filtering__region"
						background-color="#fff"
						@change="updateFilters"
						class="scl-training-filtering__select scl-training-filtering__region"
						:disabled="isDisabled"
						height="56"
						hide-details
						:items="regions"
						multiple
						outlined
						v-model="region.model"
					>
						<template v-if="region.model.length" v-slot:prepend-inner>
							{{region.label}} ({{region.model.length}})
						</template>
						<template v-else v-slot:prepend-inner>
							{{region.label}}
						</template>
					</v-select>
					<!-- testing chips for future sprint
					<div>
						<v-chip v-for="(region, regionIndex) in region.model" class="scl-training-filtering__chip" color="secondary" :key="regionIndex">
							{{ region }}
							<v-avatar right>
								<v-icon>$vuetify.icons.faTimes</v-icon>
							</v-avatar>
						</v-chip>
					</div>
					-->
				</v-col>
				<v-col cols="12" md="3">
					<v-select
						ref="category-filter"
						:append-icon="category.active ? icons.filterOpen : icons.filterClosed"
						attach=".scl-training-filtering__category"
						background-color="#fff"
						@change="updateFilters"
						class="scl-training-filtering__select scl-training-filtering__category"
						:disabled="isDisabled"
						height="56"
						hide-details
						:items="categories"
						multiple
						outlined
						v-model="category.model"
					>
						<template v-if="category.model.length" v-slot:prepend-inner>
							{{category.label}} ({{category.model.length}})
						</template>
						<template v-else v-slot:prepend-inner>
							{{category.label}}
						</template>
					</v-select>
				</v-col>
				<v-col cols="12" md="3">
					<v-select
						ref="type-filter"
						:append-icon="type.active ? icons.filterOpen : icons.filterClosed"
						attach=".scl-training-filtering__type"
						background-color="#fff"
						@change="updateFilters"
						class="scl-training-filtering__select scl-training-filtering__type"
						:disabled="isDisabled"
						height="56"
						hide-details
						:items="types"
						multiple
						outlined
						v-model="type.model"
					>
						<template v-if="type.model.length" v-slot:prepend-inner>
							{{type.label}} ({{type.model.length}})
						</template>
						<template v-else v-slot:prepend-inner>
							{{type.label}}
						</template>
					</v-select>
				</v-col>
				<v-col cols="12" md="3">
					<v-select
						ref="qualification-filter"
						:append-icon="qualification.active ? icons.filterOpen : icons.filterClosed"
						attach=".scl-training-filtering__qualification"
						background-color="#fff"
						@change="updateFilters"
						class="scl-training-filtering__select scl-training-filtering__qualification"
						:disabled="isDisabled"
						height="56"
						hide-details
						:items="qualifications"
						multiple
						outlined
						v-model="qualification.model"
					>
						<template v-if="qualification.model.length" v-slot:prepend-inner>
							{{qualification.label}} ({{qualification.model.length}})
						</template>
						<template v-else v-slot:prepend-inner>
							{{qualification.label}}
						</template>
					</v-select>
				</v-col>
				<v-col cols="12" md="8">
					<scl-tags :api="trainingTags" @emit-tags-remove="handleTagRemove"></scl-tags>
				</v-col>
				<v-col cols="12" md="4">
					<div>
						<v-btn v-if="qualification.model.length || category.model.length || type.model.length || region.model.length" class="scl-training-filtering__clear" color="primaryDark" @click="clearFilters" text>
							<v-icon left>$vuetify.icons.faTimes</v-icon>
							Clear all filters
						</v-btn>
					</div>
				</v-col>
			</v-row>
		</div>
	</div>
`;

// export template
export default template;
