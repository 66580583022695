import { gmapApi } from 'gmap-vue';

// @vue/component
import location from '../../services/api/location';

const component = {
	name: 'SclFormLocation',
	props: {
		api: {
			type: Object,
			required: false,
			// props type validation
			validator: (obj) => {
				const set = new Set();
				set.add(!!(obj.icon ? typeof obj.icon === 'string' : true));
				set.add(!!(obj.type ? typeof obj.type === 'string' : true));
				set.add(!!(obj.dismissible ? typeof obj.dismissible === 'boolean' : true));
				set.add(!!(obj.outline ? typeof obj.outline === 'boolean' : true));
				set.add(!!(obj.title ? typeof obj.title === 'string' : true));
				set.add(!!(obj.text ? typeof obj.text === 'string' : true));
				set.add(!!(obj.successMessage ? typeof obj.successMessage === 'string' : true));
				set.add(!!(obj.fallBackText ? typeof obj.fallBackText === 'string' : true));

				return !set.has(false);
			}
		},
		region: {
			type: String
		}
	},
	data() {
		return {
			dialog: false,
			color: 'black',
			snackbar: false,
			showMessage: false,
			timeout: 6000,
			y: 'bottom',
			locationSearchText: ''
		};
	},
	async mounted() {
		await this.$gmapApiPromiseLazy();
		this.checkVisit();
	},
	computed: {
		propApi() {
			return {
				icon: '$vuetify.icons.faMapMarker',
				type: 'info',
				dismissible: true,
				outline: false,
				title: 'Set my location',
				text: 'Your location is set to',
				successMessage: null,
				fallBackText: 'Regional',
				...this.api
			};
		},
		google: gmapApi
	},
	methods: {
		initSearch() {
			this.dialog = true;
			setTimeout(() => {
				const self = this;
				const locationInput = this.$refs.locationRef.$refs.input;
				const options = {
					types: ['address'],
					componentRestrictions: { country: 'au' }
				};
				const locationAutocomplete = new this.google.maps.places.Autocomplete(locationInput, options);
				locationAutocomplete.addListener('place_changed', () => {
					const place = locationAutocomplete.getPlace();
					for (let i = 0; i < place.address_components.length; i++) {
						for (let j = 0; j < place.address_components[i].types.length; j++) {
							if (place.address_components[i].types[j] === 'postal_code') {
								self.locationSearchText = place.address_components[i].long_name;
							}
						}
					}
				});
			}, 1000);
		},
		setLocation() {
			location.setLocation(this.locationSearchText, this.propApi.fallBackText).then((response) => {
				this.region = response.data.currentRegion;
				this.showMessage = true;
				this.dialog = false;
				this.snackbar = true;
			});
		},
		checkVisit() {
			if (localStorage.getItem('firstVist') == null) {
				this.snackbar = true;
				setTimeout(() => {
					localStorage.setItem('firstVist', 'done');
				}, 6000);
			}
		}
	},
	template: null
};

// set template and dummy data if development environment variable string
if (process.env.VUE_APP_DEVELOPMENT === 'true') {
	component.template = require(`./${component.name}-template`).default;
}

// css import
require(`./_${component.name}.scss`);

// export component
export default component;
