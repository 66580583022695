// @vue/component

import { mapGetters } from 'vuex';

const component = {
	name: 'SclProduct',
	props: {
		currencySymbol: {
			type: String
		},
		description: {
			type: String
		},
		longDescription: {
			type: String
		},
		name: {
			type: String
		},
		price: {
			type: Number
		},
		adjustedPrice: {
			type: Number
		}
	},
	computed: {
		...mapGetters(['getMemberStatus']),
		membersSaving() {
			return this.returnNumber(this.price - this.adjustedPrice);
		},
		membersPrice() {
			return this.returnNumber(this.adjustedPrice);
		},
		listPrice() {
			return this.returnNumber(this.price);
		},
		isMember() {
			return this.getMemberStatus === 'Member';
		}
	},
	methods: {
		returnNumber(val) {
			return Number(val).toFixed(2);
		}
	},
	template: null
};

// set template and dummy data if development environment variable string
// if (process.env.VUE_APP_DEVELOPMENT === 'true') {
component.template = require(`./${component.name}-template`).default;
// }

// css import
require(`./_${component.name}.scss`);

// export component
export default component;
