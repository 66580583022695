import axios from 'axios';
import '@/_scl-fed/config/axios';

export default {
	async siteSearch(params) {
		const fixedParams = new URLSearchParams(params);
		if (fixedParams.has('pg')) {
			fixedParams.set('pg', parseInt(fixedParams.get('pg'), 10) - 1);
		}
		const response = await axios.get(`/search/searchall?${fixedParams.toString()}`);
		return response;
	}
};
