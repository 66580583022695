import _camelCase from 'lodash/camelCase';
// Multi Step Form Widget State

export default {
	state: () => ({
		isLoading: false,
		multiStepFramework: {
			noOfSteps: 0,
			activeStep: 0,
			steps: [],
			constructedFormData: null
		}
	}),
	getters: {
		getMultiStepFrameworkState(state) {
			return state.multiStepFramework;
		},
		getNoOfSteps(state) {
			return state.multiStepFramework.noOfSteps;
		},
		getSteps(state) {
			return state.multiStepFramework.steps;
		},
		getActiveStep(state) {
			return state.multiStepFramework.activeStep;
		},
		getFormLoading(state) {
			return state.isLoading;
		},
		getStepperFormData(state) {
			return state.multiStepFramework.constructedFormData;
		}
	},
	mutations: {
		initializeSteps(state, noOfSteps) {
			if (noOfSteps === null) {
				return;
			}
			state.multiStepFramework.steps = []; // clear out previously defined step structure
			for (let step = 1; step <= noOfSteps; step++) {
				state.multiStepFramework.steps.push({
					stepNumber: step,
					noOfChildSteps: 0,
					activeChildStep: 0,
					childSteps: []
				});
			}
			state.multiStepFramework.noOfSteps = noOfSteps;
			state.multiStepFramework.activeStep = 1; // always default to step 1
		},
		initializeChildSteps(state, payload) {
			/* eslint-disable */
			const parentStepNo = payload.parentStepNo - 1;
			const noOfChildSteps = payload.noOfChildSteps;
			for (let step = 1; step <= noOfChildSteps; step++) {
				state.multiStepFramework.steps[parentStepNo].childSteps.push({
					formData: {},
					isValid: false,
					stepName: ''
				});
			}
			state.multiStepFramework.steps[parentStepNo].noOfChildSteps = noOfChildSteps;
			state.multiStepFramework.steps[parentStepNo].activeChildStep = 1;
		},
		setParentActiveStep(state, stepNumber) {
			state.multiStepFramework.activeStep = stepNumber;
		},
		setChildActiveStep(state, payload) {
			const parentStep = payload.parentStepNo - 1;
			const childStepNumber = payload.activeChildStep;
			state.multiStepFramework.steps[parentStep].activeChildStep = childStepNumber;
		},
		progressCurrentStep(state) {
			if (state.multiStepFramework.steps.length > state.multiStepFramework.activeStep) {
				state.multiStepFramework.activeStep++;
			}
		},
		progressCurrentChildStep(state) {
			const activeStep = state.multiStepFramework.activeStep - 1;
			const childStepsLength = state.multiStepFramework.steps[activeStep].childSteps.length;
			const activeChildStep = state.multiStepFramework.steps[activeStep].activeChildStep;
			if (childStepsLength >= activeChildStep) {
				state.multiStepFramework.steps[activeStep].activeChildStep++;
			}
		},
		setChildData(state, payload) {
			if (payload == null) {
				return;
			}
			const parentStepNo = payload.parentStepNo - 1;
			const childStepNo = payload.childStepNo - 1;
			const childData = {
				formData: payload.formData,
				isValid: payload.isValid,
				stepName: payload.stepName
			};

			// if payload consist of data that can be copied
			if (payload.rawFormData) {
				childData.rawFormData = payload.rawFormData;
			}

			state.multiStepFramework.steps[parentStepNo].childSteps[childStepNo] = childData;
		},
		setChildValidity(state, payload) {
			if (payload == null) {
				return;
			}
			const parentStepNo = payload.parentStepNo - 1;
			const childStepNo = payload.childStepNo - 1;
			state.multiStepFramework.steps[parentStepNo].childSteps[childStepNo].isValid = payload.isValid;
		},
		updateConstructedFormData(state) {
			const constructedFormData = {};
			state.multiStepFramework.steps.forEach((step) => {
				const normalisedChildSteps = Object.values(step.childSteps);

				normalisedChildSteps.forEach((childStep) => {
					if (Object.prototype.toString.call(childStep.formData).indexOf('Object') > -1) {
						let formData = [];
						const formObject = childStep.formData;

						if (Object.entries(formObject).length > 0) {
							formData.push(formObject);
							childStep.formData = formData;
						}
					}
				});

				const childSteps = normalisedChildSteps.filter((x) => x !== null && x.formData.length > 0);

				if (childSteps.length === 0) {
					return;
				}

				childSteps.forEach((childStep) => {
					const stepName = _camelCase(childStep.stepName);
					constructedFormData[stepName] = {};
					if (childStep.formData.length > 0) {
						childStep.formData.forEach((formValue) => {
							let propertyName = formValue.title;
							let propertyValue = formValue.text;

							if (formValue.name) {
								propertyName = formValue.name;
							}

							if (formValue.code) {
								propertyValue = formValue.code;
							}
							if (formValue.typeItemId) {
								constructedFormData[stepName] = { productId: formValue.productID, typeItemId: formValue.typeItemId };
							} else {
								constructedFormData[stepName][_camelCase(propertyName)] = propertyValue;
							}
						});
					}
				});
			});
			return (state.multiStepFramework.constructedFormData = constructedFormData);
		},
		setLoading(state, loading) {
			state.isLoading = loading;
		}
	},
	actions: {
		initializeMultiStep({ commit }, steps) {
			commit('initializeSteps', steps);
		},
		initializeChildMultiStep({ commit }, payload) {
			commit('initializeChildSteps', payload);
		},
		progressStep({ commit }) {
			commit('progressCurrentStep');
		},
		progressChildStep({ commit }) {
			commit('progressCurrentChildStep');
		},
		setChildStepData({ commit }, payload) {
			commit('setChildData', payload);
			commit('updateConstructedFormData');
		},
		setChildValidation({ commit }, payload) {
			commit('setChildValidity', payload);
		},
		navigateStep({ commit }, stepNumber) {
			commit('setParentActiveStep', stepNumber);
		},
		navigateChildStep({ commit }, payload) {
			commit('setChildActiveStep', payload);
		}
	}
};
