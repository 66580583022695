/**
 * SclCarouselTestimonial api
 *
 * cycle prop
 * @param {boolean} true || false
 *
 * delimiterIcon prop
 * @param {string} string "$vuetify.icons.faCircle"
 *
 * hideDelimiters prop
 * @param {boolean} true || false
 *
 * showArrows prop
 * @param {boolean} true || false
 *
 * height prop
 * @param {number} Number 400 (small)
 * @param {number} Number 600 (medium)
 * @param {number} Number 700 (large)
 *
 * interval prop
 * @param {number} Number 9000 (slow)
 * @param {number} Number 7000 (medium)
 * @param {number} Number 5000 (fast)
 *
 * nextIcon prop
 * @param {string} string "$vuetify.icons.faArrowRight"
 *
 * prevIcon prop
 * @param {string} string "$vuetify.icons.faArrowLeft"
 */

const template = `
<div>

	<v-carousel
		:cycle="propApi.cycle"
		class="scl-carousel-testimonial__carousel"
		:delimiter-icon="propApi.delimiterIcon"
		:height="propApi.height"
		hide-delimiter-background
		:show-arrows="propApi.showArrows"
		:hide-delimiters="propApi.hideDelimiters"
		:interval="propApi.interval"
		:next-icon="propApi.nextIcon"
		:prev-icon="propApi.prevIcon"
		ref="carouselTestimonialRef"
	>
		<v-carousel-item class="scl-carousel-testimonial__item">
			<v-row class="scl-carousel-testimonial__row">
				<v-col class="py-0" cols="12">
					<v-lazy>
						<v-card class="scl-carousel-testimonial__card" href="#" tile>
							<v-img
								v-if="$vuetify.breakpoint.xsOnly"
								aspect-ratio="0.72"
								class="scl-carousel-testimonial__img"
								:lazy-src="$root.lazyImg"
								src="https://picsum.photos/767/1070"
							>
								<v-card-title class="scl-carousel-testimonial__title">{{ $root.dummyWords }}</v-card-title>
								<v-card-subtitle class="scl-carousel-testimonial__subtitle">{{ $root.dummyWords }}</v-card-subtitle>
								<v-card-text class="scl-carousel-testimonial__text">{{ $root.dummySentence }}</v-card-text>
							</v-img>
							<v-img
								v-else
								aspect-ratio="4.37"
								class="scl-carousel-testimonial__img"
								:lazy-src="$root.lazyImg"
								src="https://picsum.photos/1608/368"
							>
								<v-card-title class="scl-carousel-testimonial__title">{{ $root.dummyWords }}</v-card-title>
								<v-card-subtitle class="scl-carousel-testimonial__subtitle">{{ $root.dummyWords }}</v-card-subtitle>
								<v-card-text class="scl-carousel-testimonial__text">{{ $root.dummySentence }}</v-card-text>
							</v-img>
						</v-card>
					</v-lazy>
				</v-col>
			</v-row>
		</v-carousel-item>
	</v-carousel>
</div>
`;

export default template;
