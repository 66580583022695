/**
 * All components for global registration
 */

import AllComponents from './GlobalComponents.vue';
import { Scl1Col, Scl2Col, Scl2Col4, Scl2Col8, Scl3Col, Scl4Col, SclGridContainer4 } from './content/grid/index';
import { SclAccordion } from './accordion/index';
import { SclAlert } from './content/alert/index';
import {
	SclArticleFacets,
	SclArticleListing,
	SclArticleListItem,
	SclArticleSearch,
	SclArticleSorting,
	SclCategoryAccordion,
	SclCategoryCarouselList,
	SclCategoryList,
	SclLatestArticles,
	SclKeyInfoDownload
} from './blog/index';
import { SclAwardsCalendar, SclAwardCategoriesList } from './awards/index';
import { SclBottomSheet } from './content/bottom-sheet/index';
import { SclBreadcrumbs } from './navigation/breadcrumbs/index';
import { SclCardVertical, SclCardHorizontal, SclCardIcon, SclCardImage, SclCardVideo } from './content/card/index';
import {
	SclArticleCardCarousel,
	SclCarouselMedia,
	SclCarouselTestimonial,
	SclCarouselCategory,
	SclContentCarousel,
	SclDynamicCarouselGrid,
	SclProductCarousel
} from './media/carousel/index';
import {
	SclCartSummary,
	SclCheckout,
	SclMinicart,
	SclPaymentSummary,
	SclProductDetail,
	SclProductListing,
	SclKeyInfoProduct,
	SclProduct,
	SclProductCard,
	SclProductCategories,
	SclProductFacets,
	SclProductGallery,
	SclProductSearch,
	SclProductSort,
	SclProductPageSize,
	SclProductRelated
} from './commerce/index';
import { SclAdContainer } from './content/containers/index';
import { SclCta } from './content/cta/index';
import { SclDatePicker } from './content/date-picker/index';
import { SclDummyData } from './example/dummy/index';
import { SclEventsFiltering, SclEventsListing, SclEventsListItem, SclEventsSearch, SclEventsCalendar } from './events/index';
import { SclExample, SclExampleApi, SclExampleButtons, SclExampleForms } from './example/index';
import { SclFooter, SclFooterContact, SclFooterSearch, SclFooterSocial } from './navigation/footer/index';
import { SclFormLocation } from './location/index';
import { SclHeader, SclHeaderContact, SclHeaderProfileMenu } from './navigation/header/index';
import { SclSecondaryHeaderBanner } from './media/banner/index';
import { SclImage, SclImageBg, SclImageGallery, SclImageSrcset } from './media/image/index';
import { SclDateInformationList, SclInformationList, SclImageList, SclRelatedArticlesList, SclRelatedDownloads, SclRelatedLinks } from './content/lists/index';
import { SclKeyInfo } from './content/key-info/index';
import { SclMap } from './mapping/index';
import { SclMiniNav } from './navigation/minnav/index';
import { SclPagination, SclPageSize } from './search/pagination/index';
import {
	SclCategorySelector,
	SclCategoryPdfList,
	SclProductCollections,
	SclProductCollectionSummary,
	SclProductCategoryList,
	SclProductList,
	SclFacets,
	SclProductLatest,
	SclRelatedProducts,
	SclProductVariantTable,
	SclProductSummary
} from './product/index';
import { SclSelectionCountryIcon, SclSelectionCountryButton, SclTertiaryNav } from './navigation/menu/index';
import { SclMultiStepFramework, SclMultiChildStepFramework, SclStepSummary } from './content/multi-step-form/index';
import { SclSelectionLanguage } from './navigation/language-select/index';
import { SclRegionSelector, SclSearchCustom, SclSearchCuratedTerms, SclSearchListing } from './search/index';
import { SclSnackbar } from './snackbar/index';
import { SclSocialFeed, SclSocialShare } from './social/index';
import { SclSpacer } from './content/spacer/index';
import { SclStoreDetail, SclStoreCategoryList, SclStoreLocator, SclStoreList } from './stores/index';
import { SclTabs } from './content/tabs/index';
import { SclTimePicker } from './content/time-picker/index';
import { SclTrainingListing } from './training/index';
import { SclVideo } from './media/video/index';
import { SclWysiwyg } from './content/wysiwyg/index';
import { SclTags } from './tagging/index';
import { SclAuthLoginBtn, SclAuthLoginModal, SclAuthActivationForm } from './membership/authentication/index';
import { SclContentGate } from './portal/index';

export default [
	AllComponents,
	Scl1Col,
	Scl2Col,
	Scl2Col4,
	Scl2Col8,
	Scl3Col,
	Scl4Col,
	SclAccordion,
	SclAdContainer,
	SclAlert,
	SclArticleCardCarousel,
	SclArticleFacets,
	SclArticleListing,
	SclArticleListItem,
	SclArticleSearch,
	SclArticleSorting,
	SclAwardCategoriesList,
	SclAwardsCalendar,
	SclBottomSheet,
	SclBreadcrumbs,
	SclCardHorizontal,
	SclCardIcon,
	SclCardImage,
	SclCardVertical,
	SclCardVideo,
	SclCarouselCategory,
	SclCarouselMedia,
	SclCarouselTestimonial,
	SclCartSummary,
	SclCheckout,
	SclCategoryList,
	SclCategoryAccordion,
	SclCategoryCarouselList,
	SclCategorySelector,
	SclCategoryPdfList,
	SclContentCarousel,
	SclContentGate,
	SclCta,
	SclDatePicker,
	SclDummyData,
	SclKeyInfoDownload,
	SclKeyInfoProduct,
	SclDynamicCarouselGrid,
	SclEventsFiltering,
	SclEventsListing,
	SclEventsListItem,
	SclEventsSearch,
	SclEventsCalendar,
	SclDateInformationList,
	SclInformationList,
	SclExample,
	SclExampleApi,
	SclExampleButtons,
	SclExampleForms,
	SclFooter,
	SclFooterContact,
	SclFooterSearch,
	SclFooterSocial,
	SclFormLocation,
	SclGridContainer4,
	SclHeader,
	SclHeaderContact,
	SclHeaderProfileMenu,
	SclSecondaryHeaderBanner,
	SclImage,
	SclImageBg,
	SclImageGallery,
	SclImageList,
	SclImageSrcset,
	SclKeyInfo,
	SclLatestArticles,
	SclMap,
	SclMinicart,
	SclPagination,
	SclPageSize,
	SclPaymentSummary,
	SclMiniNav,
	SclMiniNav,
	SclProduct,
	SclProductCollections,
	SclProductCollectionSummary,
	SclProductCategoryList,
	SclProductCard,
	SclProductCarousel,
	SclProductCategories,
	SclFacets,
	SclProductFacets,
	SclProductDetail,
	SclProductGallery,
	SclProductLatest,
	SclProductList,
	SclProductListing,
	SclProductPageSize,
	SclProductRelated,
	SclProductSearch,
	SclProductSort,
	SclProductVariantTable,
	SclProductSummary,
	SclRegionSelector,
	SclRelatedArticlesList,
	SclRelatedDownloads,
	SclRelatedLinks,
	SclRelatedProducts,
	SclSearchListing,
	SclSelectionCountryButton,
	SclSelectionCountryIcon,
	SclSelectionLanguage,
	SclSearchCustom,
	SclSearchCuratedTerms,
	SclSnackbar,
	SclSocialFeed,
	SclSocialShare,
	SclSpacer,
	SclStepSummary,
	SclStoreDetail,
	SclStoreCategoryList,
	SclStoreLocator,
	SclStoreList,
	SclTabs,
	SclTertiaryNav,
	SclTimePicker,
	SclTrainingListing,
	SclVideo,
	SclWysiwyg,
	SclTags,
	SclAuthLoginBtn,
	SclAuthLoginModal,
	SclAuthActivationForm,
	SclMultiStepFramework,
	SclMultiChildStepFramework
];
