/**
 * SclPageSize
 */

// template
const template = `
	<div>
        <div class="scl-page-size__items align-center">
            <div class="scl-page-size__title">
                View per page:
            </div>
            <div>
                <v-btn :ripple="false" v-for="(item, index) in propApi.pageSize" :key="index" :class="{ 'active' :item.value == activeItem }" :disabled="isDisabled" @click="setPageSize(item.value)" text color="white" class="scl-page-size__button" >{{item.value}}</v-btn>
            </div>
        </div>
	</div>
`;

// export template
export default template;
