/**
 * SclVideo
 *
 * showInfo prop
 * @param {boolean} showInfo - toggles rendering of container for overlay text
 */

// template
const template = `
	<div>
		<figure>
			<v-row v-if="propApi.showInfo">
				<v-col cols="12" md="6">
					<video class="scl-video__video scl-video__video--embedded" :poster="$root.dummyImage169" id="player" playsinline controls>
						<source :src="$root.dummyVideo" type="video/mp4">
					</video>
				</v-col>
				<v-col cols="12" md="6">
					<h3>{{$root.dummyWords}}</h3>
					<h4>{{$root.dummyWords}}</h4>
					<p>{{$root.dummyParagraph}}</p>
				</v-col>
			</v-row>
			<div v-else>
				<video class="scl-video__video scl-video__video--embedded" poster=""  id="player" playsinline controls>
					<source :src="$root.dummyVideo" type="video/mp4">
				</video>
				<div class="scl-video__video scl-video__video--vimeo"  id="player">
					<iframe  src="https://player.vimeo.com/video/1084537?loop=false&amp;byline=false&amp;portrait=false&amp;title=false&amp;speed=true&amp;transparent=0&amp;gesture=media" allowfullscreen allowtransparency allow="autoplay"></iframe>
				</div>
				<div class="scl-video__video scl-video__video--youtube" id="player">
					<iframe src="https://www.youtube.com/embed/aqz-KE-bpKQ?origin=https://plyr.io&amp;iv_load_policy=3&amp;modestbranding=1&amp;playsinline=1&amp;showinfo=0&amp;rel=0&amp;enablejsapi=1" allowfullscreen allowtransparency allow="autoplay"></iframe>
				</div>
			</div>
			<figcaption class="scl-video__caption">{{ $root.dummyWords }}
				<a :href="$root.dummyUrl">{{ $root.dummyWords }}</a>
			</figcaption>
		</figure>
	</div>
`;

// export template
export default template;
