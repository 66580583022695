/**
 * SclExample Buttons
 *
 */

// template
const template = `
	<div>
		<v-container>
			<v-row>
				<v-col cols="12">
					<div class="mb-3">
						<!-- x small buttons -->
						<a class="btn btn-primary btn--x-small">XSmall</a>
						<a class="btn btn-outlined btn--x-small">{{ $root.dummyWord }}</a>
						<a class="btn btn-link btn--x-small" text>{{ $root.dummyWord }} <v-icon class="btn__icon-link"> $vuetify.icons.faArrowRight </v-icon></a>
					</div>
					<div class="mb-3">
						<!-- small buttons -->
						<a class="btn btn-primary btn--small">Small</a>
						<a class="btn btn-outlined btn--small">{{ $root.dummyWord }}</a>
						<a class="btn btn-link btn--small" text>{{ $root.dummyWord }} <v-icon class="btn__icon-link"> $vuetify.icons.faArrowRight </v-icon></a>
					</div>
					<div class="mb-3">
						<!-- default buttons -->
						<v-btn :ripple="false" class="btn btn-primary">Default</v-btn>
						<v-btn :ripple="false" class="btn btn-outlined">{{ $root.dummyWord }} </v-btn>
						<v-btn :ripple="false" class="btn btn-link" text>{{ $root.dummyWord }} <v-icon class="btn__icon-link"> $vuetify.icons.faArrowRight </v-icon></v-btn>
					</div>
					<div class="mb-3">
						<!-- large buttons -->
						<v-btn :ripple="false" class="btn btn--large btn-primary">Large</v-btn>
						<v-btn :ripple="false" class="btn btn--large btn-outlined"><v-icon class="btn__icon"> $vuetify.icons.faCircle </v-icon>{{ $root.dummyWords }} </v-btn>
						<v-btn :ripple="false" class="btn btn--large btn-link" text>{{ $root.dummyWords }} <v-icon class="btn__icon-link"> $vuetify.icons.faArrowRight </v-icon></v-btn>
					</div>

					<div class="mb-3">
						<!-- disabled buttons -->
						<v-btn disabled :ripple="false" class="btn btn--large btn-primary">Disabled</v-btn>
						<v-btn disabled :ripple="false" class="btn btn--large btn-outlined"><v-icon class="btn__icon"> $vuetify.icons.faCircle </v-icon>{{ $root.dummyWords }} </v-btn>
						<v-btn disabled :ripple="false" class="btn btn--large btn-link" text>{{ $root.dummyWords }} <v-icon class="btn__icon-link"> $vuetify.icons.faArrowRight </v-icon></v-btn>
					</div>
					<div class="v-list theme--dark ">
						<v-btn :ripple="false" class="btn btn--large btn-primary">{{ $root.dummyWord }}</v-btn>
						<v-btn :ripple="false" class="btn btn--large btn-outlined">{{ $root.dummyWords }} </v-btn>
						<v-btn :ripple="false" class="btn btn--large btn-link" text>{{ $root.dummyWords }} <v-icon class="btn__icon-link"> $vuetify.icons.faArrowRight </v-icon></v-btn>
					</div>
				</v-col>
			</v-row>
		</v-container>
	</div>
`;

// export template
export default template;
