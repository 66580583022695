/**
 * SclCarouselMedia api
 *
 * cycle prop
 * @param {boolean} true || false
 *
 * delimiterIcon prop
 * @param {string} string "$vuetify.icons.faCircle"
 *
 * hideDelimiters prop
 * @param {boolean} true || false
 *
 * showArrows prop
 * @param {boolean} true || false
 *
 * height prop
 * @param {string} string 'auto'
 *
 * interval prop
 * @param {number} Number 9000 (slow)
 * @param {number} Number 7000 (medium)
 * @param {number} Number 5000 (fast)
 *
 * nextIcon prop
 * @param {string} string "$vuetify.icons.faChevronRight"
 *
 * prevIcon prop
 * @param {string} string "$vuetify.icons.faChevronLeft"
 *
 * color prop
 * @param {string} string '#00b9dd'
 *
 * alignRight prop
 * @param {boolean} true || false
 *
 *  alignRight prop
 * @param {string} string "left"
 * @param {string} string "center"
 * @param {string} string "right"
 *
 */

const template = `
	<div>
			<v-carousel
				:cycle="propApi.cycle"
				class="scl-carousel-media__carousel"
				:class="'scl-carousel-media__carousel--' + propApi.delimiterPosition "
				:delimiter-icon="propApi.delimiterIcon"
				:height="auto"
				hide-delimiter-background
				:show-arrows="propApi.showArrows"
				:hide-delimiters="propApi.hideDelimiters"
				:interval="propApi.interval"
				:next-icon="propApi.nextIcon"
				:prev-icon="propApi.prevIcon"
				ref="carouselMediaRef"
				light
			>

				<!-- item - video example -->
				<v-carousel-item class="scl-carousel-media__item ">
					<v-row class="scl-carousel-media__row">
						<v-col class="py-0" cols="12">
							<v-card class="scl-carousel-media__card" tile>
								<!-- video -->
								<video class="scl-carousel-media__media scl-carousel-media__media-video" autoplay loop muted playsinline>
									<source :src="$root.dummyVideo" type="video/mp4">
								</video>
								<div class="scl-carousel-media__content" :style="'background:linear-gradient(' +setGradient(propApi.gradient) +')'">
									<v-card-subtitle class="scl-carousel-media__subtitle">{{ $root.dummyWords }}</v-card-subtitle>
									<h1 class="scl-carousel-media__title">{{ $root.dummyWords }}</h1>
									<v-card-text class="scl-carousel-media__text">{{ $root.dummySentence }}</v-card-text>
									<v-card-actions>
										<v-btn :rippled="false" class="btn btn--large btn-primary scl-carousel-media__btn" :color="propApi.color" depressed href="#" >{{ $root.dummyWords }}</v-btn>
									</v-card-actions>
								</div>
							</v-card>
						</v-col>
					</v-row>
				</v-carousel-item>

				<!-- item - image example -->
				<v-carousel-item class="scl-carousel-media__item">
					<v-row class="scl-carousel-media__row">
						<v-col class="py-0" cols="12">
							<v-card class="scl-carousel-media__card" tile>
								<!-- mobile image -->
								<v-img
									v-if="$vuetify.breakpoint.xsOnly"
									aspect-ratio="0.8"
									class="scl-carousel-media__media scl-carousel-media__img"
									:gradient="setGradient(propApi.gradient)"
									:lazy-src="$root.lazyImg"
									src="https://picsum.photos/320/400"
								>
									<div class="scl-carousel-media__content">
										<v-card-title class="scl-carousel-media__title">{{ $root.dummyWords }}</v-card-title>
										<v-card-subtitle class="scl-carousel-media__subtitle">{{ $root.dummyWords }}</v-card-subtitle>
										<v-card-text class="scl-carousel-media__text">{{ $root.dummySentence }}</v-card-text>
										<v-card-actions>
										<v-btn :rippled="false" class="btn btn--large btn-primary scl-carousel-media__btn" :color="propApi.color" depressed href="#" >{{ $root.dummyWords }}</v-btn>
								</v-card-actions>
									</div>
								</v-img>
								<!-- tablet and up image -->
								<v-img
									v-else
									aspect-ratio="2.72"
									class="scl-carousel-media__media scl-carousel-media__img"
									:gradient="setGradient(propApi.gradient)"
									:lazy-src="$root.lazyImg"
									src="https://picsum.photos/1200/440"
								>
									<div class="scl-carousel-media__content">
										<v-card-title class="scl-carousel-media__title">{{ $root.dummyWords }}</v-card-title>
										<v-card-subtitle class="scl-carousel-media__subtitle">{{ $root.dummyWords }}</v-card-subtitle>
										<v-card-text class="scl-carousel-media__text">{{ $root.dummySentence }}</v-card-text>
										<v-card-actions>
										<v-btn :rippled="false" class="btn btn--large btn-primary scl-carousel-media__btn" :color="propApi.color" depressed href="#" >{{ $root.dummyWords }}</v-btn>
									</v-card-actions>
									</div>
								</v-img>
							</v-card>
						</v-col>
					</v-row>
				</v-carousel-item>

			</v-carousel>
	</div>
`;

export default template;
