// @vue/component
const component = {
	name: 'SclCategorySelector',
	props: {
		api: {
			type: Object,
			// props type validation
			validator: (obj) => {
				const set = new Set();
				set.add(!!(obj.collapsedIcon ? typeof obj.collapsedIcon === 'string' : true));
				set.add(!!(obj.expandedIcon ? typeof obj.expandedIcon === 'string' : true));
				return !set.has(false);
			}
		}
	},
	data() {
		return {
			panel: [],
			isActive: false
		};
	},
	computed: {
		// props defaults
		propApi() {
			return {
				collapsedIcon: '$vuetify.icons.faChevronDown',
				expandedIcon: '$vuetify.icons.faChevronUp',
				...this.api
			};
		}
	},
	mounted() {
		this.activePanels();
	},
	methods: {
		activePanels() {
			this.$refs.panel.$children.forEach((el, index) => {
				if (el.$el.classList.contains('scl-category-selector__accordion--active')) {
					this.panel.push(index);
				}
			});
		}
	},
	template: null
};

// set template and dummy data if development environment variable string
// if (process.env.VUE_APP_DEVELOPMENT === 'true') {
component.template = require(`./${component.name}-template`).default;
// }

// css import
require(`./_${component.name}.scss`);

// export component
export default component;
