/**
 * SclCartAdd
 */

// template
const template = `
	<div>
		<p class="mb-0"><strong>Quantity</strong></p>
		<div class="scl-cart-add__container">
			<div class="scl-cart-line-items__quantity">
				<v-text-field
					v-model="quantity"
					append-icon="$vuetify.icons.faPlus"
					background-color="#f4f4f4"
					class="scl-cart-line-items__input"
					color="accent"
					:disabled="getLoading"
					height="64"
					min="1"
					pattern="[1-9]*"
					prepend-inner-icon="$vuetify.icons.faMinus"
					:rules="[rules.number]"
					step="1"
					type="number"
					@click:append="incrementQuantity"
					@click:prepend-inner="decrementQuantity"
				>
				</v-text-field>
			</div>
			<v-btn class="scl-cart-add__btn btn btn-primary" :ripple="false" depressed height="64" large :disabled="getLoading" @click="emitQuantity">
				<v-icon v-if="!getLoading" left size="16">$vuetify.icons.faPlus</v-icon>
				<span v-if="!getLoading">Add to cart</span>
				<v-progress-circular v-if="getLoading" color="primary" indeterminate :size="40" :width="5"></v-progress-circular>
			</v-btn>
		</div>
	</div>
`;

// export template
export default template;
