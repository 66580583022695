const component = {
	name: 'SclTimePicker',
	props: {
		api: {
			type: Object,
			required: false,
			// props type validation
			validator: (obj) => {
				const set = new Set();
				set.add(!!(obj.pickerIcon ? typeof obj.pickerIcon === 'string' : true));
				return !set.has(false);
			}
		}
	},
	data() {
		return {
			time: null,
			subMenu: false
		};
	},
	computed: {
		// props defaults
		propApi() {
			return {
				pickerIcon: '$vuetify.icons.faClock',
				...this.api
			};
		}
	},
	template: null
};

// set template and dummy data if development environment variable string
if (process.env.VUE_APP_DEVELOPMENT === 'true') {
	component.template = require(`./${component.name}-template`).default;
}

// css import
require(`./_${component.name}.scss`);

export default component;
