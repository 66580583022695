// @vue/component
const component = {
	name: 'SclBillingDetails',
	props: {
		inputOptions: {
			type: Object
		},
		inputRules: {
			type: Object
		},
		pattern: {
			type: Object
		},
		formData: {
			type: Object
		}
	},
	data() {
		return {
			stateItems: ['ACT', 'NSW', 'NT', 'QLD', 'SA', 'TAS', 'VIC', 'WA']
		};
	},
	computed: {
		isValid() {
			return !!(this.formData.street.value && this.formData.suburb.value && this.formData.state.value && this.formData.postcode.value);
		},
		validPostcode() {
			return this.pattern.number.test(this.formData.postcode.value) && this.pattern.postcode.test(this.formData.postcode.value);
		}
	},
	watch: {
		formData() {
			if (Object.entries(this.formData).length > 0) {
				this.checkValidity();
			}
		}
	},
	methods: {
		checkValidity() {
			if (this.validPostcode && this.isValid) {
				this.$emit('emit-billing-form', true, this.formData);
			} else {
				this.$emit('emit-billing-form', false, this.formData);
			}
		}
	},
	template: null
};

// set template and dummy data if development environment variable string
// if (process.env.VUE_APP_DEVELOPMENT === 'true') {
component.template = require(`./${component.name}-template`).default;
// }

// css import
require(`./_${component.name}.scss`);

// export component
export default component;
