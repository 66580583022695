/**
 * SclStepSummary
 *
 */

// template
const template = `
	<div>	
		<!-- Default summary box -->
		<v-card class="scl-step-summary__item" flat v-if="(stepSummary[0].icon == null) && (stepSummary[0].value == null) && (stepSummary[0].price == null)">
			<div class="scl-step-summary__row" v-if="isSingle(stepSummary)">
				<div class="scl-step-summary__col">
					<p v-if="stepSummary[0].title" class="scl-step-summary__title">{{ stepSummary[0].title }}</p>
				</div>
				<div class="scl-step-summary__col">
					<p v-if="stepSummary[0].text" class="scl-step-summary__text"> {{ stepSummary[0].text }}</p>
				</div>
			</div>
			<div v-else class="scl-step-summary__row" v-for="(summary, index) in stepSummary" :key="index">
				<div class="scl-step-summary__col">
					<p v-if="summary.title" class="scl-step-summary__title">{{ summary.title }}</p>
				</div>
				<div class="scl-step-summary__col">
					<p v-if="isPassword(summary.title)" class="scl-step-summary__text">***********</p>
					<p v-else="summary.text" class="scl-step-summary__text"> {{ summary.text }}</p>
				</div>
			</div>
		</v-card>
	</div>
`;
// export template
export default template;
