/**
 * SclCategoryPdfList
 */

// template
const template = `
	<div>
		<v-row>
			<v-col cols="12" sm="6">
				<v-card class="scl-category-pdf-list__card" :ripple="false" flat >		
								
					<div class="scl-category-pdf-list__card-content">
						<v-card-title class="scl-category-pdf-list__card-title">
							{{ $root.dummySentence }}
						</v-card-title>
						<v-card-text class="scl-category-pdf-list__card-text">
							<v-icon>$vueitfy.icons.faDownload</v-icon>
						</v-card-text>
					</div>
					<v-img
						:alt="title"
						max-width="100"
						:lazy-src="$root.lazyImg"
						:src="$root.dummyImage43"
						contain
						position="bottom">	
					</v-img>	
				</v-card>
			</v-col>
			<v-col cols="12" sm="6">
				<v-card class="scl-category-pdf-list__card" :ripple="false" flat >		
								
					<div class="scl-category-pdf-list__card-content">
						<v-card-title class="scl-category-pdf-list__card-title">
							{{ $root.dummyWords }}
						</v-card-title>
						<v-card-text class="scl-category-pdf-list__card-text">
							<v-icon>$vueitfy.icons.faDownload</v-icon>
						</v-card-text>
					</div>
					<v-img
						:alt="title"
						max-width="100"
						:lazy-src="$root.lazyImg"
						:src="$root.dummyImage43"
						contain
						position="bottom">	
					</v-img>	
				</v-card>
			</v-col>
		</v-row>
			
	</div>
`;

// export template
export default template;
