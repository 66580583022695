/**
 * SclProfileMenu api
 *
 */

// template
const template = `
	<div v-if="getAuth" >
		<v-toolbar flat height="auto">
			<v-toolbar-items>
				<v-menu
					ref="profileMenu"
					:value="active"
					bottom
					rounded
					offset-y
					:close-on-content-click="false"
					transition="slide-y-transition" content-class="scl-header-profile-menu__wrapper"
				>
					<template v-slot:activator="scope">
							<v-btn :ripple="false"
							v-on="scope.on"
							v-bind="scope.attrs"
							:currentState="emitState(scope.attrs)"
							class="scl-header-profile-menu__button"
							>
								<span v-if="$vuetify.breakpoint.smAndUp">
									<v-avatar
										class="scl-header-profile-menu__avatar"
										size="36">
										JD
									</v-avatar>
									<span v-if="(fullName.length < 15)">{{ fullName }}</span>
									<span v-else>{{ firstName }}</span>
									<v-icon class="scl-header-profile-menu__button-icon">$vuetify.icon.faChevronDown</v-icon>
								</span>
								<v-icon v-else class="scl-header__secondary-icon"> {{ icon }} </v-icon>
							</v-btn>
					</template>
					<v-container class="scl-header-profile-menu__container pt-0">
						<v-row class="pt-0 justify-end">
							<v-col cols="12" sm="4" class="pt-0">
								<v-card class="scl-header-profile-menu__card">
									<v-list-item-content class="pt-0">
										<div class="scl-header-profile-menu__header-information">
											<h3>Welcome,
												<span v-if="(fullName.length < 15)">{{ fullName }}</span>
												<span v-else>{{ firstName }}</span>
											</h3>
											<v-btn icon>
												<v-icon class="scl-header-profile-menu__dismiss-button" @click="handleClose">$vuetify.icon.faTimes</v-icon>
											</v-btn>
										</div>
										<v-list>
											<v-list-item>
												<v-list-item-title class="scl-header-profile-menu__nav-list-link" @click="gotoUrl($root.dummyUrl)">
													<strong>{{$root.dummyWords}}</strong>
												</v-list-item-title>
											</v-list-item>
											<v-list-item>
												<v-list-item-title class="scl-header-profile-menu__nav-list-link" @click="gotoUrl($root.dummyUrl)">
													<strong>{{$root.dummyWords}}</strong>
												</v-list-item-title>
											</v-list-item>
											<v-list-item>
												<v-list-item-title class="scl-header-profile-menu__nav-list-link" @click="gotoUrl($root.dummyUrl)">
													<strong>{{$root.dummyWords}}</strong>
												</v-list-item-title>
											</v-list-item>
											<v-list-item>
												<v-list-item-title class="scl-header-profile-menu__nav-list-link" @click="gotoUrl($root.dummyUrl)">
													<strong>{{$root.dummyWords}}</strong>
												</v-list-item-title>
											</v-list-item>
											<v-divider class="my-4"></v-divider>
											<v-list-item>
												<v-list-item-title>
													<a class="btn btn-link  btn--x-small" text @click="handleLogout">Logout <v-icon class="btn__icon-link"> $vuetify.icons.faArrowRight </v-icon></a>
												</v-list-item-title>
										</v-list-item>
										</v-list>
									</v-list-item-content>
								</v-card>
							</v-col>
						</v-row>
					</v-container>
				</v-menu>
			</v-toolbar-items>
		</v-toolbar>
	</div>
	<div v-else>
		<v-toolbar flat height="auto">
			<v-toolbar-items>
				<v-menu bottom
						rounded
						offset-y
						:close-on-content-click="false"
						transition="slide-y-transition" content-class="scl-header-profile-menu__wrapper">
					<template v-slot:activator="scope">
						<v-btn :ripple="false"
							   v-on="scope.on"
							   v-bind="scope.attrs"
							   :currentState="emitState(scope.attrs)"
								@click="openLoginModal"
							   class="scl-header-profile-menu__button">
							<span v-if="$vuetify.breakpoint.smAndUp">
								<v-icon class="scl-header-profile-menu__button-icon">$vuetify.icon.faChevronDown</v-icon>
							</span>
							<v-icon v-else class="scl-header__secondary-icon"> {{ icon }} </v-icon>
						</v-btn>
					</template>
				</v-menu>
			</v-toolbar-items>
		</v-toolbar>
		</div>
`;

// export template
export default template;
