/**
 * SclFooterSocial
 *
 */

// template
const template = `

	<div>
		<div class="scl-footer-social__item">
			<div class="scl-footer-social__title"><v-icon class="scl-footer-social__title-icon">$vuetify.icons.faAt</v-icon> How can we help </div>
			<div class="scl-footer-social__content">
				<a :href="$root.dummyUrl" >
					<v-icon class="scl-footer-social__icon">$vuetify.icons.faFacebook</v-icon>
				</a>
				<a :href="$root.dummyUrl">
					<v-icon class="scl-footer-social__icon">$vuetify.icons.faTwitter</v-icon>
				</a>
				<a :href="$root.dummyUrl" >
					<v-icon class="scl-footer-social__icon">$vuetify.icons.faInstagram</v-icon>
				</a>
				<a :href="$root.dummyUrl" >
					<v-icon class="scl-footer-social__icon">$vuetify.icons.faLinkedinIn</v-icon>
				</a>
				<a :href="$root.dummyUrl" >
					<v-icon class="scl-footer-social__icon">$vuetify.icons.faYoutube</v-icon>
				</a>
				<div class="scl-footer-social__link">
					<a href="">{{$root.dummyWords}}</a>
				</div>

			</div>
		</div>
	</div>
`;

// export template
export default template;
