/**
 * SclCartOrderSummary
 */

// template
const template = `
	<div>
		<div class="scl-cart-order-summary__wrapper">
			<div class="scl-cart-order-summary__container">
				<h4 class="scl-cart-order-summary__title">Order summary</h4>
				<ul class="scl-cart-order-summary__items">
					<li><span>{{ getTotalItems }} item{{ getTotalItems > 1 ? 's' : '' }}</span><span>{{ subTotal }}</span></li>
					<li v-if="hasPromo" class="is-promo"><span>Discount {{ getPromo.code }}</span><span>-{{ getPrice.currencySymbol }}{{ getPromo.adjustment[0].amount | asCurrency }}</span></li>
					<li v-if="showShipping"><span>Shipping</span><span>{{ shipping }}</span></li>
				</ul>
				<div class="scl-cart-order-summary__total">
					<strong>Total</strong> <span>{{ total }}</span>
				</div>
			</div>
			<div v-if="getTotalItems === 0 || getLoading" class="scl-cart-order-summary__overlay">
				<v-progress-circular v-if="getLoading" color="primary" indeterminate :size="50" :width="7"></v-progress-circular>
				<v-alert v-if="getTotalItems === 0 && !getLoading" border="bottom" class="scl-alert__alert scl-alert--error" colored-border icon="$vuetify.icons.faExclamation" tile type="error">
					<p class="scl-alert__copy">No items in cart.</p>
				</v-alert>
			</div>
		</div>
		<v-alert v-if="getMemberStatus !== 'Member'" border="bottom" class="scl-alert__alert scl-alert--cart-order-summary" colored-border icon="$vuetify.icons.faTags" tile type="warning">
			<p class="scl-alert__copy">Not a member yet? <a href="/signup">Sign up now</a> and save {{ getPrice.currencySymbol }}{{ savings }} on this purchase</p>
		</v-alert>
		<v-alert v-else border="bottom" class="scl-alert__alert scl-alert--cart-order-summary" colored-border icon="$vuetify.icons.faTags" tile type="warning">
			<p class="scl-alert__copy"><strong>You saved {{ getPrice.currencySymbol }}{{ savings }} on this purchase!</strong></p>
		</v-alert>
	</div>
`;

// export template
export default template;
