/**
 * SclTabs api
 *
 * @param {boolean} centered - centers the tabs
 * @param {boolean} disableTouch - disables swiping of tab content
 * @param {boolean} grow - stretch the tabs to take up all horizontal space
 * @param {boolean} hideSlider - hides the slider underneath tabs
 * @param {boolean} icons - will display icons above the tabs
 * @param {boolean} overflowArrows - shows arrow when tabs dont fit within its parent container
 * @param {boolean} right - aligns tabs to the right
 */

// template
const template = `
		<div>
			<v-tabs
				v-model="activeTab"
				:centered="propApi.centered"
				:grow="propApi.grow"
				:hide-slider="propApi.hideSlider"
				:icons-and-text="propApi.icons"
				:right="propApi.right"
				:show-arrows="propApi.overflowArrows"
				color="primary"
				:mobile-break-point="$vuetify.breakpoint.thresholds.xs"
				slider-color="secondary"
				:class="propApi.overflowArrows? null : 'scl-tabs__overflow-hide'"
			>
				<v-tab href="#tab-1" class="scl-tabs__item" :ripple="false">
					<v-img v-if="propApi.icons" :src="$root.dummyImage43" height="32" width="32"></v-img>
					<div class="scl-tabs__item-title"> Item 1 </div>
				</v-tab>
				<v-tab href="#tab-2" class="scl-tabs__item" :ripple="false">
					<v-img v-if="propApi.icons" :src="$root.dummyImage43" height="32" width="32"></v-img>
					<div class="scl-tabs__item-title">	Item 2 </div>
				</v-tab>
				<v-tab href="#tab-3" class="scl-tabs__item" :ripple="false">
					<v-img v-if="propApi.icons" :src="$root.dummyImage43" height="32" width="32"></v-img>
					<div class="scl-tabs__item-title">	Item 3 </div>
				</v-tab>
				<v-tab href="#tab-4" class="scl-tabs__item" :ripple="false">
					<v-img v-if="propApi.icons" :src="$root.dummyImage43" height="32" width="32"></v-img>
					<div class="scl-tabs__item-title">	Item 4 </div>
				</v-tab>
				<v-tab href="#tab-5" class="scl-tabs__item" :ripple="false">
					<v-img v-if="propApi.icons" :src="$root.dummyImage43" height="32" width="32"></v-img>
					<div class="scl-tabs__item-title">	Item 5 </div>
				</v-tab>
				<v-tab href="#tab-6" class="scl-tabs__item" :ripple="false">
					<v-img v-if="propApi.icons" :src="$root.dummyImage43" height="32" width="32"></v-img>
					<div class="scl-tabs__item-title">	Item 6 </div>
				</v-tab>
			</v-tabs>
			<v-tabs-items v-model="activeTab" :touchless="propApi.disableTouch" class="scl-tabs__item-content">
				<v-tab-item eager value="tab-1">{{ $root.dummyParagraph }}</v-tab-item>
				<v-tab-item eager value="tab-3">{{ $root.dummyParagraph }}</v-tab-item>
				<v-tab-item eager value="tab-2">{{ $root.dummyParagraph }}</v-tab-item>
				<v-tab-item eager value="tab-4">{{ $root.dummyParagraph }}</v-tab-item>
				<v-tab-item eager value="tab-5">{{ $root.dummyParagraph }}</v-tab-item>
				<v-tab-item eager value="tab-6">{{ $root.dummyParagraph }}</v-tab-item>
			</v-tabs-items>
		</div>
`;

// export template
export default template;
