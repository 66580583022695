// @vue/component
const component = {
	name: 'SclImageGallery',
	props: {
		images: {
			type: Array
		}
	},
	data() {
		return {
			dialog: false,
			model: 0
		};
	},
	computed: {
		galleryImages() {
			if (this.images == null) {
				return;
			}
			const galleryImages = this.images;
			return galleryImages;
		},
		carouselItemsLength() {
			return this.galleryImages.length;
		},
		showControls() {
			const showArrows = this.carouselItemsLength > 1;
			return showArrows;
		}
	},
	beforeMount() {
		window.addEventListener('keydown', this.handleKeydown, null);
	},
	beforeDestroy() {
		window.removeEventListener('keydown', this.handleKeydown);
	},
	methods: {
		openModal(val) {
			this.dialog = true;
			this.model = val;
		},
		handleKeyRight() {
			this.model += 1;
		},
		handleKeyLeft() {
			this.model -= 1;
		},
		handleKeydown(e) {
			if (this.dialog) {
				// eslint-disable-next-line default-case
				switch (e.keyCode) {
					case 37:
						this.handleKeyLeft();
						break;
					case 39:
						this.handleKeyRight();
						break;
				}
			}
		}
	},
	template: null
};

// set template and dummy data if development environment variable string
if (process.env.VUE_APP_DEVELOPMENT === 'true') {
	component.template = require(`./${component.name}-template`).default;
}

// css import
require(`./_${component.name}.scss`);

// export component
export default component;
