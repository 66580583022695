/**
 * SclProductList
 */

// template
const template = `
	<div>
		<v-row>
			<v-col cols="12">
				<div class="scl-product-list__sorting ">
					<v-row class="align-center">
						<v-col cols="12" md="4" class="scl-product-list__sorting-col">
							<h3 class="ma-0">{{$root.dummyWords}}</h3>
						</v-col>
						<v-col cols="12" md="8" class="scl-product-list__sorting-col">
						<div class="d-flex justify-sm-end flex-wrap">
						<div class="d-flex">
							<div @click="toggleView('grid')" class="scl-product-list__list-view" :class="viewType === 'grid'? 'active' : ''">
								<v-icon>$vuetify.icons.faThLarge</v-icon>
							</div>
							<div @click="toggleView('list')" class="scl-product-list__list-view" :class="viewType === 'list'? 'active' : ''">
								<v-icon>$vuetify.icons.faList</v-icon>
							</div>
							</div>
							<scl-product-sort @emit-sorting="handleSortItems" :is-disabled="isLoading" class="scl-component scl-product-sort"></scl-product-sort>
							<v-btn @click="handleFilterClick" v-if="facets.length > 0" class="btn btn-primary scl-product-list__filter-btn"><v-icon left>$vuetify.icons.faSlidersH</v-icon>Filters</v-btn>
							</div>
							
							</v-col>
					</v-row>
				</div>
			</v-col>
			<v-col cols="12" md="8" class="py-0">
				<scl-tags :api="productTags" @emit-tags-remove="handleTagRemove"> </scl-tags>
			</v-col>
			<v-col cols="12" md="4" class="py-0">
				<div>
					<v-btn v-if="search || productTags.tags.length" class="scl-product-list__clear" color="primaryDark" @click="clearFilters" :ripple="false" text>
						Clear all
						<v-icon right>$vuetify.icons.faTimes</v-icon>
					</v-btn>
				</div>
			</v-col>
			<!-- error / no results message -->
			<v-col v-if="hasErrored" cols="12">
				<div class="scl-component scl-alert">
					<v-alert border="bottom" class="scl-alert__alert" :class="'scl-alert--' + error.type" colored-border icon="$vuetify.icons.faExclamation" tile :type="error.type">
						<p class="scl-alert__copy">{{ error.message }}</p>
					</v-alert>
				</div>
			</v-col>
			<template v-else>
				<!-- loading spinner -->
				<v-col v-if="isLoading" cols="12" class="text-center">
					<v-progress-circular color="primary" indeterminate :size="50" :width="7"></v-progress-circular>
				</v-col>
				<template v-else>
					<v-col v-for="(product, index) in products" :key="index" cols="12" :sm="viewType === 'grid'? 6 : 12" :lg="viewType === 'grid'? 4 : 12">
						<!-- product cards -->
						<scl-product-card
							class="scl-component scl-product-card"
							:image-url="product.Images !== null ? product.Images : ''"
							:title="product.Name"
							:list-price="product.listPrice"
							:adjusted-price="product.adjustedPrice"
							:product-id="product.productId"
							:stock-status="product.StockStatusName"
							:currency="product.CurrencySymbol"
							:description="product.ShortDescription"
							:card-type="viewType"
							:sku="product.Code"
							:url="product.Url"
						></scl-product-card>						
					</v-col>								
				</template>
			</template>
			<!-- pagination -->
			<v-col cols="12" v-if="pageCount > 1">
				<scl-pagination
					class="scl-component scl-pagination"
					:is-disabled="isLoading"
					:total-pages="pageCount"
					:current-page="pagination.currentPage"
					:visible-pages="9"
					@page-change="handlePageChange"
				></scl-pagination>
			</v-col>

		</v-row>
	</div>
`;

// export template
export default template;
