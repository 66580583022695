/**
 * SclImageGallery
 *
 * images prop
 * @param {Array} images - Array of gallery images
 */

// template
const template = `
	<div>
		<div class="scl-image-gallery__title">
			<h3>{{ $root.dummyWords }}</h3>
		</div>
		<v-row>
			<v-col cols="12" sm="4" v-for="(image, index) in galleryImages" :key="index">
				<v-lazy>
					<v-img
					:src="image"
					:aspect-ratio="3/2"
					@click="openModal(index)"
					class="scl-image-gallery__img">
					</v-img>
				</v-lazy>
			</v-col>
		</v-row>
		<v-dialog
			overlay-opacity="1"
			v-model="dialog"
			content-class="scl-image-gallery__modal"
		>
		<div>
			<v-icon class="scl-image-gallery__close-icon" @click="dialog = false">$vuetify.icon.faTimes</v-icon>
		</div>
			<v-carousel
				v-model="model"
				height="inherit"
				hide-delimiters
				ref="modalCarousel"

				class="scl-image-gallery__carousel":show-arrows="showControls"
				>
				<v-carousel-item
					v-for="(image, index) in galleryImages"
					:key="index"
					class="scl-image-gallery__carousel-item"
				>
					<img :src="image" class="scl-image-gallery__modal-img" />
				</v-carousel-item>
			</v-carousel>
		</v-dialog>
	</div>
`;

// export template
export default template;
