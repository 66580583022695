/**
 * SclEventsFiltering
 */

// template
const template = `
	<div>
		<v-btn v-if="$vuetify.breakpoint.xsOnly" class="scl-events-filtering__filter-button btn btn-outlined" @click="filtersActive = !filtersActive">
			<v-icon left>$vuetify.icons.faSlidersH</v-icon>
			Filters
		</v-btn>
		<div class="scl-events-filtering__container" :class="{ 'scl-events-filtering__container--active' : filtersActive }">
			<v-row>
				<v-col cols="12" sm="4">
					<v-select
						ref="region-filter"
						:append-icon="region.active ? icons.filterOpen : icons.filterClosed"
						attach=".scl-events-filtering__region"
						background-color="#fff"
						@change="updateFilters"
						class="scl-events-filtering__select scl-events-filtering__region"
						:disabled="isDisabled"
						height="56"
						hide-details
						:items="regions"
						multiple
						outlined
						v-model="region.model"
					>
						<template v-if="region.model.length" v-slot:prepend-inner>
							{{region.label}} ({{region.model.length}})
						</template>
						<template v-else v-slot:prepend-inner>
							{{region.label}}
						</template>
					</v-select>
				</v-col>
				<v-col cols="12" md="4">
					<v-select
						ref="type-filter"
						:append-icon="type.active ? icons.filterOpen : icons.filterClosed"
						attach=".scl-events-filtering__type"
						background-color="#fff"
						@change="updateFilters"
						class="scl-events-filtering__select scl-events-filtering__type"
						:disabled="isDisabled"
						height="56"
						hide-details
						:items="types"
						multiple
						outlined
						v-model="type.model"
					>
						<template v-if="type.model.length" v-slot:prepend-inner>
							{{type.label}} ({{type.model.length}})
						</template>
						<template v-else v-slot:prepend-inner>
							{{type.label}}
						</template>
					</v-select>
				</v-col>
				<v-col cols="12" md="4">
					<v-select
						ref="date-filter"
						:append-icon="date.active ? icons.filterOpen : icons.filterClosed"
						attach=".scl-events-filtering__date"
						background-color="#fff"
						@change="updateFilters"
						class="scl-events-filtering__select scl-events-filtering__date"
						:disabled="isDisabled"
						height="56"
						hide-details
						:items="date.value"
						multiple
						outlined
						v-model="date.model"
					>
						<template v-if="date.model.length" v-slot:prepend-inner>
							{{date.label}} ({{date.model.length}})
						</template>
						<template v-else v-slot:prepend-inner>
							{{date.label}}
						</template>
					</v-select>
				</v-col>
				<v-col cols="12" md="8">
					<scl-tags :api="eventTags" @emit-tags-remove="handleTagRemove"> </scl-tags>
				</v-col>
				<v-col cols="12" md="4">
					<div>
						<v-btn v-if="date.model.length || type.model.length || region.model.length" class="scl-events-filtering__clear" color="primaryDark" @click="clearFilters" text>
							<v-icon left>$vuetify.icons.faTimes</v-icon>
							Clear all filters
						</v-btn>
					</div>
				</v-col>
			</v-row>
		</div>
	</div>
`;

// export template
export default template;
