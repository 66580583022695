import { mapGetters } from 'vuex';

// @vue/component
const component = {
	name: 'SclKeyInfoDownload',
	data() {
		return {
			dialog: false
		};
	},
	computed: {
		...mapGetters(['getAuth'])
	},
	methods: {
		emitLogin() {
			if (this.getAuth) {
				this.$store.dispatch('authApiLogOut').then(() => {
					if (!this.getAuth) {
						window.location = '/';
					}
				});
			} else {
				this.$root.$emit('emitLoginModal');
			}
		}
	},
	template: null
};

// set template and dummy data if development environment variable string
// if (process.env.VUE_APP_DEVELOPMENT === 'true') {
component.template = require(`./${component.name}-template`).default;
// }

// css import
require(`./_${component.name}.scss`);

// export component
export default component;
