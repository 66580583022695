import storeApi from '@/_scl-fed/services/api/stores';
import { bus } from '../../main';

// @vue/component
const component = {
	name: 'SclStoreList',
	props: {
		categoryRoot: {
			type: String
		},
		radius: null,
		facetTypes: {
			type: Array
		}
	},
	data() {
		return {
			error: {
				message: '',
				messageError: 'Something has gone wrong please try again.',
				messageNoResults: 'No results found please adjust your search parameters.',
				type: ''
			},
			hasErrored: false,
			isLoading: false,
			pagination: {
				currentPage: 1,
				pageSize: 18
			},
			latitude: null,
			longitude: null,
			storeCount: 0,
			stores: [],
			facets: [],
			facetFilter: {},
			facetsSelected: [],
			storeTags: {
				tags: [],
				backgroundColor: 'secondary',
				isLabel: false
			}
		};
	},
	computed: {
		pageCount() {
			return Math.ceil(this.storeCount / this.pagination.pageSize);
		}
	},
	created() {
		this.initialFetch();
		bus.$on('emit-facet-change', (CategoryName, FacetType, facetValue, e) => {
			this.handleFacetFilter(CategoryName, FacetType, facetValue, e);
		});
	},
	methods: {
		initialFetch() {
			// stores have already been loaded
			if (this.stores.length > 0) {
				return;
			}
			this.parseUrlParameters();
			this.storeSearch();
		},
		parseUrlParameters() {
			const params = new URLSearchParams(window.location.search.substring(1));
			this.latitude = params.get('lat') ?? this.latitude;
			this.longitude = params.get('lng') ?? this.longitude;
		},
		processError(type, message, error) {
			this.error.type = type;
			this.error.message = message;
			this.hasErrored = true;
			console.error(error);
		},
		storeSearch() {
			this.hasErrored = false;
			// stores have already been requested
			if (this.isLoading) {
				return;
			}
			this.isLoading = true;
			const facetIndexNames = this.facetTypes.map((f) => f.indexName);
			const payload = {
				Category: this.categoryRoot,
				Latitude: this.latitude,
				Longitude: this.longitude,
				Radius: this.radius,
				pageSize: this.pagination.pageSize,
				page: this.pagination.currentPage,
				facetFields: facetIndexNames,
				facetValues: this.facetFilter
			};
			// get stores
			storeApi
				.storeSearch(payload)
				.then((response) => {
					this.response = response.Result;
					this.stores = response.Result.Data;
					this.storeCount = response.Result.TotalResults;
					this.facets = response.Result.Facets;
					this.formatFacets();
					bus.$emit('emit-store-facets', this.facets);
					if (this.stores.length === 0) {
						this.processError('info', this.error.messageNoResults, 'no stores found');
					}
				})
				.catch((err) => {
					this.processError('error', this.error.messageError, err);
				})
				.finally(() => {
					this.scrollTop;
					this.isLoading = false;
				});
		},
		formatFacets() {
			if (this.facets && this.facets.length > 0) {
				this.facets.forEach((facetData) => {
					facetData.displayName = facetData.Category;
					const facetType = this.facetTypes.find((f) => {
						return f.indexName === facetData.Category;
					});
					if (facetType) {
						facetData.displayName = facetType.displayName;
					}
					const newlist = { facetValues: [] };
					Object.entries(newlist).forEach(([key, value]) => {
						facetData[key] = value;
					});
					if (Object.entries(facetData.Values).length > 0) {
						facetData.facetValues = Object.entries(facetData.Values).map((facet) => {
							const facetValue = {
								label: facet[0],
								quantity: facet[1],
								isSelected:
									this.facetsSelected.filter(
										(selectedFacet) => facetData.Category === selectedFacet.category && selectedFacet.facetValue === facet[0]
									).length > 0 // set the isSelected based on the collection of facets found
							};
							return facetValue;
						});
					}
				});
			}
		},
		handleFacetFilter(CategoryName, FacetType, facetValue, e) {
			const index = Object.keys(this.facetFilter).indexOf(FacetType);

			const val = { [FacetType]: [facetValue] };
			if (!e && index > -1) {
				if (this.facetFilter[FacetType].length > 1) {
					this.facetFilter[FacetType] = this.facetFilter[FacetType].filter((el) => el !== facetValue);
				} else {
					delete this.facetFilter[FacetType];
				}
				this.storeTags.tags = this.storeTags.tags.filter((el) => !(el.category === FacetType && el.tagValue === facetValue));
				this.facetsSelected = this.facetsSelected.filter((el) => !(el.category === FacetType && el.facetValue === facetValue));
			}
			if (e && index >= 0) {
				return;
			}
			Object.assign(this.facetFilter, val);
			const tag = { label: facetValue, tagValue: facetValue, category: FacetType };
			this.storeTags.tags.push(tag);

			this.pagination.currentPage = 1;
			this.handleFacetSelection();
			this.storeSearch();
		},
		handleFacetSelection() {
			this.facetsSelected = [];
			// builds a collection of selected facets stored based on the user selection
			if (this.storeTags.tags.length > 0) {
				this.storeTags.tags.forEach((tag) => {
					const facetLabel = tag.label;
					this.facetsSelected.push({ label: facetLabel, facetValue: tag.tagValue, category: tag.category, isSelected: true });
				});
			}
		},

		handleTags() {
			this.storeTags.tags = [];
			// Handle categories
			if (this.categories.length > 0) {
				this.categories.forEach((category) => {
					if (category.isSelected) {
						this.storeTags.tags.push({ label: category.displayName, sitecoreId: category.sitecoreId });
					}
				});
			}
		},
		handleTagRemove(tag) {
			this.storeTags.tags = this.storeTags.tags.filter((el) => !(el.category === tag.category && el.label === tag.label));
			this.facetsSelected = this.facetsSelected.filter((el) => !(el.category === tag.category && el.label === tag.label));

			this.facets.forEach((facet) => {
				facet.facetValues.forEach((facetValue) => {
					if (facetValue.label === tag.label) {
						facetValue.isSelected = false;
					}
				});
			});

			if (this.facetFilter[tag.category].length > 1) {
				this.facetFilter[tag.category] = this.facetFilter[tag.category].filter((el) => el !== tag.tagValue);
			} else {
				delete this.facetFilter[tag.category];
			}
			this.storeSearch();
		},
		handlePageChange(val) {
			this.pagination.currentPage = val;
			this.storeSearch();
		},
		scrollTop() {
			window.scrollTo({
				top: 0,
				left: 0,
				behavior: 'smooth'
			});
		}
	},
	template: null
};

// set template and dummy data if development environment variable string
if (process.env.VUE_APP_DEVELOPMENT === 'true') {
	component.template = require(`./${component.name}-template`).default;
}

// css import
require(`./_${component.name}.scss`);

// export component
export default component;
