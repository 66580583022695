/**
 * SclAccordion api
 *
 * multiple prop
 * @param {boolean} true || false
 *
 * icon prop
 * @param {string} '$vuetify.icons.faChevronDown'
 */

// template
const template = `
	<div>
		<v-expansion-panels :multiple="propApi.multiple" >
			<v-expansion-panel class="scl-accordion__expansion-panel">
				<v-expansion-panel-header hide-actions class="scl-accordion__expansion-panel-header">
					<div>
						<v-img height="32" width="32" src="https://picsum.photos/767/1070"></v-img>
					</div>
					<div class="scl-accordion__title">
						{{$root.dummyWords}} {{$root.dummyWords}} {{$root.dummyWords}}
						{{$root.dummyWords}} {{$root.dummyWords}} {{$root.dummyWords}}
					</div>
					<v-icon class="scl-accordion__icon scl-accordion__icon--minus">{{ propApi.expandedIcon }} </v-icon>
					<v-icon class="scl-accordion__icon scl-accordion__icon--plus">{{ propApi.collapsedIcon }}</v-icon>
				</v-expansion-panel-header>
				<v-expansion-panel-content eager>
					<v-card class="scl-accordion__card">
						<v-card-text class="scl-accordion__card-text"><scl-dummy-data :content="$root.dummyParagraph"></scl-dummy-data></v-card-text>
					</v-card>
				</v-expansion-panel-content>
			</v-expansion-panel>
			<v-expansion-panel class="scl-accordion__expansion-panel" >
				<v-expansion-panel-header hide-actions class="scl-accordion__expansion-panel-header">
					<div>
					</div>
					<div class="scl-accordion__title">
						<scl-dummy-data :content="$root.dummyWords"></scl-dummy-data>
					</div>
					<v-icon class="scl-accordion__icon scl-accordion__icon--minus">{{ propApi.expandedIcon }} </v-icon>
					<v-icon class="scl-accordion__icon scl-accordion__icon--plus">{{ propApi.collapsedIcon }}</v-icon>
				</v-expansion-panel-header>
				<v-expansion-panel-content eager>
					<v-card class="scl-accordion__card">
						<v-card-text class="scl-accordion__card-text"><scl-dummy-data :content="$root.dummyParagraph"></scl-dummy-data></v-card-text>
					</v-card>
				</v-expansion-panel-content>
			</v-expansion-panel>
		</v-expansion-panels>
	</div>
`;

// export template
export default template;
