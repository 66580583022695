/**
 * SclAuthActivationForm
 */

// template
const template = `
<div>
	<div class="scl-auth-activation-form__header">
    	<v-img max-height="126" max-width="112" :src="$root.dummyImage43" class="scl-auth-activation-form__image"></v-img>
    	<h3 class="scl-auth-activation-form__title">Account activation</h3>
	</div>
    <h5 class="mb-2">Employers Member ID</h5>
    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p>
    <v-form ref="form" lazy-validation class="scl-auth-activation-form__form">

        <!-- firstName -->
        <label class="scl-auth-activation-form__label">First Name</label>
        <v-text-field
            v-model="formData.firstName"
            autocapitalize="off"
            autocomplete="email"
            autocorrect="off"
            :background-color="inputOptions.backgroundColor"
            :class="inputOptions.class"
            :color="inputOptions.color"
            :disabled="loading"
            :height="inputOptions.height"
            :outlined="inputOptions.outlined"
            placeholder="John"
            :rules="[inputRules.required]"
            type="text"
            @blur="validateForm"
			@keyup.enter.native="handleSubmit"
			ref="firstName"
        >
        </v-text-field>

        <!-- lastName -->
        <label class="scl-auth-activation-form__label">Last Name</label>
        <v-text-field
            v-model="formData.lastName"
            autocapitalize="off"
            autocomplete="email"
            autocorrect="off"
            :background-color="inputOptions.backgroundColor"
            :class="inputOptions.class"
            :color="inputOptions.color"
            :disabled="loading"
            :height="inputOptions.height"
            :outlined="inputOptions.outlined"
            placeholder="Smith"
            :rules="[inputRules.required]"
            type="text"
            @blur="validateForm"
            @keyup.enter.native="handleSubmit"
        >
        </v-text-field>

        <!-- Member ID -->
        <label class="scl-auth-activation-form__label">Member ID</label>
        <v-text-field
            v-model="formData.memberId"
            autocapitalize="off"
            autocomplete="email"
            autocorrect="off"
            :background-color="inputOptions.backgroundColor"
            :class="inputOptions.class"
            :color="inputOptions.color"
            :disabled="loading"
            :height="inputOptions.height"
            :outlined="inputOptions.outlined"
            placeholder="1234567"
            :rules="[inputRules.required]"
            type="text"
            @blur="validateForm"
			@keyup.enter.native="handleSubmit"
			hide-details="auto"
		>
        </v-text-field>
		<div class="mb-6">
			<a href="#" class="scl-auth-activation-form__link">Helper link</a>
		</div>
        <!-- email -->
        <label class="scl-auth-activation-form__label">Email address</label>
        <v-text-field
            v-model="formData.email"
            autocapitalize="off"
            autocomplete="email"
            autocorrect="off"
            hint="Email address is used to log in to your account"
            :background-color="inputOptions.backgroundColor"
            :class="inputOptions.class"
            :color="inputOptions.color"
            :disabled="loading"
            :height="inputOptions.height"
            :outlined="inputOptions.outlined"
            placeholder="email@domain.com"
            :rules="[inputRules.required, inputRules.email]"
            type="email"
            @blur="validateForm"
            @keyup.enter.native="handleSubmit"
        >
        </v-text-field>

        <!-- password -->
        <label class="scl-auth-activation-form__label">Password</label>
        <v-text-field
            v-model="formData.password"
            autocapitalize="off"
            autocorrect="off"
            hint="Case sensitive. Minimum 6 characters"
            :background-color="inputOptions.backgroundColor"
            :class="inputOptions.class"
            :color="inputOptions.color"
            :disabled="loading"
            :height="inputOptions.height"
            :outlined="inputOptions.outlined"
            placeholder="********"
            :rules="[inputRules.required, inputRules.password]"
            type="password"
            @blur="validateForm"
            @keyup.enter.native="handleSubmit"
        >
        </v-text-field>

        <label class="scl-auth-activation-form__label">Confirm Password</label>
        <v-text-field
            v-model="formData.confirmPassword"
            autocapitalize="off"
            autocorrect="off"
            :background-color="inputOptions.backgroundColor"
            :class="inputOptions.class"
            :color="inputOptions.color"
            :disabled="loading"
            :height="inputOptions.height"
            :outlined="inputOptions.outlined"
            placeholder="********"
            :rules="[inputRules.required, inputRules.confirmPassword]"
            type="password"
            @blur="validateForm"
			@keyup.enter.native="handleSubmit"
			hide-details="auto"
        >
        </v-text-field>

        <span class="scl-auth-activation-form__error-message" v-if="resultMessage !== ''">{{ resultMessage }}</span>

        <v-btn @click="handleSubmit" block class="scl-checkout__form-btn my-5" color="primary" depressed height="64" :disabled="!isValid || loading || !notEmpty" :ripple="false">
			<span v-if="!loading">Submit</span>
			<v-icon v-if="!loading" right>$vuetify.icons.faArrowRight</v-icon>
			<v-progress-circular v-if="loading" color="primary" indeterminate :size="40" :width="5"></v-progress-circular>
		</v-btn>
    </v-form>
    <v-snackbar v-model="snackbar" :timeout="timeout" class="scl-portal-change-password-form__snackbar">
		<v-alert border="bottom" colored-border tile class="scl-portal-change-password-form__alert">
			<div>
				<h6 class="scl-portal-change-password-form__alert-title">Notification</h6>
				{{ snackbarMessage }}
			</div>
		</v-alert>
	</v-snackbar>
</div>
`;

// export template
export default template;
