/**
 * SclSearchCuratedTerms
 *
 */

const template = `
	<div>
		<v-card :ripple="false" class="scl-search-curated-terms__card">
			<div class="scl-search-curated-terms__title">
				<h5> {{ $root.dummyWords }} </h5>
			</div>
			<div>
				<v-btn :ripple="false" @click="handleSearch($root.dummyWord)" class="scl-search-curated-terms__btn">
					{{$root.dummyWord}}
				</v-btn>
				<v-btn :ripple="false" @click="handleSearch($root.dummyWord)" class="scl-search-curated-terms__btn">
					{{$root.dummyWords}}
				</v-btn>
				<v-btn :ripple="false" @click="handleSearch($root.dummyWord)" class="scl-search-curated-terms__btn">
					{{$root.dummyWords}}
				</v-btn>
			</div>
			<div>
				<v-btn :ripple="false" class="btn btn-link scl-search-curated-terms__cta pa-0 pt-4" text>
					{{ $root.dummyWords }}
					<v-icon class="btn__icon-link"> $vuetify.icons.faArrowRight </v-icon>
				</v-btn>
			</div>
		</v-card>
	</div>
`;

export default template;
