/**
 * SclSecondaryHeaderBanner
 *
 */

// template
const template = `
	<div>
		<v-img
			:src="$root.dummyImage43"
			:class="'scl-secondary-header-banner__'+ propApi.height"
		>
		<div :class="propApi.overlay ? 'scl-secondary-header-banner__overlay ' : null + (propApi.position)" class="align-end fill-height scl-secondary-header-banner__overlay text-left scl-secondary-header-banner__white">
			<v-container class="fill-height">
				<v-row >
					<v-col cols="12" class="scl-secondary-header-banner__text">
					<h1 class="scl-secondary-header-banner__title">{{ $root.dummyWords }}</h1>
						<p class="scl-secondary-header-banner__headline">{{ $root.dummySentence }}</p>
					</v-col>
					<v-col cols="12" class="scl-secondary-header-banner__link">{{ $root.dummyWords }} <span>{{ $root.dummyWord }}</span></v-col>
				</v-row>
			</v-container>
			</div>
		</v-img>
		<a href="#"></a>
	</div>
`;

// export template
export default template;
