/**
 * SclSelectionCountryButton api
 *
 * minWidth prop : empty value results in auto width
 * @param {number} minWidth
 *
 * maxWidth prop : empty value results in auto width
 * @param {number} maxWidth
 */

const template = `
	<div>
		<v-menu offset-y>
			<template v-slot:activator="scope">
				<v-btn :ripple="false" class="btn btn--large btn-primary" v-on="scope.on">
					{{$root.dummyWords}}
				</v-btn>
			</template>
			<v-list>
				<v-list-item href="#">
					<v-list-item-title>{{$root.dummyWords}}</v-list-item-title>
				</v-list-item>
				<v-list-item href="#">
					<v-list-item-title>{{$root.dummyWords}}</v-list-item-title>
				</v-list-item>
				<v-list-item href="#">
					<v-list-item-title>{{$root.dummyWords}}</v-list-item-title>
				</v-list-item>
			</v-list>
		</v-menu>
	</div>
`;

export default template;
