/**
 * SclStoreLocator
 *
 */

// template
const template = `
	<div>	
        <div v-if="vertical" class="scl-store-locator__vertical" :class="fullHeight? '': 'scl-store-locator__vertical--auto'">
            <h4 class="scl-store-locator__vertical-heading">{{ $root.dummyWords}}</h4>
            <v-text-field 
                placeholder="Enter your suburb or postcode" 
                flat 
                solo 
                single-line 
                hide-details
                class="scl-store-locator__input" 
                ref="locationRef" 
                append-icon="$vuetify.icons.faSearch"
                @click:append="handleFindStore">
            </v-text-field>
        </div>
        <div v-else>
            <div class="d-sm-flex">
                <v-text-field 
                    placeholder="Enter your suburb or postcode" 
                    flat 
                    solo 
                    single-line class="scl-store-locator__input" 
                    ref="locationRef" 
                    hide-details  
                    >
                </v-text-field>
                <v-btn class="btn btn-primary scl-store-locator__button" @click="handleFindStore">Find</v-btn>
            </div>
            <a class="scl-store-locator__link">{{$root.dummyWords}}</a>
        </div>
       
	</div>
`;

// export template
export default template;
