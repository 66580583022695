// @vue/component
// SclMultiChildStepFramework
// @noOfSteps type Number
//
//  Used to generically create a multi step journey
//
import { mapGetters } from 'vuex';

const component = {
	name: 'SclMultiChildStepFramework',
	props: {
		parentStepNo: {
			type: Number
		},
		noOfChildSteps: {
			type: Number
		},
		stepSummary: {
			type: Object
		}
	},
	computed: {
		totalChildSteps() {
			const currentState = this.getMultiStepFrameworkState();
			if (!currentState) {
				return 0;
			}
			const totalChildSteps = currentState.steps[this.parentStepNo - 1].noOfChildSteps;
			return totalChildSteps;
		},
		activeChildStep() {
			const currentState = this.getMultiStepFrameworkState();
			if (!currentState) {
				return 1;
			}
			return currentState.steps[this.parentStepNo - 1].activeChildStep;
		},
		parentStepValid() {
			const currentState = this.getMultiStepFrameworkState();
			const currentStep = currentState.steps[this.parentStepNo - 1];
			if (currentStep.childSteps.length === 0) {
				return false;
			}
			const childStepsValid = currentStep.childSteps.filter((childStep) => !childStep.isValid).length === 0;
			return childStepsValid;
		}
	},
	created() {
		this.initializeChildSteps();
	},
	methods: {
		...mapGetters(['getMultiStepFrameworkState']),
		initializeChildSteps() {
			const payload = { parentStepNo: this.parentStepNo, noOfChildSteps: parseInt(this.noOfChildSteps, 10) };
			this.$store.dispatch('initializeChildMultiStep', payload);
		},
		handleProgressChildStep() {
			this.$store.dispatch('progressChildStep');
		},
		handleProgressParentStep() {
			this.$emit('emit-progress-parent');
		},
		handleChildStepChange(childStep) {
			const payload = {
				parentStepNo: this.parentStepNo,
				activeChildStep: parseInt(childStep, 10)
			};
			this.$store.dispatch('navigateChildStep', payload);
		}
	},
	template: null
};

// set template and dummy data if development environment variable string
if (process.env.VUE_APP_DEVELOPMENT === 'true') {
	component.template = require(`./${component.name}-template`).default;
}

// css import
require(`./_${component.name}.scss`);

// export component
export default component;
