/**
 * SclSearchBar
 */

// template
const template = `
	<div>
		<div v-if="param != null" class="scl-search-bar__header">
			<h4> {{ $root.dummyWords }} </h4>
			<h1>"{{ param }}"</h1>
		</div>
	  <v-card :ripple="false" class="scl-search-bar__card">
			<div class="scl-search-bar__title">
				<h5> {{ $root.dummyWords }} </h5>
			</div>
			<div class="scl-search-bar__search" v-if="$vuetify.breakpoint.smAndUp">
				<v-text-field
					v-model="search"
					:disabled="isDisabled"
					flat
					solo
					class="scl-search-bar__search-input"
					placeholder="Search"
					@keyup.enter="emitSearch">
				</v-text-field>
				<v-btn :ripple="false" text class="btn btn-primary scl-search-bar__search-btn" @click="emitSearch"> {{ $root.dummyWord }} </v-btn>
			</div>
			<div class="scl-search-bar__search" v-else>
				<v-text-field v-model="search"
					light
					solo
					class="scl-search-bar__search-input"
					placeholder="Search"
					append-icon="$vuetify.icons.faSearch"
					@keyup.enter="emitSearch"
					@click:append="emitSearch"
				>
				</v-text-field>
			</div>
		</v-card>
	</div>
`;

// export template
export default template;
