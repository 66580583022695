// @vue/component
import { mapGetters } from 'vuex';

const component = {
	name: 'SclProductCarousel',
	props: {
		api: {
			type: Object,
			required: false,
			// props type validation
			validator: (obj) => {
				const set = new Set();
				set.add(!!(obj.cycle ? typeof obj.cycle === 'boolean' : true));
				set.add(!!(obj.delimiterIcon ? typeof obj.delimiterIcon === 'string' : true));
				set.add(!!(obj.hideDelimiters ? typeof obj.hideDelimiters === 'boolean' : true));
				set.add(!!(obj.height ? typeof obj.height === 'string' : true));
				return !set.has(false);
			}
		}
	},
	data() {
		return {};
	},
	computed: {
		...mapGetters(['getMemberStatus']),
		// props defaults
		propApi() {
			return {
				cycle: false,
				delimiterIcon: '$vuetify.icons.faCircle',
				hideDelimiters: false,
				height: 'auto',
				...this.api
			};
		},
		carouselItemsLength() {
			return this.$refs.productCarouselRef.items.length;
		},
		isMember() {
			return this.getMemberStatus === 'Member';
		}
	},
	mounted() {
		this.setNavigationVisibility();
	},
	methods: {
		image(imageUrl) {
			return process.env.VUE_APP_ROOT_DOMAIN + imageUrl;
		},
		setDelimiterVisibility() {
			if (this.api && this.api.hideDelimiters === true) {
				return;
			}
			if (this.carouselItemsLength > 1) {
				this.propApi.hideDelimiters = false;
			} else {
				this.propApi.hideDelimiters = true;
			}
		},
		setNavigationVisibility() {
			// nexttick not working, still too soon
			const delay = (t) => new Promise((resolve) => setTimeout(resolve, t));
			delay(1000)
				.then(() => {
					this.setDelimiterVisibility();
				})
				.then(() => {
					this.$forceUpdate();
				});
		}
	},
	template: null
};

// set template if storybook environment variable string
if (process.env.VUE_APP_DEVELOPMENT === 'true') {
	component.template = require(`./${component.name}-template`).default;
}

// css import
require(`./_${component.name}.scss`);

export default component;
