/**
 * SclProgress
 */

// template
const template = `
	<div>
		<div class="scl-progress__circle" :class="{ 'is-filled': step >= 1 }"><span>Identity</span></div>
		<div class="scl-progress__bar" :class="{ 'is-filled': step >= 2 }"></div>
		<div class="scl-progress__circle scl-progress__circle-middle" :class="{ 'is-filled': step >= 2 }"><span v-if="step >= 3"><a @click="stepBack">Your details</a></span><span v-else>Your details</span></div>
		<div class="scl-progress__bar" :class="{ 'is-filled': step >= 3 }"></div>
		<div class="scl-progress__circle scl-progress__circle-last" :class="{ 'is-filled': step >= 3 }"><span>Payment</span></div>
	</div>
`;

// export template
export default template;
