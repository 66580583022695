// @vue/component
const component = {
	name: 'SclProductFacets',
	props: {
		facets: Array,
		isDisabled: {
			type: Boolean
		},
		api: {
			type: Object,
			// props type validation
			validator: (obj) => {
				const set = new Set();
				set.add(!!(obj.collapsedIcon ? typeof obj.collapsedIcon === 'string' : true));
				set.add(!!(obj.expandedIcon ? typeof obj.expandedIcon === 'string' : true));
				return !set.has(false);
			}
		},
		facetFilter: {
			type: Array,
			required: false
		},
		activeTags: {
			type: Array,
			required: false
		}
	},
	data() {
		return {
			panel: this.$vuetify.breakpoint.smAndDown ? [] : [0, 1],
			categoryFilter: [],
			tags: []
		};
	},
	computed: {
		// props defaults
		propApi() {
			return {
				collapsedIcon: '$vuetify.icons.faPlus',
				expandedIcon: '$vuetify.icons.faMinus',
				...this.api
			};
		}
	},
	watch: {
		facetFilter() {
			this.categoryFilter = this.facetFilter;
		},
		activeTags() {
			this.tags = this.activeTags;
		}
	},
	methods: {
		updateCategories(FacetType, facetValue, e) {
			const val = `${FacetType}=${facetValue}`;
			const index = this.categoryFilter.indexOf(val);

			if (!e && index > -1) {
				this.categoryFilter.splice(index, 1);
				this.tags.splice(index, 1);
			}
			if (e && !index > -1) {
				this.categoryFilter.push(val);
				const tag = { label: facetValue, value: `${FacetType}=${facetValue}` };
				this.tags.push(tag);
			}
			this.$emit('emit-facet-change', this.categoryFilter, this.tags);
		}
	},
	template: null
};

// set template and dummy data if development environment variable string
// if (process.env.VUE_APP_DEVELOPMENT === 'true') {
component.template = require(`./${component.name}-template`).default;
// }

// css import
require(`./_${component.name}.scss`);

// export component
export default component;
