/**
 * SclSearch
 */

// template
const template = `
	<div>
		<v-text-field v-if="!param" v-model="search" :disabled="isDisabled" background-color="backgroundColor" flat solo class="scl-product-search__input" append-icon="$vuetify.icons.faSearch" placeholder="Search" @keyup.enter="emitSearch" @click:append="emitSearch">

	  </v-text-field>
	  <v-text-field v-else v-model="search" :disabled="isDisabled" flat background-color="backgroundColor" solo class="scl-product-search__input" append-icon="$vuetify.icons.faTimes" @click:append="clearSearch" placeholder="Search"@keyup.enter="emitSearch"></v-text-field>

	</div>
`;

// export template
export default template;
