// @vue/component
const component = {
	name: 'SclCardHorizontal',
	props: {
		api: {
			type: Object,
			required: false,
			// props type validation
			validator: (obj) => {
				const set = new Set();
				set.add(!!(obj.overlayTextColour ? typeof obj.overlayTextColour === 'string' : true));
				set.add(!!(obj.variant ? typeof obj.variant === 'string' : true));
				set.add(!!(obj.height ? typeof obj.height === 'string' : true));
				return !set.has(false);
			}
		}
	},
	computed: {
		// props defaults
		propApi() {
			return {
				overlayTextColour: 'white--text',
				variant: 'light',
				height: 'auto',
				...this.api
			};
		}
	},
	template: null
};

// set template and dummy data if development environment variable string
if (process.env.VUE_APP_DEVELOPMENT === 'true') {
	component.template = require(`./${component.name}-template`).default;
}

// css import
require(`./_${component.name}.scss`);

// export component
export default component;
