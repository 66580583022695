/**
 * SclEventsListing
 */

// template
const template = `
	<div>
		<v-container>
			<v-row>
				<!-- filters -->
				<v-col cols="12">
					<scl-training-filtering v-model="showFilter" @emit-event-facet-change="handleFacetFilter" @emit-event-tag-remove="handleTagRemove" class="scl-component scl-training-filtering" :regions="regions" :categories="categories" :types="types" :qualifications="qualifications" :training-tags="trainingTags"></scl-training-filtering>
				</v-col>
				<!-- items -->
				<v-col cols="12">
					<v-row>
						<v-col cols="12" class="pt-0">
							<div>
								<v-row>
									<v-col cols="12" md="4" lg="3" class="scl-training-listing__search">
										<!-- search -->
										<scl-training-search v-if="showFilter" @emit-search="handleSearchQuery" :is-disabled="isLoading" :param="search" class="scl-component scl-training-search"></scl-training-search>
									</v-col>
									<v-col cols="12" md="8" lg="9">
										<!-- Page size -->
										<scl-page-size @emit-page-size="handlePageSize" :active-item="pagination.pageSize" :api="{pageSize: [{value: '12'}, {value: '24'}, {value: '48'}]}" :is-disabled="isLoading" class="scl-page-size scl-component"></scl-page-size>
									</v-col>
								</v-row>
							</div>
						</v-col>
						<!-- error / no results message -->
						<v-col v-if="hasErrored" cols="12">
							<div class="scl-component scl-alert">
								<v-alert border="bottom" class="scl-alert__alert" :class="'scl-alert--' + error.type" colored-border icon="$vuetify.icons.faExclamation" tile :type="error.type">
									<p class="scl-alert__copy">{{ error.message }}</p>
								</v-alert>
							</div>
						</v-col>
						<template v-else>
							<!-- loading spinner -->
							<v-col v-if="isLoading" cols="12" class="text-center">
								<v-progress-circular color="primary" indeterminate :size="50" :width="7"></v-progress-circular>
							</v-col>
							<template v-else>
								<v-col cols="12">
									{{ getResultsMessage }}
								</v-col>
								<v-col v-for="(item, index) in items" :key="index" cols="12" md="4">
									<!-- list Items -->
									<scl-training-list-item
										class="scl-component scl-training-list-item"
										:description="item.ShortDescription"
										:image-src="item.Thumbnail"
										:location="item.Location"
										:title="item.TrainingName"
										:type="item.CourseType"
										:href="item.Url"
									></scl-training-list-item>
								</v-col>
							</template>
						</template>
						<!-- pagination -->
						<v-col cols="12" v-if="pageCount > 1">
							<scl-pagination
								class="scl-component scl-pagination"
								:is-disabled="isLoading"
								:total-pages="pageCount"
								:current-page="pagination.currentPage"
								:visible-pages="9"
								@page-change="handlePageChange"
							></scl-pagination>
						</v-col>
					</v-row>
				</v-col>
			</v-row>
		</v-container>
	</div>
`;

// export template
export default template;
