// @vue/component
const component = {
	name: 'SclMap',
	props: {
		api: {
			type: Object,
			required: false,
			// props type validation
			validator: (obj) => {
				const set = new Set();
				set.add(!!(obj.height ? typeof obj.height === 'number' : true));
				set.add(!!(obj.lat ? typeof obj.lat === 'number' : true));
				set.add(!!(obj.lng ? typeof obj.lng === 'number' : true));
				set.add(!!(obj.marker ? typeof obj.marker === 'string' : true));
				set.add(!!(obj.zoom ? typeof obj.zoom === 'number' : true));
				return !set.has(false);
			}
		}
	},
	data() {
		return {
			mapLoaded: false
		};
	},
	computed: {
		// props defaults
		propApi() {
			return {
				height: 264,
				lat: null,
				lng: null,
				marker: process.env.NODE_ENV === 'development' ? 'assets/img/map-marker.png' : 'scl/assets/img/map-marker.png',
				zoom: 17,
				...this.api
			};
		}
	},
	mounted() {},
	methods: {},
	template: null
};

// set template and dummy data if development environment variable string
if (process.env.VUE_APP_DEVELOPMENT === 'true') {
	component.template = require(`./${component.name}-template`).default;
}

// css import
require(`./_${component.name}.scss`);

// export component
export default component;
