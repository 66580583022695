/**
 * SclFormLocation
 *
 */

// template
const template = `

	<div>
		<v-dialog v-model="dialog" max-width="1060" overlay-opacity="0.8" :overlay-color="color">
			<template v-slot:activator="scope">
				<v-btn :ripple="false" class="btn btn--small btn-link scl-form-location__location" @click="initSearch">
					<v-icon class="mr-3">$vuetify.icons.faMapMarker</v-icon>
					{{region}}
				</v-btn>
			</template>
			<v-card class="scl-form-location__card">
			<div class="scl-form-location__close">
							<v-btn :ripple="false" class="btn btn--large" text @click="dialog = false">
								<v-icon class="scl-form-location__close-icon">$vuetify.icons.faTimes</v-icon>
							</v-btn>
						</div>
				<v-row class="justify-center">
					<v-col cols="12" sm="7">
						<div class="scl-form-location__content text-center">
							<v-icon class="scl-form-location__icon">$vuetify.icons.faMapMarker</v-icon>
							<v-card-title class="scl-form-location__title">Set my location</v-card-title>
							<v-card-text scl-form-location__text>Use the form below to update your location</v-card-text>
						</div>
						<v-card-actions class="scl-form-location__form">
							<div class="scl-form-location__label">Suburb or Postcode</div>
							 <v-text-field placeholder="Enter your suburb or postcode" solo single-line class="scl-form-location__input" ref="locationRef"></v-text-field>
							<v-btn class="btn btn-primary scl-form-location__button" @click="setLocation" >Change Location</v-btn>
						</v-card-actions>
					</v-col>
				</v-row>
			</v-card>
		</v-dialog>
		<v-snackbar v-model="snackbar" :timeout="timeout" class="scl-form-location__snackbar">
			<v-alert border="bottom" class="scl-form-location__alert" :class="'scl-form-location--' + propApi.type" colored-border :dismissible="propApi.dismissible" :outlined="propApi.outlined" :icon="propApi.icon" tile :type="propApi.type">
				<div v-if="(!showMessage || propApi.successMessage == null)">
					<h6 class="scl-form-location__alert-title">{{propApi.title}}</h6>
					{{propApi.text}} <b>{{region}}</b> <a class="scl-form-location__link" @click="initSearch">Change Location</a>
				</div>
				<div v-else>
					<h6 class="scl-form-location__alert-title">{{propApi.title}}</h6>
					{{propApi.successMessage}} <b>{{region}}</b> <a class="scl-form-location__link" @click="initSearch">Change Location</a>
				</div>
			</v-alert>
		</v-snackbar>
	</div>
`;

// export template
export default template;
