/**
 * SclArticleSearch
 */

// template
const template = `
	<div>
		<v-text-field v-if="param == null" v-model="search"  background-color="backgroundColor" :disabled="isDisabled" flat solo class="scl-article-search__input" append-icon="$vuetify.icons.faSearch" placeholder="Search" @click:append="emitSearch" @keyup.enter="emitSearch">

	  </v-text-field>
	  <v-text-field v-model="search" v-else :disabled="isDisabled" background-color="backgroundColor" flat solo class="scl-article-search__input" append-icon="$vuetify.icons.faTimes" placeholder="Search" @click:append="clearSearch" @keyup.enter="emitSearch">

	  </v-text-field>
	</div>
`;

// export template
export default template;
