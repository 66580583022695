/**
 * SclPaymentDetails
 */

// template
const template = `
	<div>
		<h3 class="scl-checkout__form-title"><span>Payment details</span></h3>
		<label class="scl-checkout__form-label">Pay by</label>
		<v-btn @click="setPaymentMethod('creditCard')" class="scl-payment-details__btn" :class="{'is-active' : paymentMethod === 'creditCard'}" color="#D6E4E0" depressed height="48" outlined tile>
			<v-img alt="credit card" aspect-ratio="2.3" src="/scl/assets/img/creditcard.svg" width="112"></v-img>
		</v-btn>
		<hr />

		<!-- credit card -->
		<template v-if="paymentMethod === 'creditCard'">
			<!-- name -->
			<label class="scl-checkout__form-label">{{formData.name.label}}</label>

			<div id="cardholder-name-wrapper" ref="cardholder-name-wrapper" class="v-input theme--light v-text-field v-text-field--is-booted v-text-field--enclosed v-text-field--outlined v-text-field--placeholder scl-sc-forms__form-input" :class="{'error--text' : formData.name.error !== ''}">
				<div class="v-input__control">
					<div class="v-input__slot" style="height: 48px; background-color: rgb(244, 244, 244); border-color: rgb(244, 244, 244);">
						<fieldset aria-hidden="true"><legend style="width: 0px;"><span> </span></legend></fieldset>
						<div class="v-text-field__slot">
							<div id="cardholder-name"></div>
						</div>
					</div>
					<div v-if="formData.name.error !== ''" class="v-text-field__details">
						<div class="v-messages theme--light">
							<div class="v-messages__wrapper error--text">{{formData.name.error}}</div>
						</div>
					</div>
				</div>
			</div>
			<!-- number -->
			<label class="scl-checkout__form-label">{{formData.cardNumber.label}}</label>
			<div id="card-number-wrapper" ref="card-number-wrapper" class="v-input theme--light v-text-field v-text-field--is-booted v-text-field--enclosed v-text-field--outlined v-text-field--placeholder scl-sc-forms__form-input" :class="{'error--text' : formData.cardNumber.error !== ''}">
				<div class="v-input__control">
					<div class="v-input__slot" style="height: 48px; background-color: rgb(244, 244, 244); border-color: rgb(244, 244, 244);">
						<fieldset aria-hidden="true"><legend style="width: 0px;"><span> </span></legend></fieldset>
						<div class="v-text-field__slot">
							<div id="card-number"></div>
						</div>
					</div>
					<div v-if="formData.cardNumber.error !== ''" class="v-text-field__details">
						<div class="v-messages theme--light">
							<div class="v-messages__wrapper error--text">{{formData.cardNumber.error}}</div>
						</div>
					</div>
				</div>
			</div>
			<!-- expiry -->
			<label class="scl-checkout__form-label">{{formData.expiry.label}}</label>
			<div id="expiration-date-wrapper" ref="expiration-date-wrapper" class="v-input theme--light v-text-field v-text-field--is-booted v-text-field--enclosed v-text-field--outlined v-text-field--placeholder scl-sc-forms__form-input" :class="{'error--text' : formData.expiry.error !== ''}">
				<div class="v-input__control">
					<div class="v-input__slot" style="height: 48px; background-color: rgb(244, 244, 244); border-color: rgb(244, 244, 244);">
						<fieldset aria-hidden="true"><legend style="width: 0px;"><span> </span></legend></fieldset>
						<div class="v-text-field__slot">
							<div id="expiration-date"></div>
						</div>
					</div>
					<div v-if="formData.expiry.error !== ''" class="v-text-field__details">
						<div class="v-messages theme--light">
							<div class="v-messages__wrapper error--text">{{formData.expiry.error}}</div>
						</div>
					</div>
				</div>
			</div>
			<!-- cvv -->
			<label class="scl-checkout__form-label">{{formData.cvv.label}}</label>
			<div id="cvv-wrapper" ref="cvv-wrapper" class="v-input theme--light v-text-field v-text-field--is-booted v-text-field--enclosed v-text-field--outlined v-text-field--placeholder scl-sc-forms__form-input" :class="{'error--text' : formData.cvv.error !== ''}">
				<div class="v-input__control">
					<div class="v-input__slot" style="height: 48px; background-color: rgb(244, 244, 244); border-color: rgb(244, 244, 244);">
						<fieldset aria-hidden="true"><legend style="width: 0px;"><span> </span></legend></fieldset>
						<div class="v-text-field__slot">
							<div id="cvv"></div>
						</div>
					</div>
					<div v-if="formData.cvv.error !== ''" class="v-text-field__details">
						<div class="v-messages theme--light">
							<div class="v-messages__wrapper error--text">{{formData.cvv.error}}</div>
						</div>
					</div>
				</div>
			</div>
		</template>
	</div>
`;

// export template
export default template;
