/**
 * SclMinicart
 */

// template
const template = `
	<div>
		<v-btn :ripple="false" text class="scl-minicart__btn" color="primaryDark">
			<v-icon size="32">$vuetify.icons.faShoppingCart</v-icon>
			<span class="scl-minicart__number" v-if="(getItems > 0)">{{getItems}}</span>
		</v-btn>
	</div>
`;

// export template
export default template;
