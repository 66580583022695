/**
 * SclProductCategories
 */

// template
const template = `
	<div>
		<v-expansion-panels accordion class="scl-product-categories__accordions" flat multiple v-model="panel">

			<!-- panel -->
			<v-expansion-panel class="scl-product-categories__accordion">
				<v-expansion-panel-header hide-actions  class="scl-product-categories__header">
					<div class="scl-product-categories__title">
						Product Category
					</div>
					<v-icon class="scl-product-categories__accordion-icon scl-product-categories__accordion-icon--minus">{{ propApi.expandedIcon }} </v-icon>
					<v-icon class="scl-product-categories__accordion-icon scl-product-categories__accordion-icon--plus">{{ propApi.collapsedIcon }}</v-icon>
				</v-expansion-panel-header>
				<v-expansion-panel-content class="scl-product-categories__content">
					<v-checkbox v-for="category in categories" @change="updateCategories()" v-model="category.isSelected" class="scl-product-categories__check" :disabled="isDisabled" hide-details :label="category.displayName" :key="category.sitecoreId"></v-checkbox>
					<!--<v-btn :ripple="false" class="scl-product-categories__clear mt-3 btn btn--small btn-link" text @click="clearCat"><v-icon class="mr-1" size="12">$vuetify.icons.faTimesCircle</v-icon>clear</v-btn>-->

				</v-expansion-panel-content>
			</v-expansion-panel>

			<!-- panel -->
			<v-expansion-panel class="scl-product-categories__accordion">
				<v-expansion-panel-header hide-actions class="scl-product-categories__header">
					<div class="scl-product-categories__title">
						Test
					</div>
					<v-icon class="scl-product-categories__accordion-icon scl-product-categories__accordion-icon--minus">{{ propApi.expandedIcon }} </v-icon>
					<v-icon class="scl-product-categories__accordion-icon scl-product-categories__accordion-icon--plus">{{ propApi.collapsedIcon }}</v-icon>
				</v-expansion-panel-header>
				<v-expansion-panel-content class="scl-product-categories__content">
					Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.
				</v-expansion-panel-content>
			</v-expansion-panel>

		</v-expansion-panels>
	</div>
`;

// export template
export default template;
