// @vue/component
const component = {
	name: 'SclCartNotification',
	props: {
		isActive: {
			type: Boolean
		},
		type: {
			type: String
		}
	},
	data() {
		return {};
	},
	computed: {
		icon() {
			return this.type === 'success' ? '$vuetify.icons.faShoppingCart' : '$vuetify.icons.faExclamation';
		}
	},
	methods: {
		handleCartNotification(bool) {
			this.$store.commit('setToastActive', bool);
		}
	},
	template: null
};

// set template and dummy data if development environment variable string
// if (process.env.VUE_APP_DEVELOPMENT === 'true') {
component.template = require(`./${component.name}-template`).default;
// }

// css import
require(`./_${component.name}.scss`);

// export component
export default component;
