// @vue/component
const component = {
	name: 'SclMiniNav',
	data() {
		return { sheet: false };
	},
	computed: {
		getImage() {
			const imagePath = process.env.NODE_ENV === 'development' ? '/assets/img' : '/scl/assets/img';
			const image = `${imagePath}/elipsis-v.png`;

			return image;
		}
	},
	methods: {
		slide() {
			this.sheet = false;
		}
	},
	template: null
};

// set template and dummy data if development environment variable string
if (process.env.VUE_APP_DEVELOPMENT === 'true') {
	component.template = require(`./${component.name}-template`).default;
}

// css import
require(`./_${component.name}.scss`);

export default component;
