// @vue/component
const component = {
	name: 'SclProductCategories',
	props: {
		categories: Array,
		isDisabled: {
			type: Boolean
		},
		api: {
			type: Object,
			// props type validation
			validator: (obj) => {
				const set = new Set();
				set.add(!!(obj.collapsedIcon ? typeof obj.collapsedIcon === 'string' : true));
				set.add(!!(obj.expandedIcon ? typeof obj.expandedIcon === 'string' : true));
				return !set.has(false);
			}
		}
	},
	data() {
		return {
			panel: this.$vuetify.breakpoint.smAndDown ? [] : [0, 1]
		};
	},
	computed: {
		// props defaults
		propApi() {
			return {
				collapsedIcon: '$vuetify.icons.faPlus',
				expandedIcon: '$vuetify.icons.faMinus',
				...this.api
			};
		}
	},
	methods: {
		updateCategories() {
			const newState = this.categories
				.filter((el) => el.isSelected)
				.map((el) => {
					return {
						sitecoreId: el.sitecoreId,
						displayName: el.displayName
					};
				});
			this.$emit('emit-category-change', newState);
		},
		clearCat() {
			this.categories.forEach((category) => (category.isSelected = false));
			this.updateCategories();
		}
	},
	template: null
};

// set template and dummy data if development environment variable string
// if (process.env.VUE_APP_DEVELOPMENT === 'true') {
component.template = require(`./${component.name}-template`).default;
// }

// css import
require(`./_${component.name}.scss`);

// export component
export default component;
