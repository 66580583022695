// @vue/component
import { mapGetters } from 'vuex';

const component = {
	name: 'SclProductCard',
	props: {
		imageUrl: {
			type: Array
		},
		title: {
			type: String
		},
		listPrice: {
			type: Number
		},
		currency: {
			type: String
		},
		description: {
			type: String
		},
		productId: {
			type: String
		},
		stockStatus: {
			type: String
		},
		adjustedPrice: {
			type: Number
		},
		outOfStock: {
			type: Boolean
		},
		sku: {
			type: String
		},
		cardType: {
			type: String
		},
		url: {
			type: String
		}
	},
	computed: {
		...mapGetters(['getMemberStatus']),
		image() {
			return process.env.VUE_APP_ROOT_DOMAIN + this.imageUrl[0];
		},
		isMember() {
			return this.getMemberStatus === 'Member';
		},
		truncate() {
			const val = this.description;
			const limit = 130;
			return val && val.length > limit ? `${val.substr(0, limit)}...` : val;
		},
		computedSku() {
			const doesTitleContainSku = this.title.toLowerCase().includes(this.sku.toLowerCase());
			return doesTitleContainSku ? '' : this.sku;
		}
	},
	methods: {
		emitProdId() {
			this.$emit('emit-prod-id', this.productId);
		}
	},
	template: null
};

// set template and dummy data if development environment variable string
// if (process.env.VUE_APP_DEVELOPMENT === 'true') {
component.template = require(`./${component.name}-template`).default;
// }

// css import
require(`./_${component.name}.scss`);

// export component
export default component;
