/**
 * SclAuthLoginBtn
 */

// template
const template = `
	<div>
		<v-btn
			color="primaryDark"
			depressed
			height="40px"
			outlined
			@click="emitLogin"
		>
			<span v-if="!getAuth">Login test</span>
			<span v-else>Logout test</span>
		</v-btn>
	</div>
`;

// export template
export default template;
