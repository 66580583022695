/**
 * SclProductSummary api
 *
 * @param {string} url - Enquiry URL
 * @param {number} characterNumber - Controls truncation
 */

// template
const template = `
    <div>
        <v-row>
            <v-col cols="12" md="6">              
                <div class="scl-product-summary__image">
                    <v-img :src="$root.dummyImage43" :aspect-ratio="4/3" ></v-img>
                </div>        
            </v-col>
            <v-col cols="12" md="6" :class="$vuetify.breakpoint.smAndUp? 'pl-8' : ''">  
                <h1 class="mb-4">{{$root.dummyWords}}</h1>            
                <div v-show="!showDescription" v-html="truncatedText()">
                   
                </div>
                <v-expand-transition >
                <div v-show="showDescription" class="scl-product-summary__description-full" v-html="$root.dummyParagraph">
                    
                </div>
                </v-expand-transition>
                <div v-if="$root.dummyParagraph.length > propApi.characterNumber" class="my-4 scl-product-summary__readmore">
                    <a v-if="!showDescription"  @click="handleReadMore">Read more <v-icon right> $vuetify.icons.faAngleDown</v-icon></a>
                    <a v-else  @click="handleReadMore">Show less  <v-icon right> $vuetify.icons.faAngleUp</v-icon></a>
                </div>
                <div class="scl-product-summary__actions">
                    <v-btn class="btn btn-outlined btn--large mr-4">
                    <v-icon left>$vuetify.icons.faEnvelope</v-icon>  {{ $root.dummyWords }}
                    </v-btn>
                    <v-btn class="btn btn-outlined btn--large">
                    <v-icon left>$vuetify.icons.faFilePDF</v-icon> {{ $root.dummyWords }}        
                    </v-btn>
                </div>     
            </v-col>            
        </v-row>
    </div>
`;

// export template
export default template;
