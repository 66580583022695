// @vue/component
const component = {
	name: 'SclContentGate',
	props: {},
	data() {
		return {};
	},
	computed: {},
	methods: {
		emitLogin() {
			this.$root.$emit('emitLoginModal');
		}
	},
	template: null
};

// set template and dummy data if development environment variable string
if (process.env.VUE_APP_DEVELOPMENT === 'true') {
	component.template = require(`./${component.name}-template`).default;
}

// css import
require(`./_${component.name}.scss`);

// export component
export default component;
