import { mapGetters } from 'vuex';

// @vue/component
const component = {
	name: 'SclCartOrderSummary',
	props: {
		showShipping: {
			type: Boolean,
			default: false
		}
	},
	data() {
		return {
			cartItems: null
		};
	},
	computed: {
		...mapGetters(['getLoading', 'getMemberStatus', 'getCart', 'getPrice', 'getPromo', 'getTotalItems']),
		hasPromo() {
			return !!this.getPromo.code;
		},
		subTotal() {
			if (this.getPrice.subtotal === null || typeof this.getPrice.subtotal === 'undefined') return '';
			const subTotalValueIncGst = this.getPrice.subtotal;
			return `${this.getPrice.currencySymbol}${parseFloat(`${subTotalValueIncGst}`).toFixed(2)}`;
		},
		gst() {
			if (this.getPrice.taxTotal === null || typeof this.getPrice.taxTotal === 'undefined') return '';
			const gstValue = parseFloat(`${this.getPrice.subtotal * 0.1}`).toFixed(2);
			return `${this.getPrice.currencySymbol}${gstValue}`;
		},
		shipping() {
			if (this.getPrice.shippingTotal === null || typeof this.getPrice.shippingTotal === 'undefined') return '';
			const shippingValue = parseFloat(`${this.getPrice.shippingTotal}`).toFixed(2);
			return `${this.getPrice.currencySymbol}${shippingValue}`;
		},
		total() {
			let totalValue = this.getPrice.total;

			if (!this.showShipping) {
				totalValue -= this.getPrice.shippingTotal;
			}
			return `${this.getPrice.currencySymbol}${parseFloat(`${totalValue}`).toFixed(2)}`;
		},
		savings() {
			return this.handleSavings();
		}
	},
	methods: {
		returnNumber(val) {
			return Number(val).toFixed(2);
		},
		handleSavings() {
			this.cartItems = this.getCart;

			let memberPrice = 0;
			let fullPrice = 0;

			this.cartItems.forEach((element) => {
				memberPrice += element.product.adjustedPrice * element.quantity;
				fullPrice += element.product.listPrice * element.quantity;
			});

			return this.returnNumber(fullPrice - memberPrice);
		}
	},
	template: null
};

// set template and dummy data if development environment variable string
// if (process.env.VUE_APP_DEVELOPMENT === 'true') {
component.template = require(`./${component.name}-template`).default;
// }

// css import
require(`./_${component.name}.scss`);

// export component
export default component;
