/**
 * SclStoreDetail
 */

// template
const template = `
	<div>
		<v-row class="py-0">
			<v-col cols="12" sm="8">
				<scl-map class="scl-component scl-map" :api="{lat: -35.32445, lng: 149.1722}"></scl-map>
			</v-col>
			<v-col cols="12" sm="4">
				<h3>Address</h3>
				<div>{{ $root.dummyWords }}</div>

				<h3 class="mt-6">Contact</h3>
				<div class="scl-store-detail__contact">
					<div><b>Phone:</b> {{ $root.dummyWords }}</div>
					<div><b>Fax:</b> {{ $root.dummyWords }}</div>
					<div><b>Email:</b> <a> {{ $root.dummyWords }} </a></div>
				</div>

				<h3 class="mt-6">Hours</h3>
				<div class="scl-store-detail__opening">
					<div>Mon-Fri: <span> 7:30am – 5pm </span></div>
					<div>Sat: <span> 8:30am - 12pm </span></div>
					<div>Sunday: <span> Closed </span></div> 
				</div>
				<h3 class="mt-6">Manager</h3>
				<div class="d-flex justify-space-between">					
					<p>{{ $root.dummyWords }}</p>
					<v-img max-height="200px" max-width="200px" :src="$root.dummyImage43">
					</v-img>
				</div>
			</v-col>
		</v-row>
	</div>
`;

// export template
export default template;
