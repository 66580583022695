/**
 * SclArticleSorting
 */

// template
const template = `
	<div>
		<div class="scl-article-sorting__content align-center">
			<div class="scl-article-sorting__title">
				Sort By:
				</div>
			<v-select
			:items="sorting"
			item-text="name"
			item-value="sort"
			label="Select"
			append-icon="$vuetify.icons.faCaretDown"
			background-color="backgroundColor"
			outlined
			height="48"
			dense
			hide-details="true"
			:menu-props="{ contentClass: 'scl-article-sorting__select' }"
			@change="sortBy"
		  ></v-select>
		</div>
	</div>
`;

// export template
export default template;
