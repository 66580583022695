import { mapGetters } from 'vuex';
import { SclCartLineItems, SclCartOrderSummary, SclCartPromo } from './cart/index';

// @vue/component
const component = {
	name: 'SclCartSummary',
	components: { SclCartLineItems, SclCartOrderSummary, SclCartPromo },
	data() {
		return {};
	},
	computed: {
		...mapGetters(['getError', 'getAuth'])
	},
	created() {
		this.$store.dispatch('cartApiGet');
	},
	methods: {
		handlePromoCode(PromoCode) {
			const payload = { PromoCode };
			this.$store.dispatch('cartApiPromo', payload);
		},
		handlePromoError(status) {
			this.$store.commit('setPromoState', status);
		},
		handleQuantity(ProductId, VariantId, Quantity) {
			const payload = {
				ProductId,
				VariantId,
				Quantity
			};
			this.$store.dispatch('cartApiUpdate', payload);
		},
		handleRemoveItem(cartLineId) {
			const payload = {
				cartLineId
			};
			this.$store.dispatch('cartApiRemove', payload);
		},
		emitLogin() {
			if (this.getAuth) {
				this.$store.dispatch('authApiLogOut').then(() => {
					if (!this.getAuth) {
						window.location = '/';
					}
				});
			} else {
				this.$root.$emit('emitLoginModal');
			}
		}
	},
	template: null
};

// set template and dummy data if development environment variable string
// if (process.env.VUE_APP_DEVELOPMENT === 'true') {
component.template = require(`./${component.name}-template`).default;
// }

// css import
require(`./_${component.name}.scss`);

// export component
export default component;
