/**
 * SclSelectionLanguage api
 *
 */

const template = `
	<div>
		<v-card :ripple="false" class="scl-search-custom__card">
			<div class="scl-search-custom__title">
				<h5> {{ $root.dummyWords }} </h5>
			</div>
			<div class="scl-search-custom__search" v-if="$vuetify.breakpoint.smAndUp">
				<v-text-field v-model="searchQuery"
					light
					solo
					class="scl-search-custom__search-input"
					:placeholder="$root.dummyWords"
					@keyup.enter="handleSearch($root.dummyUrl)"
				>
				<template v-slot:prepend-inner >
					<v-img max-width="48" max-height="48" :src="$root.dummyImage43" class="scl-search-custom__image" ></v-img>
				</template>
				</v-text-field>
				<v-btn :ripple="false" text class="btn btn-primary scl-search-custom__search-btn" @click="handleSearch($root.dummyUrl)"> {{ $root.dummyWord }} </v-btn>
			</div>
			<div class="scl-search-custom__search" v-else>
				<v-text-field v-model="searchQuery"
					light
					solo
					class="scl-search-custom__search-input"
					:placeholder="$root.dummyWords"
					append-icon="$vuetify.icons.faSearch"
					@keyup.enter="handleSearch($root.dummyUrl)"
					@click:append="handleSearch($root.dummyUrl)"
				>
				<template v-slot:prepend-inner >
					<v-img max-width="32" max-height="32" :src="$root.dummyImage43" class="scl-search-custom__image" ></v-img>
				</template>
				</v-text-field>
			</div>
		</v-card>
	</div>
`;

export default template;
