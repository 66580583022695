import productApi from '@/_scl-fed/services/api/product';
import { bus } from '../../main';

// @vue/component
const component = {
	name: 'SclProductList',
	props: {
		categoryRoot: null,
		productCollection: null,
		isActive: {
			type: Boolean
		},
		productDetailPath: {
			type: String,
			default: '/products/detail'
		},
		facetTypes: {
			type: Array
		}
	},
	data() {
		return {
			error: {
				message: '',
				messageError: 'Something has gone wrong please try again.',
				messageNoResults: 'No results found please adjust your search parameters.',
				type: ''
			},
			hasErrored: false,
			isLoading: false,
			pagination: {
				currentPage: 1,
				pageSize: 12
			},
			sorting: {
				sort: 'name-asc'
			},
			products: [],
			categories: [],
			categoryFilter: [],
			facets: [],
			facetFilter: {},
			facetsSelected: [],
			response: null,
			search: null,
			productCount: 0,
			productTags: {
				tags: [],
				backgroundColor: 'secondary',
				isLabel: false
			},
			viewType: 'grid',
			showDialog: false
		};
	},
	computed: {
		searchParams() {
			let params = `?pg=${this.pagination.currentPage}&ps=${this.pagination.pageSize}&s=${this.sorting.sort}`;
			if (this.search) {
				params += `&q=${this.search}`;
			}
			if (this.categoryFilter && Array.isArray(this.categoryFilter) && this.categoryFilter.length > 0) {
				params += `&c=${this.categoryFilter.join(',')}`;
			}
			return params;
		},
		pageCount() {
			return Math.ceil(this.productCount / this.pagination.pageSize);
		}
	},
	created() {
		this.initialFetch();
		bus.$on('emit-facet-change', (CategoryName, FacetType, facetValue, e) => {
			this.handleFacetFilter(CategoryName, FacetType, facetValue, e);
		});
		bus.$on('emit-facet-clear-all', () => {
			this.clearFilters();
		});
		bus.$on('emit-facet-dialog', (val) => {
			this.showDialog = val;
		});
	},
	methods: {
		initialFetch() {
			// products have already been loaded
			if (this.products.length > 0) {
				return;
			}

			this.parseUrlParameters();
			this.productSearch();
		},
		parseUrlParameters() {
			const params = new URLSearchParams(window.location.search.substring(1));
			const pg = Number(params.get('pg'));
			const ps = Number(params.get('ps'));
			this.pagination.currentPage = Number.isNaN(pg) || pg < 1 ? this.pagination.currentPage : pg;
			this.pagination.pageSize = Number.isNaN(ps) || ps < 1 ? this.pagination.pageSize : ps;
			this.sorting.sort = params.get('s') ?? this.sorting.sort;
			this.search = params.get('q') ?? this.search;
			if (params.has('c')) {
				this.categoryFilter = params.get('c').split(/[,|]/);
			}
			if (params.has('pg')) {
				params.set('pg', parseInt(params.get('pg'), 10) - 1);
			}
			if (params.has('f')) {
				const facets = params.get('f');
				Array(facets.split('-')).forEach((facet) => {
					Object.values(facet).forEach((item) => {
						Array(item.split('=')).forEach((val) => {
							const facetItems = val[1].split(',');
							const displayName = this.facetTypes.find((f) => {
								return f.indexName === val[0];
							});
							if (facetItems.length > 1) {
								facetItems.forEach((el) => {
									this.productTags.tags.push({ label: `${displayName.displayName} ${el}`, tagValue: el, category: val[0] });
									this.facetsSelected.push({ label: `${displayName.displayName} ${el}`, facetValue: el, category: val[0] });
								});
							} else {
								this.productTags.tags.push({ label: `${displayName.displayName} ${val[1]}`, tagValue: val[1], category: val[0] });
								this.facetsSelected.push({ label: `${displayName.displayName} ${val[1]}`, facetValue: val[1], category: val[0] });
							}

							Object.assign(this.facetFilter, { [val[0]]: val[1].split(',') });
						});
					});
				});
			}
		},
		processError(type, message, error) {
			this.error.type = type;
			this.error.message = message;
			this.hasErrored = true;
			console.error(error);
		},
		productSearch() {
			this.hasErrored = false;
			// products have already been requested
			if (this.isLoading) {
				return;
			}
			this.isLoading = true;
			const facetIndexNames = this.facetTypes.map((f) => f.indexName);
			const payload = {
				query: this.search,
				pageSize: this.pagination.pageSize,
				page: this.pagination.currentPage,
				sortField: this.sorting.sort,
				facetFields: facetIndexNames,
				facetValues: this.facetFilter,
				Category: this.categoryRoot,
				ProductCollection: this.productCollection
			};
			// get products
			productApi
				.productSearch(payload)
				.then((response) => {
					this.response = response.Result;
					this.products = response.Result.Data;
					this.productCount = response.Result.TotalResults;
					this.facets = response.Result.Facets;
					this.formatFacets();

					if (response.Result.Facets === 0) {
						this.processError('info', this.error.messageNoResults, 'no facets found');
					}
					if (this.products.length === 0) {
						this.processError('info', this.error.messageNoResults, 'no products found');
					}
				})
				.catch((err) => {
					this.processError('error', this.error.messageError, err);
				})
				.finally(() => {
					let facetSearch = '';
					if (this.facetFilter && Object.keys(this.facetFilter).length > 0) {
						facetSearch += '&f=';
						Object.entries(this.facetFilter).forEach((el, index) => {
							const itemArray = el[1].join(',');
							facetSearch += Array(`${index > 0 ? '-' : ''}${el[0]}=${itemArray}`);
						});
					}
					window.history.pushState({}, '', this.searchParams + facetSearch);
					this.scrollTop;
					this.isLoading = false;
					bus.$emit('emit-facets', this.facets, this.showDialog, this.isLoading);
				});
		},
		formatFacets() {
			if (this.facets && this.facets.length > 0) {
				this.facets.forEach((facetData) => {
					facetData.displayName = facetData.Category;
					const facetType = this.facetTypes.find((f) => {
						return f.indexName === facetData.Category;
					});
					if (facetType) {
						facetData.displayName = facetType.displayName;
					}
					const newlist = { facetValues: [] };
					Object.entries(newlist).forEach(([key, value]) => {
						facetData[key] = value;
					});
					if (Object.entries(facetData.Values).length > 0) {
						facetData.facetValues = Object.entries(facetData.Values).map((facet) => {
							const facetValue = {
								label: facet[0],
								quantity: facet[1],
								isSelected:
									this.facetsSelected.filter(
										(selectedFacet) => facetData.Category === selectedFacet.category && selectedFacet.facetValue === facet[0]
									).length > 0 // set the isSelected based on the collection of facets found
							};
							return facetValue;
						});
					}
				});
			}
		},
		handlePageChange(val) {
			this.pagination.currentPage = val;
			this.productSearch();
		},
		handlePageSize(val) {
			this.pagination.pageSize = val;
			this.productSearch();
		},
		handleSearchQuery(val) {
			this.search = val;
			this.pagination.currentPage = 1;
			this.productSearch();
		},
		handleSortItems(val) {
			const [sort, by] = val.split('|');
			this.sorting.sort = sort;
			this.sorting.by = by;
			this.pagination.currentPage = 1;
			this.productSearch();
		},
		handleCategoryFilter(val) {
			this.categoryFilter = val.map((el) => el.sitecoreId);
			this.pagination.currentPage = 1;
			this.pagination.currentPage = 1;
			this.handleTags(val);
			this.productSearch();
		},
		handleFacetFilter(CategoryName, FacetType, facetValue, e) {
			const index = Object.keys(this.facetFilter).indexOf(FacetType);
			const val = { [FacetType]: [facetValue] };
			if (!e && index > -1) {
				if (this.facetFilter[FacetType].length > 1) {
					this.facetFilter[FacetType] = this.facetFilter[FacetType].filter((el) => el !== facetValue);
				} else {
					delete this.facetFilter[FacetType];
				}
				this.productTags.tags = this.productTags.tags.filter((el) => !(el.category === FacetType && el.tagValue === facetValue));
				this.facetsSelected = this.facetsSelected.filter((el) => !(el.category === FacetType && el.facetValue === facetValue));
			}
			if (e && index >= 0) {
				const category = this.facetFilter[FacetType];
				category.push(facetValue);
				const tag = { label: `${CategoryName} ${facetValue}`, tagValue: facetValue, category: FacetType };
				this.productTags.tags.push(tag);
			} else if (e && !index > -1) {
				Object.assign(this.facetFilter, val);
				const tag = { label: `${CategoryName} ${facetValue}`, tagValue: facetValue, category: FacetType };
				this.productTags.tags.push(tag);
			}
			this.pagination.currentPage = 1;
			this.handleFacetSelection();
			this.productSearch();
		},
		handleFacetSelection() {
			this.facetsSelected = [];
			// builds a collection of selected facets stored based on the user selection
			if (this.productTags.tags.length > 0) {
				this.productTags.tags.forEach((tag) => {
					const facetLabel = tag.label;
					this.facetsSelected.push({ label: facetLabel, facetValue: tag.tagValue, category: tag.category, isSelected: true });
				});
			}
		},

		handleTags() {
			this.productTags.tags = [];
			// Handle categories
			if (this.categories.length > 0) {
				this.categories.forEach((category) => {
					if (category.isSelected) {
						this.productTags.tags.push({ label: category.displayName, sitecoreId: category.sitecoreId });
					}
				});
			}
		},
		handleTagRemove(tag) {
			this.productTags.tags = this.productTags.tags.filter((el) => !(el.category === tag.category && el.label === tag.label));
			this.facetsSelected = this.facetsSelected.filter((el) => !(el.category === tag.category && el.label === tag.label));

			this.facets.forEach((facet) => {
				facet.facetValues.forEach((facetValue) => {
					if (facetValue.label === tag.label) {
						facetValue.isSelected = false;
					}
				});
			});

			if (this.facetFilter[tag.category].length > 1) {
				this.facetFilter[tag.category] = this.facetFilter[tag.category].filter((el) => el !== tag.tagValue);
			} else {
				delete this.facetFilter[tag.category];
			}
			this.productSearch();
		},
		scrollTop() {
			window.scrollTo({
				top: 0,
				left: 0,
				behavior: 'smooth'
			});
		},
		clearFilters() {
			this.search = '';
			this.categories.forEach((el) => (el.isSelected = false));
			this.categoryFilter = [];
			this.facets.forEach((el) => (el.facetValues.isSelected = false));
			this.facetFilter = {};
			this.productTags.tags = [];
			this.facetsSelected = [];
			this.showDialog = false;
			this.productSearch();
		},
		toggleView(val) {
			this.viewType = val;
		},
		handleFilterClick() {
			this.showDialog = true;
			bus.$emit('emit-facets-open', (val) => {
				this.showDialog = val;
			});
		}
	},
	template: null
};

// set template and dummy data if development environment variable string
// if (process.env.VUE_APP_DEVELOPMENT === 'true') {
component.template = require(`./${component.name}-template`).default;
// }

// css import
require(`./_${component.name}.scss`);

// export component
export default component;
