/**
 * SclCartPromo
 */

// template
const template = `
	<div>
		<p class="mb-0"><strong>Got a code? Apply it here</strong></p>
		<div class="scl-cart-promo__container">
			<v-text-field
				v-model="promoCode"
				autocomplete="off"
				background-color="#f4f4f4"
				class="scl-cart-promo__input"
				:class="{ 'has-promo' : hasPromo, 'has-error' : getPromo.hasErrored }"
				color="accent"
				:disabled="getLoading || hasPromo"
				height="64"
				hide-details
				placeholder="Promo code"
				@focus="clearInput"
			>
			</v-text-field>
			<v-btn class="scl-cart-promo__btn" color="primary" :ripple="false" :disabled="getLoading || hasPromo" large outlined @click="emitPromoCode">Apply</v-btn>
		</div>
		<v-alert v-if="hasPromo" border="bottom" class="scl-alert__alert scl-alert--success" colored-border icon="$vuetify.icons.faCheckCircle" tile type="success">
			<h6 class="scl-alert__title">Success, Promocode applied</h6>
			<p class="scl-alert__copy">Promocode <span>{{ getPromo.code }}</span> has been applied</p>
		</v-alert>
		<v-alert v-if="getPromo.hasErrored" border="bottom" class="scl-alert__alert scl-alert--error" colored-border icon="$vuetify.icons.faExclamationCircle" tile type="error">
			<h6 class="scl-alert__title">Promocode invalid</h6>
			<p class="scl-alert__copy">Promocode <span>{{ promoCode }}</span> is invalid</p>
		</v-alert>
	</div>
`;

// export template
export default template;
