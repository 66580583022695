// @vue/component
const component = {
	name: 'SclSearchCustom',
	props: {
		types: {
			type: String
		}
	},
	data() {
		return {
			searchQuery: null
		};
	},
	methods: {
		handleSearch(url) {
			if (!url) {
				return;
			}
			const searchTerms = this.searchQuery.replace(/\s+/g, '');
			if (searchTerms === null || searchTerms.length < 1) {
				return;
			}
			let contentTypes = '';
			if (this.types !== '') {
				contentTypes = `&f=type%3D${this.types}`;
			}
			window.location.href = `${url}?q=${this.searchQuery}${contentTypes}`;
		}
	},
	template: null
};

// set template and dummy data if development environment variable string
if (process.env.VUE_APP_DEVELOPMENT === 'true') {
	component.template = require(`./${component.name}-template`).default;
}

// css import
require(`./_${component.name}.scss`);

export default component;
