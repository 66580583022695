import searchApi from '@/_scl-fed/services/api/search';
import mxText from '@/_scl-fed/mixins/global/mxText';
import SclSearchBar from './SclSearchBar';
import SclSearchListingFacets from './SclSearchListingFacets';
import SclSearchListingSort from './SclSearchListingSort';
import SclSearchListItem from './SclSearchListItem';

// @vue/component
const component = {
	name: 'SclSearchListing',
	components: {
		SclSearchBar,
		SclSearchListingFacets,
		SclSearchListingSort,
		SclSearchListItem
	},
	mixins: [mxText],
	props: {},
	data() {
		return {
			error: {
				message: '',
				messageError: 'Something has gone wrong please try again.',
				messageNoResults: 'Please adjust any filters or try another search term.',
				type: ''
			},
			facets: [],
			facetFilter: [],
			facetsSelected: [],
			facetPanelActive: false,
			hasErrored: false,
			isLoading: false,
			itemCount: 0,
			items: [],
			pagination: {
				currentPage: 1,
				pageSize: 8
			},
			search: null,
			searchQuery: null,
			searchTags: {
				tags: [],
				backgroundColor: 'secondary',
				isLabel: false
			},
			sorting: {
				sort: 'relevance'
			}
		};
	},
	computed: {
		getResultsMessage() {
			let message = '';
			const total = this.itemCount;
			if (this.search !== null) {
				message = `Displaying ${total} results for "${this.search}"`;
			}
			return message;
		},
		pageCount() {
			return Math.ceil(this.itemCount / this.pagination.pageSize);
		},
		searchParams() {
			let params = `?pg=${this.pagination.currentPage}&ps=${this.pagination.pageSize}&s=${this.sorting.sort}`;
			if (this.search) {
				params += `&q=${this.search}`;
			}
			if (this.facetFilter && Array.isArray(this.facetFilter) && this.facetFilter.length > 0) {
				params += `&f=${this.facetFilter.join('%26')}`;
			}
			return params;
		},
		isDisabled() {
			if (this.items.length) {
				return false;
			}
			return true;
		}
	},
	mounted() {
		this.initialFetch();
	},
	methods: {
		clearFilters() {
			this.search = null;
			this.searchQuery = null;
			this.searchTags.tags = [];
			this.facetFilter = [];
			this.facetsSelected = [];
			this.siteSearch();
		},
		formatFacets() {
			if (this.facets && this.facets.length > 0) {
				this.facets.forEach((facetData) => {
					if (Object.entries(facetData.Values).length > 0) {
						facetData.Values = Object.keys(facetData.Values).map((facet) => {
							const facetValue = {
								label: facet,
								quantity: facetData.Values[facet],
								isSelected: this.facetsSelected.filter((selectedFacet) => selectedFacet.label === facet).length > 0
							};
							return facetValue;
						});
					}
				});
			}
		},
		handleFacetDeselection(tag) {
			this.facets.forEach((facet) => {
				facet.Values.forEach((facetValue) => {
					if (facetValue.label === tag.label) {
						facetValue.isSelected = false;
					}
				});
			});
			this.facetsSelected = this.facetsSelected.filter((facet) => facet.label !== tag.label);
		},
		handleFacetFilter(val, tags) {
			this.facetFilter = val;
			this.searchTags.tags = tags;
			this.pagination.currentPage = 1;
			this.handleFacetSelection(tags);
			this.siteSearch();
		},
		handleFacetSelection(tags) {
			this.facetsSelected = [];
			if (tags.length > 0) {
				tags.forEach((tag) => {
					const facetLabel = tag.label;
					this.facetsSelected.push({ label: facetLabel, isSelected: true });
				});
			}
		},
		handlePageChange(val) {
			this.pagination.currentPage = val;
			this.siteSearch();
		},
		handlePageSize(val) {
			this.pagination.pageSize = parseInt(val, 10);
			this.siteSearch();
		},
		handleSortItems(val) {
			this.sorting.sort = val;
			this.pagination.currentPage = 1;
			this.siteSearch();
		},
		handleTagRemove(tag) {
			this.searchTags.tags = this.searchTags.tags.filter((t) => t.value !== tag.value);
			this.facetsSelected = this.facetsSelected.filter((t) => t.label !== tag.label);
			this.facetFilter = this.facetFilter.filter((t) => !t.includes(tag.label));
			this.siteSearch();
		},
		handleSearchQuery() {
			if (!this.searchQuery) {
				return;
			}
			this.search = this.searchQuery;
			this.pagination.currentPage = 1;
			// Handle search
			this.siteSearch();
		},
		highlight(val) {
			const options = {
				targetSpanClass: 'scl-search-bar__highlight',
				target: '[class*="scl-search-list-item__title"], [class*="scl-search-list-item__description"]'
			};
			this._mxText_highlight(val, options);
		},
		initialFetch() {
			if (this.items.length > 0) {
				return;
			}

			this.parseUrlParameters();
			this.siteSearch();
		},
		parseUrlParameters() {
			const params = new URLSearchParams(window.location.search.substring(1));
			const pg = Number(params.get('pg'));
			const ps = Number(params.get('ps'));
			if (params.has('f')) {
				this.facetFilter = params.get('f').split(/[%3D|]/);
			}
			this.pagination.currentPage = Number.isNaN(pg) || pg < 1 ? this.pagination.currentPage : pg;
			this.pagination.pageSize = Number.isNaN(ps) || ps < 1 ? this.pagination.pageSize : ps;
			this.sorting.sort = params.get('s') ?? this.sorting.sort;
			this.search = params.get('q') ?? this.search;
			this.searchQuery = this.search;
		},
		processError(type, message, error) {
			this.error.type = type;
			this.error.message = message;
			this.hasErrored = true;
			console.error(error);
		},
		scrollTop() {
			window.scrollTo({
				top: 0,
				left: 0,
				behavior: 'smooth'
			});
		},
		siteSearch() {
			this.hasErrored = false;
			if (this.isLoading) {
				return;
			}
			this.isLoading = true;
			searchApi
				.siteSearch(this.searchParams)
				.then((response) => {
					if (response.data.Success) {
						// console.log(response);
						this.items = response.data.Result.Data;
						this.itemCount = response.data.Result.TotalResults;
						this.facets = response.data.Result.Facets;
						this.formatFacets();
						if (!response.data.Result.TotalResults) {
							this.processError('info', this.error.messageNoResults, 'no search results found');
						}
					}
				})
				.catch((err) => {
					this.processError('error', this.error.messageError, err);
				})
				.finally(() => {
					window.history.pushState({}, '', this.searchParams);
					this.scrollTop;
					this.isLoading = false;
					this.highlight(this.search);
				});
		},
		clearSearch() {
			this.search = null;
			this.searchQuery = null;
			this.siteSearch();
		}
	},
	template: null
};

// set template and dummy data if development environment variable string
if (process.env.VUE_APP_DEVELOPMENT === 'true') {
	component.template = require(`./${component.name}-template`).default;
}

// css import
require(`./_${component.name}.scss`);

// export component
export default component;
