/**
 * SclProductRelated
 *
 * cycle prop
 * @param {boolean} true || false
 *
 * delimiterIcon prop
 * @param {string} string "$vuetify.icons.faCircle"
 *
 * hideDelimiters prop
 * @param {boolean} true || false
 *
 * height prop
 * @param {string} string 'auto'
 */

const template = `
	<div>
		<h2 class="scl-product-related__heading">{{$root.dummyWords}}</h2>
		<v-lazy>
			<v-carousel
				:cycle="propApi.cycle"
				class="scl-product-related__carousel scl-product-related__carousel--center"
				:delimiter-icon="propApi.delimiterIcon"
				:height="propApi.height"
				hide-delimiter-background
				:show-arrows="false"
				:hide-delimiters="propApi.hideDelimiters"
				:touch="{
					left: () => nextItem(),
        			right: () => prevItem()
				}"
				v-model="currentSlide"
			>
				<!-- item -->
				<v-carousel-item class="scl-product-related__item" :key="i"  v-for="(group, i) in carouselGroups">
					<v-row class="scl-product-related__row">
						<v-col class="py-0" cols="12" sm="6" lg="3" :key="index" v-for="(product, index) in relatedProducts.slice(i * itemsPerSlide, (i + 1) * itemsPerSlide)">
						<v-card class="scl-product-related__card" :href="'/products/detail?pid=' + product.productId" :ripple="false">
						<v-btn v-if="outOfStock" class="scl-product-related__stock"> Out of Stock</v-btn>
								<v-img
									:alt="product.displayName"
									aspect-ratio="1.25"
									:lazy-src="$root.lazyImg"
									:src="image(product.imageUrls[0])"
								>
								</v-img>
							<v-card-title><h3 class="scl-product-related__title">{{ product.displayName }}</h3></v-card-title>
							<v-card-text class="text--primary scl-product-related__text">
								<div>{{ product.shortDescription }}</div>
								<div class="scl-product-related__price">
								<div class="scl-product-related__price-item" :class="memberLoggedIn? 'scl-product-related__price-item--member' : null"><small> Non-member price </small><span> {{ product.currencySymbol }}{{ product.listPrice | asCurrency }} </span> </div>
								<div class="scl-product-related__price-item"> <small> Member price </small> <span> {{ product.currencySymbol }}{{ product.adjustedPrice | asCurrency }} </span> </div>
								</div>
							</v-card-text>
						</v-card>
						</v-col>
					</v-row>
				</v-carousel-item>
			</v-carousel>
		</v-lazy>
	</div>
`;

export default template;
