import { mapGetters } from 'vuex';

// @vue/component
const component = {
	name: 'SclAuthForgotPassword',
	props: {
		inputOptions: {
			type: Object
		},
		inputRules: {
			type: Object
		},
		loading: {
			type: Boolean
		},
		pattern: {
			type: Object
		},
		configId: {
			type: String
		},
		errorMessage: {
			type: String,
			required: false,
			default: 'Unable to validate email.'
		}
	},
	data() {
		return {
			form: {
				username: null,
				config: null
			},
			resetSuccess: false,
			error: false,
			validated: false,
			validationMessage: ''
		};
	},
	computed: {
		...mapGetters(['getAuthError']),
		isValid() {
			return this.notEmpty && this.validEmail;
		},
		notEmpty() {
			return !!this.form.username;
		},
		validEmail() {
			return this.pattern.email.test(this.form.username);
		}
	},
	methods: {
		forgotPassword() {
			this.$emit('emit-forgot-password', false);
		},
		password() {
			this.form.config = this.configId; // set the Sitecore GUID to be included in the payload
			this.$store.dispatch('authApiForgotPassword', this.form).then((response) => {
				this.$emit('emit-forgot-password-response', response.data.Result.EmailSent);
			});
		},
		handleEmailValidation() {
			this.validated = false;
			this.error = false;
			this.validationMessage = '';
			if (!this.form.username) {
				return;
			}

			const payload = {
				email: this.form.username,
				password: ''
			};

			this.$store.dispatch('authApiValidateEmail', payload).then((response) => {
				// if the email is valid, it does not exist in IS and SDK therefore show error message
				if (response.data.Result.IsValid) {
					this.validationMessage = this.errorMessage;
					this.error = true;
				}
				this.validated = true;
			});
		}
	},
	template: null
};

// set template and dummy data if development environment variable string
// if (process.env.VUE_APP_DEVELOPMENT === 'true') {
component.template = require(`./${component.name}-template`).default;
// }

// css import
require(`./_${component.name}.scss`);

// export component
export default component;
