import axios from 'axios';
import '@/_scl-fed/config/axios';

export default {
	// auth
	// --------------------------------------------------

	// forgot Password
	async authForgotPassword(form) {
		const response = await axios.post('/account/forgottenpassword', form);
		return response;
	},

	// sign in
	async authSignIn(form) {
		const response = await axios.post('/account/login', form);
		this.createAuthCookie(form);
		return response;
	},

	// sign out
	async authSignOut() {
		const response = await axios.post('/account/logout');
		this.clearAuthCooke();
		return response;
	},

	// email validation
	async authValidateEmail(form) {
		const response = await axios.get('/account/validateemail', { params: { email: form.email } });
		return response;
	},

	// business no validation
	async authValidateBusinessNo(form) {
		const response = await axios.get('/account/validatebusinessnumber', { params: { businessNo: form.businessNo } });
		return response;
	},

	// registration
	authRegister(form) {
		const response = axios.post('account/register', form);
		return response;
	},

	// activation
	async authActivate(form) {
		const response = await axios.post('account/activateuser', form);
		return response;
	},

	async authChangePassword(form) {
		const response = await axios.post('account/changepassword', form);
		return response;
	},
	// Portal personal details
	async authGetPersonalDetails() {
		const response = await axios.get('account/getpersonaldetails');
		return response;
	},

	// Portal update personal details
	async authUpdatePersonalDetails(form) {
		const response = await axios.post('account/updatepersonaldetails', form);
		return response;
	},
	async createAuthCookie(form) {
		const now = new Date();
		const time = now.getTime();
		let cookie = 'stateAuthentication=session;path=/';
		if (form.rememberMe) {
			const expireTime = time + 1000 * 36000 * 24;
			now.setTime(expireTime);
			cookie = `stateAuthentication=cookie;expires=${now.toGMTString()};path=/`;
		}
		document.cookie = cookie;
	},

	async clearAuthCooke() {
		document.cookie = 'stateAuthentication=;expires=Thu, 01 Jan 1970 00:00:01 GMT;path=/';
	},
	async authGetLoggedInUser() {
		const response = await axios.get('account/getloggedinuser');
		return response;
	}
};
