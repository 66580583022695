import { mapGetters } from 'vuex';

// @vue/component
const component = {
	name: 'SclAuthRegisterForm',
	props: {
		inputOptions: {
			type: Object
		},
		inputRules: {
			type: Object
		},
		loading: {
			type: Boolean
		},
		pattern: {
			type: Object
		},
		redirectUrl: {
			type: String
		}
	},
	data() {
		return {
			valid: false,
			form: {
				title: null,
				firstName: null,
				lastName: null,
				phone: null,
				mobile: null,
				email: null,
				password: null
			},
			password: null,
			passwordConfirm: null,
			titleItems: ['Mr', 'Mrs', 'Miss', 'Ms', 'Dr', 'Prof', 'Senator', 'Sir', 'The Hon'],
			apiResponseMessage: [],
			apiResponseResult: false
		};
	},
	computed: {
		...mapGetters(['getAuth']),
		isValid() {
			return this.valid;
		},
		matchingPassword() {
			return this.password ? this.password === this.passwordConfirm : false;
		}
	},
	watch: {
		password: {
			handler() {
				this.passwordConfirm = null;
				this.form.password = this.password;
			}
		}
	},
	methods: {
		handleEmailValidation() {
			this.apiResponseMessage = [];
			this.apiResponseResult = false;
			if (!this.form.email) {
				return;
			}
			this.$store.dispatch('authApiValidateEmail', this.form).then((response) => {
				if (!response.data.Result.IsValid) {
					this.apiResponseMessage = response.data.Result.Message;
					this.apiResponseResult = response.data.Result.IsValid;
				}
			});
		},
		handleRegistration() {
			const { redirectUrl } = this;
			let userRegistered = false;
			if (this.isValid) {
				this.$store
					.dispatch('authApiRegister', this.form)
					.then((response) => {
						if (response.data.Success) {
							userRegistered = response.data.Success;
						}
					})
					.then(() => {
						if (userRegistered) {
							this.$store.dispatch('authApiLogIn', { username: this.form.email, password: this.form.password }).then(() => {
								if (this.getAuth) {
									this.$root.$emit('emitCloseLoginModal');
									window.location = redirectUrl;
								}
							});
						}
					});
			}
		}
	},
	template: null
};

// set template and dummy data if development environment variable string
// if (process.env.VUE_APP_DEVELOPMENT === 'true') {
component.template = require(`./${component.name}-template`).default;
// }

// css import
require(`./_${component.name}.scss`);

// export component
export default component;
