import '@/_scl-fed/config/axios';

export default {
	// Calendar
	//----------------------------------------------------

	async generateCalendarUrl(params) {
		console.log(params.startDate);
		const fixedParams = new URLSearchParams(params);
		if (fixedParams.has('startDate')) {
			fixedParams.set('startDate', params.startDate);
		}
		const apiUrl = `${process.env.VUE_APP_ROOT_API}/calendar/generate?${fixedParams.toString()}`;
		return apiUrl;
	}
};
