import Vue from 'vue';

export const GtmEventBus = new Vue();

GtmEventBus.$on('gtm-click', (gtm, eventName, action, data, route) => {
	const mergedData = {};
	gtm.trackEvent(
		Object.assign(
			mergedData,
			{
				event: eventName,
				action,
				url: window.location.origin + route.fullPath
			},
			data
		)
	);
});

GtmEventBus.$on('gtm-custom-event', (gtm, data) => {
	const mergedData = {};
	gtm.trackEvent(Object.assign(mergedData, data));
});
