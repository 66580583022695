/**
 * SclExampleApi
 */

// template
const template = `
	<div>
		{{ products }}
	</div>
`;

// export template
export default template;
