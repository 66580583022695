/**
 * SclArticleCardCarousel
 */

const template = `
	<div>
		<div class="scl-content-carousel__header">
			<h3 class="scl-content-carousel__header-title">{{$root.dummyWords}}</h3>
			<v-btn :ripple="false" class="btn btn-link scl-content-carousel__header-link">{{$root.dummyWords}}<v-icon class="btn__icon-link"> $vuetify.icons.faArrowRight</v-icon></v-btn>
		</div>
		<v-carousel
			:cycle="propApi.cycle"
			class="scl-content-carousel__carousel scl-content-carousel__carousel--center"
			:delimiter-icon="propApi.delimiterIcon"
			:height="propApi.height"
			hide-delimiter-background
			:show-arrows="propApi.showArrows"
			:hide-delimiters="propApi.hideDelimiters"
			light
			:next-icon="propApi.nextIcon"
			:prev-icon="propApi.prevIcon"
			ref="carouselGridRef">
				<v-carousel-item class="scl-content-carousel__item">
					<v-row class="scl-content-carousel__row" >
						<v-col class="py-0" cols="12" sm="6" md="4">
							<v-lazy>
								<v-card :ripple="false" class="scl-content-carousel__card" outlined href="#">
									<v-img
										:lazy-src="$root.dummyImage43"
										:src="$root.dummyImage43"
										:aspect-ratio="2/1"
									/>
									<div class="scl-content-carousel__content">
										<ul class="scl-content-carousel__list">
											<li class="scl-content-carousel__label">
												<v-img color="primaryDark" :src="$root.dummyImage43" class="mr-1" max-width="16"></v-img>
												{{ $root.dummyWords }}
											</li>
											<li class="scl-content-carousel__label">
												<v-img color="primaryDark" class="mr-1" max-width="16" :src="$root.dummyImage43"></v-img>
												{{ $root.dummyWords }}
											</li>
										</ul>
										<h6 class="scl-content-carousel__title mb-3">{{ $root.dummyWord }}</h6>
										<div class="scl-content-carousel__text">
											<p>{{ $root.dummySentence }}</p>
										</div>
										<v-btn :ripple="false" class="btn btn-link justify-start" text href="#">
											{{ $root.dummyWords }}
											<v-icon class="scl-content-carousel__icon btn__icon-link" right>$vuetify.icons.faArrowRight</v-icon>
										</v-btn>
									</div>
								</v-card>
							</v-lazy>
						</v-col>
					</v-row>
				</v-carousel-item>
		</v-carousel>
	</div>
`;

export default template;
