/**
 * SclRelatedArticlesList
 */

// template
const template = `
<div>
	<v-list>
		<div class="scl-related-articles-list__title">{{ $root.dummyWords }}</div>
		<v-list-item class="scl-related-articles-list__item card-accent card-accent--left card-accent__secondary">
			<v-card :ripple="false" href="#" class="scl-related-articles-list__item-link">
				<v-img
					:contain="true"
					:src="$root.dummyImage43"
					aspect-ratio="1"
					class="scl-related-articles-list__item-image"
				/>
				<div class="scl-related-articles-list__item-content">
					<span>{{ $root.dummyWord }}</span>
					<p>{{ $root.dummyWords }}</p>
				</div>
			</v-card>
		</v-list-item>

		<v-list-item class="scl-related-articles-list__item card-accent card-accent--left card-accent__secondary">
			<v-card :ripple="false" href="#" class="scl-related-articles-list__item-link">
				<div>
					<v-img
						:contain="true"
						:src="$root.dummyImage43"
						aspect-ratio="1"
						class="scl-related-articles-list__item-image"
					/>
				</div>
				<div class="scl-related-articles-list__item-content">
					<span>{{ $root.dummyWord }}</span>
					<p>{{ $root.dummyWords }}</p>
				</div>
			</v-card>
		</v-list-item>
	</v-list>
</div>
`;

// export template
export default template;
