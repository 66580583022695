// @vue/component
const component = {
	name: 'SclProductGallery',
	props: {
		altText: {
			type: String
		},
		images: {
			type: Array
		}
	},
	data() {
		return {
			activeImage: process.env.VUE_APP_ROOT_DOMAIN + this.images[0],
			dialog: false
		};
	},
	computed: {},
	methods: {
		getPath(val) {
			return process.env.VUE_APP_ROOT_DOMAIN + val;
		},
		setActiveImage(val, $event) {
			this.activeImage = val;
			console.log(val);
			this.toggleActiveClass($event);
		},
		toggleActiveClass($event) {
			Array.from(document.getElementsByClassName('scl-product-gallery__image--small is-active')).forEach((el) => el.classList.remove('is-active'));
			$event.currentTarget.classList.add('is-active');
		}
	},
	template: null
};

// set template and dummy data if development environment variable string
// if (process.env.VUE_APP_DEVELOPMENT === 'true') {
component.template = require(`./${component.name}-template`).default;
// }

// css import
require(`./_${component.name}.scss`);

// export component
export default component;
