// @vue/component
const component = {
	name: 'SclArticleListItem',
	props: {
		title: {
			type: String
		},
		date: {
			type: String
		},
		time: {
			type: String
		},
		description: {
			type: String
		},
		iconUrl: {
			type: String
		},
		imageUrl: {
			type: String
		},
		url: {
			type: String
		},
		articleType: {
			type: String
		},
		featured: {
			type: Boolean
		},
		memberLoggedIn: {
			type: Boolean
		},
		document: {
			type: Boolean
		},
		hideTime: {
			type: Boolean
		}
	},
	computed: {
		icon() {
			return this.iconUrl;
		},
		image() {
			return this.imageUrl;
		},
		truncatedDescription() {
			return this.description && this.description.length > 216 ? `${this.description.substr(0, 216)}...` : this.description;
		}
	},
	methods: {
		dateFormat(val) {
			const options = { year: 'numeric', month: 'long', day: 'numeric' };
			const date = new Date(val);
			let hours = date.getHours();
			let minutes = date.getMinutes();
			const ampm = hours >= 12 ? 'pm' : 'am';
			hours %= 12;
			hours = hours || 12;
			minutes = minutes < 10 ? `0${minutes}` : minutes;
			let strTime = `, ${hours}:${minutes} ${ampm}`;
			if (this.hideTime) {
				strTime = '';
			}
			return `${date.toLocaleDateString('en-AU', options)}${strTime}`;
		}
	},
	template: null
};

// set template and dummy data if development environment variable string
// if (process.env.VUE_APP_DEVELOPMENT === 'true') {
component.template = require(`./${component.name}-template`).default;
// }

// css import
require(`./_${component.name}.scss`);

// export component
export default component;
