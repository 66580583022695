// @vue/component
const component = {
	name: 'SclSecondaryHeaderBanner',
	props: {
		api: {
			type: Object,
			// props type validation
			validator: (obj) => {
				const set = new Set();
				set.add(!!(obj.height ? typeof obj.height === 'string' : true));
				set.add(!!(obj.position ? typeof obj.position === 'string' : true));
				set.add(!!(obj.colour ? typeof obj.colour === 'string' : true));
				set.add(!!(obj.overlay ? typeof obj.overlay === 'boolean' : true));
				return !set.has(false);
			}
		}
	},
	computed: {
		// props defaults
		propApi() {
			return {
				height: 'large',
				position: 'left',
				colour: 'white',
				overlay: true,
				...this.api
			};
		}
	},
	template: null
};

// set template and dummy data if development environment variable string
if (process.env.VUE_APP_DEVELOPMENT === 'true') {
	component.template = require(`./${component.name}-template`).default;
}

// css import
require(`./_${component.name}.scss`);

// export component
export default component;
