// @vue/component
import { mapGetters } from 'vuex';

const component = {
	name: 'SclAuthActivationForm',
	props: {
		redirectUrl: {
			type: String
		}
	},
	data() {
		return {
			inputOptions: {
				backgroundColor: '#f4f4f4',
				class: 'scl-checkout__form-input',
				color: 'accent',
				height: 48,
				outlined: true
			},
			inputRules: {
				email: (value) => {
					return value === null || value === '' || this.pattern.email.test(value) || 'Invalid email address';
				},
				number: (value) => {
					return value === null || value === '' || this.pattern.number.test(value) || 'Enter a number, no spaces';
				},
				password: (value) => {
					return value === null || value === '' || this.pattern.password.test(value) || 'Password must be minimum 6 and maximum 20 characters';
				},
				confirmPassword: (value) => {
					return value === null || value === '' || value === this.formData.password || 'Password must match';
				},
				phone: (value) => {
					return value === null || value === '' || this.pattern.phone.test(value) || 'Enter 10 digits';
				},
				required: (value) => !!value || 'This field is required'
			},
			pattern: {
				email: /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
				number: /^\d*$/,
				password: /^.{6,20}$/,
				phone: /^\d{10}$/
			},
			formData: {
				firstName: '',
				lastName: '',
				memberId: '',
				email: '',
				password: '',
				confirmPassword: ''
			},
			isValid: false,
			resultMessage: '',
			snackbar: false,
			snackbarMessage: '',
			timeout: 6000
		};
	},
	mounted() {
		this.$nextTick(() => this.$refs.firstName.focus());
	},
	computed: {
		notEmpty() {
			return this.formData.firstName !== '' && this.formData.firstName !== '';
		},
		loading() {
			return this.getLoading();
		}
	},
	methods: {
		...mapGetters(['getLoading']),
		validateForm() {
			this.isValid = this.$refs.form.validate();
		},
		handleSubmit() {
			this.resultMessage = '';
			this.$store.dispatch('authApiActivate', this.formData).then((response) => {
				if (!response.data.Result.IsSdkError) {
					this.resultMessage = response.data.Result.ActivationMessage;
					window.location.href = this.redirectUrl;
				} else {
					this.snackbarMessage = response.data.Result.ActivationMessage;
					this.snackbar = true;
				}
			});
		}
	},
	template: null
};

// set template and dummy data if development environment variable string
// if (process.env.VUE_APP_DEVELOPMENT === 'true') {
component.template = require(`./${component.name}-template`).default;
// }

// css import
require(`./_${component.name}.scss`);

// export component
export default component;
