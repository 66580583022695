/**
 * SclEventsListing
 */

// template
const template = `
	<div>
		<v-container>
			<v-row>
				<!-- Filters -->
				<v-col cols="12">
					<scl-events-filtering v-model="showFilter" @emit-event-facet-change="handleFacetFilter" @emit-event-tag-remove="handleTagRemove" class="scl-component scl-events-filtering" :is-disabled="isLoading" :regions="regions" :types="types" :event-tags="eventTags"></scl-events-filtering>
				</v-col>
				<!-- items -->
				<v-col cols="12" class="scl-events-listing__container">
					<v-row>
						<v-col cols="12" class="pt-0">
							<div class="scl-events-listing__sorting">
								<v-row>
									<v-col cols="12" md="4" lg="3" class="scl-events-listing__sorting-col scl-events-listing__search">
										<!-- Search -->
										<scl-events-search v-if="showFilter" @emit-search="handleSearchQuery" :is-disabled="isLoading" :param="search" class="scl-component scl-events-search"></scl-events-search>
									</v-col>
									<v-col cols="12" md="8" lg="9" class="scl-events-listing__sorting-col">
										<!-- Page size -->
										<scl-page-size @emit-page-size="handlePageSize" :active-item="pagination.pageSize" :api="{pageSize: [{value: '12'}, {value: '24'}, {value: '48'}]}" :is-disabled="isLoading" class="scl-page-size scl-component"></scl-page-size>
									</v-col>
								</v-row>
							</div>
						</v-col>
						<v-col cols="12">
						{{getResultsMessage}}
						</v-col>
						<!-- error / no results message -->
						<v-col v-if="hasErrored" cols="12">
							<div class="scl-component scl-alert">
								<v-alert border="bottom" class="scl-alert__alert" :class="'scl-alert--' + error.type" colored-border icon="$vuetify.icons.faExclamation" tile :type="error.type">
									<p class="scl-alert__copy">{{ error.message }}</p>
								</v-alert>
							</div>
						</v-col>
						<template v-else>
							<!-- loading spinner -->
							<v-col v-if="isLoading" cols="12" class="text-center">
								<v-progress-circular color="primary" indeterminate :size="50" :width="7"></v-progress-circular>
							</v-col>
							<template v-else>
								<v-col v-for="(item, index) in items" :key="index" cols="12" md="4">
									<!-- List Items -->
									<scl-events-list-item class="scl-component scl-events-list-item"
									:title="item.Title"
									:image-url="item.Thumbnail"
									:description="item.Description"
									:date="item.StartDateTime"
									:location="item.Location"
									:url="item.Url"
									></scl-events-list-item>
								</v-col>
							</template>
						</template>
						<!-- pagination -->
						<v-col cols="12" v-if="pageCount > 1">
							<scl-pagination
								class="scl-component scl-pagination"
								:is-disabled="isLoading"
								:total-pages="pageCount"
								:current-page="pagination.currentPage"
								:visible-pages="9"
								@page-change="handlePageChange"
							></scl-pagination>
						</v-col>
					</v-row>
				</v-col>
			</v-row>
		</v-container>
	</div>
`;

// export template
export default template;
