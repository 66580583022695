/**
 * SclLatestArticles
 */

// template
const template = `
	<div>
		<h6 class="scl-latest-articles__title">
		{{$root.dummyWords}}
		</h6>
		<a class="scl-latest-articles__link" href="#" >
		<span>{{ $root.dummySentence}}</span>
		<v-img :contain="true":src="$root.dummyImage43" aspect-ratio="1" class="scl-latest-articles__image" />
		</a>
		<a class="scl-latest-articles__link" href="#" >
		<span>{{ $root.dummySentence}}</span>
		<v-img :contain="true":src="$root.dummyImage43" aspect-ratio="1" class="scl-latest-articles__image" />
		</a>
		<a class="scl-latest-articles__link" href="#" >
		<span>{{ $root.dummySentence}}</span>
		<v-img :contain="true":src="$root.dummyImage43" aspect-ratio="1" class="scl-latest-articles__image" />
		</a>

	</div>
`;

// export template
export default template;
