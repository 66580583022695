// @vue/component
const component = {
	name: 'SclKeyInfo',
	props: {},
	data() {
		return {};
	},
	computed: {},
	mounted() {},
	methods: {
		redirect(e) {
			window.location.href = e;
		},
		trunc(value) {
			if (!value) return value;
			let newValue = value;
			if (typeof value !== 'number') newValue = Number(value);
			if (Number.isNaN(newValue)) return value;
			return Math.trunc(newValue);
		}
	},
	template: null
};

// set template and dummy data if development environment variable string
if (process.env.VUE_APP_DEVELOPMENT === 'true') {
	component.template = require(`./${component.name}-template`).default;
}

// css import
require(`./_${component.name}.scss`);

// export component
export default component;
