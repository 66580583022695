/**
 * SclSocialFeed api
 *
 *
 * */

const template = `
	<div>
		<v-row>
			<v-col cols="12">
				<h4 v-text="$root.dummyWord"></h4>
			</v-col>
			<v-col cols="12">
				<span v-html="$root.dummyCurator"></span>
			</v-col>
			<v-col cols="12" class="text-right">
				{{ $root.dummyWord }}
				<a :href="$root.dummyUrl" class="ml-2">
					<v-icon>$vuetify.icons.faFacebook</v-icon>
				</a>
				<a :href="$root.dummyUrl" class="ml-2">
					<v-icon>$vuetify.icons.faTwitter</v-icon>
				</a>
				<a :href="$root.dummyUrl" class="ml-2">
					<v-icon>$vuetify.icons.faInstagram</v-icon>
				</a>
				<a :href="$root.dummyUrl" class="ml-2">
					<v-icon>$vuetify.icons.faYoutube</v-icon>
				</a>
			</v-col>
		</v-row>
	</div>
`;

export default template;
