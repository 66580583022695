/**
 * SclGridContainer4
 *
 * api eg: :api="{ 'hasTitle': true, 'alignment': 'start', 'cols': 3 }"
 *
 * hasTitle prop
 * display the v-row or not if content has been set
 * @param {boolean} false
 *
 * alignment prop
 * allows the content editor to define how the content (component) will align if there is an odd number
 * @param {string} string start, center, end
 *
 * cols prop
 * sets the number of columns to be displayed, between 1 - 4 (desktop, other sizes are computed)
 * @param {number} 12 (1 col), 6 (2 cols), 4 (3 cols), 3 (4 cols)
 */

// template
const template = `
	<div>		
		<!-- title and link -->
		<div v-if="propApi.hasTitle" align="center" class="scl-grid-container-4__title-header">
			
				<h3 class="scl-grid-container-4__title" :class="{ 'text-no-wrap text-truncate' : $vuetify.breakpoint.smAndUp }">Lorem ipsum dolor sit amet Lorem ipsum dolor sit amet Lorem ipsum dolor sit amet</h3>
			
				<v-btn :ripple="false" class="btn btn-link scl-grid-container-4__link"  text>
					Lorem ipsum
					<v-icon class="btn__icon-link" href="#" right size="14">$vuetify.icons.faArrowRight</v-icon>
				</v-btn>
		</div>
		<div>
			<!-- grid cols -->
			<v-row :justify="propApi.alignment">
				<v-col cols="12" :sm="colsSmall" :md="colsMedium" :lg="propApi.cols">
					column
				</v-col>
			</v-row>
		</div>
	</div>
`;

// export template
export default template;
