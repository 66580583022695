const component = {
	name: 'SclDatePicker',
	template: null,
	props: {
		api: {
			type: Object,
			required: false,
			// props type validation
			validator: (obj) => {
				const set = new Set();
				set.add(!!(obj.reactive ? typeof obj.reactive === 'boolean' : true));
				set.add(!!(obj.pickerIcon ? typeof obj.pickerIcon === 'string' : true));
				set.add(!!(obj.prevIcon ? typeof obj.prevIcon === 'string' : true));
				set.add(!!(obj.nextIcon ? typeof obj.nextIcon === 'string' : true));
				return !set.has(false);
			}
		}
	},
	data() {
		return {
			picker: new Date().toISOString().substr(0, 10),
			dateFormatted: this.formatDate(new Date().toISOString().substr(0, 10)),
			menu: false,
			subMenu: false
		};
	},
	computed: {
		// props defaults
		propApi() {
			return {
				reactive: true,
				pickerIcon: '$vuetify.icons.faCalendar',
				prevIcon: this.$vuetify.icons.prev,
				nextIcon: this.$vuetify.icons.next,
				...this.api
			};
		}
	},
	watch: {
		picker() {
			this.dateFormatted = this.formatDate(this.picker);
		}
	},
	methods: {
		formatDate(date) {
			if (!date) {
				return null;
			}

			const [year, month, day] = date.split('-');
			return `${day}/${month}/${year}`;
		}
	}
};

// set template and dummy data if development environment variable string
if (process.env.VUE_APP_DEVELOPMENT === 'true') {
	component.template = require(`./${component.name}-template`).default;
}

// css import
require(`./_${component.name}.scss`);

export default component;
