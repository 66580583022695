/**
 * mxSecuredPaymentForms
 * Extends globally injected braintree and braintree hosted fields library
 * Contains mutations that can be re-invoked for any payment component
 */

const mxSecuredPaymentForms = {
	methods: {
		// void method to instantiate braintree instance
		_mxSecuredPaymentForms_createInstance(braintreeOptions) {
			this.$VueBraintreeClient.create(
				braintreeOptions.auth,
				// Hosted Fields instantiations
				(clientErr, clientInstance) => {
					if (clientErr) {
						console.error(clientErr);
					}
					this._mxSecuredPaymentForms_createHostedFieldsInstance(clientInstance, braintreeOptions);
				}
			);
		},
		// void method to instantiate hosted fields
		// hostedFields is an optional param to handle variants which contain dispatcher events to the vuex state
		// braintreeOption.hasDispatchEvent is an optional field which allows data to be pushed to the state
		_mxSecuredPaymentForms_createHostedFieldsInstance(clientInstance, braintreeOptions) {
			this.$VueBraintreeHostedFields.create(
				{
					client: clientInstance,
					styles: braintreeOptions.fieldStyles,
					fields: braintreeOptions.fieldDefinitions
				},
				(hostedFieldsErr, hostedFieldsInstance) => {
					if (hostedFieldsErr) {
						console.error(hostedFieldsErr);
					}

					const hostedFields = hostedFieldsInstance;
					const that = this;

					if (braintreeOptions.hasLocalHostedInstance) {
						that.$root.$emit(braintreeOptions.hostedInstanceEventName, hostedFields);
					}

					// add callback to hosted field instance
					hostedFields.on('validityChange', (event) => {
						const field = event.fields[event.emittedBy];
						that.$root.$emit(braintreeOptions.callbackEventName, field);
					});

					// Optional dispatcher event
					if (braintreeOptions.hasDistpachEvent) {
						this.$store.dispatch(braintreeOptions.dispatchEventName, hostedFields);
					}
				}
			);
		},

		// Void method to apply validation on the hosted fields
		// Consumes hostedFieldInstance of each field
		_mxSecuredPaymentForms_validateHostedFields(emittedField) {
			if (emittedField.isValid) {
				switch (emittedField.container.id) {
					case 'cardholder-name':
						this.formData.name.error = '';
						this.formData.name.isValid = true;
						break;
					case 'card-number':
						this.formData.cardNumber.error = '';
						this.formData.cardNumber.isValid = true;
						break;
					case 'expiration-date':
						this.formData.expiry.error = '';
						this.formData.expiry.isValid = true;
						break;
					case 'cvv':
						this.formData.cvv.error = '';
						this.formData.cvv.isValid = true;
						break;
					default:
						break;
				}
				return;
			}

			switch (emittedField.container.id) {
				case 'cardholder-name':
					this.formData.name.error = 'Please enter a valid name';
					this.formData.name.isValid = false;
					break;
				case 'card-number':
					this.formData.cardNumber.error = 'Please enter a valid card number';
					this.formData.cardNumber.isValid = false;
					break;
				case 'expiration-date':
					this.formData.expiry.error = 'Please enter a valid expiration date';
					this.formData.expiry.isValid = false;
					break;
				case 'cvv':
					this.formData.cvv.error = 'Please enter a valid CVV';
					this.formData.cvv.isValid = false;
					break;
				default:
					break;
			}
		},

		// Submission - Tokenizes the field Host Field instance for submission
		// @params paymentSubmitOptions, hostedFieldInstance, paymentPayload
		_mxSecuredPaymentForms_tokenizeHostedFields(hostedFields, paymentSubmitOptions, paymentPayload) {
			const currentLocation = window.location;
			if (hostedFields) {
				hostedFields.tokenize((tokenErr, secureFieldPayload) => {
					if (tokenErr) {
						this.$store.dispatch(paymentSubmitOptions.toastErrorEvent);
					}

					const payload = paymentPayload;
					payload.payment = {
						paymentNonce: secureFieldPayload.nonce
					};

					this.$store.dispatch(paymentSubmitOptions.postApiEvent, payload).then((response) => {
						if (response.data.Success) {
							this.$root.$emit(paymentSubmitOptions.paymentErrorEventName, false);
							currentLocation.href = paymentSubmitOptions.redirectUrl;
						} else {
							this.$root.$emit(paymentSubmitOptions.paymentErrorEventName, true);
						}
					});
				});
			}
		}
	}
};

export default mxSecuredPaymentForms;
