// @vue/component
const component = {
	name: 'SclGridContainer4',
	props: {
		api: {
			type: Object,
			required: true,
			// props type validation
			validator: (obj) => {
				const set = new Set();
				set.add(!!(obj.hasTitle ? typeof obj.hasTitle === 'boolean' : true));
				set.add(!!(obj.alignment ? typeof obj.alignment === 'string' : true));
				set.add(!!(obj.cols ? typeof obj.cols === 'number' : true));
				return !set.has(false);
			}
		}
	},
	data() {
		return {
			colsMedium: 4,
			colsSmall: 6
		};
	},
	computed: {
		// props defaults
		propApi() {
			return {
				hasTitle: false,
				alignment: 'start',
				cols: 3,
				...this.api
			};
		}
	},
	created() {
		this.computeCols();
	},
	methods: {
		computeCols() {
			switch (this.api.cols) {
				case 3:
					this.colsMedium = 4;
					this.colsSmall = 6;
					break;
				case 4:
					this.colsMedium = 6;
					this.colsSmall = 6;
					break;
				case 6:
					this.colsMedium = 6;
					this.colsSmall = 12;
					break;
				case 12:
					this.colsMedium = 12;
					this.colsSmall = 12;
					break;
				default:
					this.colsMedium = 4;
					this.colsSmall = 6;
			}
		}
	},
	template: null
};

// set template and dummy data if development environment variable string
if (process.env.VUE_APP_DEVELOPMENT === 'true') {
	component.template = require(`./${component.name}-template`).default;
}

// css import
require(`./_${component.name}.scss`);

// export component
export default component;
