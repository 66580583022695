// @vue/component
const component = {
	name: 'SclSearchCuratedTerms',
	props: {
		redirectUrl: {
			type: String
		}
	},
	methods: {
		handleSearch(searchTerms) {
			if (this.redirectUrl == null || searchTerms == null) {
				return;
			}
			window.location.href = `${this.redirectUrl}?q=${searchTerms}`;
		}
	},
	template: null
};

// set template and dummy data if development environment variable string
if (process.env.VUE_APP_DEVELOPMENT === 'true') {
	component.template = require(`./${component.name}-template`).default;
}

// css import
require(`./_${component.name}.scss`);

export default component;
