/**
 * SclTrainingListItem
 */

// template
const template = `
	<div>
		<v-lazy>
			<v-card class="scl-training-list-item__item" :href="url">
				<v-img :lazy-src="$root.lazyImg" :src="image" alt="alt test" aspect-ratio="2.1" class="scl-training-list-item__img">
				</v-img>
				<div class="scl-training-list-item__content">
					<ul class="scl-training-list-item__list">
						<li class="scl-training-list-item__label">
							<v-icon v-if="isShortCourse" color="primaryDark" class="mr-1" size="16">$vuetify.icons.faUserChart</v-icon>
							<v-icon v-else color="primaryDark" class="mr-1" size="16">$vuetify.icons.faUserChart</v-icon>
							{{ type }}
						</li>
						<li class="scl-training-list-item__label">
							<v-icon v-if="isOnline" color="primaryDark" class="mr-1" size="16">$vuetify.icons.faVideo</v-icon>
							<v-icon v-else color="primaryDark" class="mr-1" size="16">$vuetify.icons.faMapMarker</v-icon>
							{{ location }}
						</li>
					</ul>
					<h4 v-if="title" class="scl-training-list-item__title">{{ truncate(title, 60) }}</h4>
					<div>
						<p v-if="description">{{ truncate(description, 222) }}</p>
					</div>
				</div>
				<div class="scl-training-list-item__link">
					<span class="btn btn-link">Find out more <v-icon class="btn__icon-link scl-training-list-item__icon">$vuetify.icons.faArrowRight</v-icon></span>
				</div>
			</v-card>
		</v-lazy>
	</div>
`;

// export template
export default template;
