/**
 * SclProductRelated
 *
 * cycle prop
 * @param {boolean} true || false
 *
 * delimiterIcon prop
 * @param {string} string "$vuetify.icons.faCircle"
 *
 * hideDelimiters prop
 * @param {boolean} true || false
 *
 * height prop
 * @param {string} string 'auto'
 */

const template = `
	<div>
		<h2 class="scl-product-carousel__heading">{{$root.dummyWords}}</h2>
			<v-carousel
				:cycle="propApi.cycle"
				class="scl-product-carousel__carousel scl-product-carousel__carousel--center"
				:delimiter-icon="propApi.delimiterIcon"
				:height="propApi.height"
				hide-delimiter-background
				:show-arrows="false"
				:hide-delimiters="propApi.hideDelimiters"
				ref="productCarouselRef"
			>
				<!-- item -->
				<v-carousel-item class="scl-product-carousel__item">
					<v-row class="scl-product-carousel__row">
						<v-col class="py-0" cols="12" sm="6" lg="3">
						<v-card class="scl-product-carousel__card" href="" :ripple="false">
								<v-img
									:aspect-ratio="5/3"
									:src="$root.dummyImage43"
								>
								</v-img>
							<v-card-title class="scl-product-carousel__title"><h3 >{{ $root.dummySentence }}</h3></v-card-title>
							<v-card-text class="text--primary scl-product-carousel__text">
								<div>{{ $root.dummyWords}}</div>
								<div class="scl-product-carousel__price">
								<div class="scl-product-carousel__price-item" :class="isMember? 'scl-product-carousel__price-item--member' : null"><small> Non-member price </small><span> $9.99 </span> </div>
								<div class="scl-product-carousel__price-item"> <small> Member price </small> <span> $9.99 </span> </div>

								</div>
							</v-card-text>
						</v-card>
						</v-col>
						<v-col class="py-0" cols="12" sm="6" lg="3">
						<v-card class="scl-product-carousel__card" href="" :ripple="false">
								<v-img
									:aspect-ratio="5/3"
									:src="$root.dummyImage43"
								>
								</v-img>
							<v-card-title class="scl-product-carousel__title"><h3 class="scl-product-carousel__title">{{ $root.dummyWords }}</h3></v-card-title>
							<v-card-text class="text--primary scl-product-carousel__text">
								<div>{{ $root.dummyWords}}</div>
							</v-card-text>
							<v-card-actions>
								<div class="scl-product-carousel__price">
									<div class="scl-product-carousel__price-item" :class="isMember? 'scl-product-carousel__price-item--member' : null"><small> Non-member price </small><span> $9.99 </span> </div>
									<div class="scl-product-carousel__price-item"> <small> Member price </small> <span> $9.99 </span> </div>
								</div>
							</v-card-actions>
						</v-card>
						</v-col>

					</v-row>
				</v-carousel-item>
			</v-carousel>
	</div>
`;

export default template;
