// @vue/component
const component = {
	name: 'SclEventsFiltering',
	props: {
		isDisabled: {
			type: Boolean
		},
		regions: {
			type: Array
		},
		types: {
			type: Array
		},
		eventTags: {
			type: Object
		}
	},
	data() {
		return {
			date: {
				active: false,
				label: 'Event date range',
				model: [],
				value: ['Next Month', 'Next 3 Months', 'Next 6 Months', 'Next Year']
			},
			filtersActive: !this.$vuetify.breakpoint.xsOnly,
			icons: {
				filterClosed: '$vuetify.icons.faPlus',
				filterOpen: '$vuetify.icons.faMinus'
			},
			region: {
				active: false,
				label: 'Region',
				model: []
			},
			type: {
				active: false,
				label: 'Event type',
				model: [],
				value: ['type 1 test', 'type 2 test']
			},
			query: {
				active: false,
				label: 'Query',
				model: [],
				value: []
			}
		};
	},
	computed: {
		eventFilter() {
			return {
				date: {
					model: this.date.model
				},
				region: {
					model: this.region.model
				},
				type: {
					model: this.type.model
				},
				query: {
					model: this.query.model
				}
			};
		}
	},
	watch: {
		'$vuetify.breakpoint.xsOnly': {
			immediate: true,
			handler(val) {
				this.filtersActive = !val;
			}
		},
		filtersActive: {
			immediate: true,
			handler(val) {
				this.$emit('input', val);
			}
		}
	},
	mounted() {
		this.setupMenuActiveWatchers();
	},
	destroyed() {
		this.unWatchRegionFilter();
		this.unWatchTypeFilter();
		this.unWatchDateFilter();
	},
	methods: {
		clearFilters() {
			this.date.model = [];
			this.region.model = [];
			this.type.model = [];
			this.updateFilters();
		},
		setupMenuActiveWatchers() {
			this.unWatchRegionFilter = this.$watch(
				() => this.$refs['region-filter'].isMenuActive,
				(val) => {
					this.region.active = val;
				}
			);
			this.unWatchTypeFilter = this.$watch(
				() => this.$refs['type-filter'].isMenuActive,
				(val) => {
					this.type.active = val;
				}
			);
			this.unWatchDateFilter = this.$watch(
				() => this.$refs['date-filter'].isMenuActive,
				(val) => {
					this.date.active = val;
				}
			);
		},
		updateFilters() {
			this.$emit('emit-event-facet-change', this.eventFilter);
		},
		handleTagRemove(tag) {
			if (tag.value.includes('region')) {
				this.region.model = this.region.model.filter((x) => !x.includes(tag.label));
			} else if (tag.value.includes('type')) {
				this.type.model = this.type.model.filter((x) => !x.includes(tag.label));
			} else if (tag.value.includes('q')) {
				this.query.model = this.query.model.filter((x) => !x.includes(tag.label));
			} else {
				this.date.model = this.date.model.filter((x) => !x.includes(tag.label));
			}

			this.$emit('emit-event-tag-remove', tag.value, this.eventFilter);
		}
	},
	template: null
};

// set template and dummy data if development environment variable string
// if (process.env.VUE_APP_DEVELOPMENT === 'true') {
component.template = require(`./${component.name}-template`).default;
// }

// css import
require(`./_${component.name}.scss`);

// export component
export default component;
