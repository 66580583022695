/**
 * SclHeaderContact
 *
 */

// template
const template = `

	<div>
		<div class="scl-header-contact__text">{{$root.dummyWords}}</div>
		<div class="scl-header-contact__number"><v-icon left v-if="$vuetify.breakpoint.smAndUp">$vuetify.icons.faPhone</v-icon><a href="tel:1300 650 620">1300 650 620 </a></div>
	</div>
`;

// export template
export default template;
