/**
 * SclFooter api
 *
 * @param {boolean} fluid - removes max-width restraints on the container
 */

// template
const template = `

	<div>
	<!-- 
		<v-container :class="propApi.fluid ? 'fluid' : null">
		<v-row>
			<v-col cols="12" md="8">
			<v-expansion-panels v-if="$vuetify.breakpoint.smAndDown">
			<v-expansion-panel class="scl-footer__expansion-panel">
					<v-expansion-panel-header hide-actions class="scl-footer__expansion-panel-header">
						<div class="scl-footer__title">
							<scl-dummy-data :content="$root.dummyWords"></scl-dummy-data>
						</div>
						<v-icon class="scl-footer__icon scl-footer__icon--minus">{{ propApi.expandedIcon }} </v-icon>
						<v-icon class="scl-footer__icon scl-footer__icon--plus">{{ propApi.collapsedIcon }}</v-icon>
					</v-expansion-panel-header>
					<v-expansion-panel-content class="scl-footer__expansion-content">
						<v-row v-bind="flexDirection">
							<v-col cols="12" >
								<v-list subheader dense class="scl-footer__list">
									<v-subheader class="scl-footer__header">Heading 1</v-subheader>
									<v-list-item :ripple="false" :href="$root.dummyUrl">Link 1</v-list-item>
									<v-list-item :ripple="false" :href="$root.dummyUrl">Link 2</v-list-item>
									<v-list-item :ripple="false" :href="$root.dummyUrl">Another longer link just so i can see what it looks like</v-list-item>
									<v-list-item :ripple="false" :href="$root.dummyUrl">Link 4</v-list-item>
								</v-list>
							</v-col>
							<v-col cols="12" >
								<v-list subheader dense class="scl-footer__list">
									<v-subheader class="scl-footer__header">Heading 1</v-subheader>
									<v-list-item :ripple="false" :href="$root.dummyUrl">Link 1</v-list-item>
									<v-list-item :ripple="false" :href="$root.dummyUrl">Link 2</v-list-item>
									<v-list-item :ripple="false" :href="$root.dummyUrl">Another longer link just so i can see what it looks like</v-list-item>
									<v-list-item :ripple="false" :href="$root.dummyUrl">Link 4</v-list-item>
								</v-list>
							</v-col>
						</v-row>
					</v-expansion-panel-content>
				</v-expansion-panel>
			</v-expansion-panels>
			<v-row v-bind="flexDirection" v-else>
				<v-col cols="12" md="3">
					<v-list subheader dense class="scl-footer__list">
						<v-subheader class="scl-footer__header">Heading 1</v-subheader>
						<v-list-item :ripple="false" :href="$root.dummyUrl">Link 1</v-list-item>
						<v-list-item :ripple="false" :href="$root.dummyUrl">Link 2</v-list-item>
						<v-list-item :ripple="false" :href="$root.dummyUrl">Another longer link just so i can see what it looks like</v-list-item>
						<v-list-item :ripple="false" :href="$root.dummyUrl">Link 4</v-list-item>
					</v-list>
				</v-col>
				<v-col cols="12" md="3">
					<v-list subheader dense class="scl-footer__list">
						<v-subheader class="scl-footer__header">Heading 1</v-subheader>
						<v-list-item :ripple="false" :href="$root.dummyUrl">Link 1</v-list-item>
						<v-list-item :ripple="false" :href="$root.dummyUrl">Link 2</v-list-item>
						<v-list-item :ripple="false" :href="$root.dummyUrl">Another longer link just so i can see what it looks like</v-list-item>
						<v-list-item :ripple="false" :href="$root.dummyUrl">Link 4</v-list-item>
					</v-list>
				</v-col>
				<v-col cols="12" md="3">
					<v-list subheader dense class="scl-footer__list">
						<v-subheader class="scl-footer__header">Heading 1</v-subheader>
						<v-list-item :ripple="false" :href="$root.dummyUrl">Link 1</v-list-item>
						<v-list-item :ripple="false" :href="$root.dummyUrl">Link 2</v-list-item>
						<v-list-item :ripple="false" :href="$root.dummyUrl">Another longer link just so i can see what it looks like</v-list-item>
						<v-list-item :ripple="false" :href="$root.dummyUrl">Link 4</v-list-item>
					</v-list>
				</v-col>
				<v-col cols="12" md="3">
					<v-list subheader dense class="scl-footer__list">
						<v-subheader class="scl-footer__header">A Longer Heading Just To See What Happens</v-subheader>
						<v-list-item :ripple="false" :href="$root.dummyUrl">Link 1</v-list-item>
						<v-list-item :ripple="false" :href="$root.dummyUrl">Link 2</v-list-item>
						<v-list-item :ripple="false" :href="$root.dummyUrl">Link 3</v-list-item>
						<v-list-item :ripple="false" :href="$root.dummyUrl">Link 4</v-list-item>
					</v-list>
				</v-col>
			</v-row>
			</v-col>
			<v-col cols="12" md="4">
			<scl-footer-search class="scl-footer-search scl-component"></scl-footer-search>
			<scl-footer-contact class="scl-footer-contact scl-component"></scl-footer-contact>
			<scl-footer-social class="scl-footer-social scl-component"></scl-footer-social>

			</v-col>
		</v-row>

		</v-container>
		 -->
		<v-container class="py-0" :class="propApi.fluid ? 'fluid' : null">
			<v-row class="align-center">
			<v-col cols="12" sm="3" class="d-flex justify-center justify-sm-start ">
				<v-img :src="$root.dummyImage43" :max-height="56" :max-width="200">
				</v-img>
			</v-col>
				<v-col cols="12" sm="6" class="text-center pb-0">
					<a :ripple="false" :href="$root.dummyUrl" class="px-1 mx-1">Home</a>
					<a :ripple="false" :href="$root.dummyUrl" class="px-1 mx-1">About Us</a>
					<a :ripple="false" :href="$root.dummyUrl" class="px-1 mx-1">Our Team</a>
					<a :ripple="false" :href="$root.dummyUrl" class="px-1 mx-1">Contact Us</a>
					<div class="pt-4 text-center scl-footer__copyright">&copy; Switch Recursion {{ new Date().getFullYear() }}</div>
				</v-col>
			</v-row>
		</v-container>
	</div>
`;

// export template
export default template;
