/**
 * SclKeyInfoProduct
 */

// template
const template = `
	<div v-if="product != null">
		<!-- type container coming later -->
		<div class="scl-key-info-product__type-container"></div>
		<div class="scl-key-info-product__resource-container" >
			<h4 class="scl-key-info-product__title">Access this resource now</h4>
			<v-row>
				<v-col cols="12" md="7" class="scl-key-info-product__description-container">
					{{ product.longDescription}}
				</v-col>
				<v-col cols="12" md="5">
					<div class="scl-key-info-product__image-container">
						<v-img
							alt="preview"
							aspect-ration="0.77"
							:lazy-src="$root.lazyImg"
							:src="image"
							width="100%"
						>
						</v-img>
						<div class="scl-key-info-product__icon-container" @click.stop="dialog = true">
							<v-icon color="#00573d" size="32">$vuetify.icons.faSearchPlus</v-icon>
						</div>
					</div>
					<v-dialog
						content-class="scl-key-info-product__dialog"
						:width="$vuetify.breakpoint.smAndDown ? '90%' : 1024"
						v-model="dialog"
					>
						<v-img
							alt="access this resource now"
							aspect-ration="0.77"
							:lazy-src="$root.lazyImg"
							persistent
							:src="image"
							width="100%"
						>
						</v-img>
					</v-dialog>
				</v-col>
				<v-col cols="12">
					<hr />
				</v-col>
			</v-row>
			<div class="scl-key-info-product__price">
				<p>Non-Member price</p>
				<h4 :class="isMember? 'scl-key-info-product__price--strike-through': ''">{{product.currencySymbol}}{{product.listPrice | asCurrency}}</h4>
			</div>
			<div class="scl-key-info-product__price">
				<p>Member price</p>
				<h4>{{product.currencySymbol}}{{ membersPrice | asCurrency}}</h4>
			</div>
			<v-btn v-if="!digitalProductUrl" block class="scl-key-info-product__cta" color="primary" depressed :disabled="getLoading" :height="$vuetify.breakpoint.smAndDown ? 48 : 64" @click="addToCart">
				<span v-if="!getLoading">Add to cart</span>
				<v-icon v-if="!getLoading" right>$vuetify.icons.faPlus</v-icon>
				<v-progress-circular v-if="getLoading" color="primary" indeterminate :size="$vuetify.breakpoint.smAndDown ? 32 : 40" :width="5"></v-progress-circular>
			</v-btn>
			<v-btn v-if="digitalProductUrl" block class="scl-key-info-product__dlcta" color="primary" depressed :height="$vuetify.breakpoint.smAndDown ? 48 : 64" href="#">
				<span v-if="!getLoading">Get it now</span>
				<v-icon>$vuetify.icons.faArrowToBottom</v-icon>
			</v-btn>
		</div>
		<scl-cart-notification inline-template :is-active="getToast.active" :type="getToast.type" class="scl-component scl-cart-notification">
				<div>
					<v-snackbar @@input="handleCartNotification(false)"
								:timeout="5000"
								:value="isActive">
						<v-alert border="bottom" class="scl-alert__alert" :class="'scl-alert--' + type" colored-border tile :type="type">
							<template v-if="type === 'success'" v-slot:prepend>
								<div class="scl-alert__inverted-icon-wrapper">
									<v-icon size="16px" class="scl-alert__inverted-icon">$vuetify.icons.faShoppingCart</v-icon>
								</div>
							</template>
							<div v-if="(type === 'success')">
								<h6 class="scl-alert__title">Item added to cart</h6>
								<p class="scl-alert__copy">
									Please proceed to cart to checkout
									<a href="#">Cart</a>
								</p>
							</div>
							<div v-else>
								<h6 class="scl-alert__title">Item not added to cart</h6>
								<p class="scl-alert__copy">Please try again</p>
							</div>
							<v-btn @@click="handleCartNotification(false)"
								   icon>
								<v-icon size="32px">$vuetify.icons.faTimesCircle</v-icon>
							</v-btn>
						</v-alert>
					</v-snackbar>
				</div>
			</scl-cart-notification>
	</div>
`;

// export template
export default template;
