// @vue/component
const component = {
	name: 'SclLogin',
	props: {
		inputOptions: {
			type: Object
		},
		inputRules: {
			type: Object
		},
		pattern: {
			type: Object
		}
	},
	data() {
		return {
			form: {
				email: null,
				password: null,
				rememberMe: false
			}
		};
	},
	computed: {
		isValid() {
			return !!(this.form.email && this.form.password);
		},
		validEmail() {
			return this.pattern.email.test(this.form.email);
		}
	},
	methods: {
		checkValidity() {
			if (this.validEmail && this.isValid) {
				this.$emit('emit-login-form', true, this.form);
			} else {
				this.$emit('emit-login-form', false, this.form);
			}
		},
		toggleRememberMe() {
			this.form.rememberMe = !this.form.rememberMe;
			this.checkValidity();
		}
	},
	template: null
};

// set template and dummy data if development environment variable string
// if (process.env.VUE_APP_DEVELOPMENT === 'true') {
component.template = require(`./${component.name}-template`).default;
// }

// css import
require(`./_${component.name}.scss`);

// export component
export default component;
