/**
 * SclAuthRegisterForm
 */

// template
const template = `
	<div>
		<h4>Sign up now</h4>
		<p>Enter some details below to sign up</p>
		<v-form v-model="valid">
			<!-- title -->
			<label class="scl-checkout__form-label">Title</label>
			<v-select
				v-model="form.title"
				append-icon="$vuetify.icon.faChevronDown"
				:background-color="inputOptions.backgroundColor"
				class="is-narrow"
				:class="inputOptions.class"
				:color="inputOptions.color"
				:disabled="loading"
				:height="inputOptions.height"
				:items="titleItems"
				:outlined="inputOptions.outlined"
				placeholder="Title"
				:rules="[inputRules.required]"
			>
			</v-select>
			<!-- first name -->
			<label class="scl-checkout__form-label">First name</label>
			<v-text-field
				v-model="form.firstName"
				autocomplete="given-name"
				autocorrect="off"
				:background-color="inputOptions.backgroundColor"
				:class="inputOptions.class"
				:color="inputOptions.color"
				:disabled="loading"
				:height="inputOptions.height"
				:outlined="inputOptions.outlined"
				placeholder="John"
				:rules="[inputRules.required]"
			>
			</v-text-field>
			<!-- last name -->
			<label class="scl-checkout__form-label">Last name</label>
			<v-text-field
				v-model="form.lastName"
				autocomplete="family-name"
				autocorrect="off"
				:background-color="inputOptions.backgroundColor"
				:class="inputOptions.class"
				:color="inputOptions.color"
				:disabled="loading"
				:height="inputOptions.height"
				:outlined="inputOptions.outlined"
				placeholder="Smith"
				:rules="[inputRules.required]"
			>
			</v-text-field>
			<!-- phone -->
			<label class="scl-checkout__form-label is-optional">Phone</label>
			<v-text-field
				v-model="form.phone"
				autocomplete="tel"
				autocorrect="off"
				:background-color="inputOptions.backgroundColor"
				:class="inputOptions.class"
				:color="inputOptions.color"
				:disabled="loading"
				:height="inputOptions.height"
				inputmode="tel"
				maxlength="10"
				minlength="10"
				:outlined="inputOptions.outlined"
				pattern="[0-9]*"
				placeholder="0200000000"
				:rules="[inputRules.number, inputRules.phone]"
				type="tel"
			>
			</v-text-field>
			<!-- mobile -->
			<label class="scl-checkout__form-label">Mobile</label>
			<v-text-field
				v-model="form.mobile"
				autocomplete="tel"
				autocorrect="off"
				:background-color="inputOptions.backgroundColor"
				:class="inputOptions.class"
				:color="inputOptions.color"
				:disabled="loading"
				:height="inputOptions.height"
				inputmode="tel"
				maxlength="10"
				minlength="10"
				:outlined="inputOptions.outlined"
				pattern="[0-9]*"
				placeholder="0400000000"
				:rules="[inputRules.required, inputRules.number, inputRules.phone]"
				type="tel"
			>
			</v-text-field>
			<!-- email -->
			<label class="scl-checkout__form-label">Email</label>
			<v-text-field
				v-model="form.email"
				autocapitalize="off"
				autocomplete="email"
				autocorrect="off"
				:background-color="inputOptions.backgroundColor"
				:class="inputOptions.class"
				:color="inputOptions.color"
				:disabled="loading"
				:height="inputOptions.height"
				inputmode="email"
				:outlined="inputOptions.outlined"
				placeholder="name@domain.com"
				:rules="[inputRules.required, inputRules.email]"
				type="email"
				:error-messages="apiResponseMessage"
				@blur="handleEmailValidation"
			>
			</v-text-field>
			<!-- password -->
			<label class="scl-auth-login-form__label">Set password</label>
			<v-text-field
				v-model="password"
				autocomplete="current-password"
				autocapitalize="off"
				autocorrect="off"
				:background-color="inputOptions.backgroundColor"
				:class="inputOptions.class"
				:color="inputOptions.color"
				:disabled="loading"
				:height="inputOptions.height"
				hint="Password must be minimum 6 and maximum 20 characters"
				maxlength="20"
				minlength="6"
				:outlined="inputOptions.outlined"
				placeholder="********"
				persistent-hint
				:rules="[inputRules.required, inputRules.password]"
				type="password"
			>
			</v-text-field>
			<!-- confirm password -->
			<label class="scl-auth-login-form__label">Confirm password</label>
			<v-text-field
				v-model="passwordConfirm"
				autocomplete="current-password"
				autocapitalize="off"
				autocorrect="off"
				:background-color="inputOptions.backgroundColor"
				:class="inputOptions.class"
				:color="inputOptions.color"
				:disabled="loading || !password"
				:error-messages="!matchingPassword ? 'Password must match' : false"
				:height="inputOptions.height"
				hint="Password must be minimum 6 and maximum 20 characters"
				maxlength="20"
				minlength="6"
				:outlined="inputOptions.outlined"
				placeholder="********"
				persistent-hint
				:rules="[inputRules.required, inputRules.password]"
				type="password"
			>
			</v-text-field>
			<v-btn @click="handleRegistration" block class="scl-checkout__form-btn my-5" color="primary" depressed height="64" :disabled="!isValid || loading" :ripple="false">
				<span v-if="!loading">Sign up</span>
				<v-icon v-if="!loading" right>$vuetify.icons.faArrowRight</v-icon>
				<v-progress-circular v-if="loading" color="primary" indeterminate :size="40" :width="5"></v-progress-circular>
			</v-btn>
		</v-form>
	</div>
`;

// export template
export default template;
