/**
 * SclContentGate
 */

// template
const template = `
	<div>
		<div class="scl-content-gate__content"><p>{{ $root.dummyParagraph }}</p></div>
		<div class="scl-content-gate__container">
			<h3 class="scl-content-gate__title">{{$root.dummySentence}}</h3>
			<p>{{ $root.dummySentence }}</p>
			<v-btn class="scl-content-gate__btn scl-content-gate__btn--login btn btn-outlined" color="primaryDark" depressed height="64" min-width="160" outlined :ripple="false" @click="emitLogin">Already a member? Login now</v-btn>
			<v-btn class="scl-content-gate__btn scl-content-gate__btn--join btn btn-primary" depressed height="64" min-width="160" :ripple="false" @click="emitLogin">{{$root.dummyWords}}</v-btn>
		</div>
		<div class="scl-content-gate__placeholder"></div>
	</div>
`;

// export template
export default template;
