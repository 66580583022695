<template>
	<div class="all-components">
		<v-container>
			<v-row>
				<v-col cols="12">
					<v-expansion-panels flat>
						<v-expansion-panel>
							<v-expansion-panel-header>Component List</v-expansion-panel-header>
							<v-expansion-panel-content>
								<ul class="all-components__list">
									<li v-for="(component, listIndex) in allComponents" :key="listIndex">
										<a :href="`#${component}`">{{ component }}</a>
									</li>
								</ul>
							</v-expansion-panel-content>
						</v-expansion-panel>
					</v-expansion-panels>
				</v-col>
			</v-row>
		</v-container>
		<div v-for="(component, componentIndex) in allComponents" :key="componentIndex" class="all-components__wrapper">
			<a :id="component" class="all-components__name" :href="`#${component}`">
				Component:
				<strong>{{ component }}</strong>
			</a>
			<!-- container to center components, use 'fullWidth' if component should be 100% width eg: a grid -->
			<v-container :class="{ 'px-0': getProps(component).fullWidth }" :fluid="getProps(component).fullWidth">
				<v-row>
					<v-col cols="12">
						<!-- v-lazy for clientside performance -->
						<v-lazy>
							<!-- component -->
							<component :is="component" :api="getProps(component).api" class="scl-component" :class="component"></component>
						</v-lazy>
					</v-col>
				</v-row>
			</v-container>
		</div>
	</div>
</template>

<script>
export default {
	name: 'AllComponents',
	data() {
		return {
			componentExclusions: [
				'all-components',
				'scl-article-listing',
				'scl-bottom-sheet',
				'scl-cart-summary',
				'scl-date-picker',
				'scl-key-info-product',
				'scl-dummy-data',
				'scl-events-listing',
				'scl-example',
				'scl-example-api',
				'scl-footer',
				'scl-header',
				'scl-image-bg',
				'scl-image-srcset',
				'scl-pagination',
				'scl-product',
				'scl-product-card',
				'scl-product-categories',
				'scl-product-detail',
				'scl-product-gallery',
				'scl-product-listing',
				'scl-product-page-size',
				'scl-product-search',
				'scl-product-sort',
				'scl-selection-country-button',
				'scl-selection-country-icon',
				'scl-selection-language',
				'scl-snackbar',
				'scl-social-feed',
				'scl-step-summary',
				'scl-time-picker',
				'scl-training-listing',
				'scl-video',
				'scl-multi-step-framework',
				'scl-multi-child-step-framework',
				'scl-register-billing-address',
				'scl-register-personal-details',
				'scl-register-company-address',
				'scl-register-company-details',
				'scl-register-account-details',
				'scl-register-important-info',
				'scl-step-summary',
				'scl-register-payment',
				'SclSearchListing'
			],
			componentProps: [
				{
					name: 'scl-1-col',
					fullWidth: true
				},
				{
					name: 'scl-2-col',
					fullWidth: true
				},
				{
					name: 'scl-2-col-4',
					fullWidth: true
				},
				{
					name: 'scl-2-col-8',
					fullWidth: true
				},
				{
					name: 'scl-3-col',
					fullWidth: true
				},
				{
					name: 'scl-4-col',
					fullWidth: true
				},
				{
					name: 'scl-alert',
					api: {
						dismissible: true
					}
				},
				{
					name: 'scl-breadcrumbs',
					api: {
						items: [
							{ text: 'item one', disabled: false, href: '#' },
							{ text: 'item two', disabled: true, href: '#' }
						]
					}
				},
				{
					name: 'scl-cart-summary',
					fullWidth: true
				},
				{
					name: 'scl-checkout',
					fullWidth: true
				},
				{
					name: 'scl-example-forms',
					fullWidth: true
				},
				{
					name: 'scl-grid-container-4',
					api: { hasTitle: true, cols: 3 },
					fullWidth: true
				},
				{
					name: 'scl-map',
					api: { height: 264, lat: -33.392426, lng: 151.332868, marker: '/assets/img/map-marker.png', zoom: 15 }
				},
				{
					name: 'scl-social-share',
					api: { type: 'email' }
				},
				{
					name: 'scl-article-listing',
					categoryRoot: '{E757145B-908F-44B9-8923-C75F0F6D0EEA}'
				},
				{
					name: 'scl-events-calendar',
					api: { title: 'Example Title', location: 'Example Location', startDate: '2020-01-01T00:00:00Z' }
				},
				{
					name: 'scl-wysiwyg',
					api: { maxWidth: '500px' }
				}
			],
			components: []
		};
	},
	computed: {
		allComponents() {
			return this.components.filter((a) => !this.componentExclusions.includes(a));
		}
	},
	mounted() {
		this.$nextTick(() => {
			this.getAllComponents();
		});
	},
	methods: {
		// eslint-disable-next-line object-shorthand
		getAllComponents() {
			const components = Object.keys(Object.getPrototypeOf(Object.getPrototypeOf(Object.getPrototypeOf(this.$root.$options.components))));
			components.sort((a, b) => a.localeCompare(b));
			this.components = components;
		},
		// eslint-disable-next-line object-shorthand
		getProps(componentName) {
			const prop = this.componentProps.find((a) => a.name === componentName);
			if (prop) {
				return prop;
			}
			return {};
		}
	}
};
</script>

<style lang="scss" scoped>
#app {
	.all-components {
		&__wrapper {
			margin: 10vh auto;
		}
		&__list {
			padding: 0;
			column-count: 1;
			list-style: none;
			li {
				margin: gutter-size(1) 0;
			}
			a {
				#{$interact} {
					color: $primary;
				}
			}
		}
		&__name {
			display: block;
			padding: gutter-size(3);
			color: $black;
			background-color: $gray-100;
			transition: all $timing-fastest $easing;
			#{$interact} {
				color: darken($primary, 10%);
				background-color: rgba($primary, 0.1);
			}
		}
		@include breakpoint($sm) {
			&__list {
				column-count: 2;
			}
		}
		@include breakpoint($md) {
			&__list {
				column-count: 3;
			}
		}
		@include breakpoint($lg) {
			&__list {
				column-count: 4;
			}
		}
	}
}
</style>
