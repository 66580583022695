import { mapGetters } from 'vuex';

// @vue/component
const component = {
	name: 'SclCartAdd',
	data() {
		return {
			pattern: {
				quantity: /^[1-9]\d*$/
			},
			quantity: 1,
			rules: {
				number: (val) => {
					return this.pattern.quantity.test(val) || 'enter a number';
				}
			}
		};
	},
	computed: {
		...mapGetters(['getLoading'])
	},
	methods: {
		decrementQuantity() {
			let newQuantity = this.number(this.quantity);
			if (newQuantity === 1) {
				return;
			}
			newQuantity--;
			this.quantity = newQuantity;
		},
		emitQuantity() {
			if (!this.pattern.quantity.test(this.quantity)) {
				return;
			}
			this.$emit('emit-quantity', this.quantity);
			this.$emit('emit-cart-notification', true);
		},
		incrementQuantity() {
			let newQuantity = this.number(this.quantity);
			newQuantity++;
			this.quantity = newQuantity;
		},
		number(val) {
			return Number(val);
		}
	},
	template: null
};

// set template and dummy data if development environment variable string
// if (process.env.VUE_APP_DEVELOPMENT === 'true') {
component.template = require(`./${component.name}-template`).default;
// }

// css import
require(`./_${component.name}.scss`);

// export component
export default component;
