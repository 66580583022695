/**
 * SclSearchListingSort
 */

// template
const template = `
	<div>
		<div class="scl-search-listing-sort__container">
			<div class="scl-search-listing-sort__title">
				Sort by:
			</div>
			<v-select
				attach=".scl-search-listing-sort__select"
				class="scl-search-listing-sort__select"
				:disabled="isDisabled"
				:items="sorting"
				item-text="name"
				item-value="sort"
				label="Select"
				append-icon="$vuetify.icons.faCaretDown"
				background-color="#F4F4F4"
				outlined
				height="48"
				dense
				hide-details="true"
				value="relevance"
				@change="sortBy"
			></v-select>
		</div>
	</div>
`;

// export template
export default template;
