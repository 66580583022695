import { mapGetters } from 'vuex';

// @vue/component
const component = {
	name: 'SclAuthLoginBtn',
	props: {},
	data() {
		return {};
	},
	computed: { ...mapGetters(['getAuth']) },
	methods: {
		emitLogin() {
			if (this.getAuth) {
				this.$store.dispatch('authApiLogOut').then(() => {
					if (!this.getAuth) {
						window.location.href = '/';
					}
				});
			} else {
				this.$root.$emit('emitLoginModal');
			}
		}
	},
	template: null
};

// set template and dummy data if development environment variable string
if (process.env.VUE_APP_DEVELOPMENT === 'true') {
	component.template = require(`./${component.name}-template`).default;
}

// css import
require(`./_${component.name}.scss`);

// export component
export default component;
