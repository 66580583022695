/**
 * SclChip
 */

// template
const template = `
    <div class="scl-component scl-tags">
        <v-chip v-for="(tag, tagIndex) in propApi.tags" :label="propApi.isLabel" :color="propApi.backgroundColor" class="ma-2" :key="tagIndex">
            
            <a v-if="propApi.isLink && propApi.isLabel" :href="buildUrl(tag)" class="scl-tags__link" >{{ tag.label }}</a>

            <span v-else>{{ tag.label }}</span>

            <v-avatar v-if="!propApi.isLabel" right @click="handleRemove(tag)" class="scl-tags__icon" right>
                <v-icon>$vuetify.icons.faTimes</v-icon>
            </v-avatar>
        </v-chip>
    </div>
`;

// export template
export default template;
