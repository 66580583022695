// @vue/component
const component = {
	name: 'SclTabs',
	props: {
		api: {
			type: Object,
			required: false,
			// props type validation
			validator: (obj) => {
				const set = new Set();
				set.add(!!(obj.centered ? typeof obj.centered === 'boolean' : true));
				set.add(!!(obj.grow ? typeof obj.grow === 'boolean' : true));
				set.add(!!(obj.hideSlider ? typeof obj.hideSlider === 'boolean' : true));
				set.add(!!(obj.icons ? typeof obj.icons === 'boolean' : true));
				set.add(!!(obj.right ? typeof obj.right === 'boolean' : true));
				set.add(!!(obj.overflowArrows ? typeof obj.overflowArrows === 'boolean' : true));
				return !set.has(false);
			}
		}
	},
	data() {
		return {
			activeTab: 'tab-0'
		};
	},
	computed: {
		// props defaults
		propApi() {
			return {
				centered: false,
				grow: false,
				hideSlider: false,
				icons: true,
				right: false,
				overflowArrows: true,
				...this.api
			};
		}
	},
	template: null
};

// set template and dummy data if development environment variable string
if (process.env.VUE_APP_DEVELOPMENT === 'true') {
	component.template = require(`./${component.name}-template`).default;
}

// css import
require(`./_${component.name}.scss`);

// export component
export default component;
