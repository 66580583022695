/**
 * SclEventsListItem
 *
 */

// template
const template = `
	<div>
		<v-lazy>
			<v-card class="scl-events-list-item__item" :href="url">
					<v-img :lazy-src="$root.lazyImg" :src="image" :alt="title"
					aspect-ratio="2.1">
					</v-img>
					<div class="scl-events-list-item__content">
					<div class="scl-events-list-item__event-info">
						<div class="scl-events-list-item__date">
						{{ dateFormat(date) }}</span>
						</div>
						<div v-if="(!isOnline) && (location != '')" class="scl-events-list-item__location">
							<v-icon>$vuetify.icons.faMapMarker</v-icon> {{ location }}
						</div>
						<div v-if="isOnline" class="scl-events-list-item__location">
							<v-icon>$vuetify.icons.faVideo</v-icon> {{ location }}
						</div>
					 </div>
					<h4 class="scl-events-list-item__title headline">{{ truncate(title, 60) }}</h4>
					 <div>
						<p>{{ truncate(description, 222) }}</p>
					</div>
					</div>
					<div class="scl-events-list-item__link">
							<a :href="url" class="btn btn-link">Find out more <v-icon class="btn__icon-link scl-events-list-item__icon">$vuetify.icons.faArrowRight </v-icon></a>
					</div>

			</v-card>
		</v-lazy>
	</div>
`;

// export template
export default template;
