/**
 * SclProductSort
 */

// template
const template = `
	<div>
		<div class="scl-product-sort__content align-center">			
			<v-select
			v-model="defaultSelected"
			:items="sorting"
			item-text="name"
			item-value="sort"
			single-line
			append-icon="$vuetify.icons.faAngleDown"
			background-color="backgroundColor"
			outlined
			filled
			height="48"
			dense
			flat
			hide-details="true"
			@change="sortBy"
		  ></v-select>
		</div>
	</div>
`;

// export template
export default template;
