/**
 * SclDynamicCarouselGrid
 */

const template = `
	<div>
		<div class="scl-dynamic-carousel-grid__header">
			<h4 class="scl-dynamic-carousel-grid__header-title">{{$root.dummyWords}}</h4>
			<v-btn :ripple="false" class="btn btn-link scl-dynamic-carousel-grid__header-link">{{$root.dummyWords}}<v-icon class="btn__icon-link"> $vuetify.icons.faArrowRight</v-icon></v-btn>
		</div>
		<v-carousel
			:cycle="propApi.cycle"
			class="scl-dynamic-carousel-grid__carousel scl-dynamic-carousel-grid__carousel--center"
			:delimiter-icon="propApi.delimiterIcon"
			:height="propApi.height"
			hide-delimiter-background
			:show-arrows="propApi.showArrows"
			:hide-delimiters="propApi.hideDelimiters"
			light
			:next-icon="propApi.nextIcon"
			:prev-icon="propApi.prevIcon"
			ref="carouselGridRef"
		>
			<v-carousel-item class="scl-dynamic-carousel-grid__item">
				<v-row class="scl-dynamic-carousel-grid__row fill-height" >
					<v-col class="pt-0" cols="12" sm="6" :md="propApi.column">
						<v-lazy>
						<scl-card-vertical class="scl-card-vertical" :api='{"height": "100%"}'></scl-card-vertical>
						</v-lazy>
					</v-col>
				</v-row>
			</v-carousel-item>
		</v-carousel>
	</div>
`;

export default template;
