import Vue from 'vue';
import Vuex from 'vuex';
import state from './state';
import widget from './widget';

// use store
Vue.use(Vuex);

const store = {
	init() {
		return new Vuex.Store({
			modules: {
				state,
				widget
			}
		});
	}
};

export default store;
