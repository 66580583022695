// @vue/component
const component = {
	name: 'SclPageSize',
	props: {
		isDisabled: {
			type: Boolean
		},
		activeItem: {
			type: Number
		},
		api: {
			type: Object
		}
	},
	data() {
		return {
			activePageSize: this.activeItem
		};
	},
	computed: {
		propApi() {
			// props defaults
			return {
				pageSize: [{ value: '9' }, { value: '18' }],
				...this.api
			};
		}
	},
	methods: {
		setPageSize(val) {
			this.activePageSize = val;
			this.$emit('emit-page-size', val);
		}
	},
	template: null
};

// set template and dummy data if development environment variable string
if (process.env.VUE_APP_DEVELOPMENT === 'true') {
	component.template = require(`./${component.name}-template`).default;
}

// css import
require(`./_${component.name}.scss`);

// export component
export default component;
