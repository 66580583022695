import { mapGetters } from 'vuex';

// @vue/component
const component = {
	name: 'SclMinicart',
	computed: {
		...mapGetters(['getItems'])
	},
	template: null
};

// set template and dummy data if development environment variable string
// if (process.env.VUE_APP_DEVELOPMENT === 'true') {
component.template = require(`./${component.name}-template`).default;
// }

// css import
require(`./_${component.name}.scss`);

// export component
export default component;
