// @vue/component
const component = {
	name: 'SclRegionSelector',
	props: {
		redirecturl: {
			type: String
		}
	},
	computed: {
		getImagePath() {
			const imagePath = process.env.NODE_ENV === 'development' ? '/assets/img/regions/' : '/scl/assets/img/regions/';

			return imagePath;
		},
		// props defaults
		propApi() {
			return {
				regions: [
					{
						name: 'NSW',
						img: `${this.getImagePath}nsw.jpg`
					},
					{
						name: 'QLD',
						img: `${this.getImagePath}qld.jpg`
					},
					{
						name: 'VIC',
						img: `${this.getImagePath}vic.jpg`
					},
					{
						name: 'SA',
						img: `${this.getImagePath}sa.jpg`
					},
					{
						name: 'WA',
						img: `${this.getImagePath}wa.jpg`
					},
					{
						name: 'TAS',
						img: `${this.getImagePath}tas.jpg`
					},
					{
						name: 'NT',
						img: `${this.getImagePath}nt.jpg`
					},
					{
						name: 'ACT',
						img: `${this.getImagePath}act.jpg`
					},
					{
						name: 'ALL',
						img: `${this.getImagePath}all.jpg`
					}
				],
				...this.api
			};
		}
	},
	methods: {
		handleClick(val) {
			if (val.name === 'ALL') {
				window.location.href = this.redirecturl;
			} else {
				window.location.href = `${this.redirecturl}?&f=region=${val.name}`;
			}
		}
	},
	template: null
};

// set template and dummy data if development environment variable string
if (process.env.VUE_APP_DEVELOPMENT === 'true') {
	component.template = require(`./${component.name}-template`).default;
}

// css import
require(`./_${component.name}.scss`);

// export component
export default component;
