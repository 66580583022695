// @vue/component
// SclMultiStepFramework
// @noOfSteps type Number
//
//  Used to generically create a multi step journey
//
import { mapGetters } from 'vuex';

const component = {
	name: 'SclMultiStepFramework',
	props: {
		noOfSteps: {
			type: Number
		}
	},
	computed: {
		allSteps() {
			return this.getSteps();
		},
		activeStep() {
			return this.getActiveStep();
		}
	},
	watch: {
		activeStep() {
			this.scrollToClass('.scroll-reference');
		}
	},
	created() {
		this.initializeSteps();
	},
	methods: {
		...mapGetters(['getNoOfSteps', 'getSteps', 'getActiveStep', 'getMultiStepFrameworkState']),
		initializeSteps() {
			this.$store.dispatch('initializeMultiStep', this.noOfSteps);
		},
		handleProgressStep() {
			this.$store.dispatch('progressStep');
		},
		goToStep(stepNumber) {
			this.$store.dispatch('navigateStep', stepNumber);
		},
		scrollToClass(className) {
			const el = this.$el.querySelector(className);
			const rect = el.getBoundingClientRect();
			window.scrollTo(rect.left, rect.top);
		}
	},
	template: null
};

// set template and dummy data if development environment variable string
if (process.env.VUE_APP_DEVELOPMENT === 'true') {
	component.template = require(`./${component.name}-template`).default;
}

// css import
require(`./_${component.name}.scss`);

// export component
export default component;
