/**
 * SclLogin
 */

// template
const template = `
	<div>
		<h3 class="scl-checkout__form-title"><span>Login</span></h3>
		<!-- email -->
		<label class="scl-checkout__form-label">Email</label>
		<v-text-field
			v-model="form.email"
			@input="checkValidity"
			autocapitalize="off"
			autocomplete="email"
			autocorrect="off"
			:background-color="inputOptions.backgroundColor"
			:class="inputOptions.class"
			:color="inputOptions.color"
			:height="inputOptions.height"
			inputmode="email"
			:outlined="inputOptions.outlined"
			placeholder="name@domain.com"
			:rules="[inputRules.required, inputRules.email]"
			type="email"
		>
		</v-text-field>
		<!-- password -->
		<label class="scl-checkout__form-label">Password</label>
		<v-text-field
			v-model="form.password"
			@input="checkValidity"
			autocomplete="current-password"
			autocapitalize="off"
			autocorrect="off"
			:background-color="inputOptions.backgroundColor"
			:class="inputOptions.class"
			:color="inputOptions.color"
			:height="inputOptions.height"
			:outlined="inputOptions.outlined"
			placeholder="Password"
			:rules="[inputRules.required]"
			type="password"
		>
		</v-text-field>
		<span><a href="#">Forgot password</a></span>
		<v-checkbox @change="toggleRememberMe" class="scl-checkout__form-selection" label="Remember me"></v-checkbox>
	</div>
`;

// export template
export default template;
