/**
 * SclRelatedDownloads
 */

// template
const template = `
	<div>
		<h4 class="scl-related-downloads__title">
		{{$root.dummyWords}}
		</h4>
		<p>
		{{$root.dummySentence}}
		</p>
		<a class="scl-related-downloads__link" href="#" download>
            {{ $root.dummyWords }}
            <v-icon class="scl-related-downloads__icon"> $vuetify.icons.faFile </v-icon>
		</a>
		<a class="scl-related-downloads__link" href="#" download>
            {{ $root.dummyWords }}
            <v-icon class="scl-related-downloads__icon"> $vuetify.icons.faFilePDF </v-icon>
		</a>
		<a class="scl-related-downloads__link" href="#" download>
            {{ $root.dummyWords }}
            <v-icon class="scl-related-downloads__icon"> $vuetify.icons.faFileExcel </v-icon>
		</a>
		<a class="scl-related-downloads__link" href="#" download>
            {{ $root.dummyWords }}
            <v-icon class="scl-related-downloads__icon"> $vuetify.icons.faFileWord </v-icon>
		</a>
	</div>
`;

// export template
export default template;
