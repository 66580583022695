// @vue/component
const component = {
	name: 'SclSnackbar',
	props: {
		api: {
			type: Object,
			required: false,
			// props type validation
			validator: (obj) => {
				const set = new Set();
				set.add(!!(obj.buttonColour ? typeof obj.buttonColour === 'string' : true));
				set.add(!!(obj.vertical ? typeof obj.vertical === 'string' : true));
				set.add(!!(obj.horizontal ? typeof obj.horizontal === 'string' : true));
				set.add(!!(obj.autoHeight ? typeof obj.autoHeight === 'boolean' : true));
				set.add(!!(obj.timeDelay ? typeof obj.timeDelay === 'number' : true));
				set.add(!!(obj.snackColour ? typeof obj.snackColour === 'string' : true));
				set.add(!!(obj.snackButtonColour ? typeof obj.snackButtonColour === 'string' : true));
				return !set.has(false);
			}
		}
	},
	data() {
		return {
			snackbar: false
		};
	},
	computed: {
		// props defaults
		propApi() {
			return {
				buttonColour: '#ff0000',
				vertical: '',
				horizontal: '',
				timeDelay: 6000,
				autoHeight: true,
				snackColour: '#3333333',
				snackButtonColour: '#ffffff',
				...this.api
			};
		}
	},
	template: null
};

// set template and dummy data if development environment variable string
if (process.env.VUE_APP_DEVELOPMENT === 'true') {
	component.template = require(`./${component.name}-template`).default;
}

// css import
require(`./_${component.name}.scss`);

export default component;
