// @vue/component
const component = {
	name: 'SclSearchListingFacets',
	props: {
		activeTags: {
			type: Array,
			required: false
		},
		facets: {
			type: Array
		},
		facetFilter: {
			type: Array,
			required: false
		},
		isDisabled: {
			type: Boolean
		}
	},
	data() {
		return {
			panel: this.$vuetify.breakpoint.smAndDown ? [] : [0],
			categoryFilter: [],
			tags: []
		};
	},
	computed: {
		visibleFacets() {
			// removing tags
			let facets = this.facets.filter((obj) => {
				return obj.Category !== 'tag';
			});
			// moving page types to the end
			facets = facets.concat(facets.splice(0, 1));
			return facets;
		}
	},
	watch: {
		facetFilter() {
			this.categoryFilter = this.facetFilter;
		},
		activeTags() {
			this.tags = this.activeTags;
		}
	},
	created() {},
	methods: {
		formatTitle(val) {
			return val.replace(/[-]+/g, ' ');
		},
		updateCategories(FacetType, facetValue, e) {
			const val = `${FacetType}=${facetValue}`;
			const index = this.categoryFilter.indexOf(val);
			if (!e && index > -1) {
				this.categoryFilter.splice(index, 1);
				this.tags.splice(index, 1);
			}
			if (e && !index > -1) {
				this.categoryFilter.push(val);
				const tag = { label: facetValue, value: `${FacetType}=${facetValue}` };
				this.tags.push(tag);
			}
			this.$emit('emit-facet-change', this.categoryFilter, this.tags);
		}
	},
	template: null
};

// set template and dummy data if development environment variable string
// if (process.env.VUE_APP_DEVELOPMENT === 'true') {
component.template = require(`./${component.name}-template`).default;
// }

// css import
require(`./_${component.name}.scss`);

// export component
export default component;
