/*
 * Dynamic form data mixin
 */

const mxForms = {
	methods: {
		_mxForms_formatDeepFormData(formData) {
			const formDataCopy = formData;
			const constructedFormData = {};

			const keys = Object.keys(formDataCopy);
			const values = Object.values(formDataCopy);

			for (let i = 0; i < keys.length; i++) {
				const childKeys = Object.keys(values[i]);
				const childValues = Object.values(values[i]);
				for (let j = 0; j < childValues.length; j++) {
					values[i][childKeys[j]] = childValues[j].value;
				}
				constructedFormData[keys[i]] = values[i];
			}
			return constructedFormData;
		},
		_mxForms_formatFormData(formData) {
			const formDataCopy = formData;
			const constructedFormData = {};

			const keys = Object.keys(formDataCopy);
			const values = Object.values(formDataCopy);

			for (let i = 0; i < keys.length; i++) {
				constructedFormData[keys[i]] = values[i].value;
			}
			return constructedFormData;
		},
		// edge case mixin due to the data structure required for API request vs data from multi step
		_mxForms_buildRegistrationPayload(personalDetailsForm, accountDetailsForm) {
			const payload = {
				title: personalDetailsForm.title.value,
				firstName: personalDetailsForm.firstName.value,
				lastName: personalDetailsForm.lastName.value,
				phone: personalDetailsForm.phoneNumber.value,
				mobile: personalDetailsForm.mobileNumber.value,
				email: accountDetailsForm.email.value,
				businessNo: null,
				password: accountDetailsForm.password.value
			};

			return payload;
		}
	}
};

export default mxForms;
