// @vue/component
const component = {
	name: 'SclFooter',
	props: {
		api: {
			type: Object,
			required: false,
			// props type validation
			validator: (obj) => {
				const set = new Set();
				set.add(!!(obj.fluid ? typeof obj.fluid === 'boolean' : true));
				set.add(!!(obj.collapsedIcon ? typeof obj.collapsedIcon === 'string' : true));
				set.add(!!(obj.expandedIcon ? typeof obj.expandedIcon === 'string' : true));
				return !set.has(false);
			}
		}
	},
	data() {
		return {};
	},
	computed: {
		// props defaults
		propApi() {
			return {
				fluid: false,
				collapsedIcon: '$vuetify.icons.faPlus',
				expandedIcon: '$vuetify.icons.faMinus',
				...this.api
			};
		},
		flexDirection() {
			const binding = {};
			if (this.$vuetify.breakpoint.mdAndUp) {
				binding.row = true;
			} else {
				binding.column = true;
			}
			return binding;
		}
	},
	methods: {
		gotoUrl(url) {
			if (!url) {
				return;
			}
			return url;
		}
	},
	template: null
};

// set template and dummy data if development environment variable string
if (process.env.VUE_APP_DEVELOPMENT === 'true') {
	component.template = require(`./${component.name}-template`).default;
}

// css import
require(`./_${component.name}.scss`);

// export component
export default component;
