import authApi from '@/_scl-fed/services/api/authentication';
import commerceApi from '@/_scl-fed/services/api/commerce';
import VuexPersistence from 'vuex-persist';

const vuexLocal = new VuexPersistence({
	storage: window.localStorage
});

export default {
	state: () => ({
		authError: false,
		error: {
			hasErrored: false,
			message: ''
		},
		cart: [],
		isLoading: false,
		isMember: false,
		price: {},
		promo: {
			adjustment: null,
			code: null,
			hasErrored: false
		},
		toast: {
			active: false,
			type: 'success'
		},
		user: {
			IsAuthenticated: false
		},
		hostedFieldsInstance: null,
		paymentClientToken: null,
		paymentError: false
	}),
	getters: {
		getMemberStatus(state) {
			return state.user.UserType;
		},
		getAuth(state) {
			return state.user.IsAuthenticated;
		},
		getUserFullName(state) {
			return state.user.FullName;
		},
		getUserEmail(state) {
			return state.user.User;
		},
		getAuthError(state) {
			return state.authError;
		},
		getCart(state) {
			return state.cart;
		},
		getError(state) {
			return state.error;
		},
		getLoading(state) {
			return state.isLoading;
		},
		getMember(state) {
			return state.isMember;
		},
		getPrice(state) {
			return state.price;
		},
		getPromo(state) {
			return state.promo;
		},
		getToast(state) {
			return state.toast;
		},
		getItems(state) {
			return state.cart.length;
		},
		getTotalItems(state) {
			let total = 0;
			if (state.cart.length === 0) return total;
			state.cart.forEach((item) => {
				total += item.quantity;
			});
			return total;
		},
		getUser(state) {
			return state.user;
		},
		getPaymentClientToken(state) {
			return state.paymentClientToken;
		},
		getHostedFieldsInstance(state) {
			return state.hostedFieldsInstance;
		}
	},
	mutations: {
		initialiseCartItems(state) {
			if (localStorage.getItem('vuex-state-cart')) {
				const cart = JSON.parse(localStorage.getItem('vuex-state-cart'));
				state.cart = cart;
			}
		},
		setAuth(state, auth) {
			state.isAuthenticated = auth;
		},
		setAuthError(state, type) {
			state.authError = type;
		},
		setCart(state, cart) {
			state.cart = cart;
			localStorage.setItem('vuex-state-cart', JSON.stringify(state.cart));
		},
		setErrorMessage(state, message) {
			state.error.message = message;
		},
		setErrorState(state, error) {
			state.error.hasErrored = error;
		},
		setLoading(state, loading) {
			state.isLoading = loading;
		},
		setRegistationbuttonLoading(state, loading) {
			localStorage.setItem('is-loading', loading);
		},
		setMember(state, member) {
			state.isMember = member;
		},
		setPrice(state, price) {
			state.price = price;
		},
		setPromoAdjustment(state, adjustment) {
			state.promo.adjustment = adjustment;
		},
		setPromoCode(state, code) {
			state.promo.code = code;
		},
		setPromoState(state, error) {
			state.promo.hasErrored = error;
		},
		setToastActive(state, active) {
			state.toast.active = active;
		},
		setToastType(state, type) {
			state.toast.type = type;
		},
		setUser(state, response) {
			state.user = response;
		},
		setPaymentClientToken(state, paymentClientToken) {
			state.paymentClientToken = paymentClientToken;
		},
		setHostedFieldsInstance(state, hostedFieldsInstance) {
			state.hostedFieldsInstance = hostedFieldsInstance;
		}
	},
	actions: {
		// auth api change password
		authApiChangePassword({ dispatch, commit }, payload) {
			commit('setLoading', true);
			return authApi
				.authChangePassword(payload)
				.then((response) => {
					return response;
				})
				.catch((err) => {
					console.error(err);
					dispatch('errorFail');
				})
				.finally(() => {
					commit('setLoading', false);
				});
		},

		// auth api user activation
		authApiActivate({ dispatch, commit }, payload) {
			commit('setLoading', true);
			return authApi
				.authActivate(payload)
				.then((response) => {
					return response;
				})
				.catch((err) => {
					console.error(err);
					dispatch('errorFail');
				})
				.finally(() => {
					commit('setLoading', false);
				});
		},

		// auth api register user
		authApiRegister({ dispatch, commit }, payload) {
			commit('setLoading', true);
			return authApi
				.authRegister(payload)
				.then((response) => {
					return response;
				})
				.catch((err) => {
					console.error(err);
					dispatch('errorFail');
				})
				.finally(() => {
					commit('setLoading', false);
				});
		},
		// auth api validate email
		authApiValidateEmail({ dispatch, commit }, payload) {
			commit('setLoading', true);
			return authApi
				.authValidateEmail(payload)
				.then((response) => {
					return response;
				})
				.catch((err) => {
					console.error(err);
					dispatch('errorFail');
				})
				.finally(() => {
					commit('setLoading', false);
				});
		},

		// auth api forgot password
		authApiForgotPassword({ dispatch, commit }, payload) {
			commit('setLoading', true);
			return authApi
				.authForgotPassword(payload)
				.then((response) => {
					return response;
				})
				.catch((err) => {
					console.error(err);
					dispatch('errorFail');
				})
				.finally(() => {
					commit('setLoading', false);
				});
		},
		authSetAuthError({ commit }, payload) {
			commit('setAuthError', payload);
		},
		// auth api login
		authApiLogIn({ dispatch, commit }, payload) {
			commit('setLoading', true);
			return authApi
				.authSignIn(payload)
				.then((response) => {
					if (response.data.Success === true) {
						commit('setAuthError', false);
						commit('setUser', response.data.Result);
					} else {
						commit('setAuthError', true);
						commit('setUser', { IsAuthenticated: false });
					}
					return response;
				})
				.catch((err) => {
					console.log(err);
					dispatch('errorFail');
				})
				.finally(() => {
					commit('setLoading', false);
				});
		},
		// auth api logout
		authApiLogOut({ dispatch, commit }) {
			commit('setLoading', true);
			return authApi
				.authSignOut()
				.then((response) => {
					if (response.data.Success === true) {
						commit('setUser', { IsAuthenticated: false });
						return response;
					}
				})
				.catch((err) => {
					console.error(err);
					dispatch('errorFail');
				})
				.finally(() => {
					commit('setLoading', false);
				});
		},
		// cart get
		cartApiGet({ dispatch, commit, getters }) {
			commit('setLoading', true);
			commerceApi
				.cartGet()
				.then((response) => {
					commit('setCart', response.data.cartLines);
					commit('setPrice', response.data.price);
					// commit('setCart', response.cartLines);
					// commit('setPrice', response.price);
					if (!getters.getTotalItems) {
						dispatch('errorNoItems');
					}
				})
				.catch((err) => {
					// dispatch('errorFail');
					console.error(err);
				})
				.finally(() => {
					commit('setLoading', false);
				});
		},
		// cart promo
		cartApiPromo({ dispatch, commit, getters }, payload) {
			commit('setLoading', true);
			commerceApi
				.cartPromo(payload)
				.then((response) => {
					if (response.status === 'ok') {
						commit('setCart', response.data.cartLines);
						commit('setPrice', response.data.price);
						commit('setPromoAdjustment', response.data.temp.adjustments);
						commit('setPromoCode', response.data.temp.orderForms[0].promoCodes[0]);
						if (!getters.getTotalItems) {
							dispatch('errorNoItems');
						}
					} else {
						commit('setPromoState', true);
					}
				})
				.catch((err) => {
					console.warn(err.error);
					commit('setPromoState', true);
				})
				.finally(() => {
					commit('setLoading', false);
				});
		},
		// cart remove
		cartApiRemove({ dispatch, commit, getters }, payload) {
			commit('setLoading', true);
			commerceApi
				.cartRemove(payload)
				.then((response) => {
					commit('setCart', response.data.cartLines);
					commit('setPrice', response.data.price);
					if (!getters.getTotalItems) {
						dispatch('errorNoItems');
					}
				})
				.catch((err) => {
					dispatch('errorFail');
					console.error(err);
				})
				.finally(() => {
					commit('setLoading', false);
				});
		},
		// cart update
		cartApiUpdate({ dispatch, commit, getters }, payload) {
			commit('setLoading', true);
			return new Promise((resolve, reject) => {
				commerceApi
					.cartUpdate(payload)
					.then((response) => {
						commit('setCart', response.data.cartLines);
						commit('setPrice', response.data.price);
						dispatch('toastSuccess');
						if (!getters.getTotalItems) {
							dispatch('errorNoItems');
						}
						resolve(response);
					})
					.catch((err) => {
						if (err.response.data.status === 'error') {
							dispatch('toastError');
						} else {
							dispatch('errorFail');
						}
						console.error(err);
						reject(err);
					})
					.finally(() => {
						commit('setLoading', false);
					});
			});
		},
		checkoutApiGetClientToken({ dispatch, commit }) {
			commit('setLoading', true);
			return commerceApi
				.checkoutGetClientToken()
				.then((response) => {
					commit('setPaymentClientToken', response.data);
				})
				.catch((err) => {
					if (err.response.data.status === 'error') {
						dispatch('toastError');
					} else {
						dispatch('errorFail');
					}
					console.error(err);
				});
		},
		checkoutAPIGetShipping({ dispatch, commit }) {
			commit('setLoading', true);
			return commerceApi
				.checkoutGetShippingMethods()
				.then((response) => {
					return response;
				})
				.catch((err) => {
					console.error(err);
					dispatch('errorFail');
				})
				.finally(() => {
					commit('setLoading', false);
				});
		},
		checkoutAPISetShipping({ dispatch, commit }, payload) {
			commit('setLoading', true);
			return commerceApi
				.checkoutShippingPost(payload)
				.then((response) => {
					return response;
				})
				.catch((err) => {
					console.error(err);
					dispatch('errorFail');
				})
				.finally(() => {
					commit('setLoading', false);
				});
		},
		checkoutAPISetPayment({ dispatch, commit }, payload) {
			commit('setLoading', true);
			return commerceApi
				.checkoutSetPayment(payload)
				.then((response) => {
					return response;
				})
				.catch((err) => {
					console.error(err);
					dispatch('errorFail');
				})
				.finally(() => {
					commit('setLoading', false);
				});
		},
		checkoutApiSubmit({ dispatch, commit }) {
			commit('setLoading', true);
			return commerceApi
				.checkoutSubmit()
				.then((response) => {
					return response;
				})
				.catch((err) => {
					console.error(err);
					dispatch('errorFail');
				})
				.finally(() => {
					commit('setLoading', false);
				});
		},
		checkoutSetHostedFieldsInstance({ commit }, payload) {
			commit('setLoading', true);
			commit('setHostedFieldsInstance', payload);
		},
		errorFail({ commit }) {
			commit('setErrorMessage', 'Something has gone wrong please try again.');
			commit('setErrorState', true);
		},
		errorNoItems({ commit }) {
			commit('setErrorMessage', 'No items in your cart.');
			commit('setErrorState', true);
		},
		toastSuccess({ commit }) {
			commit('setToastActive', true);
			commit('setToastType', 'success');
		},
		toastError({ commit }) {
			commit('setToastActive', true);
			commit('setToastType', 'error');
		},
		registrationButtonLogin({ commit }, payload) {
			commit('setRegistationbuttonLoading', payload);
		}
	},
	plugins: [vuexLocal.plugin]
};
