/**
 * SclWysiwyg
 */

// template
const template = `
		<div :style="setMaxWidth(propApi.maxWidth)">
			<h1>Heading One</h1>
			<h2>Heading Two</h2>
			<h3>Heading Three</h3>
			<h4>Heading Four</h4>
			<h5>Heading Five</h5>
			<h6>Heading Six</h6>

			<div class="scl-ww__summaries">
				<h4>In this article</h4>
				<ul class="scl-ww__list-style-2">
					<li><a href="#">Every bit counts</a></li>
					<li><a href="#">There&rsquo;s Government subsidies available</a></li>
					<li><a href="#">We&rsquo;ll support you</a></li>
					<li><a href="#">What happens if trade apprentices stop working?</a></li>
					<li><a href="#">Frequently asked questions</a></li>
					<li><a href="#">What do skills shortages mean for the industry?</a></li>
				</ul>
			</div>

			<p class="scl-ww__accent-snippet"> {{ $root.dummyParagraph }}</p>

			<img :src="$root.dummyImage169" alt="" />

			<scl-dummy-data class="scl-ww__hero-text" :content="$root.dummyParagraph"></scl-dummy-data>

			<ul>
				<li>{{$root.dummyWords}}</li>
				<li>{{$root.dummyWords}}
					<ul>
						<li>{{$root.dummyWords}}
							<ul>
								<li>{{$root.dummyWords}}</li>
								<li>{{$root.dummyWords}}</li>
							</ul>
						</li>
					</ul>
				</li>
				<li>{{$root.dummyWords}}</li>
			</ul>

			<ol>
				<li>{{$root.dummyWords}}</li>
				<li>{{$root.dummyWords}}
					<ol>
						<li>{{$root.dummyWords}}
							<ol>
								<li>{{$root.dummyWords}}</li>
								<li>{{$root.dummyWords}}</li>
							</ol>
						</li>
					</ol>
				</li>
				<li>{{$root.dummyWords}}</li>
			</ol>

			<ul class="scl-ww__list-style-2">
				<li>{{$root.dummyWords}}</li>
				<li>{{$root.dummyWords}}</li>
			</ul>

			<blockquote class="scl-ww__blockquote">
				<div class="scl-ww__blockquote-content-wrapper">
					<div class="scl-ww__blockquote-content">
						<p>{{$root.dummyParagraph}}</p>
					</div>
					<cite class="scl-ww__blockquote-cite">{{$root.dummyWords}}</cite>
				</div>
			</blockquote>

			<div class="scl-ww__table-responsive">
				<table class="scl-ww__table">
					<tbody>
						<tr>
							<td>{{$root.dummyWords}}</td>
							<td>{{$root.dummyWords}}</td>
						</tr>
						<tr>
							<td>{{$root.dummyWords}}</td>
							<td>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</td>
						</tr>
						<tr>
							<td>{{$root.dummyWords}}</td>
							<td>{{$root.dummyWords}}</td>
						</tr>
						<tr>
							<td>{{$root.dummyWords}}</td>
							<td>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</td>
						</tr>
						<tr>
							<td>{{$root.dummyWords}}</td>
							<td>{{$root.dummyWords}}</td>
						</tr>
					</tbody>
				</table>
		  </div>
		  <div class="scl-ww__table-responsive">
			<table class="scl-ww__table scl-ww__table--yellow">
				<tbody>
					<tr>
						<td>{{$root.dummyWords}}</td>
						<td>{{$root.dummyWords}}</td>
					</tr>
					<tr>
						<td>{{$root.dummyWords}}</td>
						<td>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</td>
					</tr>
					<tr>
						<td>{{$root.dummyWords}}</td>
						<td>{{$root.dummyWords}}</td>
					</tr>
					<tr>
						<td>{{$root.dummyWords}}</td>
						<td>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</td>
					</tr>
					<tr>
						<td>{{$root.dummyWords}}</td>
						<td>{{$root.dummyWords}}</td>
					</tr>
				</tbody>
			</table>
		  </div>
		  <div class="scl-ww__table-responsive">
			<table class="scl-ww__table scl-ww__table--comparison">
				<tbody>
					<tr>
						<td>{{$root.dummyWords}}</td>
						<td>{{$root.dummyWords}}</td>
						<td>{{$root.dummyWords}}</td>
						<td>{{$root.dummyWords}}</td>
					</tr>
					<tr>
						<td colspan="4">{{$root.dummyWords}}</td>
					</tr>
					<tr>
						<td>{{$root.dummyWords}}</td>
						<td>&nbsp;</td>
						<td class="tick">&nbsp;</td>
						<td>&nbsp;</td>
					</tr>
					<tr>
						<td>{{$root.dummyWords}}</td>
						<td class="tick">&nbsp;</td>
						<td class="tick">&nbsp;</td>
						<td >&nbsp;</td>
					</tr>
					<tr>
						<td>{{$root.dummyWords}}</td>
						<td class="tick">&nbsp;</td>
						<td >&nbsp;</td>
						<td >&nbsp;</td>
					</tr>
					<tr>
						<td>{{$root.dummyWords}}</td>
						<td >&nbsp;</td>
						<td class="tick">&nbsp;</td>
						<td class="tick">&nbsp;</td>
					</tr>
					<tr>
						<td>{{$root.dummyWords}}</td>
						<td class="tick">&nbsp;</td>
						<td >&nbsp;</td>
						<td class="tick">&nbsp;</td>
					</tr>
					<tr>
						<td colspan="4">{{$root.dummyWords}}</td>
					</tr>
					<tr>
						<td>{{$root.dummyWords}}</td>
						<td>&nbsp;</td>
						<td class="tick">&nbsp;</td>
						<td>&nbsp;</td>
					</tr>
					<tr>
						<td>{{$root.dummyWords}}</td>
						<td class="tick">&nbsp;</td>
						<td class="tick">&nbsp;</td>
						<td >&nbsp;</td>
					</tr>
					<tr>
						<td>{{$root.dummyWords}}</td>
						<td class="tick">&nbsp;</td>
						<td >&nbsp;</td>
						<td >&nbsp;</td>
					</tr>
					<tr>
						<td>{{$root.dummyWords}}</td>
						<td >&nbsp;</td>
						<td class="tick">&nbsp;</td>
						<td class="tick">&nbsp;</td>
					</tr>
					<tr>
						<td>{{$root.dummyWords}}</td>
						<td class="tick">&nbsp;</td>
						<td >&nbsp;</td>
						<td class="tick">&nbsp;</td>
					</tr>
				</tbody>
		  </table>
		</div>
	</div>
`;

// export template
export default template;
