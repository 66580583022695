/**
 * Scl2Col
 */

// template
const template = `
	<div>
		<v-container>
			<v-row>
				<v-col cols="12" md="6">
					<scl-dummy-data :content="$root.dummyParagraph"></scl-dummy-data>
				</v-col>
				<v-col cols="12" md="6">
					<scl-dummy-data :content="$root.dummyParagraph"></scl-dummy-data>
				</v-col>
			</v-row>
		</v-container>
	</div>
`;

// export template
export default template;
