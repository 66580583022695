import { mapGetters } from 'vuex';

// @vue/component
const component = {
	name: 'SclShippingOptions',
	props: {
		shippingOptions: {
			type: Array,
			default: () => []
		}
	},
	data() {
		return {
			radioGroup: 0
		};
	},
	computed: {
		...mapGetters(['getPrice']),
		shippingCost() {
			if (this.getPrice.currencySymbol === null || typeof this.getPrice.currencySymbol === 'undefined') return '';
			const shippingTotal = parseFloat(`${this.getPrice.shippingTotal}`).toFixed(2);
			return `${this.getPrice.currencySymbol}${shippingTotal}`;
		}
	},
	watch: {
		shippingOptions(options) {
			if (options) {
				this.radioGroup = options[0].name;
				this.setShippingMethod(0);
			}
		}
	},
	methods: {
		setShippingMethod(index) {
			this.$emit('emit-set-shipping-method', this.shippingOptions[index], index);
		}
	},
	template: null
};

// set template and dummy data if development environment variable string
// if (process.env.VUE_APP_DEVELOPMENT === 'true') {
component.template = require(`./${component.name}-template`).default;
// }

// css import
require(`./_${component.name}.scss`);

// export component
export default component;
