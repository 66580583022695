/**
 * SclSearchListingFacets
 */

// template
const template = `
	<div>
		<v-expansion-panels accordion class="scl-search-listing-facets__accordions" flat multiple v-model="panel">
			<!-- panel -->
			<v-expansion-panel class="scl-search-listing-facets__accordion" v-for="(facet, index) in visibleFacets" :key="index">
				<v-expansion-panel-header hide-actions  class="scl-search-listing-facets__header">
					<div class="scl-search-listing-facets__title">
						{{ formatTitle(facet.Category) }}
					</div>
					<v-icon class="scl-search-listing-facets__accordion-icon scl-search-listing-facets__accordion-icon--minus">$vuetify.icons.faMinus</v-icon>
					<v-icon class="scl-search-listing-facets__accordion-icon scl-search-listing-facets__accordion-icon--plus">$vuetify.icons.faPlus</v-icon>
				</v-expansion-panel-header>
				<v-expansion-panel-content class="scl-search-listing-facets__content">
					<v-checkbox v-for="(facetValue, index) in facet.Values" hide-details ref="isChecked" v-model="facetValue.isSelected" @change="updateCategories(facet.Category,facetValue.label,$event,facetValue)" class="scl-search-listing-facets__check" :disabled="isDisabled" v-if="facetValue.quantity != '0'" :label="facetValue.label + ' ' + '(' + facetValue.quantity + ')'" :key="index"></v-checkbox>
				</v-expansion-panel-content>
			</v-expansion-panel>
		</v-expansion-panels>
	</div>
`;

// export template
export default template;
