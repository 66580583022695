// @vue/component
const component = {
	name: 'SclProductSort',
	props: {
		isDisabled: {
			type: Boolean
		}
	},
	data() {
		return {
			defaultSelected: {
				name: 'A-Z',
				sort: 'name-asc'
			},
			sorting: [
				// { name: 'High to Low', sort: 'listprice|desc' },
				// { name: 'Low to High', sort: 'listprice|asc' },
				{ name: 'A-Z', sort: 'name-asc' },
				{ name: 'Z-A', sort: 'name-desc' }
			]
		};
	},
	methods: {
		sortBy(sortBy) {
			this.$emit('emit-sorting', sortBy);
		}
	},
	template: null
};

// set template and dummy data if development environment variable string
// if (process.env.VUE_APP_DEVELOPMENT === 'true') {
component.template = require(`./${component.name}-template`).default;
// }

// css import
require(`./_${component.name}.scss`);

// export component
export default component;
