import axios from 'axios';
import '@/_scl-fed/config/axios';

export default {
	// region
	//----------------------------------------------------

	async setLocation(postCode, fallBackText) {
		const response = await axios.post(`/api/location/setlocation?postcode=${postCode}&fallbackText=${fallBackText}`, null, {
			baseURL: process.env.VUE_APP_ROOT_DOMAIN
		});
		return response;
	}
};
