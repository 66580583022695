/**
 * SclAuthLoginModal
 */

// template
const template = `
	<div>
		<v-dialog
			attach=".scl-auth-login-modal"
			@keydown.esc="handleCloseModal"
			overlay-opacity="0.75"
			persistent
			v-model="dialog"
			:width="$vuetify.breakpoint.smAndDown ? '100%' : '790px'"
		>
			<v-card class="scl-auth-login-modal__card">
				<v-card-actions class="scl-auth-login-modal__card-actions">
					<v-btn
						icon
						color="primaryDark"
						@click="handleCloseModal"
						size="36"
					>
						<v-icon>$vuetify.icons.faTimes</v-icon>
					</v-btn>
				</v-card-actions>
				<v-card-text>
					<div class="scl-auth-login-modal__tabs">
						<v-btn @click="changeTab" depressed :disabled="tabs.tab1" height="55">
							<span v-if="$vuetify.breakpoint.xsOnly">Login now</span>
							<span v-else>Have an account?</span>
						</v-btn>
						<v-btn @click="changeTab" depressed :disabled="tabs.tab2" height="55">
							<span v-if="$vuetify.breakpoint.xsOnly">Sign up</span>
							<span v-else>Register for an account</span>
						</v-btn>
					</div>
					<div v-if="tabs.tab1" class="scl-auth-login-modal__tab">
						<scl-auth-login-form v-if="!forgotPassword" @emit-forgot-password="handleForgotPassword" class="scl-component scl-auth-login-form" error-message="Wrong email address or password has been entered. Please try again." redirect-url="/" :input-options="form.inputOptions" :input-rules="form.inputRules" :loading="getLoading" :pattern="form.pattern"></scl-auth-login-form>
						<scl-auth-forgot-password v-else-if="forgotPassword && !resetSuccess" @emit-forgot-password="handleForgotPassword" @emit-forgot-password-response="handleForgotPasswordResponse" class="scl-component scl-auth-forgot-password" config-id="{sitecore-guid}" :input-options="form.inputOptions" :input-rules="form.inputRules" :loading="getLoading" :pattern="form.pattern"></scl-auth-forgot-password>
						<v-alert v-else class="scl-alert__alert scl-alert--flat" tile>
							<h6 class="scl-alert__title">Please check your mailbox for your new password</h6>
						</v-alert>
					</div>
					<div v-else class="scl-auth-login-modal__tab">
						<scl-auth-register-form class="scl-component scl-auth-register-form" :input-options="form.inputOptions" :input-rules="form.inputRules" :loading="getLoading" :pattern="form.pattern" redirect-url="/"></scl-auth-register-form>
					</div>
				</v-card-text>
			</v-card>
		</v-dialog>
	</div>
`;

// export template
export default template;
