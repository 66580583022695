/**
 * SclSearchListItem
 */

// template
const template = `
	<div>
		<div class="scl-search-list-item__container">
			<h6 class="scl-search-list-item__title">{{ title }}</h6>
			<p class="scl-search-list-item__description" v-html="truncate(description)"></p>
			<a class="scl-search-list-item__cta" :href="href" color="primaryDark" text>
				{{ cta }}
				<img v-if="icon" class="scl-search-list-item__icon" :src="icon" />
			</a>
		</div>
	</div>
`;

// export template
export default template;
