/**
 * SclBillingDetails
 */

// template
const template = `
	<div>
		<h3 class="scl-checkout__form-title"><span>Billing details</span></h3>

		<!-- address line 1 -->
		<label class="scl-checkout__form-label" >{{ formData.street.label }}</label>
		<v-text-field
			v-model="formData.street.value"
			@input="checkValidity"
			autocorrect="off"
			:background-color="inputOptions.backgroundColor"
			:class="inputOptions.class"
			:color="inputOptions.color"
			:height="inputOptions.height"
			:outlined="inputOptions.outlined"
			placeholder="24 Example Street"
			:rules="[inputRules.required]"
		>
		</v-text-field>
		<!-- suburb -->
		<label class="scl-checkout__form-label" >{{ formData.suburb.label }}</label>
		<v-text-field
			v-model="formData.suburb.value"
			@input="checkValidity"
			autocorrect="off"
			:background-color="inputOptions.backgroundColor"
			:class="inputOptions.class"
			:color="inputOptions.color"
			:height="inputOptions.height"
			:outlined="inputOptions.outlined"
			placeholder="Sydney"
			:rules="[inputRules.required]"
		>
		</v-text-field>
		<!-- state -->
		<label class="scl-checkout__form-label" >{{ formData.state.label }}</label>
		<v-select
			v-model="formData.state.value"
			@change="checkValidity"
			append-icon="$vuetify.icon.faChevronDown"
			:background-color="inputOptions.backgroundColor"
			:class="inputOptions.class"
			:color="inputOptions.color"
			:height="inputOptions.height"
			:outlined="inputOptions.outlined"
			:items="stateItems"
			placeholder="State"
			:rules="[inputRules.required]"
		>
		</v-select>
		<!-- postcode -->
		<label class="scl-checkout__form-label" >{{ formData.postcode.label }}</label>
		<v-text-field
			v-model="formData.postcode.value"
			@input="checkValidity"
			autocomplete="postal-code"
			autocorrect="off"
			:background-color="inputOptions.backgroundColor"
			class="is-narrow"
			:class="inputOptions.class"
			:color="inputOptions.color"
			:height="inputOptions.height"
			maxlength="4"
			minlength="3"
			:outlined="inputOptions.outlined"
			pattern="[0-9]*"
			placeholder="2000"
			:rules="[inputRules.required, inputRules.number, inputRules.postcode]"
		>
		</v-text-field>

		<v-checkbox v-model="formData.saveDetails.value" class="scl-checkout__form-selection" label="Save billing details"></v-checkbox>
	</div>
`;

// export template
export default template;
