import commerceApi from '@/_scl-fed/services/api/commerce';

// @vue/component
const component = {
	name: 'SclProductListing',
	props: {
		categoryRoot: null,
		isActive: {
			type: Boolean
		},
		productDetailPath: {
			type: String,
			default: '/products/detail'
		}
	},
	data() {
		return {
			error: {
				message: '',
				messageError: 'Something has gone wrong please try again.',
				messageNoResults: 'No results found please adjust your search parameters.',
				type: ''
			},
			hasErrored: false,
			isLoading: false,
			pagination: {
				currentPage: 1,
				pageSize: 12
			},
			sorting: {
				sort: 'displayName',
				by: 'asc'
			},
			products: [],
			categories: [],
			categoryFilter: [],
			facets: [],
			facetFilter: [],
			facetsSelected: [],
			response: null,
			search: null,
			productCount: 0,
			productTags: {
				tags: [],
				backgroundColor: 'secondary',
				isLabel: false
			}
		};
	},
	computed: {
		searchParams() {
			let params = `?pg=${this.pagination.currentPage}&ps=${this.pagination.pageSize}&s=${this.sorting.sort}&sd=${this.sorting.by}`;
			if (this.search) {
				params += `&q=${this.search}`;
			}
			if (this.categoryFilter && Array.isArray(this.categoryFilter) && this.categoryFilter.length > 0) {
				params += `&c=${this.categoryFilter.join(',')}`;
			}
			if (this.facetFilter && Array.isArray(this.facetFilter) && this.facetFilter.length > 0) {
				params += `&f=${this.facetFilter.join('%26')}`;
			}
			return params;
		},
		pageCount() {
			return Math.ceil(this.productCount / this.pagination.pageSize);
		}
	},
	created() {
		this.initialFetch();
	},
	methods: {
		initialFetch() {
			// products have already been loaded
			if (this.products.length > 0) {
				return;
			}
			this.parseUrlParameters();
			this.getCategories();
			this.productSearch();
		},
		parseUrlParameters() {
			const params = new URLSearchParams(window.location.search.substring(1));
			const pg = Number(params.get('pg'));
			const ps = Number(params.get('ps'));
			this.pagination.currentPage = Number.isNaN(pg) || pg < 1 ? this.pagination.currentPage : pg;
			this.pagination.pageSize = Number.isNaN(ps) || ps < 1 ? this.pagination.pageSize : ps;
			this.sorting.sort = params.get('s') ?? this.sorting.sort;
			this.sorting.by = params.get('sd') ?? this.sorting.by;
			this.search = params.get('q') ?? this.search;
			if (params.has('c')) {
				this.categoryFilter = params.get('c').split(/[,|]/);
			}
		},
		processError(type, message, error) {
			this.error.type = type;
			this.error.message = message;
			this.hasErrored = true;
			console.error(error);
		},
		getCategories() {
			// get categories
			commerceApi
				.categories(this.categoryRoot ? `?cr=${this.categoryRoot}` : '')
				.then((response) => {
					this.categories = response.data.categories.filter((category) => !category.isHidden);
					this.categories.forEach((category) => {
						category.isSelected = this.categoryFilter.includes(category.sitecoreId);
					});
					if (response.data.count === 0) {
						this.processError('info', this.error.messageNoResults, 'no categories found');
					}
				})
				.catch((err) => {
					this.processError('error', this.error.messageError, err);
				})
				.finally(() => {});
		},
		productSearch() {
			this.hasErrored = false;
			// products have already been requested
			if (this.isLoading) {
				return;
			}
			this.isLoading = true;

			// get products
			commerceApi
				.productSearch(this.searchParams)
				.then((response) => {
					this.response = response.data;
					this.products = response.data.childProducts;
					this.productCount = response.data.totalItemCount;
					this.facets = response.data.facets;
					this.formatFacets();
					if (response.data.facets === 0) {
						this.processError('info', this.error.messageNoResults, 'no facets found');
					}
					if (this.products.length === 0) {
						this.processError('info', this.error.messageNoResults, 'no products found');
					}
				})
				.catch((err) => {
					this.processError('error', this.error.messageError, err);
				})
				.finally(() => {
					window.history.pushState({}, '', this.searchParams);
					this.scrollTop;
					this.isLoading = false;
				});
		},
		handlePageChange(val) {
			this.pagination.currentPage = val;
			this.productSearch();
		},
		handlePageSize(val) {
			this.pagination.pageSize = val;
			this.productSearch();
		},
		handleSearchQuery(val) {
			this.search = val;
			this.pagination.currentPage = 1;
			this.productSearch();
		},
		handleSortItems(val) {
			const [sort, by] = val.split('|');
			this.sorting.sort = sort;
			this.sorting.by = by;
			this.pagination.currentPage = 1;
			this.productSearch();
		},
		handleCategoryFilter(val) {
			this.categoryFilter = val.map((el) => el.sitecoreId);
			this.pagination.currentPage = 1;
			this.pagination.currentPage = 1;
			this.handleTags(val);
			this.productSearch();
		},
		handleFacetFilter(val, tags) {
			this.facetFilter = val;
			this.productTags.tags = tags;
			this.pagination.currentPage = 1;
			this.handleFacetSelection(tags);
			this.productSearch();
		},
		formatFacets() {
			if (this.facets && this.facets.length > 0) {
				this.facets.forEach((facetData) => {
					if (Object.entries(facetData.foundValues).length > 0) {
						facetData.Values = Object(facetData.foundValues).map((facet) => {
							const facetValue = {
								label: facet.name,
								quantity: facet.aggregateCount,
								isSelected: this.facetsSelected.filter((selectedFacet) => selectedFacet.label === facet.name).length > 0 // set the isSelected based on the collection of facets found
							};
							return facetValue;
						});
					}
				});
			}
		},
		handleFacetSelection(tags) {
			this.facetsSelected = [];
			// builds a collection of selected facets stored based on the user selection
			if (tags.length > 0) {
				tags.forEach((tag) => {
					const facetLabel = tag.label;
					this.facetsSelected.push({ label: facetLabel, isSelected: true });
				});
			}
		},
		handleFacetDeselection(tag) {
			this.facets.forEach((facet) => {
				facet.facetValues.forEach((facetValue) => {
					if (facetValue.label === tag.label) {
						facetValue.isSelected = false;
					}
				});
			});

			Object.entries(this.facetFilter).forEach((facet, index) => {
				if (Object.values(this.facetFilter[facet[0]]).length > 1) {
					this.facetFilter[facet[0]] = Object.values(this.facetFilter[facet[0]].filter((el) => el !== tag.label));
				} else {
					delete this.facetFilter[facet[0]];
				}
			});
			this.tags = this.tags.filter((el) => !el.label.includes(tag.label));
		},
		handleTags() {
			this.productTags.tags = [];
			// Handle categories
			if (this.categories.length > 0) {
				this.categories.forEach((category) => {
					if (category.isSelected) {
						this.productTags.tags.push({ label: category.displayName, sitecoreId: category.sitecoreId });
					}
				});
			}
		},
		handleTagRemove(tag) {
			this.productTags.tags = this.productTags.tags.filter((el) => !el.label.includes(tag.label));
			this.facetFilter = this.facetFilter.filter((el) => !el.includes(tag.value));

			if (tag.sitecoreId) {
				this.categoryFilter = this.categoryFilter.filter((el) => !el.includes(tag.sitecoreId));
				this.categories.forEach((el) => {
					if (el.sitecoreId === tag.sitecoreId) {
						el.isSelected = false;
					}
				});
			} else {
				this.search = '';
			}
			this.handleFacetDeselection(tag);
			this.productSearch();
		},
		scrollTop() {
			window.scrollTo({
				top: 0,
				left: 0,
				behavior: 'smooth'
			});
		},
		clearFilters() {
			this.search = '';
			this.categories.forEach((el) => (el.isSelected = false));
			this.categoryFilter = [];
			this.facets.forEach((el) => (el.isSelected = false));
			this.facetFilter = [];
			this.productSearch();
		}
	},
	template: null
};

// set template and dummy data if development environment variable string
// if (process.env.VUE_APP_DEVELOPMENT === 'true') {
component.template = require(`./${component.name}-template`).default;
// }

// css import
require(`./_${component.name}.scss`);

// export component
export default component;
