// @vue/component
const component = {
	name: 'SclFooterSearch',
	data() {
		return {
			searchQuery: null
		};
	},
	methods: {
		handleSearch(url) {
			if (!url) {
				return;
			}
			if (!this.searchQuery.length > 1 || this.searchQuery === null) {
				return;
			}
			window.location.href = `${url}?q=${this.searchQuery}`;
		}
	},
	template: null
};

// set template and dummy data if development environment variable string
if (process.env.VUE_APP_DEVELOPMENT === 'true') {
	component.template = require(`./${component.name}-template`).default;
}

// css import
require(`./_${component.name}.scss`);

// export component
export default component;
