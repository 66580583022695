/*
 * Google Analytics/Google Tag Manager mixin
 */

const mxAnalytics = {
	methods: {
		_mxAnalytics_getMemberAction(memberType) {
			const actionTag = memberType !== 'Member' ? 'Non Member' : 'Member';
			return actionTag;
		},
		_mxAnalytics_getMemberEventTag(memberType) {
			const eventTag = memberType !== 'Member' ? 'Non Member Login' : 'Member Login';
			return eventTag;
		}
	}
};

export default mxAnalytics;
