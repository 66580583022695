// @vue/component
import calendarApi from '@/_scl-fed/services/api/calendar';

const component = {
	name: 'SclAwardsCalendar',
	props: {
		api: {
			type: Object,
			required: false,
			// props type validation
			validator: (obj) => {
				const set = new Set();
				set.add(!!(obj.title ? typeof obj.title === 'string' : true));
				set.add(!!(obj.location ? typeof obj.location === 'string' : true));
				set.add(!!(obj.startDate ? typeof obj.startDate === 'string' : true));
				return !set.has(false);
			}
		}
	},
	computed: {
		propApi() {
			return {
				location: 'Example Location',
				title: 'Example Title',
				startDate: '2020-01-01T00:00:00Z',
				...this.api
			};
		}
	},
	methods: {
		addToCalendar() {
			// Generate Calendar Item
			calendarApi.generateCalendarUrl(this.api).then((resp) => {
				window.open(resp);
			});
		}
	}
};

// set template and dummy data if development environment variable string
// if (process.env.VUE_APP_DEVELOPMENT === 'true') {
component.template = require(`./${component.name}-template`).default;
// }

// css import
require(`./_${component.name}.scss`);

// export component
export default component;
