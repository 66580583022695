// @vue/component
const component = {
	name: 'SclEventsListItem',
	props: {
		title: {
			type: String
		},
		date: {
			type: String
		},
		time: {
			type: String
		},
		description: {
			type: String
		},
		imageUrl: {
			type: String
		},
		url: {
			type: String
		},
		location: {
			type: String
		}
	},
	data() {
		return {};
	},
	computed: {
		image() {
			if (this.imageUrl === null) {
				return;
			}
			return `${process.env.VUE_APP_ROOT_DOMAIN}/${this.imageUrl}`;
		},
		isOnline() {
			if (this.location === 'Online') {
				return true;
			}
			return false;
		}
	},
	methods: {
		dateFormat(val) {
			const options = { year: 'numeric', month: 'long', day: 'numeric' };
			const date = new Date(val);
			let hours = date.getHours();
			let minutes = date.getMinutes();
			const ampm = hours >= 12 ? 'pm' : 'am';
			hours %= 12;
			hours = hours || 12;
			minutes = minutes < 10 ? `0${minutes}` : minutes;
			const strTime = `${hours}:${minutes} ${ampm}`;
			return `${date.toLocaleDateString('en-AU', options)},  ${strTime}`;
		},
		truncate(val, limit) {
			return val && val.length > limit ? `${val.substr(0, limit)}...` : val;
		}
	},
	template: null
};

// set template and dummy data if development environment variable string
// if (process.env.VUE_APP_DEVELOPMENT === 'true') {
component.template = require(`./${component.name}-template`).default;
// }

// css import
require(`./_${component.name}.scss`);

// export component
export default component;
