/**
 * SclArticleCardCarousel
 */

const template = `
	<div>
		<div class="scl-article-card-carousel__header">
			<h3 class="scl-article-card-carousel__header-title">{{$root.dummyWords}}</h3>
			<v-btn :ripple="false" class="btn btn-link scl-article-card-carousel__header-link">{{$root.dummyWords}}<v-icon class="btn__icon-link"> $vuetify.icons.faArrowRight</v-icon></v-btn>
		</div>
		<v-carousel
			:cycle="propApi.cycle"
			class="scl-article-card-carousel__carousel scl-article-card-carousel__carousel--center"
			:delimiter-icon="propApi.delimiterIcon"
			:height="propApi.height"
			hide-delimiter-background
			:show-arrows="propApi.showArrows"
			:hide-delimiters="propApi.hideDelimiters"
			light
			:next-icon="propApi.nextIcon"
			:prev-icon="propApi.prevIcon"
			ref="carouselGridRef">
			<v-lazy>
				<v-carousel-item class="scl-article-card-carousel__item">
					<v-row class="scl-article-card-carousel__row" >
						<v-col class="py-0" cols="12" sm="6" md="3">
							<v-card :ripple="false" class="scl-article-card-carousel__card" outlined href="#">
								<v-img
									:lazy-src="$root.dummyImage43"
									:src="$root.dummyImage43"
									:aspect-ratio="2/1"
								/>
								<div class="scl-article-card-carousel__content">
									<v-chip-group :show-arrows="false" class="scl-article-card-carousel__chip-group">
										<v-chip disabled class="scl-article-card-carousel__chip">
											{{ $root.dummyWords }}
										</v-chip>
										<v-chip disabled class="scl-article-card-carousel__chip">
											{{ $root.dummyWord }}
										</v-chip>
										<v-chip disabled class="scl-article-card-carousel__chip">
											{{ $root.dummyWord }}
										</v-chip>
									</v-chip-group>
									<h6 class="scl-article-card-carousel__title mb-3">{{ $root.dummyWord }}</h6>
									<div class="scl-article-card-carousel__text">
										<p>{{ $root.dummyParagraph }}</p>
									</div>
									<div class="scl-article-card-carousel__info">
										<div class="scl-article-card-carousel__info-date"> Mar 2</div>
										<div class="scl-article-card-carousel__info-time">7 min read</div>
									</div>
									<v-btn :ripple="false" class="btn btn-link" text href="#">
										{{ $root.dummyWords }}
										<v-icon class="scl-article-card-carousel__icon btn__icon-link" right>$vuetify.icons.faArrowRight</v-icon>
									</v-btn>
								</div>
							</v-card>
						</v-col>
						<v-col class="py-0" cols="12" sm="6" md="3">
							<v-card :ripple="false" class="scl-article-card-carousel__card" outlined href="#">
								<v-img
									:lazy-src="$root.dummyImage43"
									:src="$root.dummyImage43"
									:aspect-ratio="2/1"
								/>
								<div class="scl-article-card-carousel__content">
									<v-chip-group :show-arrows="false" class="scl-article-card-carousel__chip-group">
										<v-chip disabled class="scl-article-card-carousel__chip">
											{{ $root.dummyWords }}
										</v-chip>
										<v-chip disabled class="scl-article-card-carousel__chip">
											{{ $root.dummyWord }}
										</v-chip>
										<v-chip disabled class="scl-article-card-carousel__chip">
											{{ $root.dummyWord }}
										</v-chip>
									</v-chip-group>
									<h6 class="scl-article-card-carousel__title mb-3">{{ $root.dummyWord }}</h6>
									<div class="scl-article-card-carousel__text">
										<p>{{ $root.dummySentence }}</p>
									</div>
									<div class="scl-article-card-carousel__info">
										<div class="scl-article-card-carousel__info-date"> Mar 2</div>
										<div class="scl-article-card-carousel__info-time">7 min read</div>
									</div>
									<v-btn :ripple="false" class="btn btn-link" text href="#">
										{{ $root.dummyWords }}
										<v-icon class="scl-article-card-carousel__icon btn__icon-link" right>$vuetify.icons.faArrowRight</v-icon>
									</v-btn>
								</div>
							</v-card>
						</v-col>
					</v-row>
				</v-carousel-item>
			</v-lazy>
		</v-carousel>
	</div>
`;

export default template;
