/**
 * SclPaymentSummary
 */

// template
const template = `
	<div>
		<h1>Thank you for your order</h1>
		<h3>What happens next?</h3>
		<div class="scl-wysiwyg">
			<ul class="scl-wysiwyg__list-style-2">
				<li>Receive an order confirmation email</li>
				<li>Receive an email when order is packed and ready to be shipped (if applicable)</li>
				<li>Lorem ipsum doler sit amet</li>
			</ul>
		</div>
		<ul class="scl-payment-summary__order-details">
			<li><span>Order number</span>SWITCH00000000001</li>
			<li><span>Order date</span>Wed 12th August</li>
		</ul>
		<v-row>
			<v-col cols="12" sm="6" lg="4">
				<div class="scl-payment-summary__list-container">
					<h5 class="scl-payment-summary__list-title"><span>Payment total</span></h5>
					<ul class="scl-payment-summary__list">
						<li><span>Payment method</span><span>Paypal</span></li>
						<li><span>Subtotal</span><span>$4,480.00</span></li>
						<li><span>Standard shipping</span><span>Free</span></li>
						<li><span>Discount</span><span>- $20.00</span></li>
						<li><span>GST</span><span>$7.50</span></li>
						<li class="font-weight-bold"><span>Order total</span><span>$4,480.00</span></li>
					</ul>
				</div>
			</v-col>
			<v-col cols="12" sm="6" lg="4" offset-lg="1">
				<div class="scl-payment-summary__list-container">
					<h5 class="scl-payment-summary__list-title"><span>Billing address</span></h5>
					<ul class="scl-payment-summary__list">
						<li><span>Miss Deanna Grindrod</span></li>
						<li><span>123 Australia street</span></li>
						<li><span>Sydney</span></li>
						<li><span>NSW</span></li>
						<li><span>2000</span></li>
					</ul>
				</div>
				<div class="scl-payment-summary__list-container">
					<h5 class="scl-payment-summary__list-title"><span>Shipping details</span></h5>
					<ul class="scl-payment-summary__list">
						<li class="font-weight-bold"><span>Standard shipping</span><span>Free</span></li>
						<li><span>Miss Deanna Grindrod</span></li>
						<li><span>123 Australia street</span></li>
						<li><span>Sydney</span></li>
						<li><span>NSW</span></li>
						<li><span>2000</span></li>
					</ul>
				</div>
			</v-col>
		</v-row>
	</div>
`;

// export template
export default template;
