/**
 * SclBreadcrumb api
 *
 * divider prop
 * @param {string} '$vuetify.icons.faChevronRight'
 *
 * items prop
 * @param {array} [ { text: 'item one', disabled: false, href: '#' } ]
 *
 * characterDivider prop
 * @param {string} '|'
 *
 * mobileLinkLimit prop
 * @param {number} null,
 */

const template = `
	<div>
		<v-breadcrumbs :items="breakpointAdjustedItems" :divider="propApi.characterDivider" >
			<template v-if="!propApi.characterDivider" v-slot:divider>
				<v-icon>
					{{ propApi.divider }}
				</v-icon>
			</template>
		</v-breadcrumbs>
	</div>
`;

export default template;
