// @vue/component
const component = {
	name: 'SclPersonalDetails',
	props: {
		inputOptions: {
			type: Object
		},
		inputRules: {
			type: Object
		},
		pattern: {
			type: Object
		},
		formData: {
			type: Object
		}
	},
	data() {
		return {
			titleItems: ['Mr', 'Mrs', 'Miss', 'Ms', 'Dr', 'Prof', 'Senator', 'Sir', 'The Hon']
		};
	},
	computed: {
		isValid() {
			return !!(
				this.formData.personal.firstName.value &&
				this.formData.personal.lastName.value &&
				this.formData.contact.mobile.value &&
				this.formData.contact.email.value
			);
		},
		validEmail() {
			return this.pattern.email.test(this.formData.contact.email.value);
		},
		validPhone() {
			return this.pattern.number.test(this.formData.contact.mobile.value) && this.pattern.phone.test(this.formData.contact.mobile.value);
		}
	},
	watch: {
		formData() {
			if (Object.entries(this.formData).length > 0) {
				this.checkValidity();
			}
		}
	},
	methods: {
		checkValidity() {
			if (this.validPhone && this.validEmail && this.isValid) {
				this.$emit('emit-personal-form', true, this.formData);
			} else {
				this.$emit('emit-personal-form', false, this.formData);
			}
		}
	},
	template: null
};

// set template and dummy data if development environment variable string
// if (process.env.VUE_APP_DEVELOPMENT === 'true') {
component.template = require(`./${component.name}-template`).default;
// }

// css import
require(`./_${component.name}.scss`);

// export component
export default component;
