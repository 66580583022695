// @vue/component
const component = {
	name: 'SclDeliveryDetails',
	props: {
		details: {
			type: Object
		},
		inputOptions: {
			type: Object
		},
		inputRules: {
			type: Object
		},
		pattern: {
			type: Object
		},
		formData: {
			type: Object
		}
	},
	data() {
		return {
			sameAsBilling: false,
			stateItems: ['ACT', 'NSW', 'NT', 'QLD', 'SA', 'TAS', 'VIC', 'WA'],
			temp: {}
		};
	},
	computed: {
		isValid() {
			return !!(
				this.formData.deliveryDetails.street.value &&
				this.formData.deliveryDetails.suburb.value &&
				this.formData.deliveryDetails.state.value &&
				this.formData.deliveryDetails.postcode.value
			);
		},
		validPostcode() {
			return (
				this.pattern.number.test(this.formData.deliveryDetails.postcode.value) &&
				this.pattern.postcode.test(this.formData.deliveryDetails.postcode.value)
			);
		}
	},
	watch: {
		details: {
			handler() {
				if (this.sameAsBilling) {
					this.setBilling();
					this.checkValidity();
				}
			},
			deep: true,
			immediate: true
		},
		formData() {
			if (Object.entries(this.formData).length > 0) {
				this.checkValidity();
			}
		}
	},
	methods: {
		checkValidity() {
			if (this.validPostcode && this.isValid) {
				this.$emit('emit-delivery-form', true, this.formData.deliveryDetails);
			} else {
				this.$emit('emit-delivery-form', false, this.formData.deliveryDetails);
			}
		},
		setBilling() {
			this.formData.deliveryDetails.street.value = this.details.street.value;
			this.formData.deliveryDetails.suburb.value = this.details.suburb.value;
			this.formData.deliveryDetails.state.value = this.details.state.value;
			this.formData.deliveryDetails.postcode.value = this.details.postcode.value;
		},
		toggleBilling() {
			if (!this.sameAsBilling) {
				this.sameAsBilling = true;
				this.setBilling();
				this.checkValidity();
			} else {
				this.sameAsBilling = false;
				this.checkValidity();
			}
		}
	},
	template: null
};

// set template and dummy data if development environment variable string
// if (process.env.VUE_APP_DEVELOPMENT === 'true') {
component.template = require(`./${component.name}-template`).default;
// }

// css import
require(`./_${component.name}.scss`);

// export component
export default component;
