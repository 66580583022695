// @vue/component
const component = {
	name: 'SclSearchListItem',
	props: {
		cta: {
			type: String
		},
		description: {
			type: String
		},
		href: {
			type: String
		},
		icon: {
			type: String
		},
		title: {
			type: String
		}
	},
	data() {
		return {};
	},
	computed: {},
	methods: {
		truncate(val) {
			return val && val.length > 222 ? `${val.substr(0, 222)}...` : val;
		}
	},
	template: null
};

// set template and dummy data if development environment variable string
// if (process.env.VUE_APP_DEVELOPMENT === 'true') {
component.template = require(`./${component.name}-template`).default;
// }

// css import
require(`./_${component.name}.scss`);

// export component
export default component;
