/**
 * SclCard api
 *
 * overlayTextColour prop
 * @param {string} 'white--text'
 * @param {string} 'black--text'
 *
 * variant prop
 * @param {string} 'dark'
 * @param {string} 'light'
 */

// template
const template = `
	<div :class="propApi.height === 'auto' ? 'scl-card-horizontal__auto': 'scl-card-horizontal__fill-height'">
		<v-card class="scl-card-horizontal__card" :dark="propApi.variant === 'dark'" :light="propApi.variant === 'light'" outlined href="#">
			<v-row :column="$vuetify.breakpoint.mdAndDown" class="scl-card-horizontal__row">
				<v-col cols="12" md="4" class="pt-0 pb-0 scl-card-horizontal__media">
					<v-img
						:lazy-src="$root.dummyImage43"
						:src="$root.dummyImage43"
						aspect-ratio="1.33"
						:class="propApi.overlayTextColour"
						class="scl-card-horizontal__image"
					/>
				</v-col>
				<v-col cols="12" md="8" class="scl-card-horizontal__content">
					<v-card-title class="scl-card-horizontal__card-title">
						<div>
							<h3 class="scl-card-horizontal__title headline mb-3">{{ $root.dummyWords }}</h3>
							<div class="scl-card-horizontal__text">
								<p>{{ $root.dummyWords }}</p>
							</div>
						</div>
					</v-card-title>
					<v-card-actions class="scl-card-horizontal__card-actions">
						<v-btn :ripple="false" class="btn btn-link" text>
							{{ $root.dummyWords }}
							<v-icon class="btn__icon-link" right>$vuetify.icons.faArrowRight</v-icon>
						</v-btn>
					</v-card-actions>
				</v-col>
			</v-row>
		</v-card>
	</div>
`;

// export template
export default template;
