import axios from 'axios';
import '@/_scl-fed/config/axios';

export default {
	// search stores
	async storeSearch(payload) {
		const response = await axios.post('/stores/search', payload);
		return response.data;
	}
};
