/**
 * SclProductDetail
 */

// template
const template = `
	<div>
		<v-container>
			<v-row>
				<!-- error message -->
				<v-col v-if="hasErrored || getError.hasErrored" cols="12">
					<div class="scl-component scl-alert">
						<v-alert border="bottom" class="scl-alert__alert scl-alert--error" colored-border icon="$vuetify.icons.faExclamation" tile type="error">
							<p class="scl-alert__copy">Something has gone wrong.</p>
						</v-alert>
					</div>
				</v-col>
				<template v-else>
					<!-- loading spinner -->
					<v-col v-if="isLoading" cols="12" class="text-center">
						<v-progress-circular color="primary" indeterminate :size="50" :width="7"></v-progress-circular>
					</v-col>
					<template v-else-if="product != null">
						<!-- gallery -->
						<v-col cols="12" md="6">
							<scl-product-gallery v-if="!isLoading" :alt-text="product.displayName" :images="product.imageUrls" class="scl-component scl-product-gallery"></scl-product-gallery>
						</v-col>
						<!-- product detail -->
						<v-col cols="12" md="6">
							<scl-product
								v-if="!isLoading"
								class="scl-component scl-product"
								:currency-symbol="product.currencySymbol"
								:description="product.description"
								:name="product.displayName"
								:price="product.listPrice"
								:adjusted-price="product.adjustedPrice"
							></scl-product>
							<scl-cart-add @emit-quantity="handleQuantity" class="scl-component scl-cart-add"></scl-cart-add>
							<scl-cart-notification :is-active="getToast.active" :type="getToast.type" class="scl-component scl-cart-notification"></scl-cart-notification>
						</v-col>
						<!--
						<v-col cols="12" v-if="(product.relatedProducts.length >= 1)">
							<scl-product-related class="scl-product-related scl-component" :items-per-slide="($vuetify.breakpoint.xsOnly? 1 : $vuetify.breakpoint.mdAndDown? 2 : 4)" :related-products="product.relatedProducts"></scl-product-related>
						</v-col>
						-->
					</template>
				</template>
			</v-row>
		</v-container>
	</div>
`;

// export template
export default template;
