/**
 * SclShippingOptions
 */

// template
const template = `
	<div>
		<h3 class="scl-checkout__form-title"><span>Delivery options</span></h3>
		<v-radio-group v-model="radioGroup">
			<div v-for="(option, index) in shippingOptions">
				<div class="scl-shipping-options__selection">
					<span class="scl-shipping-options__selection-cost">{{ shippingCost }}</span>
					<v-radio
						class="scl-shipping-options__selection-radio"
						:key="index"
						:label="option.name"
						:value="option.name"
						@click="setShippingMethod(index)"
					></v-radio>
					<span>{{option.description}}</span>
				</div>
			</div>
		</v-radio-group>
	</div>
`;

// export template
export default template;
