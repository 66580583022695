/**
 * SclProductCollections
 *
 * cycle prop
 * @param {boolean} true || false
 *
 * delimiterIcon prop
 * @param {string} string "$vuetify.icons.faCircle"
 *
 * hideDelimiters prop
 * @param {boolean} true || false
 *
 * showArrows prop
 * @param {boolean} true || false
 *
 * height prop
 * @param {string} string 'auto'
 */

const template = `
	<div>
		<h3 class="scl-product-collections__heading">{{$root.dummyWords}}</h3>
		<v-lazy>
			<v-carousel
				:cycle="propApi.cycle"
				class="scl-product-collections__carousel scl-product-collections__carousel--center"
				:class="setArrowVisibility? 'scl-product-collections__carousel--arrows': ''"
				:delimiter-icon="propApi.delimiterIcon"
				:height="propApi.height"
				hide-delimiter-background
				:show-arrows="setArrowVisibility"
				:hide-delimiters="propApi.hideDelimiters"
				:touch="{
					left: () => nextItem(),
        			right: () => prevItem()
				}"
				v-model="currentSlide"
			>
				<!-- item -->
				<v-carousel-item class="scl-product-collections__item" :key="i"  v-for="(slide, i) in carouselGroups">
					<v-row class="scl-product-collections__row">
						<v-col class="py-0" cols="12" sm="6" lg="3" :key="index" v-for="(collection, index) in slide">
							<v-card class="scl-product-collections__card" :ripple="false" flat :href="collection.Url" >
									<v-img
										:alt="collection.Name"
										:aspect-ratio="4/3"
										:lazy-src="$root.lazyImg"
										:src="collection.Image.src"
										contain
									>
									</v-img>
								<v-card-title class="scl-product-collections__title justify-space-between">
									<div>{{ collection.Name }}</div>
									<v-icon>$vuetify.icons.faArrowRightReg</v-icon>
								</v-card-title>
							</v-card>
						</v-col>
					</v-row>
				</v-carousel-item>
			</v-carousel>
		</v-lazy>
	</div>
`;

export default template;
