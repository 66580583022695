/**
 * SclKeyInfoDownload
 */

// template
const template = `
	<div>
		<div class="scl-key-info-download__resource-container" >
			<h4 class="scl-key-info-download__title">{{ $root.dummyWords}}</h4>
			<v-row>
				<v-col cols="12" md="7" class="scl-key-info-download__description-container">
					{{ $root.dummyParagraph}}
				</v-col>
				<v-col cols="12" md="5">
					<div class="scl-key-info-download__image-container">
						<v-img
							alt="preview"
							aspect-ratio="1"
							:lazy-src="$root.lazyImg"
							:src="$root.dummyImage43"
							width="100%"
						>
						</v-img>
						<div class="scl-key-info-download__icon-container" @click.stop="dialog = true">
							<v-icon color="primaryDark" size="32">$vuetify.icons.faSearchPlus</v-icon>
						</div>
					</div>
					<v-dialog
						content-class="scl-key-info-download__dialog"
						:width="$vuetify.breakpoint.smAndDown ? '90%' : 1024"
						v-model="dialog"
					>
						<v-img
							alt="access this resource now"
							aspect-ratio="1"
							:lazy-src="$root.lazyImg"
							persistent
							:src="$root.dummyImage43"
							width="100%"
						>
						</v-img>
					</v-dialog>
				</v-col>
				<v-col cols="12">
					<hr />
				</v-col>
			</v-row>

			<v-btn v-if="!getAuth" class="scl-key-info-download__cta" :ripple="false" @click="emitLogin" color="primary" depressed height="64" large>
				{{$root.dummyWords}}
				<v-icon>$vuetify.icons.faArrowToBottom</v-icon>
			</v-btn>
			<v-btn v-else class="scl-key-info-download__cta" href="#" :ripple="false" color="primary" depressed height="64" large>
				{{$root.dummyWords}}
				<v-icon >$vuetify.icons.faArrowToBottom</v-icon>
			</v-btn>
		</div>
	</div>
`;

// export template
export default template;
