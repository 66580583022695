/**
 * SclCartNotification
 */

// template
const template = `
	<div>
		<v-snackbar
			@input="handleCartNotification(false)"
			:timeout="5000"
			:value="isActive"
		>
			<v-alert border="bottom" class="scl-alert__alert" :class="'scl-alert--' + type" colored-border tile :type="type">
				<template v-if="type === 'success'" v-slot:prepend>
					<div class="scl-alert__inverted-icon-wrapper">
						<v-icon size="16px" class="scl-alert__inverted-icon">$vuetify.icons.faShoppingCart</v-icon>
					</div>
				</template>
				<div>
					<h6 class="scl-alert__title">Lorem ipsum dolor</h6>
					<p class="scl-alert__copy">Lorem ipsum dolor sit amet, consectetur adipiscing <a href="#">elit</a></p>
				</div>
				<v-btn @@click="handleCartNotification(false)"
						icon>
					<v-icon size="32px">$vuetify.icons.faTimesCircle</v-icon>
				</v-btn>
			</v-alert>
		</v-snackbar>
	</div>
`;

// export template
export default template;
