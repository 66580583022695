/**
 * SclResourceListing
 */

// template
const template = `
	<div>
		<v-container>
			<v-row>
				<!-- sidebar -->
				<v-col cols="12" md="4" lg="3">
					<v-btn class="scl-article-listing__filter-button btn btn-outlined" @click="isActive = !isActive"><v-icon left>$vuetify.icons.faSlidersH</v-icon>Filters</v-btn>
					<div class="scl-article-listing__filter" :class="isActive? 'scl-article-listing__filter--active': null">
					<!-- Search -->
					<scl-article-search @emit-search="handleSearchQuery" :is-disabled="isLoading" :param="search" :key="search" class="scl-component scl-article-search"></scl-article-search>
					<!-- Filters -->
					<scl-article-facets @emit-facet-change="handleFacetFilter" class="scl-component scl-article-facets" :is-disabled="isLoading" :facets="facets" :active-tags="articleTags.tags" :facet-filter="facetFilter"></scl-article-facets>
					</div>
				</v-col>
				<!-- items -->
				<v-col cols="12" md="8" lg="9" class="scl-article-listing__container">
					<v-row>
						<v-col cols="12" md="8">
							<scl-tags :api="articleTags" @emit-tags-remove="handleTagRemove"> </scl-tags>
						</v-col>
						<v-col cols="12" md="4">
							<div>						
								<v-btn v-if="search || articleTags.tags.length" class="scl-article-listing__clear" color="primaryDark" @click="clearFilters" text>
									<v-icon left>$vuetify.icons.faTimes</v-icon>
									Clear all filters
								</v-btn>
							</div>
						</v-col>
						<!-- sorting -->
						<v-col cols="12" class="pt-0">
							<div class="scl-article-listing__sorting">
								<v-row>
									<v-col cols="12" md="6" class="scl-article-listing__sorting-col">
									<!-- Page size -->
									<scl-page-size @emit-page-size="handlePageSize" :active-item="pagination.pageSize" :is-disabled="isLoading" class="scl-page-size scl-component"></scl-page-size>
									</v-col>
									<v-col cols="12" md="6" class="scl-article-listing__sorting-col">
									<!-- Sort Items -->
									<scl-article-sorting @emit-sorting="handleSortItems" :is-disabled="isLoading" class="scl-component scl-article-sorting"></scl-article-sorting>
									</v-col>
								</v-row>
							</div>
						</v-col>
						<v-col cols="12">
						{{getResultsMessage}}
						</v-col>
						<!-- error / no results message -->
						<v-col v-if="hasErrored" cols="12">
							<div class="scl-component scl-alert">
								<v-alert border="bottom" class="scl-alert__alert" :class="'scl-alert--' + error.type" colored-border icon="$vuetify.icons.faExclamation" tile :type="error.type">
									<p class="scl-alert__copy">{{ error.message }}</p>
								</v-alert>
							</div>
						</v-col>
						<template v-else>
							<!-- loading spinner -->
							<v-col v-if="isLoading" cols="12" class="text-center">
								<v-progress-circular color="primary" indeterminate :size="50" :width="7"></v-progress-circular>
							</v-col>
							<template v-else>
								<v-col v-for="(item, index) in items" :key="index" cols="12">
									<!-- List Items -->
									<scl-article-list-item class="scl-component scl-article-list-item"
									:title="item.Title"
									:description="item.IntroText"
									:date="item.Date"
									:featured="item.IsFeatured"
									:article-type="item.ArticleType"
									:url="item.Url"
									></scl-article-list-item>
								</v-col>
							</template>
						</template>
						<!-- pagination -->
						<v-col cols="12" v-if="pageCount > 1">
							<scl-pagination
								class="scl-component scl-pagination"
								:is-disabled="isLoading"
								:total-pages="pageCount"
								:current-page="pagination.currentPage"
								:visible-pages="9"
								@page-change="handlePageChange"
							></scl-pagination>
						</v-col>

					</v-row>
				</v-col>

			</v-row>
		</v-container>
	</div>
`;

// export template
export default template;
