/**
 * SclCategoryCarousel
 *
 * cycle prop
 * @param {boolean} true || false
 *
 * showArrows prop
 * @param {boolean} true || false
 *
 * delimiterIcon prop
 * @param {string} string "$vuetify.icons.faCircle"
 *
 * nextIcon prop
 * @param {string} string "$vuetify.icons.faChevronRight"
 *
 * prevIcon prop
 * @param {string} string "$vuetify.icons.faChevronLeft"
 *
 * hideDelimiters prop
 * @param {boolean} true || false
 *
 * height prop
 * @param {string} string 'auto'
 */

const template = `
	<div>
		<v-lazy class="scl-carousel-category__slide">
			<v-carousel
				:cycle="propApi.cycle"
				class="scl-carousel-category__carousel scl-carousel-category__carousel--center"
				:delimiter-icon="propApi.delimiterIcon"
				:height="propApi.height"
				hide-delimiter-background
				:show-arrows="propApi.showArrows"
				:hide-delimiters="propApi.hideDelimiters"
				light
				:next-icon="propApi.nextIcon"
				:prev-icon="propApi.prevIcon"
				:touch="{
					left: () => nextItem(),
        			right: () => prevItem()
				}"
				v-model="currentSlide"
			>
				<!-- row -->
				<v-carousel-item class="scl-carousel-category__item" v-for="(item, i) in carousel" :key="i">
					<v-row class="scl-carousel-category__row" >
						<v-col class="py-0" cols="12" sm="6" lg="4"  v-for="(slide, index) in item" :key="index" >
						<v-card class="scl-carousel-category__item-card" light :ripple="false">
							<div class="scl-carousel-category__card-image">
								<a :href="slide.link">
									<v-img
										aspect-ratio="1.66667"
										:lazy-src="$root.lazyImg"
										:src="slide.image"
									>
									</v-img>
								</a>
							</div>
							<div class="scl-carousel-category__card-content">
							<v-card-title><h5 class="scl-carousel-category__card-title">{{slide.title}}</h5></v-card-title>
							<v-card-text class="text--primary scl-carousel-category__card-text">
								{{slide.introText}}
							</v-card-text>
							<v-btn :ripple="false" class="btn btn-link scl-carousel-category__card-button">View {{slide.title}}<v-icon class="btn__icon-link"> $vuetify.icons.faArrowRight</v-icon></v-btn>
							</div>
						</v-card>
						</v-col>
					</v-row>
				</v-carousel-item>
			</v-carousel>
		</v-lazy>
	</div>
`;

export default template;
