/**
 * SclRelatedLinks
 */

// template
const template = `
	<div>
		<h4 class="scl-related-links__title">
			{{$root.dummyWords}}
		</h4>
		<p>
			{{$root.dummySentence}}
		</p>
		<a class="scl-related-links__link" href="#">
			{{ $root.dummyWords }}
			<v-icon class="scl-related-links__icon">{{ setIcon('http://localhost:8080') }}</v-icon>
		</a>
		<a class="scl-related-links__link" href="#">
			{{ $root.dummyWords }}
			<v-icon class="scl-related-links__icon">{{ setIcon('http://localhost:8080/test/some-long-url-path.html') }}</v-icon>
		</a>
		<a class="scl-related-links__link" href="#">
			{{ $root.dummyWords }}
			<v-icon class="scl-related-links__icon">{{ setIcon('http://www.google.com.au/test/ashdiashdiugasndansdklsa') }}</v-icon>
		</a>
	</div>
`;

// export template
export default template;
