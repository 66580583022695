/**
 * SclMap api
 *
 * height prop
 * sets the height of the map, defaults to 264px
 * @param {number} 264
 *
 * lat prop
 * sets the latitude for the center of the map
 * if not set the map will not render
 * @param {number} null
 *
 * lng prop
 * sets the longitude for the center of the map
 * if not set the map will not render
 * @param {number} null
 *
 * marker prop
 * sets a custom map marker icon, defaults to a sourcecode .png if not set
 * needs to ba a square transparent png at least 50 x 50, will be displayed as 50 x 50
 * @param {string} '/assets/img/map-marker.png'
 *
 * zoom prop
 * sets the initial zoom level
 * @param {number} 17
 */

// template
const template = `
	<div>
		<div class="scl-map__map-container">
			<v-progress-circular v-if="!mapLoaded && propApi.lat && propApi.lng" class="scl-map__spinner" color="primary" indeterminate :size="50" :width="7"></v-progress-circular>
			<gmap-map
				v-if="propApi.lat && propApi.lng"
				:center="{ lat: propApi.lat, lng: propApi.lng }"
				:zoom="propApi.zoom"
				class="scl-map__map"
				ref="mapRef"
				:style="{ height: propApi.height }"
				@tilesloaded="mapLoaded = true"
			>
				<gmap-marker
					:icon="{ scaledSize: { width: 50, height: 50 }, url: propApi.marker }"
					:position="{ lat: propApi.lat, lng: propApi.lng }"
				/>
				</gmap-marker>
			</gmap-map>
		</div>
		<div class="scl-map__info">
			<p>Lorem ipsum dolor</p>
			<p>Lorem ipsum dolor sit amet, consectetur adipiscing Narara</p>
		</div>
	</div>
`;

// export template
export default template;
