/**
 * SclSearchListing
 */

// template
const template = `
	<div>
		<v-container class="pa-0">
			<v-row>
					<!-- sidebar -->
				<v-col cols="12" md="4" lg="3">
				<div class="content-wrapper">
				<!-- search -->
				<v-text-field v-if="!search" v-model="searchQuery"  background-color="backgroundColor" :disabled="isLoading" flat solo class="scl-article-search__input" append-icon="$vuetify.icons.faSearch" placeholder="Search" @click:append="handleSearchQuery" @keyup.enter="handleSearchQuery">

				</v-text-field>
				<v-text-field v-model="searchQuery" v-else :disabled="isLoading" background-color="backgroundColor" flat solo class="scl-article-search__input" append-icon="$vuetify.icons.faTimes" placeholder="Search" @click:append="clearSearch" @keyup.enter="clearSearch">
		  
				</v-text-field>
					<v-btn class="scl-search-listing__filter-button btn btn-outlined" @click="facetPanelActive = !facetPanelActive"><v-icon left>$vuetify.icons.faSlidersH</v-icon>Filters</v-btn>
					<div class="scl-search-listing__filter" :class="facetPanelActive ? 'scl-search-listing__filter--active' : null">
						<!-- facets -->
						<scl-search-listing-facets
							:active-tags="searchTags.tags"
							class="scl-component scl-search-listing-facets"
							@emit-facet-change="handleFacetFilter"
							:facets="facets"
							:facet-filter="facetFilter"
							:is-disabled="isLoading"
						></scl-search-listing-facets>
					</div>
					<div>
				</v-col>
				<!-- items -->
				<v-col cols="12" md="8" lg="9">
					<div class="content-wrapper">
					<v-row v-if="search || searchTags.tags.length" justify="space-between">
						<!-- tags -->
						<v-col>
							<scl-tags :api="searchTags" @emit-tags-remove="handleTagRemove"></scl-tags>
						</v-col>
						<!-- clear -->
						<v-col class="text-right">
							<v-btn class="scl-search-listing__clear" color="primaryDark" @click="clearFilters" height="32" text>
								<v-icon left>$vuetify.icons.faTimes</v-icon> Clear all filters
							</v-btn>
						</v-col>
					</v-row>
					<v-row class="scl-search-listing__page-row" justify="space-between">
						<!-- page size -->
						<v-col :class="{'col-12 mx-3 px-0':$vuetify.breakpoint.xsOnly, 'ml-3 pl-0':$vuetify.breakpoint.smAndUp}">
							<scl-page-size @emit-page-size="handlePageSize" :active-item="pagination.pageSize" :api="{pageSize: [{value: '8'}, {value: '16'}, {value: '32'}]}" :is-disabled="isLoading" class="scl-page-size scl-component"></scl-page-size>
						</v-col>
						<!-- sort -->
						<v-col :class="{'col-12 mx-3 px-0':$vuetify.breakpoint.xsOnly, 'mr-3 pr-0':$vuetify.breakpoint.smAndUp}">
							<scl-search-listing-sort class="scl-component scl-search-listing-sort" @emit-sorting="handleSortItems" :is-disabled="isLoading || isDisabled"></scl-search-listing-sort>
						</v-col>
					</v-row>
					<v-row>
						<v-col v-if="!isLoading" class="pb-0" cols="12">
							<p class="my-1">{{ getResultsMessage }}</p>
						</v-col>
						<!-- error / no results message -->
						<v-col v-if="hasErrored" cols="12">
							<div class="scl-component scl-alert">
								<v-alert border="bottom" class="scl-alert__alert" :class="'scl-alert--' + error.type" colored-border icon="$vuetify.icons.faExclamation" tile :type="error.type">
									<p class="scl-alert__copy">{{ error.message }}</p>
								</v-alert>
							</div>
						</v-col>
						<template v-else>
							<!-- loading spinner -->
							<v-col v-if="isLoading" cols="12" class="text-center">
								<v-progress-circular color="primary" indeterminate :size="50" :width="7"></v-progress-circular>
							</v-col>
							<template v-else>
								<!-- search items -->
								<v-col v-for="(item, index) in items" cols="12" :key="index">
									<scl-search-list-item
										class="scl-component scl-search-list-item"
										:cta="item.Cta"
										:description="item.Description"
										:href="item.Url"
										:icon="item.Icon"
										:title="item.Title"
									>
									</scl-search-list-item>
								</v-col>
							</template>
						</template>
					</v-row>
					<v-row v-if="pageCount > 1">
						<!-- pagination -->
						<v-col cols="12" class="text-center">
							<scl-pagination
								class="scl-component scl-pagination"
								:is-disabled="isLoading"
								:total-pages="pageCount"
								:current-page="pagination.currentPage"
								:visible-pages="9"
								@page-change="handlePageChange"
							></scl-pagination>
						</v-col>
					</v-row>
					</div>
				</v-col>
			</v-row>
		</v-container>
	</div>
`;

// export template
export default template;
