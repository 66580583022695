/**
 * SclSocialShare api
 *
 * type prop
 * @param {string} 'email'
 * @param {string} 'facebook'
 * @param {string} 'linkedin'
 * @param {string} 'pinterest'
 * @param {string} 'twitter'
 * */

const template = `
	<div>
		<v-btn :ripple="false" @click="socialType(api.type)" :class="'scl-social-share__button scl-social-share--' + api.type" icon large>
			<v-icon v-html="socialIcon(api.type)" class="scl-social-share__icon"></v-icon>
		</v-btn>
	</div>
`;

export default template;
