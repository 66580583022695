// @vue/component
const component = {
	name: 'SclSearchListingSort',
	props: {
		isDisabled: {
			type: Boolean
		}
	},
	data() {
		return {
			sorting: [
				{ name: 'Relevance', sort: 'relevance' },
				{ name: 'Most Recent', sort: 'most-recent' },
				{ name: 'A-Z', sort: 'title-asc' },
				{ name: 'Z-A', sort: 'title-desc' }
			]
		};
	},
	computed: {},
	methods: {
		sortBy(sortBy) {
			this.$emit('emit-sorting', sortBy);
		}
	},
	template: null
};

// set template and dummy data if development environment variable string
// if (process.env.VUE_APP_DEVELOPMENT === 'true') {
component.template = require(`./${component.name}-template`).default;
// }

// css import
require(`./_${component.name}.scss`);

// export component
export default component;
