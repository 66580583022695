/**
 * SclAdContainer api
 */

// template
const template = `
	<div>
		<!-- small ad container (mobile & tablet) -->
		<div v-if="$vuetify.breakpoint.smAndDown" class="scl-ad-container__small">
			small test
		</div>
		<!-- large ad container (desktop and up) -->
		<div v-if="$vuetify.breakpoint.mdAndUp" class="scl-ad-container__large">
			large test
		</div>
	</div>
`;

// export template
export default template;
