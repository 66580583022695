// @vue/component
const component = {
	name: 'SclBreadcrumbs',
	props: {
		api: {
			type: Object,
			required: true,
			// props type validation
			validator: (obj) => {
				const set = new Set();
				set.add(!!(obj.divider ? typeof obj.divider === 'string' : true));
				set.add(!!(obj.item ? Array.isArray(obj.item) : true));
				return !set.has(false);
			}
		}
	},
	computed: {
		// props defaults
		propApi() {
			return {
				divider: '$vuetify.icons.faChevronRight',
				items: [],
				...this.api
			};
		},
		isMobile() {
			return this.$vuetify.breakpoint.xsOnly;
		},
		breakpointAdjustedItems() {
			if (!this.isMobile) {
				return this.propApi.items;
			}
			return this.propApi.items.slice(this.propApi.items.length - this.propApi.mobileLinkLimit, this.propApi.items.length);
		}
	},
	methods: {},
	template: null
};

// set template and dummy data if development environment variable string
if (process.env.VUE_APP_DEVELOPMENT === 'true') {
	component.template = require(`./${component.name}-template`).default;
}

// css import
require(`./_${component.name}.scss`);

export default component;
