// @vue/component
const component = {
	name: 'SclImageSrcset',
	props: {
		api: {
			type: Object,
			required: false,
			// props type validation
			validator: (obj) => {
				const set = new Set();
				set.add(!!(obj.alt ? typeof obj.alt === 'string' : true));
				set.add(!!(obj.gradient ? typeof obj.gradient === 'boolean' : true));
				set.add(!!(obj.showInfo ? typeof obj.showInfo === 'boolean' : true));
				set.add(!!(obj.src ? typeof obj.src === 'string' : true));
				set.add(!!(obj.srcsetSmall ? typeof obj.srcsetSmall === 'string' : true));
				set.add(!!(obj.srcsetLarge ? typeof obj.srcsetLarge === 'string' : true));
				return !set.has(false);
			}
		}
	},
	computed: {
		// props defaults
		propApi() {
			return {
				alt: '',
				src: '',
				srcsetSmall: '',
				srcsetLarge: '',
				gradient: false,
				showInfo: true,
				...this.api
			};
		}
	},
	methods: {
		setSrcset(small, large) {
			const smallImg = small || '';
			const largeImg = large || '';
			return `${smallImg} 768w, ${largeImg} 1120w`;
		}
	},
	template: null
};

// set template and dummy data if development environment variable string
if (process.env.VUE_APP_DEVELOPMENT === 'true') {
	component.template = require(`./${component.name}-template`).default;
}

// css import
require(`./_${component.name}.scss`);

// export component
export default component;
