/**
 * SclProductCategoryList
 */

// template
const template = `
	<div>
		<h3 class="scl-product-category-list__title">{{$root.dummyWords}}</h3>
		<v-row class="py-0">
			<v-col cols="12" sm="4">
				<div class="scl-product-category-list__card-wrapper">
				<v-card class="scl-product-category-list__card" :ripple="false" flat>		
					<v-img
						:alt="title"
						:aspect-ratio="4/3"
						:lazy-src="$root.lazyImg"
						:src="image"
						contain>	
					</v-img>				
					<div class="scl-product-category-list__card-content">
						<v-card-title class="scl-product-category-list__card-title justify-space-between">
							{{ $root.dummyWords }} <v-icon> $vuetify.icons.faArrowRightReg</v-icon>
						</v-card-title>
						<v-card-text class="scl-product-category-list__card-text">
							{{ $root.dummySentence}}
							{{ $root.dummySentence}}

						</v-card-text>
					</div>
				</v-card>
				</div>
			</v-col>
			<v-col cols="12" sm="4" >
			<div class="scl-product-category-list__card-wrapper">
			
				<v-card class="scl-product-category-list__card" :ripple="false" flat>		
					<v-img
						:alt="title"
						:aspect-ratio="4/3"
						:lazy-src="$root.lazyImg"
						:src="image"
						contain>	
					</v-img>				
					<div class="scl-product-category-list__card-content">
						<v-card-title class="scl-product-category-list__card-title">
							{{ $root.dummyWords }}
						</v-card-title>
						<v-card-text class="scl-product-category-list__card-text">
							{{ $root.dummySentence}}
						</v-card-text>
					</div>
				</v-card>
				</div>
			</v-col>
			<v-col cols="12" sm="4" >
			<div class="scl-product-category-list__card-wrapper">

				<v-card class="scl-product-category-list__card" :ripple="false" flat>		
					<v-img
						:alt="title"
						:aspect-ratio="4/3"
						:lazy-src="$root.lazyImg"
						:src="image"
						contain>	
					</v-img>				
					<div class="scl-product-category-list__card-content">
						<v-card-title class="scl-product-category-list__card-title">
							{{ $root.dummyWords }}
						</v-card-title>
						<v-card-text class="scl-product-category-list__card-text">
							{{ $root.dummySentence}}
						</v-card-text>
					</div>
				</v-card>
				</div>
			</v-col>
			<v-col cols="12" sm="4" >
			<div class="scl-product-category-list__card-wrapper">
				<v-card class="scl-product-category-list__card" :ripple="false" flat>		
					<v-img
						:alt="title"
						:aspect-ratio="4/3"
						:lazy-src="$root.lazyImg"
						:src="image"
						contain>	
					</v-img>				
					<div class="scl-product-category-list__card-content">
						<v-card-title class="scl-product-category-list__card-title">
							{{ $root.dummyWords }}
						</v-card-title>
						<v-card-text class="scl-product-category-list__card-text">
							{{ $root.dummySentence}}
						</v-card-text>
					</div>
				</v-card>
				</div>
			</v-col>
		<v-row>
	</div>
`;

// export template
export default template;
