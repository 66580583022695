/**
 * SclTertiaryNav
 */

const template = `
	<div>
		<h5 class="scl-tertiary-nav__title">{{$root.dummyWords}}</h5>
		<v-expansion-panels accordion class="scl-tertiary-nav__accordions" flat :value="1">
			<!-- panel -->
			<v-expansion-panel class="scl-tertiary-nav__accordion">
				<v-expansion-panel-header disable-icon-rotate expand-icon="$vuetify.icons.faCaretRight" class="scl-tertiary-nav__header">
					<a href="#">{{$root.dummyWords}}</a>
				</v-expansion-panel-header>
				<v-expansion-panel-content class="scl-category-accordion__content">
					<a href="#" class="scl-tertiary-nav__link">{{$root.dummyWords}}</a>
					<a href="#" class="scl-tertiary-nav__link">{{$root.dummyWords}}</a>
					<a href="#" class="scl-tertiary-nav__link">{{$root.dummyWords}}</a>
					<a href="#" class="scl-tertiary-nav__link">{{$root.dummyWords}}</a>
				</v-expansion-panel-content>
			</v-expansion-panel>
			<!-- panel -->
			<v-expansion-panel class="scl-tertiary-nav__accordion">
				<v-expansion-panel-header disable-icon-rotate expand-icon="$vuetify.icons.faCaretRight" class="scl-tertiary-nav__header">
					<a href="#">{{$root.dummyWords}}</a>
				</v-expansion-panel-header>
				<v-expansion-panel-content class="scl-category-accordion__content">
					<a href="#" class="scl-tertiary-nav__link">{{$root.dummyWords}}</a>
					<a href="#" class="scl-tertiary-nav__link">{{$root.dummyWords}}</a>
					<a href="#" class="scl-tertiary-nav__link">{{$root.dummyWords}}</a>
					<a href="#" class="scl-tertiary-nav__link">{{$root.dummyWords}}</a>
				</v-expansion-panel-content>
			</v-expansion-panel>
			<!-- panel -->
			<v-expansion-panel class="scl-tertiary-nav__accordion">
				<v-expansion-panel-header disable-icon-rotate expand-icon="$vuetify.icons.faCaretRight" class="scl-tertiary-nav__header">
					<a href="#">{{$root.dummyWords}}</a>
				</v-expansion-panel-header>
				<v-expansion-panel-content class="scl-category-accordion__content">
					<a href="#" class="scl-tertiary-nav__link">{{$root.dummyWords}}</a>
					<a href="#" class="scl-tertiary-nav__link">{{$root.dummyWords}}</a>
					<a href="#" class="scl-tertiary-nav__link">{{$root.dummyWords}}</a>
					<a href="#" class="scl-tertiary-nav__link">{{$root.dummyWords}}</a>
				</v-expansion-panel-content>
			</v-expansion-panel>
		</v-expansion-panels>
	</div>
`;

export default template;
