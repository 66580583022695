// @vue/component
const component = {
	name: 'SclProductPageSize',
	props: {
		isDisabled: {
			type: Boolean
		},
		activeItem: {
			type: Number
		}
	},
	data() {
		return {
			pageSize: [{ value: '12' }, { value: '24' }, { value: '48' }, { value: '96' }]
		};
	},
	methods: {
		setPageSize(val) {
			this.activeItem = val;
			this.$emit('emit-page-size', val);
		}
	},
	template: null
};

// set template and dummy data if development environment variable string
// if (process.env.VUE_APP_DEVELOPMENT === 'true') {
component.template = require(`./${component.name}-template`).default;
// }

// css import
require(`./_${component.name}.scss`);

// export component
export default component;
