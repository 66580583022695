// @vue/component
const component = {
	name: 'SclCategoryList',
	props: {
		api: {
			type: Object,
			// props type validation
			validator: (obj) => {
				const set = new Set();
				set.add(!!(obj.showRightSideOnMobile ? typeof obj.showRightSideOnMobile === 'boolean' : true));
				set.add(!!(obj.showRightSide ? typeof obj.showRightSide === 'boolean' : true));
				set.add(!!(obj.defaultButtonText ? typeof obj.defaultButtonText === 'string' : true));
				set.add(!!(obj.buttonText ? typeof obj.buttonText === 'string' : true));
				return !set.has(false);
			}
		}
	},
	computed: {
		// props defaults
		propApi() {
			return {
				showRightSideOnMobile: false,
				showRightSide: true,
				buttonText: 'Browse',
				defaultButtonText: 'Browse Topic',
				...this.api
			};
		}
	},
	methods: {
		buttonText(val) {
			if (val.length >= 26) {
				return this.propApi.defaultButtonText;
			}
			return `${this.propApi.buttonText} ${val}`;
		}
	},
	template: null
};

// set template and dummy data if development environment variable string
if (process.env.VUE_APP_DEVELOPMENT === 'true') {
	component.template = require(`./${component.name}-template`).default;
}

// css import
require(`./_${component.name}.scss`);

// export component
export default component;
