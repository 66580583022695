import Vue from 'vue';
import Vuetify, {
	VAlert,
	VApp,
	VAppBar,
	VAppBarNavIcon,
	VAutocomplete,
	VAvatar,
	VBadge,
	VBanner,
	VBottomNavigation,
	VBottomSheet,
	VBreadcrumbs,
	VBreadcrumbsItem,
	VBtn,
	VBtnToggle,
	VCalendar,
	VCard,
	VCardActions,
	VCardSubtitle,
	VCardText,
	VCardTitle,
	VCarousel,
	VCarouselItem,
	VCheckbox,
	VChip,
	VChipGroup,
	VCol,
	VColorPicker,
	VCombobox,
	VContainer,
	VContent,
	VCounter,
	VData,
	VDataIterator,
	VDataTable,
	VDatePicker,
	VDialog,
	VDivider,
	VExpansionPanels,
	VExpansionPanel,
	VExpansionPanelHeader,
	VExpansionPanelContent,
	VExpandTransition,
	VFileInput,
	VFlex,
	VFooter,
	VForm,
	VHover,
	VIcon,
	VImg,
	VInput,
	VItem,
	VItemGroup,
	VLabel,
	VLayout,
	VLazy,
	VList,
	VListItem,
	VListItemTitle,
	VListItemContent,
	VListItemSubtitle,
	VMenu,
	VMessages,
	VNavigationDrawer,
	VOverflowBtn,
	VOverlay,
	VPagination,
	VParallax,
	VPicker,
	VProgressCircular,
	VProgressLinear,
	VRadio,
	VRadioGroup,
	VRangeSlider,
	VRating,
	VResponsive,
	VRow,
	VSelect,
	VSheet,
	VSkeletonLoader,
	VSlideGroup,
	VSlider,
	VSnackbar,
	VSpacer,
	VSparkline,
	VSpeedDial,
	VStepper,
	VSubheader,
	VSwitch,
	VSystemBar,
	VTabs,
	VTab,
	VTabsItems,
	VTabItem,
	VTextField,
	VTextarea,
	VThemeProvider,
	VTimePicker,
	VTimeline,
	VToolbar,
	VToolbarTitle,
	VToolbarItems,
	VTooltip,
	VTreeview,
	VWindow,
	VStepperHeader,
	VStepperContent,
	VStepperItems,
	VStepperStep
} from 'vuetify/lib';
import { Ripple } from 'vuetify/lib/directives';
import './fontawesome';

Vue.use(Vuetify, {
	components: {
		VAlert,
		VApp,
		VAppBar,
		VAppBarNavIcon,
		VAutocomplete,
		VAvatar,
		VBadge,
		VBanner,
		VBottomNavigation,
		VBottomSheet,
		VBreadcrumbs,
		VBreadcrumbsItem,
		VBtn,
		VBtnToggle,
		VCalendar,
		VCard,
		VCardActions,
		VCardSubtitle,
		VCardText,
		VCardTitle,
		VCarousel,
		VCarouselItem,
		VCheckbox,
		VChip,
		VChipGroup,
		VCol,
		VColorPicker,
		VCombobox,
		VContainer,
		VContent,
		VCounter,
		VData,
		VDataIterator,
		VDataTable,
		VDatePicker,
		VDialog,
		VDivider,
		VExpansionPanels,
		VExpansionPanel,
		VExpansionPanelHeader,
		VExpansionPanelContent,
		VExpandTransition,
		VFileInput,
		VFlex,
		VFooter,
		VForm,
		VHover,
		VIcon,
		VImg,
		VInput,
		VItem,
		VItemGroup,
		VLabel,
		VLayout,
		VLazy,
		VList,
		VListItem,
		VListItemTitle,
		VListItemContent,
		VListItemSubtitle,
		VMenu,
		VMessages,
		VNavigationDrawer,
		VOverflowBtn,
		VOverlay,
		VPagination,
		VParallax,
		VPicker,
		VProgressCircular,
		VProgressLinear,
		VRadio,
		VRadioGroup,
		VRangeSlider,
		VRating,
		VResponsive,
		VRow,
		VSelect,
		VSheet,
		VSkeletonLoader,
		VSlideGroup,
		VSlider,
		VSnackbar,
		VSpacer,
		VSparkline,
		VSpeedDial,
		VStepper,
		VSubheader,
		VSwitch,
		VSystemBar,
		VTabs,
		VTab,
		VTabsItems,
		VTabItem,
		VTextField,
		VTextarea,
		VThemeProvider,
		VTimePicker,
		VTimeline,
		VToolbar,
		VToolbarTitle,
		VToolbarItems,
		VTooltip,
		VTreeview,
		VWindow,
		VStepperHeader,
		VStepperContent,
		VStepperItems,
		VStepperStep
	},
	directives: {
		Ripple
	}
});

export default new Vuetify({
	// https://vuetifyjs.com/en/features/breakpoints/
	// md and lg require values 16px greater
	breakpoint: {
		thresholds: {
			xs: 768,
			sm: 1024,
			md: 1296,
			lg: 1624
		}
	},
	icons: {
		iconfont: 'faSvg',
		values: {
			sclExample: {
				component: 'font-awesome-icon',
				props: {
					icon: ['fas', 'skull']
				}
			},
			faAt: {
				component: 'font-awesome-icon',
				props: {
					icon: ['fal', 'at']
				}
			},
			faArrowToBottom: {
				component: 'font-awesome-icon',
				props: {
					icon: ['far', 'arrow-to-bottom']
				}
			},
			faBell: {
				component: 'font-awesome-icon',
				props: {
					icon: ['fal', 'bell']
				}
			},
			faAngleDown: {
				component: 'font-awesome-icon',
				props: {
					icon: ['fal', 'angle-down']
				}
			},
			faAngleUp: {
				component: 'font-awesome-icon',
				props: {
					icon: ['fal', 'angle-up']
				}
			},
			faArrowLeft: {
				component: 'font-awesome-icon',
				props: {
					icon: ['fas', 'arrow-left']
				}
			},
			faArrowRight: {
				component: 'font-awesome-icon',
				props: {
					icon: ['fas', 'arrow-right']
				}
			},
			faArrowRightReg: {
				component: 'font-awesome-icon',
				props: {
					icon: ['far', 'arrow-right']
				}
			},
			faBars: {
				component: 'font-awesome-icon',
				props: {
					icon: ['fas', 'bars']
				}
			},
			faCaretDown: {
				component: 'font-awesome-icon',
				props: {
					icon: ['fas', 'caret-down']
				}
			},
			faCaretRight: {
				component: 'font-awesome-icon',
				props: {
					icon: ['fas', 'caret-right']
				}
			},
			faCalendar: {
				component: 'font-awesome-icon',
				props: {
					icon: ['fal', 'calendar']
				}
			},
			faCheck: {
				component: 'font-awesome-icon',
				props: {
					icon: ['fal', 'check']
				}
			},
			faCheckCircle: {
				component: 'font-awesome-icon',
				props: {
					icon: ['fas', 'check-circle']
				}
			},
			faCheckSquare: {
				component: 'font-awesome-icon',
				props: {
					icon: ['fas', 'check-square']
				}
			},
			faCircle: {
				component: 'font-awesome-icon',
				props: {
					icon: ['far', 'circle']
				}
			},
			faChevronDown: {
				component: 'font-awesome-icon',
				props: {
					icon: ['fas', 'chevron-down']
				}
			},
			faChevronLeft: {
				component: 'font-awesome-icon',
				props: {
					icon: ['fas', 'chevron-left']
				}
			},
			faChevronRight: {
				component: 'font-awesome-icon',
				props: {
					icon: ['fas', 'chevron-right']
				}
			},
			faChevronUp: {
				component: 'font-awesome-icon',
				props: {
					icon: ['fas', 'chevron-up']
				}
			},
			faClipboardListCheck: {
				component: 'font-awesome-icon',
				props: {
					icon: ['fal', 'clipboard-list-check']
				}
			},
			faClock: {
				component: 'font-awesome-icon',
				props: {
					icon: ['fas', 'clock']
				}
			},
			faComments: {
				component: 'font-awesome-icon',
				props: {
					icon: ['fal', 'comments']
				}
			},
			faDotCircle: {
				component: 'font-awesome-icon',
				props: {
					icon: ['far', 'dot-circle']
				}
			},
			faDownload: {
				component: 'font-awesome-icon',
				props: {
					icon: ['far', 'download']
				}
			},
			faEnvelope: {
				component: 'font-awesome-icon',
				props: {
					icon: ['far', 'envelope']
				}
			},
			faEllipsisV: {
				component: 'font-awesome-icon',
				props: {
					icon: ['fas', 'ellipsis-v']
				}
			},
			faExclamation: {
				component: 'font-awesome-icon',
				props: {
					icon: ['fas', 'exclamation-triangle']
				}
			},
			faExclamationCircle: {
				component: 'font-awesome-icon',
				props: {
					icon: ['fas', 'exclamation-circle']
				}
			},
			faFileExcel: {
				component: 'font-awesome-icon',
				props: {
					icon: ['far', 'file-excel']
				}
			},
			faExternalLink: {
				component: 'font-awesome-icon',
				props: {
					icon: ['fas', 'external-link-alt']
				}
			},
			faExternalLinkSquare: {
				component: 'font-awesome-icon',
				props: {
					icon: ['fas', 'external-link-square-alt']
				}
			},
			faFile: {
				component: 'font-awesome-icon',
				props: {
					icon: ['far', 'file-alt']
				}
			},
			faFacebook: {
				component: 'font-awesome-icon',
				props: {
					icon: ['fab', 'facebook-f']
				}
			},
			faGlobe: {
				component: 'font-awesome-icon',
				props: {
					icon: ['fas', 'globe']
				}
			},
			faHandReceiving: {
				component: 'font-awesome-icon',
				props: {
					icon: ['fas', 'hand-receiving']
				}
			},
			faHeadset: {
				component: 'font-awesome-icon',
				props: {
					icon: ['far', 'user-headset']
				}
			},
			faInfo: {
				component: 'font-awesome-icon',
				props: {
					icon: ['fal', 'info-circle']
				}
			},
			faInstagram: {
				component: 'font-awesome-icon',
				props: {
					icon: ['fab', 'instagram']
				}
			},
			faLinkedin: {
				component: 'font-awesome-icon',
				props: {
					icon: ['fab', 'linkedin']
				}
			},
			faLinkedinIn: {
				component: 'font-awesome-icon',
				props: {
					icon: ['fab', 'linkedin-in']
				}
			},
			faList: {
				component: 'font-awesome-icon',
				props: {
					icon: ['fas', 'list']
				}
			},
			faMapMarker: {
				component: 'font-awesome-icon',
				props: {
					icon: ['fal', 'map-marker-alt']
				}
			},
			faMinus: {
				component: 'font-awesome-icon',
				props: {
					icon: ['fal', 'minus']
				}
			},
			faFilePDF: {
				component: 'font-awesome-icon',
				props: {
					icon: ['far', 'file-pdf']
				}
			},
			faPencil: {
				component: 'font-awesome-icon',
				props: {
					icon: ['far', 'pencil-alt']
				}
			},
			faPhone: {
				component: 'font-awesome-icon',
				props: {
					icon: ['fal', 'phone']
				}
			},
			faPlus: {
				component: 'font-awesome-icon',
				props: {
					icon: ['fal', 'plus']
				}
			},
			faPinterest: {
				component: 'font-awesome-icon',
				props: {
					icon: ['fab', 'pinterest']
				}
			},
			faPrint: {
				component: 'font-awesome-icon',
				props: {
					icon: ['far', 'print']
				}
			},
			faSearch: {
				component: 'font-awesome-icon',
				props: {
					icon: ['fal', 'search']
				}
			},
			faSearchBold: {
				component: 'font-awesome-icon',
				props: {
					icon: ['fas', 'search']
				}
			},
			faSearchPlus: {
				component: 'font-awesome-icon',
				props: {
					icon: ['fal', 'search-plus']
				}
			},
			faShoppingCart: {
				component: 'font-awesome-icon',
				props: {
					icon: ['fal', 'shopping-cart']
				}
			},
			faSquare: {
				component: 'font-awesome-icon',
				props: {
					icon: ['far', 'square']
				}
			},
			faSlidersH: {
				component: 'font-awesome-icon',
				props: {
					icon: ['fas', 'sliders-h']
				}
			},
			faStar: {
				component: 'font-awesome-icon',
				props: {
					icon: ['far', 'star']
				}
			},
			faTags: {
				component: 'font-awesome-icon',
				props: {
					icon: ['far', 'tags']
				}
			},
			faTimes: {
				component: 'font-awesome-icon',
				props: {
					icon: ['fas', 'times']
				}
			},
			faTimesCircle: {
				component: 'font-awesome-icon',
				props: {
					icon: ['fas', 'times-circle']
				}
			},
			faThLarge: {
				component: 'font-awesome-icon',
				props: {
					icon: ['fas', 'th-large']
				}
			},
			faTrashAlt: {
				component: 'font-awesome-icon',
				props: {
					icon: ['far', 'trash-alt']
				}
			},
			faTwitter: {
				component: 'font-awesome-icon',
				props: {
					icon: ['fab', 'twitter']
				}
			},
			faUser: {
				component: 'font-awesome-icon',
				props: {
					icon: ['far', 'user']
				}
			},
			faUserChart: {
				component: 'font-awesome-icon',
				props: {
					icon: ['fal', 'user-chart']
				}
			},
			faVideo: {
				component: 'font-awesome-icon',
				props: {
					icon: ['fal', 'video']
				}
			},
			faWindowClose: {
				component: 'font-awesome-icon',
				props: {
					icon: ['fas', 'window-close']
				}
			},
			faFileWord: {
				component: 'font-awesome-icon',
				props: {
					icon: ['far', 'file-word']
				}
			},
			faYoutube: {
				component: 'font-awesome-icon',
				props: {
					icon: ['fab', 'youtube']
				}
			},
			faPencilAlt: {
				component: 'font-awesome-icon',
				props: {
					icon: ['fal', 'pencil-alt']
				}
			}
		}
	},
	theme: {
		themes: {
			light: {
				accent: '#2d373e',
				error: '#c92d0e',
				info: '#2d373e',
				primary: '#1D4789',
				primaryDark: '#012169',
				secondary: '#F3C828',
				success: '#088921',
				warning: '#e8ad16',
				backgroundColor: '#EBEBED'
			}
		}
	}
});
