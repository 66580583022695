/**
 * SclStoreList
 */

// template
const template = `
	<div>
		<v-row>	
			<v-col cols="12">
				<scl-tags :api="storeTags" @emit-tags-remove="handleTagRemove"> </scl-tags>
			</v-col>		
			<v-col v-for="(store, index) in stores" :key="index" cols="12" :sm="2" :md="4">
				<v-lazy>
					<v-card class="scl-store-list__card" :ripple="false" flat :href="store.Url">		
						<v-img
							:alt="store.Name"
							:aspect-ratio="4/3"
							:lazy-src="$root.lazyImg"
							:src="store.Image"
						>
						</v-img>
						<div class="scl-store-list__card-content">
							<v-card-title><h3 class="scl-store-list__card-title">{{ store.Name }}</h3></v-card-title>
							<v-card-text class="scl-store-list__card-text">
								{{ store.Address }}
							</v-card-text>
						</div>
					</v-card>
				</v-lazy>				
			</v-col>
			<!-- pagination -->
			<v-col cols="12" v-if="pageCount > 1">
				<scl-pagination
					class="scl-component scl-pagination"
					:is-disabled="isLoading"
					:total-pages="pageCount"
					:current-page="pagination.currentPage"
					:visible-pages="9"
					@page-change="handlePageChange"
				></scl-pagination>
			</v-col>

		</v-row>
	</div>
`;

// export template
export default template;
