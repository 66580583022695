// @vue/component
const component = {
	name: 'SclSpacer',
	props: {
		api: {
			type: Object,
			required: false,
			validator: (obj) => {
				const set = new Set();
				set.add(!!(obj.size ? typeof obj.size === 'string' : true));
				return !set.has(false);
			}
		}
	},
	computed: {
		getClass() {
			return this.api && this.api.size ? `scl-spacer--${this.api.size}` : 'scl-spacer--medium';
		}
	},
	template: null
};

// set template and dummy data if development environment variable string
// if (process.env.VUE_APP_DEVELOPMENT === 'true') {
component.template = require(`./${component.name}-template`).default;
// }

// css import
require(`./_${component.name}.scss`);

// export component
export default component;
