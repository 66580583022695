// @vue/component
import { mapGetters } from 'vuex';

const component = {
	name: 'SclHeader',
	props: {
		api: {
			type: Object,
			required: false,
			// props type validation
			validator: (obj) => {
				const set = new Set();
				set.add(!!(obj.logo ? typeof obj.logo === 'boolean' : true));
				set.add(!!(obj.name ? typeof obj.name === 'string' : true));
				set.add(!!(obj.search ? typeof obj.search === 'boolean' : true));
				set.add(!!(obj.profile ? typeof obj.profile === 'boolean' : true));
				set.add(!!(obj.cart ? typeof obj.cart === 'boolean' : true));
				return !set.has(false);
			}
		}
	},
	data() {
		return {
			panel: false,
			drawer: false,
			isActive: false,
			searchQuery: null
		};
	},
	computed: {
		...mapGetters(['getAuth']),
		// props defaults
		propApi() {
			return {
				logo: true,
				name: 'null',
				icon: '$vuetify.icons.faBars',
				closeIcon: '$vuetify.icons.faTimes',
				searchIcon: '$vuetify.icons.faSearchBold',
				profileIcon: '$vuetify.icons.faUser',
				search: true,
				profile: true,
				cart: true,
				...this.api
			};
		}
	},
	methods: {
		gotoUrl(url) {
			if (!url) {
				return;
			}
			window.location.href = url;
		},
		handleSearch(url) {
			if (!url) {
				return;
			}
			if (!this.searchQuery.length > 1 || this.searchQuery === null) {
				return;
			}
			window.location.href = `${url}?q=${this.searchQuery}`;
		},
		setActiveMenu(val) {
			this.isActive = val;
		}
	},
	template: null
};

// set template and dummy data if development environment variable string
if (process.env.VUE_APP_DEVELOPMENT === 'true') {
	component.template = require(`./${component.name}-template`).default;
}

// css import
require(`./_${component.name}.scss`);

// export component
export default component;
