/**
 * SclAuthForgotPassword
 */

// template
const template = `
	<div>
		<v-btn class="scl-auth-forgot-password__btn-text" color="primaryDark" text @click="forgotPassword">
			<v-icon color="primaryDark" left>$vuetify.icons.faArrowLeft</v-icon>
			Back to login
		</v-btn>
		<h5>Forgot password</h5>
		<p>lorem ipsum</p>
		<!-- email -->
		<label class="scl-checkout__form-label">Email</label>
		<v-text-field
			v-model="form.username"
			autocapitalize="off"
			autocomplete="email"
			autocorrect="off"
			:background-color="inputOptions.backgroundColor"
			:class="inputOptions.class"
			:color="inputOptions.color"
			:disabled="loading"
			:height="inputOptions.height"
			inputmode="email"
			:outlined="inputOptions.outlined"
			placeholder="name@domain.com"
			:rules="[inputRules.required, inputRules.email]"
			type="email"
			:error="error"
			:error-messages="validationMessage"
			@blur="handleEmailValidation"
		>
		</v-text-field>
		<v-btn @click="password" block class="scl-checkout__form-btn my-5" color="primary" depressed height="64" :disabled="!isValid || loading || !validated" :ripple="false">
			<span v-if="!loading">Forgot password</span>
			<v-icon v-if="!loading" right>$vuetify.icons.faArrowRight</v-icon>
			<v-progress-circular v-if="loading" color="primary" indeterminate :size="40" :width="5"></v-progress-circular>
		</v-btn>
		<v-alert class="scl-alert__alert scl-alert--flat" icon="$vuetify.icons.faHeadset" tile>
			<h6 class="scl-alert__title">Need some help?</h6>
			<p class="scl-alert__copy">{{$root.dummySentence}}</p>
		</v-alert>
	</div>
`;

// export template
export default template;
