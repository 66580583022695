// @vue/component
const component = {
	name: 'SclShippingDetails',
	props: {
		shipping: {
			type: Object
		}
	},
	data() {
		return {};
	},
	computed: {},
	methods: {
		edit() {
			this.$emit('emit-previous-step');
		}
	},
	template: null
};

// set template and dummy data if development environment variable string
// if (process.env.VUE_APP_DEVELOPMENT === 'true') {
component.template = require(`./${component.name}-template`).default;
// }

// css import
require(`./_${component.name}.scss`);

// export component
export default component;
