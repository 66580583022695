import productApi from '@/_scl-fed/services/api/product';
import _chunk from 'lodash/chunk';

// @vue/component
const component = {
	name: 'SclProductLatest',
	props: {
		api: {
			type: Object,
			required: false,
			// props type validation
			validator: (obj) => {
				const set = new Set();
				set.add(!!(obj.cycle ? typeof obj.cycle === 'boolean' : true));
				set.add(!!(obj.delimiterIcon ? typeof obj.delimiterIcon === 'string' : true));
				set.add(!!(obj.hideDelimiters ? typeof obj.hideDelimiters === 'boolean' : true));
				set.add(!!(obj.height ? typeof obj.height === 'string' : true));
				set.add(!!(obj.itemsPerSlide ? typeof obj.itemsPerSlide === 'number' : true));
				set.add(!!(obj.maxNumber ? typeof obj.maxNumber === 'number' : true));
				return !set.has(false);
			}
		}
	},
	data() {
		return {
			currentSlide: 0,
			products: []
		};
	},
	computed: {
		// props defaults
		propApi() {
			return {
				cycle: false,
				delimiterIcon: '$vuetify.icons.faCircle',
				hideDelimiters: false,
				height: 'auto',
				itemsPerSlide: 4,
				showArrows: false,
				maxNumber: 12,
				...this.api
			};
		},
		carouselGroups() {
			if (!this.products) return [];
			let slides;
			switch (this.$vuetify.breakpoint.name) {
				case 'sm':
				case 'md':
					slides = _chunk(this.products, 2);
					break;
				case 'lg':
				case 'xl':
					slides = _chunk(this.products, 4);
					break;
				default:
					slides = _chunk(this.products, 1);
			}
			return slides;
		}
	},
	mounted() {
		this.setNavigationVisibility();
		this.getLatestProducts();
	},
	methods: {
		image(val) {
			return process.env.VUE_APP_ROOT_DOMAIN + val[0];
		},
		getLatestProducts() {
			productApi
				.latestProducts(this.propApi.maxNumber)
				.then((response) => {
					this.products = response.Result;
				})
				.catch((err) => {
					this.processError('error', this.error.messageError, err);
				});
		},
		nextItem() {
			this.currentSlide += 1;
		},
		prevItem() {
			this.currentSlide -= 1;
		},
		setDelimiterVisibility() {
			if (this.api && this.api.hideDelimiters === true) {
				return;
			}
			if (this.carouselGroups.length > 1) {
				this.propApi.hideDelimiters = false;
			} else {
				this.propApi.hideDelimiters = true;
			}
		},
		setArrowVisibility() {
			if (this.api && this.api.showArrows === true) {
				return;
			}
			this.propApi.showArrows = this.carouselGroups.length > 1;
		},
		setNavigationVisibility() {
			// nexttick not working, still too soon
			const delay = (t) => new Promise((resolve) => setTimeout(resolve, t));
			delay(1000)
				.then(() => {
					this.setDelimiterVisibility();
					this.setArrowVisibility();
				})
				.then(() => {
					this.$forceUpdate();
				});
		}
	},
	template: null
};

// set template if storybook environment variable string
if (process.env.VUE_APP_DEVELOPMENT === 'true') {
	component.template = require(`./${component.name}-template`).default;
}

// css import
require(`./_${component.name}.scss`);

export default component;
