// @vue/component
const component = {
	name: 'SclImageBg',
	props: {
		api: {
			type: Object,
			required: false,
			// props type validation
			validator: (obj) => {
				const set = new Set();
				set.add(!!(obj.alt ? typeof obj.alt === 'string' : true));
				set.add(!!(obj.gradient ? typeof obj.gradient === 'boolean' : true));
				set.add(!!(obj.showInfo ? typeof obj.showInfo === 'boolean' : true));
				set.add(!!(obj.src ? typeof obj.src === 'string' : true));
				return !set.has(false);
			}
		}
	},
	computed: {
		// props defaults
		propApi() {
			return {
				alt: '',
				src: '',
				showInfo: true,
				...this.api
			};
		}
	},
	methods: {
		setGradient(gradient) {
			if (gradient) {
				return 'to bottom, rgba(0,0,0,0.1) 0%, rgba(0,0,0,0.5) 100%';
			}
			return undefined;
		}
	},
	template: null
};

// set template and dummy data if development environment variable string
if (process.env.VUE_APP_DEVELOPMENT === 'true') {
	component.template = require(`./${component.name}-template`).default;
}

// css import
require(`./_${component.name}.scss`);

// export component
export default component;
