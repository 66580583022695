/*
 * Text mixin
 */

const mxText = {
	methods: {
		_mxText_highlight(val, options) {
			if (val && val !== '') {
				setTimeout(() => {
					const keywords = val.split(' '); // split on space for keywords
					const potentials = document.querySelectorAll(options.target); // options.target = '[class*="scl-search-list-item__title"], [class*="scl-search-list-item__description"]'
					const { targetSpanClass } = options; // options.targetSpanClass = 'scl-search-bar__highlight'
					keywords.forEach((targetKeyword) => {
						const query = new RegExp(targetKeyword, 'gi');
						for (let i = 0; i < potentials.length; i++) {
							const e = potentials[i].innerHTML.replace(query, (str) => {
								return `<span class="${targetSpanClass}">${str}</span>`;
							});
							potentials[i].innerHTML = e;
						}
					});
				}, 300);
			}
		}
	}
};

export default mxText;
