/**
 * SclFooterSearch
 *
 */

// template
const template = `
	<div>
		<div class="scl-footer-search__item">
			<div class="scl-footer-search__title">
				<v-icon class="scl-footer-search__title-icon">$vuetify.icons.faSearch</v-icon>
				How can we help
			</div>
			<v-text-field
			light
			flat
			solo
			v-model="searchQuery"
			class="scl-footer-search__input"
			append-icon="$vuetify.icons.faSearch"
			placeholder="Search"
			@keyup.enter="handleSearch($root.dummyUrl)"
			@click:append="handleSearch($root.dummyUrl)"
			></v-text-field>
		</div>
	</div>
`;

// export template
export default template;
