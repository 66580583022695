/**
 * SclGuest
 */

// template
const template = `
	<div>
		<h3 class="scl-checkout__form-title"><span>Guest checkout</span></h3>
		<p class="mb-0">If you do not have a user login you can proceed as a guest.</p>
		<v-btn @click="nextStep" block class="btn btn-outlined scl-checkout__form-btn" color="#00573d" depressed height="64" outlined :ripple="false">
			<span>Continue as guest</span>
			<v-icon class="btn__icon-link" right>$vuetify.icons.faArrowRight</v-icon>
		</v-btn>
		<v-alert class="scl-alert__alert scl-alert--flat" icon="$vuetify.icons.faTags" tile>
			<p class="scl-alert__copy">Not a member yet? <a href="#">Sign up now</a> and save {{ getPrice.currencySymbol }}XXXX.xx on this purchase</p>
		</v-alert>
	</div>
`;

// export template
export default template;
