/**
 * SclFacets
 */

// template
const template = `
	<div>
		<v-dialog v-model="showDialog" class="scl-facets__dialog" content-class="scl-facets__dialog">
		<div class="scl-facets__filter" :class="isActive? 'scl-facets__filter--active': null">
		<div class="d-flex align-center justify-space-between">
			<h4 class="scl-facets__heading">{{$root.dummyWords}}</h4>
			<v-icon @click="showDialog = false" class="scl-facets__heading-icon">$vuetify.icons.faTimes</v-icon>
		</div>
				
		<v-expansion-panels accordion class="scl-facets__accordions" flat multiple v-model="panel">
			<!-- panel -->
			<v-expansion-panel class="scl-facets__accordion"  v-for="(facet, index) in groupedFacets" :key="index">
				<v-expansion-panel-header hide-actions  class="scl-facets__header">
					<div class="scl-facets__title">
						{{facet.displayName}}
					</div>
					<v-icon class="scl-facets__accordion-icon scl-facets__accordion-icon--minus">{{ propApi.expandedIcon }} </v-icon>
					<v-icon class="scl-facets__accordion-icon scl-facets__accordion-icon--plus">{{ propApi.collapsedIcon }}</v-icon>
				</v-expansion-panel-header>
				<v-expansion-panel-content class="scl-facets__content">
					<v-checkbox v-for="(facetValue, index) in filterFacetvalues(facet.facetValues)"
					v-model="facetValue.isSelected"	
					@change="updateCategories(facet.displayName, facet.Category,facetValue.label, $event)"
					class="scl-facets__check"
					:class="index > 4? 'scl-facets__check--hidden' : ''"
					:label="facetLabel(facetValue)"
					:key="facetValue.label"
					hide-details></v-checkbox>
				<a v-if="filterFacetvalues(facet.facetValues).length > 5" @click="showMore" class="scl-facets__show-more"></a>

				</v-expansion-panel-content>
			</v-expansion-panel>

		</v-expansion-panels>
		<div class="scl-facets__footer">
		<a @click="handleClearAll">Clear all</a>
			
			<v-btn @click="showDialog = false" :loading="isLoading" class="btn btn-outlined btn--large">
				Update
			</v-btn>
		</div>
		</div>
		</v-dialog>
	</div>
`;

// export template
export default template;
