import axios from 'axios';
import '@/_scl-fed/config/axios';

export default {
	// search products
	async productSearch(payload) {
		const response = await axios.post('/products/search', payload);
		return response.data;
	},
	// get latest products
	async latestProducts(payload) {
		const response = await axios.get(`/products/latest?maxresults=${payload}`);
		return response.data;
	}
};
