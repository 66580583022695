/**
 * SclStoreCategoryList
 */

// template
const template = `
	<div>
		<div v-for="(facet, index) in groupedFacets" :key="index">
			<div class="scl-store-category-list__item"  v-for="(item, index) in facet.facetValues" :key="index" :class="item.isSelected? 'scl-store-category-list__item--active' : ''"  v-if="item.quantity > 0">
				<a @click="updateCategories(facet.displayName, facet.Category, item.label, $event)"> {{ item.label }} </a>
				<div class=" scl-store-category-list__item scl-store-category-list__item-child"  v-for="(item, index) in facet.facetValues" :key="index" :class="item.isSelected? 'scl-store-category-list__item--active' : ''"  v-if="item.quantity > 0">
				<a @click="updateCategories(facet.displayName, facet.Category, item.label, $event)"> {{ item.label }} </a>
				</div>
			</div>
			</div>
		</div>
	</div>
`;

// export template
export default template;
