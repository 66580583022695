/**
 * SclSelectionCountryIcon api
 *
 * minWidth prop : empty value results in auto width
 * @param {number} minWidth
 *
 * maxWidth prop : empty value results in auto width
 * @param {number} maxWidth
 *
 * icon prop
 * @param {string} `$vuetify.icons.faGlobe`
 */

const template = `
	<div>
		<v-menu offset-y>
			<template v-slot:activator="scope">
				<button v-on="scope.on" class="v-btn v-btn--icon">
					<v-icon>{{propApi.icon}}</v-icon>
				</button>
			</template>

			<v-list>
				<v-list-item href="#">
					<v-list-item-title>{{$root.dummyWords}}</v-list-item-title>
				</v-list-item>
				<v-list-item href="#">
					<v-list-item-title>{{$root.dummyWords}}</v-list-item-title>
				</v-list-item>
				<v-list-item href="#">
					<v-list-item-title>{{$root.dummyWords}}</v-list-item-title>
				</v-list-item>
			</v-list>
		</v-menu>
	</div>
`;

export default template;
