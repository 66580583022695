import { mapGetters } from 'vuex';

// @vue/component
const component = {
	name: 'SclCartLineItems',
	data() {
		return {
			pattern: {
				quantity: /^[1-9]\d*$/
			},
			rules: {
				number: (val) => {
					return this.pattern.quantity.test(val) || 'enter a number';
				}
			}
		};
	},
	computed: {
		...mapGetters(['getCart', 'getLoading'])
	},
	methods: {
		getVariantId(product) {
			if (product.variants.length) {
				return product.variants[0].productVariantId;
			}
			return '';
		},
		decrementQuantity(productId, variantId, quantity) {
			let newQuantity = this.number(quantity);
			if (newQuantity === 1) {
				return;
			}
			newQuantity--;
			this.emitQuantity(productId, variantId, newQuantity);
		},
		emitQuantity(productId, variantId, quantity) {
			const newQuantity = this.number(quantity);
			if (!this.pattern.quantity.test(newQuantity)) {
				return;
			}
			this.$emit('emit-quantity', productId, variantId, newQuantity);
		},
		incrementQuantity(productId, variantId, quantity) {
			let newQuantity = this.number(quantity);
			newQuantity++;
			this.emitQuantity(productId, variantId, newQuantity);
		},
		number(val) {
			return Number(val);
		},
		removeItem(id) {
			this.$emit('emit-remove-item', id);
		},
		resolveImage(image) {
			return process.env.VUE_APP_ROOT_DOMAIN + image;
		}
	},
	template: null
};

// set template and dummy data if development environment variable string
// if (process.env.VUE_APP_DEVELOPMENT === 'true') {
component.template = require(`./${component.name}-template`).default;
// }

// css import
require(`./_${component.name}.scss`);

// export component
export default component;
