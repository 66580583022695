// @vue/component
const component = {
	name: 'SclAwardCategoriesList',
	props: {
		types: {
			type: Array
		}
	},
	data() {
		return {
			defaultSelected: {
				key: ''
			}
		};
	},
	computed: {
		getTypes() {
			const queryParams = new URLSearchParams(window.location.search);
			const myParam = queryParams.get('typeID');
			this.setDefaultValue(myParam);
			const filterTypes = this.types;
			filterTypes.unshift({ key: '', value: 'All' });
			return filterTypes;
		}
	},
	methods: {
		setDefaultValue(val) {
			if (val !== null) {
				this.defaultSelected.key = val;
			}
		},
		handleSelect(e) {
			const queryParams = new URLSearchParams(window.location.search);
			queryParams.set('typeID', e);
			// eslint-disable-next-line
			history.replaceState(null, null, `?${queryParams.toString()}`);
			window.location.reload();
		}
	},
	template: null
};

// set template and dummy data if development environment variable string
if (process.env.VUE_APP_DEVELOPMENT === 'true') {
	component.template = require(`./${component.name}-template`).default;
}

// css import
require(`./_${component.name}.scss`);

// export component
export default component;
