/**
 * SclSocialShare api
 *
 * buttonColor prop
 * @param {string} '#333333'
 *
 * vertical prop
 * @param {string} 'top'
 * @param {string} 'bottom'
 *
 * horizontal prop
 * @param {string} 'left'
 * @param {string} 'right'
 *
 * timeDelay prop - auto close after time
 * @param {number} 6000
 *
 * snackColor prop
 * @param {boolean} '#0000ff'
 *
 * snackButtonColor prop
 * @param {boolean} '#ffffff'
 * */

const template = `
		<div>
		<v-btn :ripple="false" class="btn   btn-primary " block :color="propApi.buttonColour" dark @click="snackbar = true">
				{{ $root.dummyWords }}
			</v-btn>

			<v-snackbar
				v-model="snackbar"
				:color="propApi.snackColour"
				:timeout="propApi.timeDelay"
				:bottom="propApi.vertical === 'bottom'"
				:left="propApi.horizontal === 'left'"
				:right="propApi.horizontal === 'right'"
				:top="propApi.vertical === 'top'"
			>
				{{ $root.dummyParagraph }}
				<v-btn :ripple="false" class="btn btn-primary " text @click="snackbar = false" :color="propApi.snackButtonColour">
					Close
				</v-btn>
			</v-snackbar>
		</div>

`;

export default template;
