import truncate from 'truncate-html';

// @vue/component
const component = {
	name: 'SclProductCollectionSummary',
	props: {
		api: {
			type: Object,
			// props type validation
			validator: (obj) => {
				const set = new Set();
				set.add(!!(obj.characterNumber ? typeof obj.characterNumber === 'number' : true));
				set.add(!!(obj.variant ? typeof obj.variant === 'boolean' : true));
				set.add(!!(obj.url ? typeof obj.url === 'string' : true));
				return !set.has(false);
			}
		},
		description: {
			type: String
		}
	},
	data() {
		return {
			showDescription: false,
			descriptionLength: 0
		};
	},
	computed: {
		// props defaults
		propApi() {
			return {
				characterNumber: 180,
				variant: false,
				url: '',
				...this.api
			};
		}
	},
	methods: {
		truncatedText() {
			this.descriptionLength = truncate(this.description, { stripTags: true }).length; // strip tags to get true description length
			truncate.setup({ stripTags: false, length: this.propApi.characterNumber });
			return truncate(this.description);
		},
		handleReadMore() {
			this.showDescription = !this.showDescription;
		}
	},
	template: null
};
// set template and dummy data if development environment variable string
if (process.env.VUE_APP_DEVELOPMENT === 'true') {
	component.template = require(`./${component.name}-template`).default;
}

// css import
require(`./_${component.name}.scss`);

// export component
export default component;
