/**
 * SclCategoryAccordion
 */

// template
const template = `
	<div>
		<h5 class="scl-category-accordion__title">{{$root.dummyWords}}</h5>
		<v-expansion-panels accordion class="scl-category-accordion__accordions" flat :multiple="propApi.multiple" ref="panels" v-model="panel">
			<!-- panel -->
			<v-expansion-panel class="scl-category-accordion__accordion">
				<v-expansion-panel-header expand-icon="$vuetify.icons.faCaretDown" ref="panelHeader"  class="scl-category-accordion__header">
					{{$root.dummyWords}}
				</v-expansion-panel-header>
				<v-expansion-panel-content class="scl-category-accordion__content">
					<a href="#" class="scl-category-accordion__link">{{$root.dummyWords}}</a>
					<a href="#" class="scl-category-accordion__link">{{$root.dummyWords}}</a>
					<a href="#" class="scl-category-accordion__link">{{$root.dummyWords}}</a>
					<a href="#" class="scl-category-accordion__link">{{$root.dummyWords}}</a>
				</v-expansion-panel-content>
			</v-expansion-panel>
			<!-- panel -->
			<v-expansion-panel class="scl-category-accordion__accordion" expand>
				<v-expansion-panel-header expand-icon="$vuetify.icons.faCaretDown" class="scl-category-accordion__header">
					simon
				</v-expansion-panel-header>
				<v-expansion-panel-content class="scl-category-accordion__content">
					<a href="#" class="scl-category-accordion__link">{{$root.dummyWords}}</a>
					<a href="#" class="scl-category-accordion__link">{{$root.dummyWords}}</a>
					Amet consectetur
					<a href="#" class="scl-category-accordion__link">{{$root.dummyWords}}</a>
				</v-expansion-panel-content>
			</v-expansion-panel>
			<!-- panel -->
			<v-expansion-panel class="scl-category-accordion__accordion">
				<v-expansion-panel-header expand-icon="$vuetify.icons.faCaretDown" class="scl-category-accordion__header">
				Amet consectetur
				</v-expansion-panel-header>
				<v-expansion-panel-content class="scl-category-accordion__content">
					<a href="#" class="scl-category-accordion__link">{{$root.dummyWords}}</a>
					<a href="#" class="scl-category-accordion__link">{{$root.dummyWords}}</a>
					<a href="#" class="scl-category-accordion__link">{{$root.dummyWords}}</a>
					<a href="#" class="scl-category-accordion__link">{{$root.dummyWords}}</a>
				</v-expansion-panel-content>
			</v-expansion-panel>
		</v-expansion-panels>
	</div>
`;

// export template
export default template;
