/**
 * SclImageSrcset api
 *
 * alt prop
 * @param {string} alt text
 *
 * gradient prop
 * @param {boolean} true || false
 *
 * src prop
 * @param {string} default large img src
 *
 * srcsetSmall prop
 * @param {string} small srcset image src
 *
 * srcsetLarge prop
 * @param {string} large srcset image src
 *
 * showInfo prop
 * @param {boolean} showInfo - toggles rendering of container for overlay text
 */

// template
const template = `
	<div>
		<figure>
			<v-card :href="$root.dummyUrl">
				<v-img
					:alt="propApi.alt"
					lazy-src="assets/img/icons/apple-touch-icon.png"
					:src="propApi.src"
					:srcset="setSrcset(propApi.srcsetSmall, propApi.srcsetLarge)"
					class="scl-image__content"
				>
					<v-row v-if="propApi.showInfo">
						<v-spacer></v-spacer>
						<v-col :class="(propApi.gradient? 'scl-image__overlay--gradient' : null)" class="scl-image__overlay" cols="12">
							<div>{{ $root.dummyWords }}</div>
							<a :href="$root.dummyUrl" target="_top">{{ $root.dummyWords }}</a>
						</v-col>
					</v-row>

					<template v-slot:placeholder>
						<v-row class="fill-height ma-0" align="center" justify="center">
							<v-progress-circular indeterminate color="primary"></v-progress-circular>
						</v-row>
					</template>
				</v-img>
			</v-card>

			<figcaption class="scl-image__caption">{{ $root.dummyWords }}
			<a :href="$root.dummyUrl">{{ $root.dummyWords }}</a>
			</figcaption>
		</figure>
	</div>
`;

// export template
export default template;
