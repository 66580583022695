/**
 * SclProductListing
 */

// template
const template = `
	<div>
		<v-container>
			<v-row>
				<!-- sidebar -->
				<v-col cols="12" md="4" lg="3">
					<v-btn class="scl-product-listing__filter-button btn btn-outlined" @click="isActive = !isActive"><v-icon left>$vuetify.icons.faSlidersH</v-icon>Filters</v-btn>
					<div class="scl-product-listing__filter" :class="isActive? 'scl-product-listing__filter--active': null">
						<scl-product-search @emit-search="handleSearchQuery" :is-disabled="isLoading" :param="search" :key="search" class="scl-component scl-product-search"></scl-product-search>
						<scl-product-categories @emit-category-change="handleCategoryFilter" class="scl-component scl-product-categories" :is-disabled="isLoading" :categories="categories"></scl-product-categories>
						<scl-product-facets @emit-facet-change="handleFacetFilter" class="scl-component scl-product-facets" :is-disabled="isLoading" :facets="facets" :active-tags="productTags.tags" :facet-filter="facetFilter"></scl-product-facets>
					</div>
				</v-col>
				<!-- products -->
				<v-col cols="12" md="8" lg="9">
					<v-row>
						<v-col cols="12" md="8">
							<scl-tags :api="productTags" @emit-tags-remove="handleTagRemove"> </scl-tags>
						</v-col>
						<v-col cols="12" md="4">
							<div>
								<v-btn v-if="search || categoryFilter.length" class="scl-product-listing__clear" color="primaryDark" @click="clearFilters" text>
									<v-icon left>$vuetify.icons.faTimes</v-icon>
									Clear all filters
								</v-btn>
							</div>
						</v-col>
						<!-- sorting -->
						<v-col cols="12">
							<div class="scl-product-listing__sorting">
								<v-row>
									<v-col cols="12" md="6" class="scl-product-listing__sorting-col">
									<scl-product-page-size @emit-page-size="handlePageSize" :active-item="pagination.pageSize" :is-disabled="isLoading" class="scl-component scl-product-page-size"></scl-product-page-size>
									</v-col>
									<v-col cols="12" md="6" class="scl-product-listing__sorting-col">
										<scl-product-sort @emit-sorting="handleSortItems" :is-disabled="isLoading" class="scl-component scl-product-sort"></scl-product-sort>
									</v-col>
								</v-row>
							</div>
						</v-col>
						<!-- error / no results message -->
						<v-col v-if="hasErrored" cols="12">
							<div class="scl-component scl-alert">
								<v-alert border="bottom" class="scl-alert__alert" :class="'scl-alert--' + error.type" colored-border icon="$vuetify.icons.faExclamation" tile :type="error.type">
									<p class="scl-alert__copy">{{ error.message }}</p>
								</v-alert>
							</div>
						</v-col>
						<template v-else>
							<!-- loading spinner -->
							<v-col v-if="isLoading" cols="12" class="text-center">
								<v-progress-circular color="primary" indeterminate :size="50" :width="7"></v-progress-circular>
							</v-col>
							<template v-else>
								<v-col v-for="(product, index) in products" :key="index" cols="12" sm="6" lg="4">
									<!-- product cards -->
									<scl-product-card
										class="scl-component scl-product-card"
										:image-url="product.imageUrls !== null ? product.imageUrls : ''"
										:title="product.displayName"
										:list-price="product.listPrice"
										:adjusted-price="product.adjustedPrice"
										:product-id="product.productId"
										:stock-status="product.stockStatusName"
										:currency="product.currencySymbol"
										:description="product.shortDescription"
										productDetailPath="productDetailPath"
									></scl-product-card>
								</v-col>
							</template>
						</template>
						<!-- pagination -->
						<v-col cols="12" v-if="pageCount > 1">
							<scl-pagination
								class="scl-component scl-pagination"
								:is-disabled="isLoading"
								:total-pages="pageCount"
								:current-page="pagination.currentPage"
								:visible-pages="9"
								@page-change="handlePageChange"
							></scl-pagination>
						</v-col>

					</v-row>
				</v-col>

			</v-row>
		</v-container>
	</div>
`;

// export template
export default template;
