/**
 * SclImageBg api
 *
 * alt prop
 * @param {string} alt text
 *
 * gradient prop
 * @param {boolean} true || false
 *
 * src prop
 * @param {string} img src
 *
 * showInfo prop
 * @param {boolean} showInfo - toggles rendering of container for overlay text
 */

// template
const template = `
	<div>
		<figure>
			<v-card :href="$root.dummyUrl">
				<v-img
					:alt="propApi.alt"
					:gradient="setGradient(api.gradient)"
					lazy-src="assets/img/icons/apple-touch-icon.png"
					:src="propApi.src"
				>
					<v-row v-if="propApi.showInfo" class="pa-2 fill-height">
						<v-spacer></v-spacer>
						<v-col class="scl-image__content shrink">
							<div>{{ $root.dummyWords }}</div>
							<a :href="$root.dummyUrl" target="_top">{{ $root.dummyWords }}</a>
						</v-col>
					</v-row>

					<template v-slot:placeholder>
						<v-row class="fill-height ma-0" align="center" justify="center">
							<v-progress-circular indeterminate color="primary"></v-progress-circular>
						</v-row>
					</template>
				</v-img>
			</v-card>
			<figcaption>{{ $root.dummyWords }}</figcaption>
		</figure>
	</div>
`;

// export template
export default template;
