/**
 * SclTimePicker api
 *
 *
 *  pickerIcon prop
 * @param {string} '$vuetify.icons.faClock'
 *
 * */

const template = `
	<div>
		<v-row>
			<v-menu
				ref="menu"
				v-model="subMenu"
				:close-on-content-click="false"
				:nudge-right="40"
				:return-value.sync="time"
				transition="scale-transition"
				offset-y
				max-width="320px"
				min-width="320px"
			>
				<template v-slot:activator="scope">
					<v-text-field v-model="time" :prepend-icon="propApi.pickerIcon" readonly v-on="scope.on"></v-text-field>
				</template>
				<v-time-picker v-if="subMenu" v-model="time" full-width @click:minute="$refs.menu.save(time)"></v-time-picker>
			</v-menu>
		</v-row>
	</div>
`;

export default template;
