import { String } from 'core-js';
import { mapGetters } from 'vuex';
import mxAnalytics from '@/_scl-fed/mixins/global/mxAnalytics';

// @vue/component
const component = {
	name: 'SclAuthLoginForm',
	mixins: [mxAnalytics],
	props: {
		errorMessage: {
			type: String
		},
		memberIdErrorMessage: {
			type: String
		},
		inputOptions: {
			type: Object
		},
		inputRules: {
			type: Object
		},
		loading: {
			type: Boolean
		},
		pattern: {
			type: Object
		},
		redirectUrl: {
			type: String
		}
	},
	data() {
		return {
			form: {
				username: null,
				password: null,
				rememberMe: false
			},
			error: this.errorMessage
		};
	},
	computed: {
		...mapGetters(['getAuthError', 'getAuth', 'getMemberStatus']),
		isValid() {
			return this.notEmpty && this.validEmail;
		},
		notEmpty() {
			return !!(this.form.username && this.form.password);
		},
		validEmail() {
			return this.pattern.email.test(this.form.username);
		}
	},
	methods: {
		forgotPassword() {
			this.$emit('emit-forgot-password', true);
		},
		submitAnalytics() {
			if (this.$gtmEnabled) {
				this.$gtmEventBus.$emit(
					'gtm-custom-event',
					this.$gtm,
					{
						event: this._mxAnalytics_getMemberEventTag(this.getMemberStatus),
						category: this._mxAnalytics_getMemberAction(this.getMemberStatus),
						action: 'Login',
						label: 'Home'
					},
					this.$route
				);
			}
		},
		login() {
			this.$store.dispatch('authApiLogIn', this.form).then((response) => {
				this.error = this.errorMessage; // reset the error message to the original;

				// replace the error message if memberId errors
				if (response.data.Result.MemberIdError) {
					this.error = this.memberIdErrorMessage;
					this.$store.dispatch('authSetAuthError', response.data.Result.MemberIdError);
				}

				// if all is good, continue with redirect
				if (this.getAuth) {
					this.$root.$emit('emitCloseLoginModal');
					this.submitAnalytics();
					if (this.redirectUrl !== '') {
						window.location = this.redirectUrl;
					} else {
						window.location.reload();
					}
				}
			});
		},
		toggleRememberMe() {
			this.form.rememberMe = !this.form.rememberMe;
		}
	},
	template: null
};

// set template and dummy data if development environment variable string
// if (process.env.VUE_APP_DEVELOPMENT === 'true') {
component.template = require(`./${component.name}-template`).default;
// }

// css import
require(`./_${component.name}.scss`);

// export component
export default component;
