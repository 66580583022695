/**
 * SclCartLineItems
 */

// template
const template = `
	<div>
		<h1 class="scl-cart-line-items__title">Shopping cart</h1>
		<ul v-if="$vuetify.breakpoint.smAndUp" class="scl-cart-line-items__header">
			<li>Product</li>
			<li>Price</li>
			<li>Quantity</li>
			<li>Total</li>
		</ul>
		<div v-for="(item, itemIndex) in getCart" class="scl-cart-line-items__item" :key="itemIndex">
			<div class="scl-cart-line-items__details">
				<a class="d-inline-block" :href="'/products/detail?pid=' + item.product.productId"><v-img :alt="item.product.displayName" aspect-ratio="1" class="scl-cart-line-items__image" :lazy-src="$root.lazyImg" :src="resolveImage(item.product.imageUrls[0])"></v-img></a>
				<div>
					<a :href="'/products/detail?pid=' + item.product.productId"><h3 class="scl-cart-line-items__name">{{ item.product.displayName }}</h3></a>
					<ul class="scl-cart-line-items__properties" v-if="item.product.variants.length">
						<li v-if="item.product.variants[0].variantProperties.color">Colour: {{ item.product.variants[0].variantProperties.color }}</li>
						<li v-if="item.product.variants[0].variantProperties.size">Size: {{ item.product.variants[0].variantProperties.size }}</li>
					</ul>
				</div>
			</div>
			<div v-if="$vuetify.breakpoint.smAndUp" class="scl-cart-line-items__price">
				{{ item.product.currencySymbol }}{{ item.temp.product.price.amount  | asCurrency }}
			</div>
			<div class="scl-cart-line-items__quantity">
				<v-text-field
					v-model="item.quantity"
					append-icon="$vuetify.icons.faPlus"
					background-color="#f4f4f4"
					class="scl-cart-line-items__input"
					color="accent"
					:disabled="getLoading"
					height="64"
					min="1"
					pattern="[1-9]*"
					prepend-inner-icon="$vuetify.icons.faMinus"
					:rules="[rules.number]"
					step="1"
					type="number"
					@blur="emitQuantity(item.product.productId, getVariantId(item.product), $event.target.value)"
					@click:append="incrementQuantity(item.product.productId, getVariantId(item.product), item.quantity)"
					@click:prepend-inner="decrementQuantity(item.product.productId, getVariantId(item.product), item.quantity)"
					@keyup.enter="emitQuantity(item.product.productId, getVariantId(item.product), $event.target.value)"
				>
				</v-text-field>
			</div>
			<div class="scl-cart-line-items__total">
				<div>
					{{ item.product.currencySymbol }}{{ item.price.subtotal | asCurrency }}
				</div>
				<div>
					<v-btn class="scl-cart-line-items__remove" color="#00573D" :disabled="getLoading" small text @click="removeItem(item.id)">
						<v-icon color="#00573D" :left="$vuetify.breakpoint.mdAndUp" size="14">$vuetify.icons.faTrashAlt</v-icon>
						<span v-if="$vuetify.breakpoint.mdAndUp">Remove</span>
					</v-btn>
				</div>
			</div>
		</div>
	</div>
`;

// export template
export default template;
