import commerceApi from '@/_scl-fed/services/api/commerce';
import { mapGetters } from 'vuex';
import { SclCartAdd, SclCartNotification } from './cart/index';

// @vue/component
const component = {
	name: 'SclProductDetail',
	components: { SclCartAdd, SclCartNotification },
	data() {
		return {
			hasErrored: false,
			isLoading: false,
			product: null,
			productId: process.env.NODE_ENV === 'development' ? 'WOC' : null
		};
	},
	computed: {
		...mapGetters(['getError', 'getToast']),
		setProductId() {
			return `${this.productId}`;
		},
		variantId() {
			return this.product.variants.length ? this.product.variants[0].productVariantId : '';
		}
	},
	created() {
		this.initialFetch();
	},
	mounted() {
		this.productGet();
	},
	methods: {
		handleQuantity(val) {
			const payload = {
				ProductId: this.productId,
				VariantId: this.variantId,
				Quantity: val
			};
			this.$store.dispatch('cartApiUpdate', payload, this).then((response) => {
				if (this.$gtmEnabled && response.status === 'ok') {
					this.$gtmEventBus.$emit('gtm-custom-event', this.$gtm, {
						event: 'Add to Cart',
						ecommerce: {
							add: {
								actionField: {
									list: 'Product Detail'
								},
								products: [
									{
										id: this.productId,
										name: this.product.displayName,
										variant: this.variantId,
										quantity: val
									}
								]
							}
						}
					});
				}
			});
		},
		initialFetch() {
			const params = new URLSearchParams(window.location.search.substring(1));
			const id = params.get('pid');
			// product has already been loaded
			if (this.product) {
				return;
			}
			// check for pid query string
			if (id) {
				this.productId = id;
			}

			if (!this.productId) {
				this.hasErrored = true;
				console.error('no product id param');
			}
		},
		submitAnalytics() {
			if (this.$gtmEnabled) {
				this.$gtmEventBus.$emit('gtm-custom-event', this.$gtm, {
					event: 'Product Detail',
					ecommerce: {
						detail: {
							actionField: {
								list: 'Product Detail'
							},
							products: [
								{
									id: this.product.productId,
									name: this.product.displayName
								}
							]
						}
					}
				});
			}
		},
		productGet() {
			// products have already been requested
			if (this.isLoading) {
				return;
			}
			this.isLoading = true;
			// get products
			commerceApi
				.productGet(this.setProductId)
				.then((response) => {
					this.product = response.data;
				})
				.catch((error) => {
					this.hasErrored = true;
					console.error(error);
				})
				.finally(() => {
					this.isLoading = false;
					this.submitAnalytics();
				});
		}
	},
	template: null
};

// set template and dummy data if development environment variable string
// if (process.env.VUE_APP_DEVELOPMENT === 'true') {
component.template = require(`./${component.name}-template`).default;
// }

// css import
require(`./_${component.name}.scss`);

// export component
export default component;
