/**
 * SclProductVariantTable
 */

// template
const template = `
	<div>
		<div class="v-data-table scl-product-variant-table__table theme--light">
			<div class="v-data-table__wrapper">

				<table>
					<thead class="v-data-table-header">
						<tr>							
							<th role="columnheader" scope="col" class="text-start sortable"><v-img :src="$root.dummyImage43" max-width="24" class="my-2 mx-auto"></v-img><span>{{$root.dummyWords}}</span></th>
							<th role="columnheader" scope="col" class="text-start sortable"><span>{{$root.dummyWords}}</span></th>							
							<th role="columnheader" scope="col" class="text-start sortable"><span>{{$root.dummyWords}}</span></th>	
						</tr>
					</thead>
					<tbody>						
						<tr>
								<td>
									{{$root.dummyWords}}
								</td>
								<td>
									{{$root.dummyWords}}
								</td>
								<td>
									{{$root.dummySentence}}
								</td>
						</tr>
						<tr>
								<td>
									{{$root.dummyWords}}
								</td>
								<td>
									{{$root.dummyWords}}
								</td>
								<td>
									{{$root.dummyWords}}
								</td>
						</tr>
						<tr>
								<td>
									{{$root.dummyWords}}
								</td>
								<td>
									{{$root.dummyWords}}
								</td>
								<td>
									{{$root.dummySentence}}
								</td>
						</tr>
					</tbody>
				</table>

			</div>
		</div>
	</div>
`;

// export template
export default template;
