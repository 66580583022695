/**
 * SclProduct
 */

// template
const template = `
	<div>
		<h1 class="scl-product__title">{{ name }}</h1>
		<span class="scl-product__price">Non-member price</span>
		<h3 class="mb-0" :class="isMember? 'scl-product__strike-through' : '' ">{{ currencySymbol }}{{ listPrice | asCurrency }}</h3>
		<v-alert border="bottom" class="scl-alert__alert scl-alert--contextual" colored-border :icon="false" tile type="warning">
			<div>
				<span class="scl-product__price">Member price</span>
				<h3 class="scl-alert__title">{{ currencySymbol }}{{ membersPrice | asCurrency }}</h3>
			</div>
			<p class="scl-alert__copy"><v-icon color="primaryDark" size="16">$vuetify.icons.faTags</v-icon> Not a member yet? <a href="#">Sign up now</a> and save {{ currencySymbol }}{{ membersSaving | asCurrency }} on this purchase.</p>
		</v-alert>
		<div class="scl-product__desc" v-html="longDescription"></div>
		<p><em><sup>*</sup>All prices include GST</em></p>
	</div>
`;

// export template
export default template;
