/**
 * SclInformationList
 *
 */

// template
const template = `
	<div>
		<v-list class="scl-information-list__container">
			<v-list-item class="scl-information-list__item">
				<div class="scl-information-list__row">
					<v-img
						:lazy-src="$root.dummyImage43"
						:src="$root.dummyImage43"
						aspect-ratio="1"
						position="top center"
						:max-width="48"
						:max-height="48"
						class="scl-information-list__col ml-2 mr-6"
					/>
					<div class="scl-information-list__col">
						<p class="scl-information-list__subtitle"> {{ $root.dummyWords }}</p>
						<h5 class="scl-information-list__title">{{ $root.dummyWords }}</h5>
					</div>
				</div>
			</v-list-item>
		</v-list>
	</div>
`;
// export template
export default template;
