/**
 * SclImageList
 *
 */

// template
const template = `
	<div>
		<v-list>
		<v-list-item class="scl-image-list__item" :class="propApi.divider? 'scl-image-list__item--divide' : null">
				<div class="scl-image-list__row" :class="propApi.verticleAlign? 'align-center' : 'align-start'">
					<v-img
						v-if="$vuetify.breakpoint.smAndUp"
						:lazy-src="$root.dummyImage43"
						:src="$root.dummyImage43"
						aspect-ratio="1"
						position="top center"
						:max-width="propApi.maxWidth"
						class="scl-image-list__col mr-6"
						:class="propApi.rounded? 'scl-image-list__rounded' : null"
					/>
					<div class="scl-image-list__col">
						<h4 class="scl-image-list__title headline">{{ $root.dummyWords }}</h4>
						<h6 class="scl-image-list__subtitle"> {{ $root.dummyWords }}</h6>
						<div>
							<p><a>link1</a></p>
							<p><a>link2</a></p>
							<p><span>{{ $root.dummyParagraph }}</span><a href="#" class="btn btn-link scl-image-list__link">{{ $root.dummyWord }}<v-icon class="btn__icon-link scl-image-list__icon"> $vuetify.icons.faArrowRight </v-icon></a></p>
						</div>
					</div>
				</div>
			</v-list-item>
			<!-- Item with linked image/title -->
			<v-list-item class="scl-image-list__item" :class="propApi.divider? 'scl-image-list__item--divide' : null">
				<div class="scl-image-list__row" :class="propApi.verticleAlign? 'align-center' : 'align-start'">
				<a href="" v-if="$vuetify.breakpoint.smAndUp" class="scl-image-list__col mr-6" :style="'max-width:'+propApi.maxWidth+'px;'">
					<v-img
						:lazy-src="$root.dummyImage43"
						:src="$root.dummyImage43"
						aspect-ratio="1"
						position="top center"
						:max-width="propApi.maxWidth"
						:class="propApi.rounded? 'scl-image-list__rounded' : null"
					/>
					</a>
					<div class="scl-image-list__col">
					<a href="">
						<h4 class="scl-image-list__title headline">{{ $root.dummyWords }}</h4>
						</a>
						<h6 class="scl-image-list__subtitle"> {{ $root.dummyWords }}</h6>
						<div>
						</div>
					</div>
				</div>
			</v-list-item>
		</v-list>
	</div>
`;
// export template
export default template;
