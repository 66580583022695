/**
 * SclFooterContact
 *
 */

// template
const template = `

	<div>
		<div class="scl-footer-contact__item">
			<div class="scl-footer-contact__title"><v-icon class="scl-footer-contact__title-icon">$vuetify.icons.faHeadset</v-icon> How can we help </div>
			<div class="scl-footer-contact__content">
				<p class="scl-footer-contact__number"><a href="tel:">1300 123 456 </a></p>
				<a href="mailto:" class="scl-footer-contact__link">Enquiries</a>
				<a href="mailto:" class="scl-footer-contact__link">Regional Officies</a>
				<a href="mailto:" class="scl-footer-contact__link">Media enquiries</a>
			</div>
		</div>
	</div>
`;

// export template
export default template;
