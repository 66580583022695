import Vue from 'vue';

import { library } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';

import {
	faAngleDown,
	faAngleUp,
	faAt,
	faBell,
	faCalendar,
	faCheck,
	faClipboardListCheck,
	faComments,
	faInfoCircle,
	faMapMarkerAlt,
	faMinus,
	faPhone,
	faPlus,
	faShoppingCart,
	faSearch,
	faSearchPlus,
	faUserChart,
	faVideo
} from '@fortawesome/pro-light-svg-icons';

import {
	faArrowRight as faArrowRightReg,
	faCircle,
	faDotCircle,
	faArrowToBottom,
	faDownload,
	faEnvelope,
	faSquare,
	faFilePdf,
	faFileExcel,
	faFileWord,
	faFileAlt,
	faPencilAlt,
	faPrint,
	faUser,
	faStar,
	faTags,
	faTrashAlt,
	faUserHeadset,
	faHeadset
} from '@fortawesome/pro-regular-svg-icons';

import {
	faArrowLeft,
	faArrowRight,
	faBars,
	faCaretDown,
	faCaretRight,
	faCheckCircle,
	faCheckSquare,
	faChevronDown,
	faChevronRight,
	faChevronLeft,
	faClock,
	faEllipsisV,
	faExclamationCircle,
	faExclamationTriangle,
	faExternalLinkAlt,
	faExternalLinkSquareAlt,
	faHandReceiving,
	faGlobe,
	faList,
	faSearch as faSearchBold,
	faSlidersH,
	faSkull,
	faTimes,
	faTimesCircle,
	faThLarge,
	faWindowClose
} from '@fortawesome/pro-solid-svg-icons';
import { faFacebookF, faInstagram, faLinkedin, faPinterest, faTwitter, faYoutube, faLinkedinIn } from '@fortawesome/free-brands-svg-icons';

library.add(
	faAngleDown,
	faAngleUp,
	faArrowLeft,
	faArrowRight,
	faArrowRightReg,
	faArrowToBottom,
	faAt,
	faBars,
	faBell,
	faCaretDown,
	faCaretRight,
	faCalendar,
	faCheck,
	faCheckCircle,
	faCheckSquare,
	faCircle,
	faChevronDown,
	faChevronLeft,
	faChevronRight,
	faClipboardListCheck,
	faClock,
	faComments,
	faDotCircle,
	faDownload,
	faEllipsisV,
	faEnvelope,
	faExclamationTriangle,
	faExclamationCircle,
	faFileExcel,
	faExternalLinkAlt,
	faExternalLinkSquareAlt,
	faFileAlt,
	faGlobe,
	faUserHeadset,
	faHandReceiving,
	faHeadset,
	faInfoCircle,
	faMapMarkerAlt,
	faMinus,
	faFilePdf,
	faPhone,
	faPlus,
	faPrint,
	faFacebookF,
	faInstagram,
	faLinkedin,
	faLinkedinIn,
	faList,
	faPencilAlt,
	faPinterest,
	faSearch,
	faSearchBold,
	faShoppingCart,
	faSearchPlus,
	faSkull,
	faSquare,
	faStar,
	faTimes,
	faSlidersH,
	faTags,
	faThLarge,
	faTimesCircle,
	faTrashAlt,
	faTwitter,
	faUser,
	faUserChart,
	faVideo,
	faWindowClose,
	faFileWord,
	faYoutube
);

Vue.component('font-awesome-icon', FontAwesomeIcon);
