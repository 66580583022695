/**
 * SclCardIcon api
 *
 * textAlignment prop
 * @param {string} 'left'
 *
 * iconAlignment prop
 * @param {string} 'start'
 *
 * backgroundColor prop
 * @param {string} 'null'
 *
 * accent prop
 * @param {string} 'null'
 */

// template
const template = `
	<div :class="(propApi.accent != null ? 'card-accent card-accent--bottom card-accent__'+propApi.accent : null )">
		<v-card :ripple="false" class="scl-card-icon__card"  :color="propApi.backgroundColor" href="@" >
			<div class="scl-card-icon__image" :class="'justify-'+ propApi.iconAlignment">
				<v-img :src="$root.dummyImage43"  max-width="64" aspect-ratio="1"></v-img>
			</div>
			<div class="scl-card-icon__content" :class="propApi.textAlignment">
					<h4 class="scl-card-icon__title">{{ $root.dummyWord }}</h4>
					<div class="scl-card-icon__label">{{ $root.dummyWords }}</div>
					<div class="scl-card-icon__text">
						<p>{{ truncate($root.dummySentence) }}</p>
					</div>
			</div>
			<v-card-actions class="scl-card-icon__card-actions" :class="propApi.textAlignment">
				<v-btn :ripple="false" class="btn btn-link" text href="#">
					{{ $root.dummyWords }}
					<v-icon class="scl-card-icon__icon btn__icon-link" right>$vuetify.icons.faArrowRight</v-icon>
				</v-btn>
			</v-card-actions>
		</v-card>
	</div>
`;

// export template
export default template;
