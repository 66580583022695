/**
 * SclProductGallery
 */

// template
const template = `
	<div>
		<v-lazy>
			<div class="scl-product-gallery__container">
				<div class="scl-product-gallery__large">
					<v-img
						:alt="altText"
						aspect-ratio="1"
						class="scl-product-gallery__image scl-product-gallery__image--large"
						:lazy-src="$root.lazyImg"
						:src="activeImage"
						width="100%"
						@click.stop="dialog = true"
					>
					</v-img>
				</div>
				<div v-if="images.length > 1" class="scl-product-gallery__small">
					<v-img
						v-for="(image, index) in images.slice(0, 5)"
						:alt="altText"
						aspect-ratio="1"
						class="scl-product-gallery__image scl-product-gallery__image--small"
						:key="index"
						:lazy-src="$root.lazyImg"
						:src="getPath(image)"
						@click="setActiveImage(getPath(image), $event)"
					>
					</v-img>
				</div>
			</div>
		</v-lazy>
		<v-dialog
			content-class="scl-product-gallery__dialog"
			scrollable
			v-model="dialog"
		>
			<v-card>
				 <v-card-text class="scl-product-gallery__dialog-container">
					<v-img
						:alt="altText"
						aspect-ratio="1"
						class="scl-product-gallery__image scl-product-gallery__image--dialog"
						:lazy-src="$root.lazyImg"
						persistent
						:src="activeImage"
					>
					</v-img>
				</v-card-text>
				<v-card-actions>
					<span class="pr-4">{{ altText }}</span>
					<v-icon class="scl-product-gallery__dialog-close" @click="dialog = false">$vuetify.icons.faWindowClose</v-icon>
				</v-card-actions>
			</v-card>
		</v-dialog>
	</div>
`;

// export template
export default template;
