/**
 * SclCheckout
 */

// template
const template = `
	<div>
		<v-container>
			<v-row align="center" class="scl-checkout__title-row">
				<v-col cols="12" sm="7">
					<h1 class="scl-checkout__title">Checkout</h1>
				</v-col>
				<v-col cols="12" sm="5" lg="5">
					<scl-progress class="scl-component scl-progress" @emit-step-back="handlePreviousStep" :step="step"></scl-progress>
				</v-col>
			</v-row>
			<v-row>

				<!-- error message -->
				<v-col v-if="false" cols="12">
					<v-alert border="bottom" class="scl-alert__alert scl-alert--error" colored-border icon="$vuetify.icons.faExclamation" tile type="error">
						<p class="scl-alert__copy">{{ getError.message }}</p>
					</v-alert>
				</v-col>

				<template v-else>

					<!-- step 1 -->
					<v-col v-show="step === 1" class="py-0" cols="12" md="8">
						<v-row>
							<v-col cols="12" sm="6">
								<scl-login @emit-login-form="handleLoginForm" class="scl-component scl-login" :inputOptions="form.inputOptions" :inputRules="form.inputRules" :pattern="form.pattern"></scl-login>
								<v-btn @click="login" block class="btn btn-primary scl-checkout__form-btn my-5" depressed height="64" :disabled="!step1Valid" :ripple="false">
									<span>Login and checkout</span>
									<v-icon class="btn__icon-link" right>$vuetify.icons.faArrowRight</v-icon>
								</v-btn>
								<v-alert class="scl-alert__alert scl-alert--flat mb-0" icon="$vuetify.icons.faCheckCircle" tile>
									<p class="scl-alert__copy">Did you become a member online? If not, you will need to activate your account to login.</p>
								</v-alert>
								<v-alert class="scl-alert__alert scl-alert--flat" icon="$vuetify.icons.faHeadset" tile>
									<p class="scl-alert__copy">{{$root.dummySentence}}</p>
								</v-alert>
							</v-col>
							<v-col class="scl-checkout__col-offset" cols="12" sm="6">
								<scl-guest @emit-guest-checkout="handleGuestCheckout" class="scl-component scl-guest"></scl-guest>
							</v-col>
						</v-row>
					</v-col>

					<!-- step 2 -->
					<v-col v-show="step === 2" class="py-0" cols="12" md="8">
						<v-row>
							<v-col cols="12" sm="6">
								<scl-personal-details @emit-personal-form="handlePersonalForm" class="scl-component scl-checkout__form scl-personal-details" :formData="formData" :inputOptions="form.inputOptions" :inputRules="form.inputRules" :pattern="form.pattern"></scl-personal-details>
								<scl-billing-details @emit-billing-form="handleBillingForm" class="scl-component scl-checkout__form scl-billing-details" :formData="formData.billingDetails" :inputOptions="form.inputOptions" :inputRules="form.inputRules" :pattern="form.pattern"></scl-billing-details>
							</v-col>
							<v-col class="scl-checkout__col-offset" cols="12" sm="6">
								<scl-delivery-details @emit-delivery-form="handleDeliveryForm" class="scl-component scl-checkout__form scl-delivery-details" :formData="formData" :details="formData.billingDetails" :inputOptions="form.inputOptions" :inputRules="form.inputRules" :pattern="form.pattern"></scl-delivery-details>
								<scl-shipping-options class="scl-component scl-checkout__form scl-shipping-options" :shipping-options="shippingOptions" @emit-set-shipping-method="handleShippingOptions"></scl-shipping-options>
								<v-btn @click="submitShippingDetails" block class="btn btn-primary scl-checkout__form-btn" depressed height="64" :disabled="!step2Valid" :ripple="false">
									<span>Continue to payment</span>
									<v-icon class="btn__icon-link" right>$vuetify.icons.faArrowRight</v-icon>
								</v-btn>
							</v-col>
						</v-row>
					</v-col>

					<!-- step 3 -->
					<v-col v-show="step === 3" class="py-0" cols="12" md="8">
						<v-row>
							<v-col cols="12" sm="6">
								<scl-payment-details @emit-payment-form="handlePaymentForm" class="scl-component scl-payment-details" :inputOptions="form.inputOptions" :inputRules="form.inputRules" :pattern="form.pattern"></scl-payment-details>
								<v-btn @click="submitPayment" block class="btn btn-primary scl-checkout__form-btn mb-5" depressed height="64" :loading="getLoading" :disabled="!step3Valid || getLoading" :ripple="false">
									<span>Place order</span>
									<v-icon class="btn__icon-link" right>$vuetify.icons.faArrowRight</v-icon>
								</v-btn>
								<p>By hitting 'place order' you are agreeing to our <a href="/terms-and-conditions">Terms & Conditions</a>.</p>
							</v-col>
							<v-col class="scl-checkout__col-offset" cols="12" sm="6">
								<scl-shipping-details @emit-previous-step="handlePreviousStep" class="scl-component scl-shipping-details" :shipping="formData"></scl-shipping-details>
							</v-col>
						</v-row>
					</v-col>

					<!-- summary -->
					<v-col cols="12" md="4" lg="3" offset-lg="1">
						<scl-cart-order-summary :show-shipping="true" v-if="step >= 2" class="scl-component scl-cart-order-summary"></scl-cart-order-summary>
					</v-col>

				</template>

			</v-row>
		</v-container>
	</div>
`;

// export template
export default template;
