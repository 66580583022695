/**
 * SclCard api
 *
 * overlayTextColour prop
 * @param {string} 'white--text'
 * @param {string} 'black--text'
 *
 * variant prop
 * @param {string} 'dark'
 * @param {string} 'light'
 */

// template
const template = `
	<div :class="propApi.height === 'auto' ? 'scl-card-vertical__auto': 'scl-card-vertical__fill-height'">
		<v-card class="scl-card-vertical__card" :dark="propApi.variant === 'dark'" :light="propApi.variant === 'light'"  outlined href="#">
			<v-img
				:lazy-src="$root.dummyImage43"
				:src="$root.dummyImage43"
				aspect-ratio="2.0"
				:class="propApi.overlayTextColour"
			/>
			<div class="scl-card-vertical__content">
			<v-card-title>
				<div>
					<h3 class="scl-card-vertical__title headline mb-3">{{ $root.dummyWord }}</h3>
					<div class="scl-card-vertical__text">
						<p ref="description">{{ $root.dummyParagraph }}</p>
					</div>
				</div>
			</v-card-title>
			</div>
			<v-card-actions class="scl-card-vertical__card-actions">
				<v-btn :ripple="false" class="btn btn-link" text>
					{{ $root.dummyWords }}
					<v-icon class="scl-card-vertical__icon" right>$vuetify.icons.faArrowRight</v-icon>
				</v-btn>
			</v-card-actions>
		</v-card>
		<scl-cta class="scl-cta"></scl-cta>
		<scl-cta class="scl-cta"></scl-cta>


	</div>
`;

// export template
export default template;
