/**
 * SclMiniNav
 *
 */

const template = `
	<div>
		<v-list class="scl-mini-nav__list" v-if="$vuetify.breakpoint.mdAndUp">
			<v-list-item class="scl-mini-nav__items">
				<v-tooltip right content-class="scl-mini-nav__tooltip">
					<template v-slot:activator="scope">
						<a href="#" class="scl-mini-nav__item scl-mini-nav__item--active" v-on="scope.on" >
							<v-img :src="$root.dummyImage43"  max-height="36" max-width="36"></v-img>
						</a>
					</template>
					<span >Item 1 tooltip</span>
				</v-tooltip>
			</v-list-item>

			<v-list-item class="scl-mini-nav__items">
				<v-tooltip right content-class="scl-mini-nav__tooltip">
					<template v-slot:activator="scope">
						<a href="#" class="scl-mini-nav__item" v-on="scope.on" >
							<v-img :src="$root.dummyImage43"  max-height="36" max-width="36"></v-img>
						</a>
					</template>
					<span >Item 2 tooltip</span>
				</v-tooltip>
			</v-list-item>
		</v-list>

		<!-- Mobile nav -->
		<div v-else class="scl-mini-nav__mobile-nav">
			<div class="scl-mini-nav__mobile-nav__list">
				<!-- Horizontal nav items -->
				<div class="scl-mini-nav__mobile-nav__items">
					<a class="scl-mini-nav__mobile-nav__item scl-mini-nav__item--active">
						<v-img :src="$root.dummyImage43"  max-height="32" max-width="32"></v-img>
					</a>
				</div>
				<div class="scl-mini-nav__mobile-nav__items">
					<a class="scl-mini-nav__mobile-nav__item ">
						<v-img :src="$root.dummyImage43"  max-height="32" max-width="32"></v-img>
					</a>
				</div>
				<div class="scl-mini-nav__mobile-nav__items">
					<a class="scl-mini-nav__mobile-nav__item ">
						<v-img :src="$root.dummyImage43"  max-height="32" max-width="32"></v-img>
					</a>
				</div>
				<div class="scl-mini-nav__mobile-nav__items">
					<a class="scl-mini-nav__mobile-nav__item">
						<v-img :src="$root.dummyImage43"  max-height="32" max-width="32"></v-img>
					</a>
				</div>

				<!-- Show if list items are greater than 4 -->
				<div class="scl-mini-nav__mobile-nav__extend">
					<v-bottom-sheet v-model="sheet">
						<template v-slot:activator="scope">
							<div class="scl-mini-nav__mobile-nav__extend-icon">
								<v-img v-on="scope.on"
									contain
									:src="getImage"
									max-height="24"
									max-width="24">
								</v-img>
							</div>
						</template>
						<v-list class="scl-mini-nav__mobile-nav__extend-list">
							<div class="scl-mini-nav__mobile-nav__slider">
								<a @mousedown="slide" @touchmove.passive="slide"> </a>
							</div>
							<v-list-item class="scl-mini-nav__mobile-nav__extend-items ">
								<a class="scl-mini-nav__mobile-nav__extend-item scl-mini-nav__mobile-nav__extend-item--active">
									<v-img :src="$root.dummyImage43"  max-height="36" max-width="36"></v-img>
									<div class="scl-mini-nav__mobile-nav__extend-title"> {{ $root.dummyWords }}</div>
								</a>
							</v-list-item>
							<v-list-item class="scl-mini-nav__mobile-nav__extend-items">
								<a class="scl-mini-nav__mobile-nav__extend-item">
									<v-img :src="$root.dummyImage43"  max-height="36" max-width="36"></v-img>
									<div class="scl-mini-nav__mobile-nav__extend-title"> {{ $root.dummyWords }}</div>
								</a>
							</v-list-item>
							<v-list-item class="scl-mini-nav__mobile-nav__extend-items">
								<a class="scl-mini-nav__mobile-nav__extend-item">
									<v-img :src="$root.dummyImage43"  max-height="36" max-width="36"></v-img>
									<div class="scl-mini-nav__mobile-nav__extend-title"> {{ $root.dummyWords }}</div>
								</a>
							</v-list-item>
							<v-list-item class="scl-mini-nav__mobile-nav__extend-items">
								<a class="scl-mini-nav__mobile-nav__extend-item">
									<v-img :src="$root.dummyImage43"  max-height="36" max-width="36"></v-img>
									<div class="scl-mini-nav__mobile-nav__extend-title"> {{ $root.dummyWords }}</div>
								</a>
							</v-list-item>
							<v-list-item class="scl-mini-nav__mobile-nav__extend-items">
								<a class="scl-mini-nav__mobile-nav__extend-item">
									<v-img :src="$root.dummyImage43"  max-height="36" max-width="36"></v-img>
									<div class="scl-mini-nav__mobile-nav__extend-title"> {{ $root.dummyWords }}</div>
								</a>
							</v-list-item>
							<v-list-item class="scl-mini-nav__mobile-nav__extend-items">
								<a class="scl-mini-nav__mobile-nav__extend-item">
									<v-img :src="$root.dummyImage43"  max-height="36" max-width="36"></v-img>
									<div class="scl-mini-nav__mobile-nav__extend-title"> {{ $root.dummyWords }}</div>
								</a>
							</v-list-item>
							<v-list-item class="scl-mini-nav__mobile-nav__extend-items">
								<a class="scl-mini-nav__mobile-nav__extend-item">
									<v-img :src="$root.dummyImage43"  max-height="36" max-width="36"></v-img>
									<div class="scl-mini-nav__mobile-nav__extend-title"> {{ $root.dummyWords }}</div>
								</a>
							</v-list-item>
						</v-list>
					</v-bottom-sheet>
				</div>
			</div>
		</div>
	</div>
`;

export default template;
