/**
 * SclProductFacets
 */

// template
const template = `
	<div>
		<v-expansion-panels accordion class="scl-product-facets__accordions" flat multiple v-model="panel">
			<!-- panel -->
			<v-expansion-panel class="scl-product-facets__accordion" v-for="(facet, index) in facets" :key="index">
				<v-expansion-panel-header hide-actions  class="scl-product-facets__header">
					<div class="scl-product-facets__title">
						{{facet.displayName}}
					</div>
					<v-icon class="scl-product-facets__accordion-icon scl-product-facets__accordion-icon--minus">{{ propApi.expandedIcon }} </v-icon>
					<v-icon class="scl-product-facets__accordion-icon scl-product-facets__accordion-icon--plus">{{ propApi.collapsedIcon }}</v-icon>
				</v-expansion-panel-header>
				<v-expansion-panel-content class="scl-product-facets__content">
					<v-checkbox v-for="(facetValue, index) in facet.Values"
					ref="isChecked"
					v-model="facetValue.isSelected"
					@change="updateCategories(facet.name,facetValue.label,$event,facetValue)"
					class="scl-product-facets__check"
					:disabled="isDisabled"
					v-if="facetValue.quantity != '0'"
					:label="facetValue.label +'(' + facetValue.quantity + ')'"
					:key="index"
					hide-details></v-checkbox>
				</v-expansion-panel-content>
			</v-expansion-panel>

		</v-expansion-panels>
	</div>
`;

// export template
export default template;
