/**
 * SclCard api
 *
 * overlayTextColour prop
 * @param {string} 'white--text'
 * @param {string} 'black--text'
 *
 */

// template
const template = `
	<div>
		<v-row>
			<v-col class="pa-4">
				<v-card flat :ripple="false" href="#">
					<v-img
						:lazy-src="$root.dummyImage43"
						:src="$root.dummyImage43"
						aspect-ratio="1.33"
						:class="propApi.overlayTextColour"
					/>
				</v-card>
			</v-col>
		</v-row>
	</div>
`;

// export template
export default template;
