/**
 * SclCategoryList
 *
 */

// template
const template = `
	<div>
		<h4 class="scl-category-list__title">{{$root.dummyWords}}</h4>
		<v-card class="scl-category-list__card" outlined>
			<v-row :column="$vuetify.breakpoint.mdAndDown" class="scl-category-list__row">
				<v-col cols="12" class="scl-category-list__content" :class="propApi.showRightSide? 'col-md-6' : null ">
					<div>
						<v-card-title>
							<h3 class="scl-category-list__card-title headline mb-3">this website doesn't work properly without JavaScript.</h3>
							<div class="scl-category-list__text">
								<p>{{ $root.dummySentence}}</p>
							</div>
						</v-card-title>
						<v-card-actions class="scl-category-list__card-actions">
							<v-btn :ripple="false" class="btn btn-outlined" text href="#">
								<span v-if="$vuetify.breakpoint.smAndUp">
									{{ buttonText($root.dummyWords) }}
								</span>
								<span v-else>
									{{ propApi.defaultButtonText}}
								</span>
							</v-btn>
						</v-card-actions>
					</div>
				</v-col>
				<v-col cols="12" md="6" class="pt-0 pb-0 " v-if="(propApi.showRightSide) && (propApi.showRightSideOnMobile? true : $vuetify.breakpoint.smAndUp)" :class="propApi.showRightSide? 'scl-category-list__content--border' : null">
					<scl-latest-articles class="scl-component scl-latest-articles"></scl-latest-articles>
				</v-col>
			</v-row>
		</v-card>
	</div>
`;

// export template
export default template;
