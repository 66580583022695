/**
 * SclDatePicker api
 *
 * reactive prop
 * @param {boolean} true || false
 *
 * pickerIcon prop
 * @param {string} '$vuetify.icons.faCalendar'
 *
 * prevIcon prop
 * @param {string} '$vuetify.icons.faChevronLeft'
 *
 * nextIcon prop
 * @param {string} '$vuetify.icons.faChevronRight'
 *
 * */

const template = `
	<div>
		<v-row>
			<v-col cols="12">
				<v-menu
					ref="menu"
					v-model="menu"
					:close-on-content-click="false"
					transition="scale-transition"
					offset-y
					max-width="auto"
					min-width="auto"
				>
					<template v-slot:activator="scope">
						<v-text-field v-model="dateFormatted" :prepend-icon="propApi.pickerIcon" v-on="scope.on"></v-text-field>
					</template>
					<v-date-picker
						v-model="picker"
						:reactive="propApi.reactive"
						:first-day-of-week="1"
						:scrollable="true"
						:prev-icon="propApi.prevIcon"
						:next-icon="propApi.nextIcon"
						@input="subMenu = false"
					></v-date-picker>
				</v-menu>
			</v-col>
		</v-row>
	</div>
`;

export default template;
