// @vue/component

const component = {
	name: 'SclCategoryCarouselList',
	template: null
};

// set template if storybook environment variable string
if (process.env.VUE_APP_DEVELOPMENT === 'true') {
	component.template = require(`./${component.name}-template`).default;
}

// css import
require(`./_${component.name}.scss`);

export default component;
