// @vue/component
const component = {
	name: 'SclCarouselTestimonial',
	props: {
		api: {
			type: Object,
			required: false,
			// props type validation
			validator: (obj) => {
				const set = new Set();
				set.add(!!(obj.cycle ? typeof obj.cycle === 'boolean' : true));
				set.add(!!(obj.delimiterIcon ? typeof obj.delimiterIcon === 'string' : true));
				set.add(!!(obj.hideDelimiters ? typeof obj.hideDelimiters === 'boolean' : true));
				set.add(!!(obj.showArrows ? typeof obj.showArrows === 'boolean' : true));
				set.add(!!(obj.height ? typeof obj.height === 'string' : true));
				set.add(!!(obj.interval ? typeof obj.interval === 'number' : true));
				set.add(!!(obj.nextIcon ? typeof obj.nextIcon === 'string' : true));
				set.add(!!(obj.prevIcon ? typeof obj.prevIcon === 'string' : true));
				return !set.has(false);
			}
		}
	},
	data() {
		return {};
	},
	computed: {
		// props defaults
		propApi() {
			return {
				cycle: false,
				delimiterIcon: '$vuetify.icons.faCircle',
				hideDelimiters: false,
				showArrows: false,
				height: 'auto',
				interval: 7000,
				nextIcon: '$vuetify.icons.faChevronRight',
				prevIcon: '$vuetify.icons.faChevronLeft',
				...this.api
			};
		},
		carouselItemsLength() {
			return this.$refs.carouselTestimonialRef.items.length;
		}
	},
	mounted() {
		this.setNavigationVisibility();
	},
	methods: {
		setArrowVisibility() {
			if (this.api && this.api.showArrows === false) {
				return;
			}
			if (this.carouselItemsLength > 1) {
				this.propApi.showArrows = true;
			} else {
				this.propApi.showArrows = false;
			}
		},
		setDelimiterVisibility() {
			if (this.api && this.api.hideDelimiters === true) {
				return;
			}
			if (this.carouselItemsLength > 1) {
				this.propApi.hideDelimiters = false;
			} else {
				this.propApi.hideDelimiters = true;
			}
		},
		setNavigationVisibility() {
			// nexttick not working, still too soon
			const delay = (t) => new Promise((resolve) => setTimeout(resolve, t));
			delay(1000)
				.then(() => {
					this.setArrowVisibility();
					this.setDelimiterVisibility();
				})
				.then(() => {
					this.$forceUpdate();
				});
		}
	},
	template: null
};

// set template if storybook environment variable string
if (process.env.VUE_APP_DEVELOPMENT === 'true') {
	component.template = require(`./${component.name}-template`).default;
}

// css import
require(`./_${component.name}.scss`);

export default component;
