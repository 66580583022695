import { mapGetters } from 'vuex';
import SclAuthForgotPassword from './SclAuthForgotPassword';
import SclAuthLoginForm from './SclAuthLoginForm';
import SclAuthRegisterForm from './SclAuthRegisterForm';

// @vue/component
const component = {
	name: 'SclAuthLoginModal',
	components: {
		SclAuthForgotPassword,
		SclAuthLoginForm,
		SclAuthRegisterForm
	},
	props: {},
	data() {
		return {
			dialog: false,
			forgotPassword: false,
			form: {
				inputOptions: {
					backgroundColor: '#f4f4f4',
					class: 'scl-checkout__form-input',
					color: 'accent',
					height: 48,
					outlined: true
				},
				inputRules: {
					email: (value) => {
						return value === null || value === '' || this.form.pattern.email.test(value) || 'Invalid email address';
					},
					number: (value) => {
						return value === null || value === '' || this.form.pattern.number.test(value) || 'Enter a number, no spaces';
					},
					password: (value) => {
						return (
							value === null || value === '' || this.form.pattern.password.test(value) || 'Password must be minimum 6 and maximum 20 characters'
						);
					},
					phone: (value) => {
						return value === null || value === '' || this.form.pattern.phone.test(value) || 'Enter 10 digits';
					},
					required: (value) => !!value || 'This field is required'
				},
				pattern: {
					email: /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
					number: /^\d*$/,
					password: /^.{6,20}$/,
					phone: /^\d{10}$/
				}
			},
			resetSuccess: false,
			tabs: {
				tab1: true,
				tab2: false
			}
		};
	},
	computed: {
		...mapGetters(['getLoading'])
	},
	mounted() {
		this.$root.$on('emitLoginModal', () => {
			this.dialog = true;
		});
		this.$root.$on('emitCloseLoginModal', () => {
			this.dialog = false;
		});
	},
	methods: {
		changeTab() {
			this.tabs.tab1 = !this.tabs.tab1;
			this.tabs.tab2 = !this.tabs.tab2;
		},
		handleForgotPassword(val) {
			this.forgotPassword = val;
		},
		handleForgotPasswordResponse(val) {
			this.resetSuccess = val;
		},
		handleCloseModal() {
			this.dialog = false;
			this.forgotPassword = false;
			this.resetSuccess = false;
		}
	},
	template: null
};

// set template and dummy data if development environment variable string
if (process.env.VUE_APP_DEVELOPMENT === 'true') {
	component.template = require(`./${component.name}-template`).default;
}

// css import
require(`./_${component.name}.scss`);

// export component
export default component;
