/**
 * SclAlert api
 *
 * icon prop
 * @param {string} '$vuetify.icons.faInfo'
 * @param {string} '$vuetify.icons.faCheckSquare'
 *
 * type prop
 * @param {string} 'error'
 * @param {string} 'info'
 * @param {string} 'success'
 * @param {string} 'warning'
 *
 * dismissible prop
 * @param {boolean} true || false
 *
 * outline prop
 * @param {boolean} true || false
 */

// template
const template = `
	<div>
		<v-alert border="bottom" class="scl-alert__alert" :class="'scl-alert--' + propApi.type" colored-border :dismissible="propApi.dismissible" :outlined="propApi.outlined" :icon="propApi.icon" tile :type="propApi.type">
			<div>
				<h6 class="scl-alert__title">Lorem ipsum dolor</h6>
				<p class="scl-alert__copy">Lorem ipsum dolor sit amet, consectetur adipiscing <a href="#">elit</a></p>
			</div>
		</v-alert>
	</div>
`;

// export template
export default template;
