import { mapGetters } from 'vuex';

// @vue/component
const component = {
	name: 'SclCartPromo',
	data() {
		return {
			promoCode: null
		};
	},
	computed: {
		...mapGetters(['getLoading', 'getPromo']),
		hasPromo() {
			return !!this.getPromo.code;
		}
	},
	methods: {
		clearInput() {
			if (this.promoCode) {
				this.promoCode = null;
				this.emitPromoError(false);
			}
		},
		emitPromoCode() {
			if (this.promoCode) {
				this.$emit('emit-promo-code', this.promoCode);
			}
		},
		emitPromoError(bool) {
			this.$emit('emit-promo-error', bool);
		}
	},
	template: null
};

// set template and dummy data if development environment variable string
// if (process.env.VUE_APP_DEVELOPMENT === 'true') {
component.template = require(`./${component.name}-template`).default;
// }

// css import
require(`./_${component.name}.scss`);

// export component
export default component;
