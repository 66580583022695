// @vue/component
import { bus } from '../../main';

const component = {
	name: 'SclFacets',
	props: {
		api: {
			type: Object,
			// props type validation
			validator: (obj) => {
				const set = new Set();
				set.add(!!(obj.collapsedIcon ? typeof obj.collapsedIcon === 'string' : true));
				set.add(!!(obj.expandedIcon ? typeof obj.expandedIcon === 'string' : true));
				set.add(!!(obj.showDialog ? typeof obj.showDialog === 'boolean' : true));
				set.add(!!(obj.isLoading ? typeof obj.isLoading === 'boolean' : true));

				return !set.has(false);
			}
		}
	},
	data() {
		return {
			hidden: true,
			panel: [],
			facets: [],
			groupedFacets: [],
			isActive: false,
			showDialog: false,
			isLoading: false
		};
	},
	computed: {
		// props defaults
		propApi() {
			return {
				collapsedIcon: '$vuetify.icons.faPlus',
				expandedIcon: '$vuetify.icons.faMinus',
				...this.api
			};
		}
	},
	created() {
		bus.$on('emit-facets', (val, showDialog, isLoading) => {
			this.facets = val;
			this.showDialog = showDialog;
			this.isLoading = isLoading;
			this.IsVisible();
		});
		bus.$on('emit-facet-remove', (tag, selectedFacetes) => {
			this.handleFacetDeselection(tag, selectedFacetes);
		});
		bus.$on('emit-facets-open', (newVal) => {
			this.showDialog = newVal;
		});
	},
	watch: {
		// showDialog(newVal) {
		// 	bus.$emit('emit-facet-dialog', newVal);
		// }
	},
	methods: {
		updateCategories(DisplayName, FacetType, facetValue, e) {
			bus.$emit('emit-facet-change', DisplayName, FacetType, facetValue, e);
		},
		IsVisible() {
			this.groupedFacets = [];
			this.facets.forEach((facet) => {
				const items = Object.entries(facet.Values).filter((el) => el[1] > 0);
				if (items.length > 0) {
					const panelIndex = this.groupedFacets.length;
					this.groupedFacets.push(facet);
					this.panel.push(panelIndex);
				}
			});
		},
		facetLabel(val) {
			return `${val.label} (${val.quantity})`;
		},
		showMore(e) {
			const elem = e.target.parentElement;
			elem.classList.toggle('scl-facets__accordion--show');
		},
		filterFacetvalues(val) {
			return val.filter((facet) => facet.quantity !== 0);
		},
		handleClearAll() {
			bus.$emit('emit-facet-clear-all');
		}
	},
	template: null
};

// set template and dummy data if development environment variable string
if (process.env.VUE_APP_DEVELOPMENT === 'true') {
	component.template = require(`./${component.name}-template`).default;
}

// css import
require(`./_${component.name}.scss`);

// export component
export default component;
