const template = `
    <div>
        <v-btn :ripple="false" depressed class="btn btn-outlined scl-cta__button mt-4" block>
            {{ $root.dummySentence }}
            <v-icon class="btn__icon-link scl-cta__icon"> $vuetify.icons.faArrowRight </v-icon>
        </v-btn>
	</div>
`;

export default template;
