import axios from 'axios';
import '@/_scl-fed/config/axios';

export default {
	// Events
	//----------------------------------------------------

	async eventSearch(params) {
		const fixedParams = new URLSearchParams(params);
		if (fixedParams.has('pg')) {
			fixedParams.set('pg', parseInt(fixedParams.get('pg'), 10) - 1);
		}
		const response = await axios.get(`/events/search?${fixedParams.toString()}`);
		return response.data;
	}
};
