// @vue/component
const component = {
	name: 'SclTrainingFiltering',
	props: {
		isDisabled: {
			type: Boolean
		},
		regions: {
			type: Array
		},
		types: {
			type: Array
		},
		categories: {
			type: Array
		},
		qualifications: {
			type: Array
		},
		trainingTags: {
			type: Object
		}
	},
	data() {
		return {
			filtersActive: !this.$vuetify.breakpoint.xsOnly,
			icons: {
				filterClosed: '$vuetify.icons.faPlus',
				filterOpen: '$vuetify.icons.faMinus'
			},
			region: {
				active: false,
				label: 'Region',
				model: []
			},
			type: {
				active: false,
				label: 'Course type',
				model: []
			},
			category: {
				active: false,
				label: 'Course Category',
				model: []
			},
			qualification: {
				active: false,
				label: 'Qualifications',
				model: []
			}
		};
	},
	computed: {
		trainingFilter() {
			return {
				region: {
					model: this.region.model
				},
				type: {
					model: this.type.model
				},
				category: {
					model: this.category.model
				},
				qualification: {
					model: this.qualification.model
				}
			};
		}
	},
	watch: {
		'$vuetify.breakpoint.xsOnly': {
			immediate: true,
			handler(val) {
				this.filtersActive = !val;
			}
		},
		filtersActive: {
			immediate: true,
			handler(val) {
				this.$emit('input', val);
			}
		}
	},
	mounted() {
		this.setupMenuActiveWatchers();
	},
	destroyed() {
		this.unWatchRegionFilter();
		this.unWatchCategoryFilter();
		this.unWatchTypeFilter();
		this.unWatchQualificationFilter();
	},
	methods: {
		clearFilters() {
			this.region.model = [];
			this.type.model = [];
			this.category.model = [];
			this.qualification.model = [];
			this.updateFilters();
		},
		setFilters() {
			console.log('set filters');
		},
		updateFilters() {
			this.$emit('emit-event-facet-change', this.trainingFilter);
		},
		handleTagRemove(tag) {
			if (tag.value.includes('region')) {
				this.region.model = this.region.model.filter((x) => !x.includes(tag.label));
			}
			if (tag.value.includes('type')) {
				this.type.model = this.type.model.filter((x) => !x.includes(tag.label));
			}
			if (tag.value.includes('coursecategory')) {
				this.category.model = this.category.model.filter((x) => !x.includes(tag.label));
			}
			if (tag.value.includes('qualificationtype')) {
				this.qualification.model = this.qualification.model.filter((x) => !x.includes(tag.label));
			}
			this.$emit('emit-event-tag-remove', tag.value, this.trainingFilter);
		},
		setupMenuActiveWatchers() {
			this.unWatchRegionFilter = this.$watch(
				() => this.$refs['region-filter'].isMenuActive,
				(val) => {
					this.region.active = val;
				}
			);
			this.unWatchCategoryFilter = this.$watch(
				() => this.$refs['category-filter'].isMenuActive,
				(val) => {
					this.category.active = val;
				}
			);
			this.unWatchTypeFilter = this.$watch(
				() => this.$refs['type-filter'].isMenuActive,
				(val) => {
					this.type.active = val;
				}
			);
			this.unWatchQualificationFilter = this.$watch(
				() => this.$refs['qualification-filter'].isMenuActive,
				(val) => {
					this.qualification.active = val;
				}
			);
		}
	},
	template: null
};

// set template and dummy data if development environment variable string
// if (process.env.VUE_APP_DEVELOPMENT === 'true') {
component.template = require(`./${component.name}-template`).default;
// }

// css import
require(`./_${component.name}.scss`);

// export component
export default component;
