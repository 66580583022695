/**
 * SclProductCollectionSummary api
 *
 * @param {boolean} variant - Changes the layout of the component
 * @param {string} url - Enquiry URL
 * @param {number} characterNumber - Controls truncation
 */

// template
const template = `
    <div>
        <div v-if="!propApi.variant">
            <div v-show="!showDescription" v-html="truncatedText()">
               
            </div>

            <v-expand-transition >
            <div v-show="showDescription">
                {{$root.dummyParagraph}}
            </div>
            </v-expand-transition>

            <div v-if="descriptionLength > propApi.characterNumber" class="mt-4 scl-product-collection-summary__readmore">
                <a v-if="!showDescription"  @click="handleReadMore">Read more <v-icon right> $vuetify.icons.faAngleDown</v-icon></a>
                <a v-else  @click="handleReadMore">Show less  <v-icon right> $vuetify.icons.faAngleUp</v-icon></a>
            </div>
            <div class="scl-product-collection-summary__image">
                <v-img :src="$root.dummyImage43" :aspect-ratio="16/9" max-height="240"></v-img>
            </div>
        </div>
        <v-row v-else>
            <v-col cols="12" md="8">              
                <div v-show="!showDescription" v-html="truncatedText">
                </div>
                <v-expand-transition >
                <div v-show="showDescription">
                    {{$root.dummyParagraph}}
                </div>
                </v-expand-transition>
                <div v-if="descriptionLength > propApi.characterNumber" class="mt-4 scl-product-collection-summary__readmore">
                    <a v-if="!showDescription"  @click="handleReadMore">Read more <v-icon right> $vuetify.icons.faAngleDown</v-icon></a>
                    <a v-else  @click="handleReadMore">Show less  <v-icon right> $vuetify.icons.faAngleUp</v-icon></a>
                </div>
            </v-col>
            <v-col cols="12" md="4">              
                <div class="scl-product-collection-summary__image" :class="propApi.variant ? 'my-0': ''">
                    <v-img :src="$root.dummyImage43" :aspect-ratio="4/3" max-height="250"></v-img>
                </div>        
            </v-col>
        </v-row>
        <div>
            <v-btn class="btn btn-outlined btn--large mr-4">
              <v-icon left>$vuetify.icons.faEnvelope</v-icon>  {{ $root.dummyWords }}
            </v-btn>
            <v-btn class="btn btn-outlined btn--large">
              <v-icon left>$vuetify.icons.faFilePDF</v-icon> {{ $root.dummyWords }}        
            </v-btn>
        </div>        
    </div>
`;

// export template
export default template;
