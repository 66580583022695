// @vue/component
const component = {
	name: 'SclPaymentSummary',
	props: {
		orderInformation: {
			type: Object,
			required: false
		}
	},
	data() {
		return {};
	},
	computed: {},
	created() {},
	mounted() {
		if (this.orderInformation) {
			this.submitAnalytics();
		}
	},
	methods: {
		submitAnalytics() {
			if (this.$gtmEnabled) {
				this.$gtmEventBus.$emit(
					'gtm-custom-event',
					this.$gtm,
					{
						event: 'Purchase',
						ecommerce: {
							currencyCode: 'AUD',
							purchase: {
								actionField: {
									id: this.orderInformation.OrderId,
									revenue: this.orderInformation.OrderTotalAmount,
									shipping: this.orderInformation.ShippingTotalAmount
								},
								products: this.orderInformation.LineItems
							}
						}
					},
					this.$route
				);
			}
		}
	},
	template: null
};

// set template and dummy data if development environment variable string
if (process.env.VUE_APP_DEVELOPMENT === 'true') {
	component.template = require(`./${component.name}-template`).default;
}

// css import
require(`./_${component.name}.scss`);

// export component
export default component;
