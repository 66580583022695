/**
 * SclAwardCategoriesList api
 *
 * */

// template
const template = `
	<div>
		<div class="scl-award-categories-list__header">
		<h2>{{$root.dummyWords}}</h2>
		<p>{{$root.dummySentence}}</p>
		</div>
		<div class="scl-award-categories-list__sort">
			<v-row>
				<v-col cols="12" md="4">
					<div class="scl-award-categories-list__sort-header">
						<div class="scl-award-categories-list__sort-title">
							Type
						</div>
						<v-select
						v-model="defaultSelected"
						:items="getTypes"
						item-text="value"
						item-value="key"
						solo
						hide-details
						placeholder="All"
						class="scl-award-categories-list__sort-select"
						@change="handleSelect"
						>
						</v-select>
					</div>
				</v-col>
			</v-row>
		</div>
		<div>
			<v-card class="scl-award-categories-list__item" href="#">
				<div class="scl-award-categories-list__content">
					<h4 class="scl-award-categories-list__item-title"> {{ $root.dummyWords }} </h4>
					<div>
						<v-btn :ripple="false" class="btn btn--x-small btn-outlined scl-award-categories-list__btn">
							<v-img :src="$root.dummyImage43"
							max-height="16"
							max-width="16"
							class="mr-3">
							</v-img>
							with a really super duper long title so it breaks
						</v-btn>
					</div>
					<div class="scl-award-categories-list__item-text">
						<p>{{$root.dummyParagraph}}</p>
					</div>
				</div>
				<div class="scl-award-categories-list__link">
						<a  href="#" class="btn btn-link">Find out more <v-icon class="btn__icon-link scl-award-categories-list__icon">$vuetify.icons.faArrowRight </v-icon></a>
				</div>
			</v-card>
			<v-card class="scl-award-categories-list__item" href="#">
				<div class="scl-award-categories-list__content">
					<h4 class="scl-award-categories-list__item-title"> {{ $root.dummyWords }} </h4>
					<div>
						<v-btn :ripple="false" class="btn btn--x-small btn-outlined">
							<v-img :src="$root.dummyImage43"
							max-height="16"
							max-width="16"
							class="mr-3">
							</v-img>
							{{ $root.dummyWords }}
						</v-btn>
					</div>
					<div class="scl-award-categories-list__item-text">
						<p>{{$root.dummyParagraph}}</p>
					</div>
				</div>
				<div class="scl-award-categories-list__link">
						<a  href="#" class="btn btn-link">Find out more <v-icon class="btn__icon-link scl-award-categories-list__icon">$vuetify.icons.faArrowRight </v-icon></a>
				</div>
			</v-card>
		</div>
	</div>
`;

// export template
export default template;
