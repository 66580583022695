// @vue/component
const component = {
	name: 'SclPagination',
	props: {
		currentPage: null,
		isDisabled: {
			type: Boolean
		},
		totalPages: null,
		visiblePages: null
	},
	data() {
		return {
			page: 1
		};
	},
	watch: {
		currentPage: {
			immediate: true,
			handler(val) {
				this.page = val;
			}
		}
	},
	template: null
};

// set template and dummy data if development environment variable string
if (process.env.VUE_APP_DEVELOPMENT === 'true') {
	component.template = require(`./${component.name}-template`).default;
}

// css import
require(`./_${component.name}.scss`);

export default component;
