/**
 * SclCartSummary
 */

// template
const template = `
	<div>
		<v-container>
			<v-row>
				<!-- error message -->
				<v-col v-if="getError.hasErrored" cols="12">
					<v-alert border="bottom" class="scl-alert__alert scl-alert--error" colored-border icon="$vuetify.icons.faExclamation" tile type="error">
						<p class="scl-alert__copy">{{ getError.message }}</p>
					</v-alert>
				</v-col>
				<template v-else>
					<!-- cart -->
					<v-col class="scl-cart-summary__items-container" cols="12" lg="9">
						<scl-cart-line-items @emit-quantity="handleQuantity" @emit-remove-item="handleRemoveItem" class="scl-component scl-cart-line-items"></scl-cart-line-items>
						<scl-cart-promo @emit-promo-code="handlePromoCode" @emit-promo-error="handlePromoError" class="scl-component scl-cart-promo"></scl-cart-promo>
					</v-col>
					<!-- summary -->
					<v-col cols="12" lg="3">
						<scl-cart-order-summary class="scl-component scl-cart-order-summary"></scl-cart-order-summary>
						<v-btn v-if="!getAuth" class="scl-cart-summary__proceed btn btn-primary" :ripple="false" @click="emitLogin" depressed height="64" large>
							Proceed to checkout
							<v-icon class="btn__icon-link" color="#fff" size="16">$vuetify.icons.faArrowRight</v-icon>
						</v-btn>
						<v-btn v-else class="scl-cart-summary__proceed btn btn-primary" href="#" :ripple="false"  depressed height="64" large>
							Proceed to checkout
							<v-icon class="btn__icon-link" color="#fff" size="16">$vuetify.icons.faArrowRight</v-icon>
						</v-btn>
					</v-col>
				</template>
			</v-row>
			<v-row>
				<v-col cols="12">
					you might also like section
				</v-col>
			</v-row>
		</v-container>
	</div>
`;

// export template
export default template;
