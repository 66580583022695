import Vue from 'vue';

// https://github.com/georgechang/vue-scexpeditor
const SitecoreExpEditorPlugin = {
	// eslint-disable-next-line
	install(Vue, options) {
		const reinitialiseComponents = function (chrome) {
			// grab the names of the currently registered components in vue
			const componentNames = Object.keys(Vue.options.components);

			// the setTimeout() is a bit ugly but is necessary to prevent an async race condition with a jQuery animation
			setTimeout(() => {
				// check all of the renderings in this placeholder as they are all reset
				Array.prototype.forEach.call(chrome.element, (element) => {
					// Check if the element is an uninstatiated component
					let elementNameToMatch = element.nodeName.toLowerCase();
					if (componentNames.includes(elementNameToMatch)) {
						// If here the tag and name matched so create a new instance of the component
						new Vue.options.components[elementNameToMatch]({
							el: element
						});
					} else if (
						// When using an edit frame the component is a child element so check for an edit frame
						element.nodeName === 'DIV' &&
						element.getAttribute('class') === 'scLooseFrameZone' &&
						element.getAttribute('sc-part-of') === 'placeholder rendering editframe'
					) {
						Array.prototype.forEach.call(element.childNodes, (childElement) => {
							elementNameToMatch = childElement.nodeName.toLowerCase();
							if (componentNames.includes(elementNameToMatch)) {
								// If here the tag and name matched so create a new instance of the component
								new Vue.options.components[elementNameToMatch]({
									el: element
								});
							}
						});
					}
				});

				// reset the chrome in Experience Editor after the Vue rendering pipeline is complete
				Sitecore.PageModes.ChromeManager.resetChromes();
			}, 500);
		};

		if (typeof Sitecore !== typeof undefined) {
			// extend the Sitecore placeholder chrome type
			Sitecore.PageModes.ChromeTypes.Placeholder = Sitecore.PageModes.ChromeTypes.Placeholder.extend(
				{
					insertRendering(data, openProperties) {
						this.base(data, openProperties);
						reinitialiseComponents(this.chrome);
					},
					_doUpdateRenderingProperties(renderingChrome, html) {
						this.base(renderingChrome, html);
						reinitialiseComponents(this.chrome);
					}
				},
				{
					emptyLookFillerCssClass: Sitecore.PageModes.ChromeTypes.Placeholder.emptyLookFillerCssClass,
					getDefaultAjaxOptions: Sitecore.PageModes.ChromeTypes.Placeholder.getDefaultAjaxOptions
				}
			);
		}

		Vue.mixin({
			data() {
				return {
					isSitecoreEE: false
				};
			},
			beforeCreate() {
				if (typeof Sitecore !== 'undefined') {
					document.querySelectorAll('code[type="text/sitecore"]').forEach((el) => el.setAttribute('v-pre', ''));
					this.isSitecoreEE = true;
				}
			}
		});
	}
};

Vue.use(SitecoreExpEditorPlugin);

export default SitecoreExpEditorPlugin;
