/**
 * SclPersonalDetails
 */

// template
const template = `
	<div>
		<h3 class="scl-checkout__form-title"><span>Personal details</span></h3>
		<!-- title -->
		<label class="scl-checkout__form-label"> {{ formData.personal.title.label }}</label>
		<v-select
			v-model="formData.personal.title.value"
			@change="checkValidity"
			append-icon="$vuetify.icon.faChevronDown"
			:background-color="inputOptions.backgroundColor"
			class="is-narrow"
			:class="inputOptions.class"
			:color="inputOptions.color"
			:height="inputOptions.height"
			:items="titleItems"
			:outlined="inputOptions.outlined"
			placeholder="Title"
			:rules="[inputRules.required]"
		>
		</v-select>
		<!-- first name -->
		<label class="scl-checkout__form-label"> {{ formData.personal.firstName.label}}</label>
		<v-text-field
			v-model="formData.personal.firstName.value"
			@input="checkValidity"
			@change="checkValidity"
			autocomplete="given-name"
			autocorrect="off"
			:background-color="inputOptions.backgroundColor"
			:class="inputOptions.class"
			:color="inputOptions.color"
			:height="inputOptions.height"
			:outlined="inputOptions.outlined"
			placeholder="John"
			:rules="[inputRules.required]"
		>
		</v-text-field>
		<!-- last name -->
		<label class="scl-checkout__form-label"> {{ formData.personal.lastName.label}} </label>
		<v-text-field
			v-model="formData.personal.lastName.value"
			@input="checkValidity"
			@change="checkValidity"
			autocomplete="family-name"
			autocorrect="off"
			:background-color="inputOptions.backgroundColor"
			:class="inputOptions.class"
			:color="inputOptions.color"
			:height="inputOptions.height"
			:outlined="inputOptions.outlined"
			placeholder="Smith"
			:rules="[inputRules.required]"
		>
		</v-text-field>
		<!-- phone -->
		<label class="scl-checkout__form-label is-optional"> {{ formData.contact.phone.label}} </label>
		<v-text-field
			v-model="formData.contact.phone.value"
			@input="checkValidity"
			@change="checkValidity"
			autocomplete="tel"
			autocorrect="off"
			:background-color="inputOptions.backgroundColor"
			:class="inputOptions.class"
			:color="inputOptions.color"
			:height="inputOptions.height"
			inputmode="tel"
			maxlength="10"
			minlength="10"
			:outlined="inputOptions.outlined"
			pattern="[0-9]*"
			placeholder="0200000000"
			:rules="[inputRules.number, inputRules.phone]"
			type="tel"
		>
		</v-text-field>
		<!-- mobile -->
		<label class="scl-checkout__form-label"> {{ formData.contact.mobile.label}} </label>
		<v-text-field
			v-model="formData.contact.mobile.value"
			@input="checkValidity"
			@change="checkValidity"
			autocomplete="tel"
			autocorrect="off"
			:background-color="inputOptions.backgroundColor"
			:class="inputOptions.class"
			:color="inputOptions.color"
			:height="inputOptions.height"
			inputmode="tel"
			maxlength="10"
			minlength="10"
			:outlined="inputOptions.outlined"
			pattern="[0-9]*"
			placeholder="0400000000"
			:rules="[inputRules.required, inputRules.number, inputRules.phone]"
			type="tel"
		>
		</v-text-field>
		<!-- email -->
		<label class="scl-checkout__form-label"> {{ formData.contact.email.label}} </label>
		<v-text-field
			v-model="formData.contact.email.value"
			@input="checkValidity"
			@change="checkValidity"
			autocapitalize="off"
			autocomplete="email"
			autocorrect="off"
			:background-color="inputOptions.backgroundColor"
			:class="inputOptions.class"
			:color="inputOptions.color"
			:height="inputOptions.height"
			inputmode="email"
			:outlined="inputOptions.outlined"
			placeholder="name@domain.com"
			:rules="[inputRules.required, inputRules.email]"
			type="email"
		>
		</v-text-field>
	</div>
`;

// export template
export default template;
