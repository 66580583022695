/**
 * SclResourceItem
 *
 */

// template
const template = `
	<div>
		<v-list-item class="scl-article-list-item__item">
			<v-row>
				<v-col md="8" cols="12">
					<div class="scl-article-list-item__col">
						<a v-if="document != null" :href="document" target="_blank" class="scl-article-list-item__title-link"><v-icon class="scl-article-list-item__title-icon"> $vuetify.icons.faFilePDF </v-icon><h4 class="scl-article-list-item__title headline">{{ title }}</h4></a>
						<h4 v-else class="scl-article-list-item__title headline">{{ title }}</h4>
						<div class="scl-article-list-item__actions">
						<div class="scl-article-list-item__actions-date">
						{{ dateFormat(date) }}
						</div>
						<div v-if="!memberLoggedIn && propApi.showMemberDetail" class="scl-article-list-item__actions-member">
							<v-icon>$vuetify.icons.faMapMarker</v-icon>{{$root.dummyword}}
						</div>
						<div v-if="propApi.showArticleType" class="scl-article-list-item__actions-type">
							<v-img v-if="iconUrl != null" class="scl-article-list-item__icon" width="24" :src="icon"></v-img> {{ articleType }}
						</div>
						<div v-if="featured" class="scl-article-list-item__actions-featured">
							<div class="btn btn--x-small btn-outlined">
								<v-icon>$vuetify.icons.faStar</v-icon>
								Featured
							</div>
						</div>
						</div>
						<div class="scl-article-list-item__content">
							<p>{{ truncatedDescription }}</p>
						</div>
					</div>
				</v-col>
				<v-col md="4" cols="12" align-self="end" class="scl-article-list-item__link">
					<a v-if="document != null" :href="document" class="btn btn-link" target="_blank">Go to {{ articleType }}<v-img v-if="iconUrl != null" class="scl-article-list-item__icon" width="24" :src="icon"></v-img> <v-icon v-else class="btn__icon-link scl-article-list-item__icon"> $vuetify.icons.faArrowRight </v-icon></a>
					<a v-else :href="url" class="btn btn-link">Go to {{ articleType }}
						<v-img v-if="iconUrl != null" class="scl-article-list-item__icon" width="24" :src="icon"></v-img>
						<v-icon v-else class="btn__icon-link scl-article-list-item__icon"> $vuetify.icons.faArrowRight</v-icon>
					</a>
				</v-col>
			</v-row>
		</v-list-item>
	</div>
`;

// export template
export default template;
