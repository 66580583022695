// @vue/component
const component = {
	name: 'SclRelatedLinks',
	methods: {
		setIcon(href) {
			const link = new URL(href, window.location);
			if (window.location.host !== link.host) {
				return '$vuetify.icons.faExternalLink';
			}
			return '$vuetify.icons.faArrowRight';
		}
	},
	template: null
};

// set template and dummy data if development environment variable string
if (process.env.VUE_APP_DEVELOPMENT === 'true') {
	component.template = require(`./${component.name}-template`).default;
}

// css import
require(`./_${component.name}.scss`);

// export component
export default component;
