import { gmapApi } from 'gmap-vue';

// @vue/component

const component = {
	name: 'SclStoreLocator',
	props: {
		vertical: {
			type: Boolean
		},
		redirectUrl: {
			type: String
		},
		fullHeight: {
			type: Boolean
		},
		countryRestrictions: {
			type: String
		}
	},
	data() {
		return {
			latitude: '',
			longitude: ''
		};
	},
	destroyed() {
		this.removeListener();
	},
	async mounted() {
		await this.$gmapApiPromiseLazy();
		this.initLookup();
	},
	computed: {
		google: gmapApi
	},
	methods: {
		addListener() {
			window.addEventListener('keyup', this.handleKeyup);
		},
		removeListener() {
			window.removeEventListener('keyup', this.handleKeyup);
		},
		initLookup() {
			this.$nextTick(() => {
				const self = this;
				const locationInput = this.$refs.locationRef.$refs.input;
				const options = {
					types: ['postal_code', 'locality'],
					componentRestrictions: { country: `${this.countryRestrictions}` }
				};
				const locationAutocomplete = new this.google.maps.places.Autocomplete(locationInput, options);
				locationAutocomplete.addListener('place_changed', () => {
					const place = locationAutocomplete.getPlace();
					self.latitude = place.geometry.location.lat();
					self.longitude = place.geometry.location.lng();
				});
			});
			this.addListener();
		},
		handleKeyup(event) {
			event.preventDefault();
			if (event.code === 'Enter') {
				this.handleFindStore();
			}
		},
		handleFindStore() {
			if (this.latitude && this.longitude) {
				window.location.href = `${this.redirectUrl}?lat=${this.latitude}&lng=${this.longitude}`;
			}
		}
	},
	template: null
};

// set template and dummy data if development environment variable string
if (process.env.VUE_APP_DEVELOPMENT === 'true') {
	component.template = require(`./${component.name}-template`).default;
}

// css import
require(`./_${component.name}.scss`);

// export component
export default component;
