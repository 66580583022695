// @vue/component
const component = {
	name: 'SclEventsSearch',
	props: {
		isDisabled: {
			type: Boolean
		},
		param: {
			type: String,
			default: null
		}
	},
	data() {
		return {
			search: this.param
		};
	},
	watch: {
		param() {
			this.search = this.param;
		}
	},
	methods: {
		emitSearch() {
			const searchTerms = this.search.replace(/\s+/g, '');
			if (searchTerms === null || searchTerms.length < 1) {
				return;
			}
			this.$emit('emit-search', this.search);
		},
		clearSearch() {
			this.search = null;
			this.$emit('emit-search', this.search);
		}
	},
	template: null
};

// set template and dummy data if development environment variable string
// if (process.env.VUE_APP_DEVELOPMENT === 'true') {
component.template = require(`./${component.name}-template`).default;
// }

// css import
require(`./_${component.name}.scss`);

// export component
export default component;
